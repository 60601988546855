import { createSlice } from "@reduxjs/toolkit";
import { AUTH_SAVE } from "../../../helpers/config";
import { LoginInitialState } from "../../../types/redux";
import {
  createChangePasswordAsync,
  forgotPasswordRetailerAsync,
  getLogoutAsync,
  getRetailerLogoutAsync,
  loginUserAsync,
  retailerChangePasswordAsync,
  retailerUpdatePasswordAsync,
} from "./loginAPI";

const initialState: LoginInitialState<any> = {
  loading: false,
  updateLoading: false,
  token: "",
  login: null,
  siteRole: null,
  notificationToken: "",
  signOutLoading: false,
};

const loginSlice = createSlice({
  name: "admin-login",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.login = action.payload?.data;
      state.token = action.payload?.token;
      state.siteRole = action.payload?.siteRole;
    },
    userLogout: (state) => {
      localStorage.removeItem(`${AUTH_SAVE}`);
      state.login = null;
      state.token = "";
      state.siteRole = null;
    },
    setNotificationToken: (state, action) => {
      state.notificationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.login = action.payload.data;
        state.siteRole = action.payload.siteRole;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        localStorage.removeItem(`${AUTH_SAVE}`);
        state.loading = false;
        state.siteRole = null;
      });

    // get all admin logout
    builder
      .addCase(getLogoutAsync.pending, (state) => {
        state.signOutLoading = true;
      })
      .addCase(getLogoutAsync.fulfilled, (state, action) => {
        state.signOutLoading = false;
        // state.bankList = action.payload.data;
        // state.countBank = action.payload.length;
      })
      .addCase(getLogoutAsync.rejected, (state) => {
        state.signOutLoading = false;
        // state.bankList = [];
        // state.countBank = 0;
      });

    // get all retailer logout
    builder
      .addCase(getRetailerLogoutAsync.pending, (state) => {
        state.signOutLoading = true;
      })
      .addCase(getRetailerLogoutAsync.fulfilled, (state, action) => {
        state.signOutLoading = false;
        // state.bankList = action.payload.data;
        // state.countBank = action.payload.length;
      })
      .addCase(getRetailerLogoutAsync.rejected, (state) => {
        state.signOutLoading = false;
        // state.bankList = [];
        // state.countBank = 0;
      });

    // change password

    builder
      .addCase(createChangePasswordAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(createChangePasswordAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(createChangePasswordAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // retailer change password
    builder
      .addCase(retailerChangePasswordAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(retailerChangePasswordAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(retailerChangePasswordAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // forgot password retailer
    builder
      .addCase(forgotPasswordRetailerAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(forgotPasswordRetailerAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(forgotPasswordRetailerAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // update password retailer
    builder
      .addCase(retailerUpdatePasswordAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(retailerUpdatePasswordAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(retailerUpdatePasswordAsync.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export const { userLoggedIn, userLogout, setNotificationToken } =
  loginSlice.actions;
export default loginSlice;
