import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteRetailerProductAsync,
  getAllRetailerProductAsync,
} from "../../../store/features/retailerProduct/retailerProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import BsrmGreenCheck from "../../../assets/icons/BsrmGreenCheck";
import BsrmStockOut from "../../../assets/icons/BsrmStockOut";
import { getAllBrandAsync } from "../../../store/features/Brand/BrandApi";
import { getAllProductCategoryAsync } from "../../../store/features/productCategory/productCategoryAPI";
import { TableData } from "../../../types/redux";
import {
  GetAllRetailerProductParams,
  GetRetailerProductData,
} from "../../../types/redux/ADMIN/retailerProduct";
import CreateProductForm from "./CreateProductForm";

export default function AllProduct() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const { open, toggle } = useModal();
  const [selectCategory, setSelectCategory] = useState("");
  const [selectBrand, setSelectBrand] = useState("");
  const [selectStatus, setSelectStatus] = useState<"Active" | "Inactive" | "">(
    "Active"
  );

  const { loading, countRetailerProduct, retailerProduct, deleteLoading } =
    useAppSelector((state) => state.retailerProduct);
  const { brand } = useAppSelector((state) => state.brand);
  const { productCategory } = useAppSelector((state) => state.productCategory);

  const columns: ColumnsType<TableData<GetRetailerProductData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            className="rounded"
            width={70}
            height={70}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val?.image}`}
            fallback={placeholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Product Title",
      align: "left",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.name}</p>
          <p className="data_table_sku_data">{val?.sku}</p>
        </Space>
      ),
    },

    {
      title: "Category",
      align: "left",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <div className="flex items-center gap-1">
            <BsrmDOT />
            {<h5 className="">{val?.categoryId?.name}</h5>}
          </div>
        </Space>
      ),
    },

    {
      title: "Brand",
      align: "left",
      render: (val) => val?.brandId?.name,
    },

    {
      title: "Total Unit",
      align: "left",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.stockQuantity} {val?.unit}
          </p>
          <p className="text-[#FF3838] flex items-center">
            {typeof val?.stockQuantity === "number" &&
            val?.stockQuantity <= 0 ? (
              <>
                <BsrmStockOut /> Sold Out
              </>
            ) : (
              <>
                <BsrmGreenCheck />
                In Stock
              </>
            )}
          </p>
        </Space>
      ),
    },

    {
      title: "Price",
      align: "left",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.regularPrice} BDT</p>
          <span className="data_table_price_discount_data">
            {val?.discountPercentage}% Off
          </span>
        </Space>
      ),
    },

    {
      title: "Status",
      align: "center",
      // dataIndex: "status",
      render: (val: GetAllRetailerProductParams) => (
        <span
          className={`status ${
            val?.status === "Active" ? "status-done" : "status-danger"
          }`}
        >
          {val?.status}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetRetailerProductData) => (
        <div className="flex justify-center gap-2">
          <Link
            to={`/retailer/manage-products/all-products/details/${val?._id}`}
          >
            <Button className="bg-white py-[6px]" icon={<BsrmEyeIcon />} />
          </Link>

          <Link
            to={`/retailer/manage-products/pending-products/update-products/${val?._id}`}
          >
            <Button className="bg-white py-[6px]" icon={<BsrmEdit />} />
          </Link>

          {/* <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          /> */}
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteRetailerProductAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllRetailerProductAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus || "Active",
          brandId: selectBrand,
          categoryId: selectCategory,
          retailerRequestStatus: "Approved",
        },
      })
    );
  }, [dispatch, limit, page, selectBrand, selectCategory, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  // dispatch category data
  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: "true",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  const allStatus = [
    { label: "Active", value: "Active" },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];

  return (
    <AdminLayout
      title="Products"
      headerTitle="All Product"
      className="dashboard"
    >
      <section className="">
        <CustomTable<TableData<GetRetailerProductData>>
          loading={loading}
          total={countRetailerProduct}
          page={page}
          limit={limit}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(retailerProduct)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Category"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(productCategory, "name", "_id")}
                onChange={setSelectCategory}
              />
              <Select
                placeholder="Filter By Brand"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(brand, "name", "_id")}
                onChange={setSelectBrand}
              />
              <Select
                className="filter_select_width"
                placeholder="Status"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
                value={selectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <CreateProductForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          questionText="Are you sure to delete product ?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
