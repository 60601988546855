export default function BsrmMagnifine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.3324 11.3328L14.7496 14.7501M13.0625 7.15625C13.0625 10.4182 10.4182 13.0625 7.15625 13.0625C3.89432 13.0625 1.25 10.4182 1.25 7.15625C1.25 3.89432 3.89432 1.25 7.15625 1.25C10.4182 1.25 13.0625 3.89432 13.0625 7.15625Z"
        stroke="#3D4A5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
