export default function ShiftIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#E9EFF7" />
      <path
        d="M6.90803 6.30001C6.6601 6.31848 6.42825 6.43429 6.25959 6.6239C6.09093 6.81352 5.99812 7.0627 6.00003 7.32082V12.4612H12V7.32082C12.0019 7.0627 11.9091 6.81352 11.7405 6.6239C11.5718 6.43429 11.34 6.31848 11.092 6.30001C9.69881 6.20664 8.30125 6.20664 6.90803 6.30001ZM16.919 9.05713C16.8622 8.84082 16.7382 8.65006 16.5662 8.51448C16.3942 8.3789 16.1839 8.30609 15.968 8.30736H14C13.7348 8.30736 13.4805 8.41677 13.2929 8.61152C13.1054 8.80627 13 9.0704 13 9.34582V15.8549C13.3041 15.6726 13.6489 15.5766 14 15.5766C14.3511 15.5766 14.696 15.6726 15 15.8549C15.304 16.0372 15.5565 16.2993 15.7321 16.6151C15.9076 16.9308 16 17.2889 16 17.6535H17C17.131 17.6547 17.2608 17.6285 17.3818 17.5765C17.5028 17.5245 17.6125 17.4478 17.7043 17.3509C17.7962 17.2541 17.8684 17.139 17.9166 17.0126C17.9649 16.8862 17.9881 16.751 17.985 16.6151C17.9107 14.0616 17.553 11.5255 16.919 9.05713Z"
        fill="#8897AE"
      />
      <path
        d="M15 17.6534C15 17.9288 14.8946 18.1929 14.7071 18.3877C14.5196 18.5824 14.2652 18.6918 14 18.6918C13.7348 18.6918 13.4804 18.5824 13.2929 18.3877C13.1054 18.1929 13 17.9288 13 17.6534C13 17.3779 13.1054 17.1138 13.2929 16.9191C13.4804 16.7243 13.7348 16.6149 14 16.6149C14.2652 16.6149 14.5196 16.7243 14.7071 16.9191C14.8946 17.1138 15 17.3779 15 17.6534ZM6 16.6149V13.4995H12V16.6149C12 16.8903 11.8946 17.1545 11.7071 17.3492C11.5196 17.5439 11.2652 17.6534 11 17.6534C11 17.1025 10.7893 16.5743 10.4142 16.1848C10.0391 15.7953 9.53043 15.5764 9 15.5764C8.46957 15.5764 7.96086 15.7953 7.58579 16.1848C7.21071 16.5743 7 17.1025 7 17.6534C6.73478 17.6534 6.48043 17.5439 6.29289 17.3492C6.10536 17.1545 6 16.8903 6 16.6149Z"
        fill="#8897AE"
      />
      <path
        d="M10 17.6532C10 17.9286 9.89464 18.1928 9.70711 18.3875C9.51957 18.5823 9.26522 18.6917 9 18.6917C8.73478 18.6917 8.48043 18.5823 8.29289 18.3875C8.10536 18.1928 8 17.9286 8 17.6532C8 17.3778 8.10536 17.1137 8.29289 16.9189C8.48043 16.7242 8.73478 16.6147 9 16.6147C9.26522 16.6147 9.51957 16.7242 9.70711 16.9189C9.89464 17.1137 10 17.3778 10 17.6532Z"
        fill="#8897AE"
      />
    </svg>
  );
}
