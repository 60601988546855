import { Divider, Image } from "antd";
import { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BsrmBackArrow from "../../../assets/icons/BsrmBackArrow";
import BsrmDoubleTikMark from "../../../assets/icons/BsrmDoubleTikMark";
import BsrmReceive from "../../../assets/icons/BsrmReceive";
import BsrmWriteSideArrow from "../../../assets/icons/BsrmWriteSideArrow";
import BsrmgreenMarkOutline from "../../../assets/icons/BsrmgreenMarkOutline";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import OrderDetailsHistory from "../../../components/ModalForm/OrderDetailsHistory";
import OrderDetailsHistoryModal from "../../../components/ModalForm/OrderDetailsHistoryModal";
import DeleteModal from "../../../components/common/DeleteModal";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import useModal from "../../../hooks/useModal";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  cancelProductAsync,
  getSingleOrderProductAsync,
} from "../../../store/features/Order/orderProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { formatDate } from "../../../utils/time";
import BsrmTAKAicon from "./../../../assets/icons/BsrmTAKAicon";

export default function RetailerOrderDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const { singleRetailerOrderProduct } = useAppSelector(
    (state) => state.orderProduct
  );
  console.log("singleRetailerOrderProduct", singleRetailerOrderProduct);
  const pathArray = stringToArray(location?.pathname, "/");
  const findPath = pathArray?.slice(0, pathArray?.length - 2)?.join("/");
  const getData = useCallback(
    () =>
      dispatch(
        getSingleOrderProductAsync({
          params: {
            id: orderId as string,
          },
        })
      ),
    [dispatch, orderId]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCancel = (data: any) => {
    dispatch(
      cancelProductAsync({
        data: {
          status: "Cancel",
          details: "Cancel",
          orderId: data?.orderId,
          orderDetailsId: data?._id,
          numberOfItem: singleRetailerOrderProduct?.orderDetails?.length || 0,
        },
        others: { reRender },
      })
    );
  };

  function reRender(val: boolean) {
    if (val) {
      navigate(`/${findPath}`);
      toggle();
      getData();
    }
  }

  function reRenderUpdate(val?: boolean) {
    if (val) {
      toggle();
      getData();
    }
  }

  return (
    <AdminLayout
      title="Product Details"
      headerTitle={
        <Link to={`/${findPath}`} className="flex items-center gap-5">
          <div className="w-10 h-10 border border-solid border-[#D7DFF9] rounded-full flex justify-center items-center ">
            <BsrmBackArrow />
          </div>
          <h3 className="text-body text-[24px]">Order Details</h3>
        </Link>
      }
      className="dashboard"
    >
      <section className="flex gap-4">
        <div className="w-[344px] h-full  bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
          <div className="bg-accent h-[5rem] rounded-t-[14px] p-4 relative">
            <Image
              className="absolute top-0 start-0 "
              width={90}
              height={90}
              preview={false}
              src={`${IMAGE_URL}${singleRetailerOrderProduct?.order?.customerId?.image}`}
              alt={APP_NAME}
              fallback={placeholderImage}
              style={{ border: "1px solid white", borderRadius: "100%" }}
            />
          </div>

          <div className="p-4 rounded-[14px] ">
            <div className="mt-[18px]">
              <h3 className="text-[18px] leading-[30px] font-semibold text-[#3D4A5C]">
                {singleRetailerOrderProduct?.order?.customerId?.name}
              </h3>
              <p className="text-[16px] leading-7 font-medium text-[#5E718D]">
                {singleRetailerOrderProduct?.order?.customerId?.phoneNumber} |
                {singleRetailerOrderProduct?.order?.customerId?.email}
              </p>
              <p className="text-[16px] leading-7 font-medium text-[#5E718D]">
                {singleRetailerOrderProduct?.order?.districtId?.name}{" "}
                {singleRetailerOrderProduct?.order?.divisionId?.name}
              </p>
            </div>

            <div className="my-4">
              {singleRetailerOrderProduct?.order?.status === "Cancel" ? (
                <div className="flex items-center ">
                  <BsrmgreenMarkOutline color="#bf2307" />
                  <span className="text-[#bf2307] font-medium text-[16px] leading-7 ms-1">
                    Cancel
                  </span>
                </div>
              ) : singleRetailerOrderProduct?.order?.status === "Delivered" ? (
                <div className="flex items-center ">
                  <BsrmgreenMarkOutline />
                  <span className="text-[#07BFA5] font-medium text-[16px] leading-7 ms-1">
                    Delivered
                  </span>
                </div>
              ) : (
                <div className="flex items-center text-status-pending-text">
                  <BsrmgreenMarkOutline color="currentColor" />
                  <span className="font-medium text-[16px] leading-7 ms-1">
                    {singleRetailerOrderProduct?.order?.status}
                  </span>
                </div>
              )}

              <p className="text-[14px] font-medium leading-6">
                Products was ordered on
              </p>
              <p className="text-[16px] font-medium leading-7">
                {formatDate(singleRetailerOrderProduct?.order?.createdAt)}
              </p>
            </div>

            <div className="my-4">
              <div className="flex items-center ">
                <BsrmReceive />
                <span className="text-[#FC8E28] font-medium text-[16px] leading-7 ms-1">
                  {singleRetailerOrderProduct?.order?.customerId?.name}
                </span>
              </div>

              <p className="text-[14px] font-medium leading-6">
                {singleRetailerOrderProduct?.order?.deliveryAddress}
              </p>
              <p className="text-[14px] font-medium leading-6 text-[#8897AE]">
                {singleRetailerOrderProduct?.order?.customerId?.phoneNumber}
              </p>
            </div>
          </div>
        </div>

        <div
          className="flex-auto bg-white rounded-[10px] p-6"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <div className="">
            <p className="text-[20px] font-semibold leading-8 text-body">
              Order Details
            </p>
          </div>

          <div
            className="bg-white mt-6 p-2.5 rounded-[10px] space-y-1"
            style={{ border: "1px solid #E9EFF6" }}
          >
            <p className="text-[14px] font-medium leading-6">
              {singleRetailerOrderProduct?.order?.retailerId?.organizationName}
            </p>
            <hr className="hr" />
            <div className="">
              <p className="text-[#4A72FF] text-[14px] leading-6">
                Order Id: {singleRetailerOrderProduct?.order?.invoiceNo}
              </p>
              <p className="text-[14px] leading-6 text-[#5E718D]">
                To track the delivery of your order
              </p>
            </div>
            <Divider />
            <div className=" flex justify-between items-center">
              <div className="flex gap-x-3">
                <p className="text-[14px] font-medium leading-6 -tracking-[0.2]">
                  {formatDate(singleRetailerOrderProduct?.order?.date)}
                </p>
                {singleRetailerOrderProduct?.order?.paymentStatus ===
                "processing" ? (
                  <p className="text-[14px] font-medium leading-6 text-[#FF574D] ">
                    Processing
                  </p>
                ) : (
                  <p className="text-[14px] font-medium leading-6 text-[#11A75C]">
                    Paid
                  </p>
                )}
              </div>

              <button
                className={`rounded-3xl bg-white px-4 py-1 flex items-center cursor-pointer border border-solid ${
                  singleRetailerOrderProduct?.order?.status === "Pending"
                    ? "text-[#d8a800] border-[#d8a800]"
                    : singleRetailerOrderProduct?.order?.status === "Cancel"
                    ? "text-[#FF574D] border-[#FF574D] cursor-default"
                    : singleRetailerOrderProduct?.order?.status ===
                      "Ready to Shipped"
                    ? "text-[#1b4dff] border-[#1b4dff] cursor-default"
                    : singleRetailerOrderProduct?.order?.status === "Shipped"
                    ? "text-[#fc8e28] border-[#fc8e28] cursor-default"
                    : singleRetailerOrderProduct?.order?.status === "Delivered"
                    ? "text-[#07BFA5] border-[#07BFA5] cursor-default"
                    : singleRetailerOrderProduct?.order?.status ===
                      "Partially Processed"
                    ? "text-[#4A72FF] border-[#4A72FF] cursor-default"
                    : ""
                }`}
                onClick={() =>
                  handleOpen({
                    type: "VIEW",
                    data: singleRetailerOrderProduct,
                  })
                }
                disabled={
                  singleRetailerOrderProduct?.order?.status === "Cancel"
                }
              >
                {singleRetailerOrderProduct?.order?.status}

                {singleRetailerOrderProduct?.order?.status === "Pending" ? (
                  <BsrmWriteSideArrow color="#d8a800" />
                ) : singleRetailerOrderProduct?.order?.status === "Cancel" ? (
                  <BsrmWriteSideArrow color="#FF574D" />
                ) : singleRetailerOrderProduct?.order?.status ===
                  "Ready to Shipped" ? (
                  <BsrmWriteSideArrow color="#1b4dff" />
                ) : singleRetailerOrderProduct?.order?.status === "Shipped" ? (
                  <BsrmWriteSideArrow color="#fc8e28" />
                ) : singleRetailerOrderProduct?.order?.status ===
                  "Delivered" ? (
                  <BsrmWriteSideArrow color="#07BFA5" />
                ) : singleRetailerOrderProduct?.order?.status ===
                  "Partially Processed" ? (
                  <BsrmWriteSideArrow color="#4A72FF" />
                ) : (
                  ""
                )}
              </button>
            </div>
            <Divider />

            {singleRetailerOrderProduct?.orderDetails?.map((val) => (
              <div className="flex justify-between items-center">
                <div className="flex gap-x-[10px]">
                  <Image
                    className="rounded-[10px]"
                    width={100}
                    height={80}
                    preview={false}
                    src={`${IMAGE_URL}${val?.productId?.image}`}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                  />
                  <div className="">
                    <h5 className="text-others-deep text-[16px] font-semibold leading-7">
                      {val?.productId?.name}
                    </h5>

                    {/* 
                    <div className="text-[16px] pr-1.5 font-semibold leading-7 text-body ">
                      <span className="line-through text-[#8897AE]">
                        {val?.unitPrice} <BsrmTAKAicon color="#3d4a5c" />
                      </span>
                      <span className="ml-1.5 text-[#4A72FF]">
                        {val?.unitPrice - val?.discountAmount}
                        <BsrmTAKAicon color="#4A72FF" />
                      </span>
                    </div> */}

                    <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      {val?.discountAmount > 0 ? (
                        <>
                          <span className="line-through font-semibold   text-[#8897AE] ">
                            {val?.unitPrice} <BsrmTAKAicon color="#8897AE" />
                          </span>
                          <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                            {val?.unitPrice -
                              val?.discountAmount / val?.quantity}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      ) : (
                        <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                          {val?.unitPrice}
                          <BsrmTAKAicon color="#4A72FF" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex gap-x-2.5 mb-1">
                    {val?.status !== "Delivered" ? (
                      <button
                        className="border-none bg-[#FF574D] px-2 rounded-sm font-medium leading-5 text-[12px] text-white cursor-pointer disabled:cursor-default disabled:bg-red-200"
                        onClick={() =>
                          handleOpen({
                            type: "CANCEL",
                            data: val,
                          })
                        }
                        disabled={val?.status === "Cancel"}
                      >
                        Cancel
                      </button>
                    ) : null}
                    {val?.status !== "Cancel" ? (
                      <button
                        className="border-none bg-[#07BFA5] px-2 rounded-sm font-medium leading-5 text-[12px] text-white cursor-pointer"
                        onClick={() =>
                          handleOpen({
                            type: "SHIPPED",
                            data: val,
                          })
                        }
                      >
                        {val?.status === "Delivered"
                          ? "View Status"
                          : "Status Update"}
                      </button>
                    ) : null}
                  </div>

                  {val?.status === "Pending" ? (
                    <p className="text-[16px] text-[#d8a800] font-medium leading-5 ">
                      <BsrmDoubleTikMark color="#d8a800" />
                      <span className="ml-1">{val?.status}</span>
                    </p>
                  ) : val?.status === "Cancel" ? (
                    <p className="text-[16px] text-[#FF574D] font-medium leading-5">
                      <BsrmDoubleTikMark color="#FF574D" />
                      <span className="ml-1">{val?.status}</span>
                    </p>
                  ) : val?.status === "Ready to Shipped" ? (
                    <p className="text-[12px] text-[#1b4dff] font-medium leading-5">
                      <BsrmDoubleTikMark color="#1b4dff" />
                      <span className="ml-1">{val?.status}</span>
                    </p>
                  ) : val?.status === "Shipped" ? (
                    <p className="text-[16px] text-others-rating font-medium leading-5">
                      <BsrmDoubleTikMark color=" #fc8e28" />
                      <span className="ml-1">{val?.status}</span>
                    </p>
                  ) : val?.status === "Delivered" ? (
                    <p className="text-[16px] text-[#11A75C] font-medium leading-5">
                      <BsrmDoubleTikMark color="#11A75C" />
                      <span className="ml-1">{val?.status}</span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-[12px] font-medium leading-5 cursor-pointer">
                  <table className="border-separate border-spacing-2 ">
                    <tbody className="text-[12px] font-normal leading-5 text-[#5E718D]">
                      <tr>
                        <td className="flex justify-end pr-3">
                          Total Amount :
                        </td>
                        <td className="">{val?.totalAmount}</td>
                      </tr>
                      <tr>
                        <td className="flex justify-end pr-3">Discount :</td>
                        <td className="">{val?.discountAmount}</td>
                      </tr>
                      <tr>
                        <td className=" flex justify-end pr-3">Net Amount :</td>
                        <td className="">{val?.netAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}

            <Divider />

            <div className="flex justify-end ">
              <table className="border-separate border-spacing-2 ">
                <tbody className="text-[14px] font-medium leading-6 text-[#5E718D]">
                  <tr>
                    <td className="flex justify-end pr-3">
                      {singleRetailerOrderProduct?.orderDetails?.length} Item.
                      Total Amount :
                    </td>
                    <td className="">
                      {singleRetailerOrderProduct?.order?.totalAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="flex justify-end pr-3">Discount :</td>
                    <td className="">
                      {singleRetailerOrderProduct?.order?.discountAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className=" flex justify-end pr-3">Net Amount :</td>
                    <td className="">
                      {singleRetailerOrderProduct?.order?.netAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {open.type === "VIEW" && (
        <OrderDetailsHistory
          title="Order Details"
          data={singleRetailerOrderProduct}
          open={open.open}
          handleClose={toggle}
          // reRender={reRender}
        />
      )}

      {/* Modal */}
      {open.type === "SHIPPED" && (
        <OrderDetailsHistoryModal
          title="Order Status"
          data={singleRetailerOrderProduct}
          open={open.open}
          detailsData={open?.data}
          handleClose={toggle}
          reRender={reRenderUpdate}
        />
      )}

      {open.type === "CANCEL" && (
        <DeleteModal
          title="Cancel Order"
          questionText="Are you sure to cancel this order?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleCancel={handleCancel}
          deleteLoading={false}
        />
      )}
    </AdminLayout>
  );
}
