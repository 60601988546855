import { Divider, Image } from "antd";
import { Fragment, Key, ReactNode } from "react";
import BsrmDoubleTikMark from "../../../assets/icons/BsrmDoubleTikMark";
import BsrmSecondIcon from "../../../assets/icons/BsrmSecondIcon";
import BsrmTAKAicon from "../../../assets/icons/BsrmTAKAicon";
import BsrmWriteSideArrow from "../../../assets/icons/BsrmWriteSideArrow";
import placeHolderImage from "../../../assets/images/placeholder-image.png";
import CustomerOrderDetails from "../../../components/ModalForm/CustomerOrderDetails";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import useModal from "../../../hooks/useModal";
import { formatDate } from "../../../utils/time";
export default function CustomerManageOrder({ Data }: any) {
  const { open, toggle, handleOpen } = useModal();
  return (
    <>
      <div
        className="flex-auto w-[712px] rounded-[14px]  bg-white p-6 "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="rounded-t-[14px] flex justify-between">
          <p className="text-[20px] font-semibold leading-8 text-body">
            Order Details
          </p>

          <div className="flex gap-x-2 items-center">
            <p>To Receive</p>
            <span className="text-[#159383] bg-[#DBF6EE] rounded-[100px] px-3 py-1 flex items-center cursor-pointer">
              All Orders
            </span>
          </div>
        </div>

        {Data?.ordersData?.map(
          (val: {
            orderDetails: any;
            discountAmount: ReactNode;
            netAmount: any;
            totalAmount: any;
            status: string;
            date: any;
            paymentStatus: ReactNode;
            products: any;
            invoiceNo: ReactNode;
            retailer: any;
            _id: Key | null | undefined;
          }) => (
            <div
              key={val?._id}
              className="bg-white mt-6 p-2.5 rounded-[10px] space-y-1"
              style={{ border: "1px solid #E9EFF6" }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="px-2.5 rounded-[3px] "
                  style={{ border: "0.5px solid #FC8415" }}
                >
                  <BsrmSecondIcon />
                </span>
                <p className="text-[14px] font-medium leading-6">
                  {val?.retailer?.organizationName}
                </p>
              </div>

              <div className="">
                <p className="text-[#4A72FF] text-[14px] font-medium leading-6">
                  Order Id: {val?.invoiceNo}
                </p>
                <p className="text-[14px] font-normal leading-6 text-[#5E718D]">
                  To track the delivery of your order
                </p>
              </div>
              <Divider />
              <div className=" flex justify-between items-center">
                <p className="text-[14px] leading-6 text-[#455468]">
                  {formatDate(val?.date)}
                </p>
                <button
                  className={`rounded-3xl bg-white px-4 py-1 flex items-center cursor-pointer border border-solid ${
                    val?.status === "Pending"
                      ? "text-[#d8a800] border-[#d8a800]"
                      : val?.status === "Cancel"
                      ? "text-[#FF574D] border-[#FF574D] cursor-default"
                      : val?.status === "Ready to Shipped"
                      ? "text-[#1b4dff] border-[#1b4dff] cursor-default"
                      : val?.status === "Shipped"
                      ? "text-[#fc8e28] border-[#fc8e28] cursor-default"
                      : val?.status === "Delivered"
                      ? "text-[#07BFA5] border-[#07BFA5] cursor-default"
                      : val?.status === "Partially Processed"
                      ? "text-[#4A72FF] border-[#4A72FF] cursor-default"
                      : ""
                  }`}
                  onClick={() =>
                    handleOpen({
                      type: "VIEW",
                      data: val,
                    })
                  }
                  disabled={val?.status === "Cancel"}
                >
                  {val?.status}

                  {val?.status === "Pending" ? (
                    <BsrmWriteSideArrow color="#d8a800" />
                  ) : val?.status === "Cancel" ? (
                    <BsrmWriteSideArrow color="#FF574D" />
                  ) : val?.status === "Ready to Shipped" ? (
                    <BsrmWriteSideArrow color="#1b4dff" />
                  ) : val?.status === "Shipped" ? (
                    <BsrmWriteSideArrow color="#fc8e28" />
                  ) : val?.status === "Delivered" ? (
                    <BsrmWriteSideArrow color="#07BFA5" />
                  ) : val?.status === "Partially Processed" ? (
                    <BsrmWriteSideArrow color="#4A72FF" />
                  ) : (
                    ""
                  )}
                </button>
              </div>
              <Divider />

              {val?.products?.map(
                (productVal: {
                  status: ReactNode;
                  paymentStatus: ReactNode;
                  netAmount: ReactNode;
                  discountAmount: number;
                  totalAmount: ReactNode;
                  productId: any;
                  unitPrice: number;
                  quantity: number;
                  _id: Key | null | undefined;
                }) => (
                  <Fragment key={productVal?._id}>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-x-[10px]">
                        <Image
                          className="rounded-[10px]"
                          width={100}
                          height={80}
                          preview={false}
                          src={`${IMAGE_URL}${productVal?.productId?.image}`}
                          alt={APP_NAME}
                          fallback={placeHolderImage}
                        />
                        <div className="">
                          <h5 className="text-others-deep text-[16px] font-semibold leading-7 ">
                            {productVal?.productId?.name}
                          </h5>
                          <span className="mb-2 block text-body text-xs font-medium">
                            SKU: {productVal?.productId?.sku}
                          </span>

                          <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                            {productVal?.discountAmount > 0 ? (
                              <>
                                <span className="line-through font-semibold   text-[#8897AE]">
                                  {productVal?.unitPrice?.toLocaleString(
                                    "en-IN"
                                  )}
                                  <BsrmTAKAicon color="#3d4a5c" />
                                </span>
                                <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                                  {productVal?.discountAmount > 0
                                    ? (
                                        productVal?.unitPrice -
                                        productVal?.discountAmount /
                                          productVal?.quantity
                                      )?.toLocaleString("en-IN")
                                    : productVal?.unitPrice}
                                  <BsrmTAKAicon color="#4A72FF" />
                                </span>
                              </>
                            ) : (
                              <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                                {productVal?.netAmount?.toLocaleString("en-IN")}{" "}
                                <BsrmTAKAicon color="#4A72FF" />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {productVal?.status === "Pending" ? (
                        <p className="text-[16px] text-[#d8a800] font-medium leading-5 ">
                          <BsrmDoubleTikMark color="#d8a800" />
                          <span className="ml-1">{val?.status}</span>
                        </p>
                      ) : productVal?.status === "Cancel" ? (
                        <p className="text-[16px] text-[#FF574D] font-medium leading-5">
                          <BsrmDoubleTikMark color="#FF574D" />
                          <span className="ml-1">{val?.status}</span>
                        </p>
                      ) : productVal?.status === "Ready to Shipped" ? (
                        <p className="text-[16px] text-[#1b4dff] font-medium leading-5">
                          <BsrmDoubleTikMark color="#1b4dff" />
                          <span className="ml-1">{val?.status}</span>
                        </p>
                      ) : productVal?.status === "Shipped" ? (
                        <p className="text-[16px] text-others-rating font-medium leading-5">
                          <BsrmDoubleTikMark color=" #fc8e28" />
                          <span className="ml-1">{val?.status}</span>
                        </p>
                      ) : productVal?.status === "Delivered" ? (
                        <p className="text-[16px] text-[#11A75C] font-medium leading-5">
                          <BsrmDoubleTikMark color="#11A75C" />
                          <span className="ml-1">{val?.status}</span>
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="text-[12px] font-medium leading-5 cursor-pointer">
                        <table className="border-separate border-spacing-2 ">
                          <tbody className="text-[12px] font-normal leading-5 text-[#5E718D]">
                            <tr>
                              <td className="flex justify-end pr-3">
                                Amount :
                              </td>
                              <td className="">{productVal?.totalAmount}</td>
                            </tr>
                            <tr>
                              <td className="flex justify-end pr-3">
                                Discount :
                              </td>
                              <td className="">{productVal?.discountAmount}</td>
                            </tr>
                            <tr>
                              <td className=" flex justify-end pr-3">
                                Net Amount :
                              </td>
                              <td className="">{productVal?.netAmount}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Divider />
                  </Fragment>
                )
              )}

              <div className="flex justify-end ">
                <table className="border-separate border-spacing-2 ">
                  <tbody className="text-[14px] font-medium leading-6 text-[#5E718D]">
                    <tr>
                      <td className="flex justify-end">
                        {val?.products?.length} Item Total Amount :
                      </td>
                      <td className="">
                        {val?.totalAmount?.toLocaleString("en-IN")}{" "}
                        <BsrmTAKAicon color="#5E718D" />
                      </td>
                    </tr>
                    <tr>
                      <td className="flex justify-end">Discount :</td>
                      <td className="">
                        {val?.discountAmount?.toLocaleString("en-IN")}{" "}
                        <BsrmTAKAicon color="#5E718D" />
                      </td>
                    </tr>
                    <tr>
                      <td className=" flex justify-end">Net Amount :</td>
                      <td className="">
                        {val?.netAmount?.toLocaleString("en-IN")}{" "}
                        <BsrmTAKAicon color="#5E718D" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        )}
      </div>

      {/* Modal */}
      {open.type === "VIEW" && (
        <CustomerOrderDetails
          title="Order Details"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          // reRender={reRender}
        />
      )}
    </>
  );
}
