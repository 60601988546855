import { Image } from "antd";
import { ReactNode } from "react";
import BsrmReceive from "../../assets/icons/BsrmReceive";
import BsrmTAKAicon from "../../assets/icons/BsrmTAKAicon";
import BsrmgreenMarkOutline from "../../assets/icons/BsrmgreenMarkOutline";
import placeholderImage from "../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import DeleveryStatusTable from "../../pages/retailers/ManageOrder/DeleveryStatusTable";
import { CustomModalProps } from "../../types";
import { formatDate } from "../../utils/time";
import CustomModal from "../common/CustomModal";

type Props = {
  children?: ReactNode;
  detailsData?: any;
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function OrderDetailsHistoryModal({
  data,
  detailsData,
  children,
  reRender,
  ...rest
}: Props) {
  return (
    <CustomModal width={670} {...rest}>
      <div className="p-6 space-y-2.5">
        <div className="grid grid-cols-2 gap-2.5">
          <div className="">
            {data?.order?.status === "Cancel" ? (
              <div className="flex items-center ">
                <BsrmgreenMarkOutline color="#bf2307" />
                <span className="text-[#bf2307] font-medium text-[16px] leading-7 ms-1">
                  Cancel
                </span>
              </div>
            ) : data?.order?.status === "Delivered" ? (
              <div className="flex items-center ">
                <BsrmgreenMarkOutline />
                <span className="text-[#07BFA5] font-medium text-[16px] leading-7 ms-1">
                  Delivered
                </span>
              </div>
            ) : (
              <div className="flex items-center text-status-pending-text">
                <BsrmgreenMarkOutline color="currentColor" />
                <span className="font-medium text-[16px] leading-7 ms-1">
                  {data?.order?.status}
                </span>
              </div>
            )}
            <p className="text-[14px] font-medium leading-6">
              Products was ordered on
            </p>
            <p className="text-[16px] font-medium leading-7">
              {formatDate(data?.order?.createdAt)}
            </p>
          </div>

          <div className="">
            <div className="flex items-center ">
              <BsrmReceive />
              <span className="text-[#FC8E28] font-medium text-[16px] leading-7 ms-1">
                {data?.order?.customerId?.name}
              </span>
            </div>

            <p className="text-[14px] font-medium leading-6">
              {data?.order?.deliveryAddress}
            </p>
            <p className="text-[16px] font-medium leading-7">
              {data?.order?.customerId?.phoneNumber}
            </p>
          </div>
        </div>

        <div
          className="rounded-[10px] p-2.5"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <p className="text-[14px] font-medium leading-6">
            {data?.order?.customerId?.organizationName}
          </p>
          <hr className="hr" />

          <div className="">
            <p className="text-blue-500 text-[14px] font-medium leading-6">
              Order Id: {data?.order?.invoiceNo}
            </p>
            <p className="text-[14px] font-medium leading-6">
              To track the delivery of your order
            </p>
          </div>
        </div>

        <div
          className="bg-white mt-6 p-2.5 rounded-[10px] space-y-1"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <p className="text-[14px] font-medium leading-6">
            Nirvana Enterprise ltd
          </p>
          <hr className="hr" />

          {data?.orderDetails?.map(
            (val: {
              discountAmount: ReactNode | null | undefined;
              unitPrice: ReactNode | null | undefined;
              productId: {
                name: ReactNode;
                image: string | undefined;
              };
            }) => (
              <div className="flex justify-between ">
                <div className="flex gap-x-[10px]">
                  <Image
                    className="rounded-[10px]"
                    width={100}
                    height={80}
                    preview={false}
                    src={`${IMAGE_URL}${val?.productId?.image}`}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                  />
                  <div className="">
                    <h5 className="text-others-deep text-[20px] font-semibold leading-8 mb-2.5">
                      {val?.productId?.name}
                    </h5>

                    {/* <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      <span className="line-through ml-5 font-semibold   text-body ">
                        {val?.unitPrice} <BsrmTAKAicon color="#3d4a5c" />
                      </span>
                      <span className="ml-11 font-semibold   text-[#4A72FF]">
                        {Number(val?.unitPrice ?? 0) -
                          Number(val?.discountAmount ?? 0)}
                        <BsrmTAKAicon color="#4A72FF" />
                      </span>
                    </div> */}

                    <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      {Number(val?.discountAmount) > 0 ? (
                        <>
                          <span className="line-through font-semibold   text-[#8897AE] ">
                            {val?.unitPrice} <BsrmTAKAicon color="#8897AE" />
                          </span>
                          <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                            {Number(val?.unitPrice ?? 0) -
                              Number(val?.discountAmount ?? 0)}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      ) : (
                        <span className="font-semibold   text-[#4A72FF]">
                          {Number(val?.unitPrice ?? 0) -
                            Number(val?.discountAmount ?? 0)}
                          <BsrmTAKAicon color="#4A72FF" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className="rounded-md" style={{ border: "1px solid #E9EFF6" }}>
          <DeleveryStatusTable
            allData={data}
            data={detailsData}
            reRender={reRender}
          />
        </div>
      </div>
    </CustomModal>
  );
}
