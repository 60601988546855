import { createSlice } from "@reduxjs/toolkit";
import {
  GetDistrictData,
  GetDivisionData,
  LocationInitialStatus,
} from "../../../types/redux";
import {
  createDistrictAsync,
  createDivisionAsync,
  deleteDistrictAsync,
  deleteDivisionAsync,
  getAllDistrictAsync,
  getAllDivisionAsync,
  updateDistrictAsync,
  updateDivisionAsync,
} from "./locationAPI";

const initialState: LocationInitialStatus<GetDivisionData, GetDistrictData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  divisions: [],
  countDivision: 0,
  districts: [],
  countDistrict: 0,
};

const locationSlice = createSlice({
  name: "admin-location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create division
    builder
      .addCase(createDivisionAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createDivisionAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createDivisionAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all division
    builder
      .addCase(getAllDivisionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDivisionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.divisions = action.payload.data;
        state.countDivision = action.payload.length;
      })
      .addCase(getAllDivisionAsync.rejected, (state) => {
        state.loading = false;
        state.divisions = [];
        state.countDivision = 0;
      });
    // update division
    builder
      .addCase(updateDivisionAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateDivisionAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateDivisionAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete division
    builder
      .addCase(deleteDivisionAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteDivisionAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteDivisionAsync.rejected, (state) => {
        state.deleteLoading = false;
      });

    // create district
    builder
      .addCase(createDistrictAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createDistrictAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createDistrictAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all district
    builder
      .addCase(getAllDistrictAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDistrictAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.districts = action.payload.data;
        state.countDistrict = action.payload.length;
      })
      .addCase(getAllDistrictAsync.rejected, (state) => {
        state.loading = false;
        state.districts = [];
        state.countDistrict = 0;
      });
    // update district
    builder
      .addCase(updateDistrictAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateDistrictAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateDistrictAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete district
    builder
      .addCase(deleteDistrictAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteDistrictAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteDistrictAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default locationSlice;
