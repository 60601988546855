export default function BsrmUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 6C7.5 4.80653 7.97411 3.66193 8.81802 2.81802C9.66194 1.97411 10.8065 1.5 12 1.5C13.1935 1.5 14.3381 1.97411 15.182 2.81802C16.0259 3.66193 16.5 4.80653 16.5 6C16.5 7.19347 16.0259 8.33807 15.182 9.18198C14.3381 10.0259 13.1935 10.5 12 10.5C10.8065 10.5 9.66194 10.0259 8.81802 9.18198C7.97411 8.33807 7.5 7.19347 7.5 6ZM3.751 20.105C3.78472 17.9395 4.66865 15.8741 6.21197 14.3546C7.75529 12.8351 9.83422 11.9834 12 11.9834C14.1658 11.9834 16.2447 12.8351 17.788 14.3546C19.3314 15.8741 20.2153 17.9395 20.249 20.105C20.2516 20.2508 20.2117 20.3942 20.134 20.5176C20.0564 20.641 19.9445 20.7392 19.812 20.8C17.3612 21.9237 14.6962 22.5037 12 22.5C9.214 22.5 6.567 21.892 4.188 20.8C4.05549 20.7392 3.94358 20.641 3.86597 20.5176C3.78835 20.3942 3.7484 20.2508 3.751 20.105Z"
        fill="#8897AE"
      />
    </svg>
  );
}
