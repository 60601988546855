import { Table, TableColumnsType } from "antd";
import React, { useState } from "react";
import PendingIcon from "../../../assets/icons/PendingIcon";
import ShiftIcon from "../../../assets/icons/ShiftIcon";
import DeleteModal from "../../../components/common/DeleteModal";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import { cancelProductAsync } from "../../../store/features/Order/orderProductAPI";
import { useAppDispatch } from "../../../store/store";
import { formatDate } from "../../../utils/time";

interface DataType {
  key: React.Key;
  date?: string;
  details?: string;
  isActive?: boolean;
  status: string;
}

type Props = {
  data?: any;
  allData?: any;
  reRender: (val: boolean) => void;
};

const DeleveryStatusTable = ({ data, allData, reRender }: Props) => {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const [select, setSelect] = useState<React.Key[]>([]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      width: 200,
      render: (val) => (
        <div className="grid grid-cols-36">
          {val?.icon}
          <div className="min-h-[50px]">
            <h4 className="text-[#2d3643] text-sm font-medium font-Roboto leading-normal">
              {val?.status}
            </h4>
            <p className="text-[#5e718d] !text-xs font-normal font-Roboto leading-tight">
              {val?.date ? formatDate(val?.date) : ""}
            </p>
            <p className="text-[#5e718d] !text-sm font-normal font-Roboto leading-normal mt-2">
              Info that helps a user with this field.
            </p>
          </div>
        </div>
      ),
    },
  ];

  const dataList = [
    {
      _id: 1,
      ...dataItem("Pending"),
      icon: <PendingIcon />,
    },
    {
      _id: 2,
      ...dataItem("Ready to Shipped"),
      icon: <ShiftIcon />,
    },
    {
      _id: 3,
      ...dataItem("Shipped"),
      icon: <ShiftIcon />,
    },
    {
      _id: 4,
      ...dataItem("Delivered"),
      icon: <ShiftIcon />,
    },
  ];

  function dataItem(str: string) {
    const findData = data?.deliveryLog?.find((el: any) => el?.status === str);
    if (findData) {
      return {
        ...findData,
        isActive: true,
      };
    }

    return {
      status: str,
    };
  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelect(selectedRowKeys);
      handleOpen({
        type: "SHIPPED_UPDATE",
        data: selectedRowKeys[selectedRowKeys?.length - 1],
      });
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    //   getCheckboxProps: (record: DataType) => ({
    //     disabled: record.name === "Disabled User", // Column configuration not to be checked
    //     name: record.name,
    //   }),
  };

  const findStatus = dataList?.find(
    (el) => el?._id === select?.[select?.length - 1]
  );

  const handleToggle = () => {
    toggle(true);
    setSelect([]);
  };

  const handleUpdate = () => {
    dispatch(
      cancelProductAsync({
        data: {
          status: findStatus?.status,
          details: findStatus?.status,
          orderId: data?.orderId,
          orderDetailsId: data?._id,
          numberOfItem: allData?.orderDetails?.length || 0,
        },
        others: { reRender: render },
      })
    );
  };

  function render(val: boolean) {
    if (val) {
      toggle();
      reRender(val);
    }
  }

  return (
    <>
      {open.type === "SHIPPED_UPDATE" && (
        <DeleteModal
          title={findStatus?.status}
          questionText={`Are you sure to ${findStatus?.status?.toLowerCase()}?`}
          open={open.open}
          data={open?.data}
          handleClose={handleToggle}
          handleCancel={handleUpdate}
          deleteLoading={false}
        />
      )}
      <Table
        className="big_checkbox disable_checkbox"
        pagination={false}
        rowSelection={{
          selectedRowKeys: [
            ...dataList?.filter((el) => el?.isActive)?.map((item) => item?._id),
            ...select,
          ],
          getCheckboxProps: (record) => {
            return {
              disabled: record?.isActive,
            };
          },
          ...rowSelection,
        }}
        columns={columns}
        dataSource={addKeyInArray(dataList)}
        showHeader={false}
      />
    </>
  );
};

export default DeleveryStatusTable;
