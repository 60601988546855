import { lazy } from "react";
import NewPasswordChange from "../pages/NewPassword/NewPasswordChange";
import AboutUs from "../pages/admin/AppSettings/AboutUs";
import AppSlider from "../pages/admin/AppSettings/AppSlider";
import BankList from "../pages/admin/AppSettings/BankList";
import FAQ from "../pages/admin/AppSettings/FAQ";
import Privacy from "../pages/admin/AppSettings/Privacy";
import RefundPolicy from "../pages/admin/AppSettings/RefundPolicy";
import Terms_Conditions from "../pages/admin/AppSettings/Terms_Conditions";
import BlogCategory from "../pages/admin/Blog/BlogCategory";
import BlogPost from "../pages/admin/Blog/BlogPost";
import CustomerMange from "../pages/admin/CustomerMange/CustomerMange";
import CustomerProfileDetails from "../pages/admin/CustomerMange/CustomerProfileDetails";
import AdminDashboard from "../pages/admin/Dashboard";
import ForgotPassword from "../pages/admin/ForgotPassword/ForgotPassword";
import LiveChat from "../pages/admin/LiveChat/LiveChat";
import DistrictList from "../pages/admin/Location/DistrictList";
import DivisionList from "../pages/admin/Location/DivisionList";
import AllProducts from "../pages/admin/ManageProducts/AllProducts";
import PendingProduct from "../pages/admin/ManageProducts/PendingProduct";
import ProductDetails from "../pages/admin/ManageProducts/ProductDetails";
import ManageRetailers from "../pages/admin/ManageRetailers/ManageRetailers";
import Notification from "../pages/admin/Notification/Notification";
import ManageOrder from "../pages/admin/Order/ManageOrder";
import OrderDetails from "../pages/admin/Order/OrderDetails";
import ExpertCategory from "../pages/admin/OurExpert/ExpertCategory";
import ManageExpert from "../pages/admin/OurExpert/ManageExpert";
import ProductBrandList from "../pages/admin/ProductBrandList/ProductBrandList";
import Category from "../pages/admin/ProductCategory/Category";
import RadyMixCement from "../pages/admin/ProductCategory/RadyMixCement";
import OrderList from "../pages/admin/SalesReport/OrderList";
import ProductSalesReport from "../pages/admin/SalesReport/ProductSalesReport";
import ScratchList from "../pages/admin/ScratchType/ScratchList";
import ScratchTypeSetup from "../pages/admin/ScratchType/ScratchTypeSetup";
import Settings from "../pages/admin/Settings/Settings";
import Chat from "../pages/retailers/LiveChat/Chat";
import Order from "../pages/retailers/ManageOrder/Order";
import RetailerOrderDetails from "../pages/retailers/ManageOrder/RetailerOrderDetails";
import AddNewProducts from "../pages/retailers/ManageProducts/AddNewProducts";
import AllProduct from "../pages/retailers/ManageProducts/AllProduct";
import CreateProductForm from "../pages/retailers/ManageProducts/CreateProductForm";
import RetailerProductDetails from "../pages/retailers/ManageProducts/RetailerProductDetails";
import RetailerNotification from "../pages/retailers/Notification/RetailerNotification";
import RetailerDashboard from "../pages/retailers/RetailerDashboard";
import RetailerSettings from "../pages/retailers/Settings/RetailerSettings";
import { RouteType } from "../types";
const AdminLogin = lazy(() => import("../pages/admin/Login"));
const RetailerLogin = lazy(() => import("../pages/retailers/Login"));
const NotFound = lazy(() => import("../pages/NotFound"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/scratch-type",
    element: ScratchList,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/scratch-type/type/:id",
    element: ScratchTypeSetup,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/product-category/category",
    element: Category,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/product-category/ready-mix-cement",
    element: RadyMixCement,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/Product-brandList",
    element: ProductBrandList,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/location/division-list",
    element: DivisionList,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/location/district-list",
    element: DistrictList,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/manage-retailers",
    element: ManageRetailers,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/manage-products/all-products",
    element: AllProducts,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/manage-products/pending-products",
    element: PendingProduct,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/manageOrder",
    element: ManageOrder,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/manageOrder/orderDetails/:orderId",
    element: OrderDetails,
    roles: ["ADMIN"],
  },
  //.................................................................

  {
    path: "/admin/manage-products/all-products/details/:allViewId",
    element: ProductDetails,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/manage-products/pending-products/details/:viewId",
    element: ProductDetails,
    roles: ["ADMIN"],
  },

  //.........................................................
  {
    path: "/admin/salesReport/orderList",
    element: OrderList,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/salesReport/productSalesReport",
    element: ProductSalesReport,
    roles: ["ADMIN"],
  },
  {
    path: "/retailer/manage-products/pending-products/create-product",
    element: CreateProductForm,
    roles: ["RETAILER"],
  },
  {
    path: "/admin/our-category/export-category",
    element: ExpertCategory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/our-category/manage-expert",
    element: ManageExpert,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/liveChat",
    element: LiveChat,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/notification",
    element: Notification,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/customerManage",
    element: CustomerMange,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/customerManage/:viewId",
    element: CustomerProfileDetails,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/blog/blog-category",
    element: BlogCategory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/blog/blog-post",
    element: BlogPost,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/appSettings/app-slider",
    element: AppSlider,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/privacy-confidentiality",
    element: Privacy,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/terms-condition",
    element: Terms_Conditions,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/refund-policy",
    element: RefundPolicy,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/faq",
    element: FAQ,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/about-us",
    element: AboutUs,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/appSettings/bank-list",
    element: BankList,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/settings",
    element: Settings,
    roles: ["ADMIN"],
  },

  // retailer routes

  {
    path: "/retailer/dashboard",
    element: RetailerDashboard,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/manage-products/all-products",
    element: AllProduct,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/manage-products/pending-products",
    element: AddNewProducts,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/manage-products/pending-products/details/:viewId",
    element: RetailerProductDetails,
    roles: ["RETAILER"],
  },

  {
    path: "/retailer/manage-products/pending-products/update-products/:id",
    element: CreateProductForm,
    roles: ["RETAILER"],
  },

  {
    path: "/retailer/manage-products/all-products/details/:allViewId",
    element: RetailerProductDetails,
    roles: ["RETAILER"],
  },

  {
    path: "/retailer/manage-order",
    element: Order,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/manage-order/order-details/:orderId",
    element: RetailerOrderDetails,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/report/order-list",
    element: Order,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/report/order-list/order-details/:orderId",
    element: RetailerOrderDetails,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/live-chat",
    element: Chat,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/notification",
    element: RetailerNotification,
    roles: ["RETAILER"],
  },
  {
    path: "/retailer/settings",
    element: RetailerSettings,
    roles: ["RETAILER"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },

  {
    path: "/retailer/login",
    element: RetailerLogin,
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
  },
  {
    path: "/update-password",
    element: NewPasswordChange,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
