import { createSlice } from "@reduxjs/toolkit";
import {
  ExpertCategoryInitialStatus,
  GetExpertCategoryData,
} from "../../../types/redux";
import {
  createExpertCategoryAsync,
  deleteExpertCategoryAsync,
  getAllExpertCategoryAsync,
  updateExpertCategoryAsync,
} from "./expertCategoryAPI";

const initialState: ExpertCategoryInitialStatus<GetExpertCategoryData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  expertCategory: [],
  countExpertCategory: 0,
};

const expertCategorySlice = createSlice({
  name: "admin-expertCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create expertCategory
    builder
      .addCase(createExpertCategoryAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createExpertCategoryAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createExpertCategoryAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all expertCategory
    builder
      .addCase(getAllExpertCategoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllExpertCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.expertCategory = action.payload.data;
        state.countExpertCategory = action.payload.length;
      })
      .addCase(getAllExpertCategoryAsync.rejected, (state) => {
        state.loading = false;
        state.expertCategory = [];
        state.countExpertCategory = 0;
      });
    // update expertCategory
    builder
      .addCase(updateExpertCategoryAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateExpertCategoryAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateExpertCategoryAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete expertCategory
    builder
      .addCase(deleteExpertCategoryAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteExpertCategoryAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteExpertCategoryAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default expertCategorySlice;
