import { Button, Form, FormProps, Image, Input, Select } from "antd";
import { ReactNode, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import placeholderImage from "../../assets/images/placeholder.png";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  ArrayOption,
  createHash,
  removeHash,
  selectSearchOption,
} from "../../helpers/siteConfig";
import { getAllBlogCategoryAsync } from "../../store/features/Blog/blogAPI";
import {
  createBlogPostAsync,
  updateBlogPostAsync,
} from "../../store/features/BlogPost/blogPostAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetBlogPostData } from "../../types/redux/ADMIN/blogPost";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  children?: ReactNode;
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function BlogPostForm({
  children,
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  // const [selectCategory, setSelectCategory] = useState("");
  // const [icon, seticon] = useState<string | null>(null);
  const [imageName, setImageName] = useState("Upload Image");
  const [uploadingImage, setUploadingImage] = useState(false);
  const { blogCategory } = useAppSelector((state) => state.blog);
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.blogPost
  );

  const blogPostData = data as GetBlogPostData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, ...rest } = values;
    const newValues = {
      ...rest,
      details: createHash(details),
    };

    if (type === "EDIT" && blogPostData?._id) {
      dispatch(
        updateBlogPostAsync({
          data: newValues,
          others: {
            reRender,
            id: blogPostData?._id,
          },
        })
      );
    } else {
      dispatch(
        createBlogPostAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      blogPostData?._id &&
      updateLoading === false
    ) {
      const bannerNameArr = stringToArray(blogPostData?.image, ".");
      setImageName(
        blogPostData?.image
          ? `${sliceString(blogPostData?.image, 15, "...")}.${
              bannerNameArr?.[bannerNameArr?.length - 1]
            }`
          : "Upload Banner"
      );

      form.setFieldsValue({
        title: blogPostData?.title,
        categoryId: blogPostData?.categoryId?._id || undefined,
        details: blogPostData?.details ? removeHash(blogPostData?.details) : "",
        image: blogPostData?.image,
        status: blogPostData?.status + "" || undefined,
      });
    }
  }, [
    blogPostData?._id,
    blogPostData?.categoryId,
    blogPostData?.details,
    blogPostData?.image,
    blogPostData?.status,
    blogPostData?.title,
    form,
    type,
    updateLoading,
  ]);

  // dispatch Category Data
  useEffect(() => {
    dispatch(
      getAllBlogCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          // status: true,
        },
      })
    );
  }, [dispatch]);

  return (
    <CustomModal width={903} {...rest}>
      <div className="px-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="flex">
            <div className="flex-initial w-[582px] mr-4 py-4">
              <Form.Item
                name="title"
                label="Post Title"
                className="mb-[10px]"
                rules={[
                  {
                    required: true,
                    message: "Please enter title type",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Blog Title"
                  disabled={type === "VIEW"}
                />
              </Form.Item>

              <Form.Item
                name="details"
                label="Post Content"
                className="mb-[10px]"
                rules={[
                  {
                    required: true,
                    message: "Please enter details type",
                  },
                ]}
              >
                <ReactQuill
                  className="h-[315px]"
                  theme="snow"
                  formats={formats}
                  modules={modules}
                  readOnly={type === "VIEW"}
                />
                <br />
              </Form.Item>
            </div>

            <div className="flex-initial w-[289px] ml-4 py-4">
              <Form.Item
                name="categoryId"
                label="Blog Category"
                className="mb-[10px]"
                rules={[
                  {
                    required: true,
                    message: "Please select category",
                  },
                ]}
              >
                <Select
                  placeholder="Select category"
                  rootClassName="select_full_option"
                  size="large"
                  allowClear
                  showSearch
                  filterOption={selectSearchOption}
                  options={ArrayOption(blogCategory, "name", "_id")}
                  onChange={(val) => {
                    // setSelectCategory(val);
                    form.setFieldValue("name", undefined);
                  }}
                  disabled={type === "VIEW"}
                />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                className="mb-[10px]"
                rules={[
                  {
                    required: true,
                    message: "Please select status",
                  },
                ]}
              >
                <Select
                  placeholder="Select status"
                  rootClassName="select_full_option"
                  size="large"
                  allowClear
                  showSearch
                  filterOption={selectSearchOption}
                  options={[
                    {
                      label: "Published",
                      value: "true",
                    },
                    {
                      label: "Un published",
                      value: "false",
                    },
                  ]}
                  disabled={type === "VIEW"}
                />
              </Form.Item>

              <>
                <p className="text-[14px] text-header font-medium leading-6">
                  Post Thumbnail
                </p>
                <Image
                  width={241}
                  height={150}
                  src={
                    form.getFieldValue("image")
                      ? `${IMAGE_URL}${form.getFieldValue("image")}`
                      : placeholderImage
                  }
                  alt={APP_NAME}
                  preview={false}
                />
              </>

              <Form.Item
                className="custom__image__input__placeholder"
                name="image"
                label="Select Image"
                htmlFor="..."
                extra={
                  type !== "VIEW"
                    ? "Upload the banner carefully | JPG, PNG"
                    : null
                }
                valuePropName="fileUrl"
                rules={[
                  {
                    required: true,
                    message: "Please upload a image",
                  },
                ]}
              >
                <FileUpload
                  errorMessage="Image upload fail"
                  disabled={type === "VIEW"}
                  handleLoading={(val) => setUploadingImage(val)}
                  handleName={(val) => setImageName(val || "Upload Image")}
                  onChange={(url) => form.setFieldsValue({ image: url })}
                >
                  <Button
                    size="large"
                    disabled={type === "VIEW"}
                    style={{ width: "100%" }}
                  >
                    <div className="flex justify-between">
                      <span className="flex gap-3 items-center">
                        <BsrmTableHeader />
                        {uploadingImage ? "Uploading..." : imageName}
                      </span>
                      <BsrmAttachment />
                    </div>
                  </Button>
                </FileUpload>
              </Form.Item>
              {type !== "VIEW" ? (
                <div className="text-center">
                  <Button
                    className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                    type="primary"
                    htmlType="submit"
                    loading={postLoading || updateLoading}
                  >
                    {type === "EDIT" ? "Submit Now" : "Submit"}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
}
