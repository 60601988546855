import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import io, { Socket } from "socket.io-client";
import { API_URL } from "../helpers/config";

interface SocketContextType {
  socket: Socket;
  isConnected: boolean;
  lastPong: string | null;
  sendPing: () => void;
}

export const SocketContext = createContext<SocketContextType | undefined>(
  undefined
);
const url = API_URL || "";
const socket = io(url, {
  transports: ["websocket"],
});

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }

  return context;
};

type Props = {
  children: ReactNode;
};

export default function SocketProvider({ children }: Props) {
  const [isConnected, setIsConnected] = useState(socket.connected);

  const [lastPong, setLastPong] = useState<any>(null);

  useEffect(() => {
    socket.on("connect", () => {
      // console.log("connected");
      setIsConnected(true);
    });
    socket.on("disconnect", () => {
      // console.log("disconnected");
      setIsConnected(false);
    });
    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });
    socket.emit("ConnectToNotification");
    return () => {
      // socket.emit("disconnect", user?._id);
      // socket.off("connect", user?._id);
      // socket.off("pong", user?._id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendPing = () => {
    socket.emit("ping");
  };

  return (
    <SocketContext.Provider value={{ socket, isConnected, lastPong, sendPing }}>
      {children}
    </SocketContext.Provider>
  );
}
