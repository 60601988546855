import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { CustomModalProps } from "../../types";

export default function CustomModal({
  title,
  open,
  centered = true,
  disableCloseBtn = false,
  handleClose,
  children,
  className = "",
  width = 370,
  ...rest
}: CustomModalProps) {
  return (
    <Modal
      title={title}
      centered={centered}
      className={`custom-modal ${className}`}
      open={open}
      onCancel={() => !disableCloseBtn && handleClose()}
      footer={null}
      closeIcon={
        <div className="text-[#5E718D] text-[18px] bg-none">
          <CloseOutlined />
        </div>
      }
      width={width}
      {...rest}
    >
      {children}
    </Modal>
  );
}
