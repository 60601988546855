import { DatePicker, Input, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import BsrmCalenderIcon from "../../assets/icons/BsrmCalenderIcon";
import BsrmMagnifine from "../../assets/icons/BsrmMagnifine";
import BsrmTAKAicon from "../../assets/icons/BsrmTAKAicon";
import BsrmCustomers from "../../assets/icons/dashboard/BsrmCustomers";
import BsrmDelivered from "../../assets/icons/dashboard/BsrmDelivered";
import BsrmFailedOrder from "../../assets/icons/dashboard/BsrmFailedOrder";
import BsrmIncrementDecrement from "../../assets/icons/dashboard/BsrmIncrementDecrement";
import BsrmOrderCancel from "../../assets/icons/dashboard/BsrmOrderCancel";
import BsrmOrders from "../../assets/icons/dashboard/BsrmOrders";
import BsrmOrderValue from "../../assets/icons/dashboard/BsrmOrderValue";
import BsrmPending from "../../assets/icons/dashboard/BsrmPending";
import BsrmSkuActive from "../../assets/icons/dashboard/BsrmSkuActive";
import AverageSales from "../../components/DashboardComponent/AverageSales";
import SalesChart from "../../components/DashboardComponent/SalesChart";
import { calculatePercentage } from "../../helpers/siteConfig";
import AdminLayout from "../../layouts/AdminLayout";
import {
  getAdminWeeklyAverageSalesGraphDataAsync,
  getAdminYearSalesGraphDataAsync,
  getAllAdminOrderDataAsync,
} from "../../store/features/adminDashboard/adminOdersDataApi";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { formatDate } from "../../utils/time";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const [year, setYear] = useState(dayjs());
  const { oderData, averageSalesWeekData, salesYearData } = useAppSelector(
    (state) => state.adminDashboard
  );
  // console.log("oderData->", oderData);
  // console.log("salesYearData->", salesYearData);
  // console.log("averageSalesWeekData->", averageSalesWeekData);

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  const handleDateChange = (val: any) => {
    setYear(val);
  };

  useEffect(() => {
    dispatch(
      getAllAdminOrderDataAsync({
        params: {
          fromDate: date?.startDate || undefined,
          toDate: date?.endDate || undefined,
        },
      })
    );
  }, [date?.endDate, date?.startDate, dispatch]);

  // ............Yearly sales..............
  useEffect(() => {
    dispatch(
      getAdminYearSalesGraphDataAsync({
        params: {
          year: year.year(),
        },
      })
    );
  }, [dispatch, year]);

  // ............Average sales..............
  useEffect(() => {
    dispatch(
      getAdminWeeklyAverageSalesGraphDataAsync({
        params: {},
      })
    );
  }, [dispatch]);

  return (
    <AdminLayout
      title="Dashboard"
      headerTitle="Dashboard"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <Input
            placeholder="input search text"
            style={{ width: 200 }}
            suffix={<BsrmMagnifine />}
          />
          <RangePicker
            suffixIcon={<BsrmCalenderIcon />}
            onChange={(val) =>
              setDate({
                startDate: val?.[0]?.format()
                  ? formatDate(val?.[0]?.format(), dateFormat)
                  : "",
                endDate: val?.[1]?.format()
                  ? formatDate(val?.[1]?.format(), dateFormat)
                  : "",
              })
            }
            format={dateFormat}
          />
        </Space>
      }
    >
      <section className="">
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Total Revenue</span>{" "}
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmIncrementDecrement />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              <BsrmTAKAicon color="#3D4A5C" />{" "}
              {Math.round(oderData?.totalRevenue)?.toLocaleString("en-IN")}
            </div>
            {/* <div className="text-[14px] leading-6">
              <span className="text-primary-admin">+3.4% </span>
              <span className="text-[#3D4A5C]">increase this week</span>
            </div> */}
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Total Customers</span>{" "}
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmCustomers />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.totalCustomers?.toLocaleString("en-IN")}
            </div>
            {/* <div className="text-[14px] leading-6">
              <span className="text-primary-admin">+0.10% </span>
              <span className="text-[#3D4A5C]">increase this week</span>
            </div> */}
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Total Orders</span>{" "}
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmOrders />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.totalOrder?.toLocaleString("en-IN")}
            </div>
            {/* <div className="text-[14px] leading-6">
              <span className="text-primary-admin">
                {(145).toLocaleString("en-IN")}
              </span>
              <span className="text-[#3D4A5C]">increase this week</span>
            </div> */}
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Delivered Orders</span>{" "}
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmDelivered />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.deliveredOrder?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              <span className="text-primary-admin">
                {calculatePercentage(
                  oderData?.totalOrder,
                  oderData?.deliveredOrder
                )}{" "}
                %{" "}
              </span>
              {/* <span className="text-[#3D4A5C]">increase this week</span> */}
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Pending Order</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmPending />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.pendingOrder?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              {/* <span className="text-[#3D4A5C]">Pending Order value: </span> */}
              <span className="text-primary-admin">
                {calculatePercentage(
                  oderData?.totalOrder,
                  oderData?.pendingOrder
                )}{" "}
                %
              </span>
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Ready to Shipped</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmPending />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.readyToShipped?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              {/* <span className="text-[#3D4A5C]">Pending Order value: </span> */}
              <span className="text-primary-admin">
                {calculatePercentage(
                  oderData?.totalOrder,
                  oderData?.readyToShipped
                )}{" "}
                %
              </span>
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Shipped</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmPending />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.shipped?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              {/* <span className="text-[#3D4A5C]">Pending Order value: </span> */}
              <span className="text-primary-admin">
                {calculatePercentage(oderData?.totalOrder, oderData?.shipped)} %
              </span>
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Order Cancellation</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmOrderCancel />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.cancelOrder?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              <p className="text-[#3D4A5C]">
                <span className="text-[#FF574D]">
                  {calculatePercentage(
                    oderData?.totalOrder,
                    oderData?.cancelOrder
                  )}{" "}
                  %
                </span>{" "}
                Orders
              </p>
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Partially Processed</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmFailedOrder />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.partiallyProcessedOrder?.toLocaleString("en-IN")}
            </div>
            <div className="text-[14px] leading-6">
              <p className="text-[#3D4A5C]">
                <span className="text-primary-admin">
                  +{" "}
                  {calculatePercentage(
                    oderData?.totalOrder,
                    oderData?.partiallyProcessedOrder
                  )}{" "}
                  %{" "}
                </span>{" "}
                Orders
              </p>
              {/* <span className="text-[#3D4A5C]">decrease this week</span> */}
            </div>
          </div>

          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Active SKU</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmSkuActive />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              {oderData?.totalActiveSKU?.toLocaleString("en-IN")}
            </div>
            {/* <div className="text-[14px] leading-6">
              <span className="text-primary-admin">364 </span>
              <span className="text-[#3D4A5C]">increase this week</span>
            </div> */}
          </div>

          {/* new */}
          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Average Order Value</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmOrderValue />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              <BsrmTAKAicon color="#3D4A5C" />{" "}
              {Math.round(oderData?.averageOrderValue)?.toLocaleString("en-IN")}
            </div>
          </div>
          <div
            className="dashboard__card"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <div className="flex justify-between items-center">
              <span className="text-[14px] leading-7">Value Per Customer</span>
              <span className="p-2 bg-[#F2FFF9] rounded-md">
                <BsrmCustomers />
              </span>
            </div>
            <div className="text-[22px] font-semibold leading-9 mt-[21px] text-[#3D4A5C]">
              <BsrmTAKAicon color="#3D4A5C" />{" "}
              {Math.round(oderData?.valuePerCustomer)?.toLocaleString("en-IN")}
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 mt-6 gap-6">
          <div
            className="p-4 md:col-span-2 !bg-white rounded-md"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <SalesChart
              data={salesYearData?.monthlySales}
              handleDateChange={handleDateChange}
              year={year}
              selectedYearSales={salesYearData?.selectedYearSales}
            />
          </div>
          <div
            className="p-4 !bg-white rounded-md"
            style={{ border: "1px solid #F0F3F9" }}
          >
            <AverageSales data={averageSalesWeekData?.weeklySales} />
          </div>
        </div>
      </section>
    </AdminLayout>
  );
}
