import { Button, DatePicker, Pagination, Select, Space } from "antd";
import { useEffect, useState } from "react";
import BsrmCalenderIcon from "../../../assets/icons/BsrmCalenderIcon";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmReload from "../../../assets/icons/BsrmReload";
import placeholderImager from "../../../assets/images/placeholder.png";
import Image from "../../../components/common/Image";
import Loader from "../../../components/common/Loader";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  refreshPage,
  selectSearchOption,
  timeAgo,
} from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllAdminNotificationAsync } from "../../../store/features/adminNotification/adminNotificationAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";
import { formatDate, formatTime } from "../../../utils/time";

export default function Notification() {
  const dispatch = useAppDispatch();
  const { page, limit, handleLimit, handlePagination } = useTable();
  const { loading, countAdminNotification, adminNotification } = useAppSelector(
    (state) => state.adminNotification
  );

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    dispatch(
      getAllAdminNotificationAsync({
        params: {
          fromDate: date?.startDate,
          toDate: date?.endDate,
          limit,
          pageNo: page,
        },
      })
    );
  }, [date?.endDate, date?.startDate, dispatch, limit, page]);

  const totalPage = Math.ceil(countAdminNotification / limit);
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  return (
    <AdminLayout
      title="Notification"
      headerTitle="Notification"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <Button
            type="text"
            // size="large"
            className="bg-accent px-3 py-[6px]"
            icon={<BsrmReload />}
            onClick={refreshPage}
          >
            <span className="text-[#455468]">Reload</span>
          </Button>
        </Space>
      }
    >
      <section className="space-y-2.5 ">
        <div className="flex justify-between gap-5 items-center flex-wrap mb-4">
          <div className="flex items-center gap-2 text-body text-[14px]">
            <span>Total Rows</span>
            <Select
              size="middle"
              showSearch
              filterOption={selectSearchOption}
              value={limit}
              options={[
                {
                  label: "10",
                  value: "10",
                },
                {
                  label: "20",
                  value: "20",
                },
                {
                  label: "50",
                  value: "50",
                },
                {
                  label: "100",
                  value: "100",
                },
              ]}
              onChange={handleLimit}
              className="w-[70px] limit_dropdown"
            />
            <span>of {AddZero(countAdminNotification)}</span>
          </div>

          <RangePicker
            suffixIcon={<BsrmCalenderIcon />}
            className="w-[200px]"
            onChange={(val) =>
              setDate({
                startDate: val?.[0]?.format()
                  ? formatDate(val?.[0]?.format(), dateFormat)
                  : "",
                endDate: val?.[1]?.format()
                  ? formatDate(val?.[1]?.format(), dateFormat)
                  : "",
              })
            }
            format={dateFormat}
          />
        </div>

        {/* <p className="text-body text-[16px] leading-7 font-medium">Today</p> */}

        {loading ? (
          <Loader />
        ) : (
          adminNotification?.map((val) => (
            <div className="flex justify-center">
              <div
                className="bg-white rounded-xl cursor-pointer px-3 py-4 flex justify-between items-center w-[70%]"
                style={{ border: "1px solid #F0F3F9", borderRadius: "12px" }}
              >
                <div className="flex justify-start gap-x-4">
                  <Image
                    className="rounded-full"
                    width={40}
                    height={40}
                    preview={false}
                    alt={APP_NAME}
                    src={`${IMAGE_URL}${val?.retailerId?.proprietorImage}`}
                    fallback={placeholderImager}
                  />
                  <div className="">
                    <p className="text-body text-[16px] leading-7 font-medium flex items-center gap-x-1.5">
                      <span>{val?.retailerId?.ownerName}</span>

                      <span className="text-header text-[14px] leading-6">
                        {timeAgo(val?.createdAt)}
                      </span>
                    </p>
                    <p className="text-[#FC8E28] text-[14px] leading-6 font-medium">
                      {val?.title}
                    </p>
                  </div>
                </div>

                <div className="flex items-center text-header text-[14px] leading-6 gap-x-2">
                  <span>{formatTime(val?.createdAt)}</span>
                  <BsrmDOT color="#5e718d" />

                  <span>{formatDate(val?.createdAt)}</span>
                </div>
              </div>
            </div>
          ))
        )}

        {/* pagination */}
        {!loading ? (
          <div className="flex justify-between items-center mt-4 relative custom_pagination">
            <div className="text-[14] leading-6 font-[400] text-others-header">
              Showing {page} of {totalPage} {totalPage > 1 ? "Pages" : "Page"}
            </div>
            <Pagination
              onChange={handlePagination}
              current={page}
              showSizeChanger={false}
              total={countAdminNotification}
              // itemRender={itemRender}
              pageSize={limit}
            />
          </div>
        ) : null}
      </section>
    </AdminLayout>
  );
}
