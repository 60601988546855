export default function BsrmScratchType() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.625 8.5C12.625 8.08579 12.2892 7.75 11.875 7.75C11.4608 7.75 11.125 8.08579 11.125 8.5H12.625ZM11.125 14.5C11.125 14.9142 11.4608 15.25 11.875 15.25C12.2892 15.25 12.625 14.9142 12.625 14.5H11.125ZM10.3321 5.3746C10.4699 4.98399 10.265 4.5556 9.87441 4.41775C9.48381 4.2799 9.05541 4.4848 8.91756 4.8754L10.3321 5.3746ZM7.375 11.5L6.66775 11.2504C6.58675 11.4799 6.62217 11.7345 6.76275 11.9332C6.90334 12.1319 7.1316 12.25 7.375 12.25V11.5ZM11.875 12.25C12.2892 12.25 12.625 11.9142 12.625 11.5C12.625 11.0858 12.2892 10.75 11.875 10.75V12.25ZM18.25 10C18.25 14.5563 14.5563 18.25 10 18.25V19.75C15.3848 19.75 19.75 15.3848 19.75 10H18.25ZM10 18.25C5.44365 18.25 1.75 14.5563 1.75 10H0.25C0.25 15.3848 4.61522 19.75 10 19.75V18.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75V0.25C4.61522 0.25 0.25 4.61522 0.25 10H1.75ZM10 1.75C14.5563 1.75 18.25 5.44365 18.25 10H19.75C19.75 4.61522 15.3848 0.25 10 0.25V1.75ZM11.125 8.5V14.5H12.625V8.5H11.125ZM8.91756 4.8754L6.66775 11.2504L8.08225 11.7496L10.3321 5.3746L8.91756 4.8754ZM7.375 12.25H11.875V10.75H7.375V12.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
