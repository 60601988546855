import { Divider, Image } from "antd";
import { Key } from "react";
import BsrmGreenCheck from "../../../assets/icons/BsrmGreenCheck";
import BsrmTAKAicon from "../../../assets/icons/BsrmTAKAicon";
import placeHolderImage from "../../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { AddZero } from "./../../../utils/number";

export default function CustomerProfileWishlist({ wishlistData }: any) {
  // console.log("wishlistData", wishlistData);
  return (
    <>
      <div
        className="flex-auto w-[712px] rounded-[14px]  bg-white p-6 "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="rounded-t-[14px] p-4">
          <p className="text-[20px] font-semibold leading-8 text-body">
            Wishlist ({AddZero(wishlistData.length)})
          </p>
        </div>

        {/* one */}
        {wishlistData.map(
          (val: {
            regularPrice: any;
            salesPrice: any;
            discountAmount: number;
            categoryId: any;
            productId: any;
            _id: Key | null | undefined;
          }) => (
            <div
              key={val?._id}
              className="bg-white mt-2.5 p-2.5 rounded-[10px] space-y-1"
              style={{ border: "1px solid #E9EFF6" }}
            >
              <p className="text-[14px] font-medium leading-6 py-1">
                Nagnin Enterprise ltd.
              </p>

              <Divider />

              <div className="flex justify-between ">
                <div className="flex gap-x-[10px]">
                  <Image
                    className="rounded-[10px]"
                    width={100}
                    height={80}
                    preview={false}
                    src={`${IMAGE_URL}${val?.productId?.image}`}
                    alt={APP_NAME}
                    fallback={placeHolderImage}
                  />
                  <div className="">
                    <h5 className="text-others-deep text-[16px] font-semibold leading-8 mb-2.5">
                      {val?.productId?.name}
                    </h5>
                    <p className="text-[#8897AE] text-[12px] font-semibold leading-6 tracking-[0.2px]">
                      {val?.categoryId?.name}
                    </p>
                    <div className="flex items-center gap-x-1">
                      <BsrmGreenCheck />{" "}
                      <p className="text-body text-[12px] font-semibold leading-6  ">
                        In Stock
                      </p>
                    </div>

                    <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      {/* <span className="line-through  font-semibold text-[#8897AE] ">
                        {val?.productId?.regularPrice.toLocaleString("en-IN")}{" "}
                        <BsrmTAKAicon color="#3d4a5c" />
                      </span>
                      <span className="ml-2.5 font-semibold    text-[#4A72FF]">
                        {val?.productId?.salesPrice.toLocaleString("en-IN")}{" "}
                        <BsrmTAKAicon color="#4A72FF" />
                      </span> */}

                      {/* Discount absent */}
                      {val?.productId?.regularPrice ===
                      val?.productId?.salesPrice ? (
                        <>
                          <span className="ml-2.5 font-semibold    text-[#4A72FF]">
                            {val?.productId?.salesPrice.toLocaleString("en-IN")}{" "}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="line-through  font-semibold text-[#8897AE] ">
                            {val?.productId?.regularPrice.toLocaleString(
                              "en-IN"
                            )}{" "}
                            <BsrmTAKAicon color="#3d4a5c" />
                          </span>
                          <span className="ml-2.5 font-semibold    text-[#4A72FF]">
                            {val?.productId?.salesPrice.toLocaleString("en-IN")}{" "}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}
