import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllRetailerOrderProductParams,
  OrderCancelData,
  RetailerOrderProductData,
  RetailerOrderProductDetails,
} from "../../../types/redux/ADMIN/retailerOrderProduct";

// get all Order
export const getAllOrderProductAsync = createAsyncThunk<
  GetCommonData<RetailerOrderProductData>,
  Params<GetAllRetailerOrderProductParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-order/getAllOrderProduct",
  async ({ params }, { rejectWithValue }) => {
    const { ...rest } = params as GetAllRetailerOrderProductParams;

    try {
      const data = await AXIOS.get(
        `${BASE_API.manageRetailerOrder}/order-list`,
        {
          params: { ...rest },
        }
      );
      return {
        data: data.data?.orders,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Order fail");
      return rejectWithValue(error);
    }
  }
);

// single api
export const getSingleOrderProductAsync = createAsyncThunk<
  RetailerOrderProductDetails,
  Params<SingleDataParams>
>(
  "retailer-order/getSingleOrderProduct",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageRetailerOrder}/order-details/${params?.id}`
      );

      return data.data as RetailerOrderProductDetails;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Order fail");
      return rejectWithValue(error);
    }
  }
);

// handle cancel
export const cancelProductAsync = createAsyncThunk<
  null,
  PostRequest<OrderCancelData, CommonOthers>
>(
  "retailer-order/cancelProduct",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(
        `${BASE_API.manageRetailerOrder}/update-order-item-status`,
        data
      );

      reRender && reRender(true);
      toast("success", "Order cancel successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order cancel fail");
      return rejectWithValue(error);
    }
  }
);
