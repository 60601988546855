import { HTMLAttributes, ReactNode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import favicon from "../assets/images/favicon.svg";
import { APP_NAME } from "../helpers/config";

type Props = {
  title?: string;
  className?: string;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const AppLayout: React.FC<Props> = ({
  title,
  className = "",
  children,
  ...rest
}) => {
  // const location = useLocation();
  // const isRETAILER = stringToArray(location.pathname, "/")?.[0] === "retailer";

  // useEffect(() => {
  //   // const root = document.getElementById("root");
  //   const body = document.getElementsByTagName("body")[0];

  //   if (isRETAILER) {
  //     body?.classList.add("retailer_panel");
  //   }
  // }, [isRETAILER]);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{title ? `${title} | ${APP_NAME}` : APP_NAME}</title>
      </Helmet>

      <div className={`${className}`} {...rest}>
        {children}
      </div>
    </HelmetProvider>
  );
};

export default AppLayout;
