export default function BsrmTAKAicon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
    >
      <path
        d="M10.6617 7.141C10.2717 5.875 9.33716 5.191 8.11016 5.26C7.37216 5.302 6.83666 5.7355 6.67766 6.4555C6.62516 6.6925 6.61616 6.9475 6.63416 7.1905C6.70616 8.179 7.28966 8.7205 8.27966 8.7355C8.41916 8.7385 8.55716 8.7355 8.71316 8.7355C8.68466 8.908 8.67416 9.0445 8.63666 9.175C8.29616 10.381 7.48466 11.1985 6.43016 11.8C6.09866 11.9905 5.72966 12.046 5.34416 12.022C4.54016 11.971 4.12016 11.5345 4.11866 10.723C4.11716 9.622 4.11866 8.5225 4.11866 7.4215V7.144H6.18566C5.88266 6.832 5.60516 6.583 5.37116 6.2965C5.07566 5.9335 4.74116 5.7175 4.25666 5.8165C4.22516 5.8225 4.18916 5.812 4.11866 5.803V5.5075C4.11566 4.5955 4.13216 3.682 4.10666 2.77C4.10216 2.62 4.09016 2.473 4.06616 2.329C3.98816 1.8355 3.79916 1.378 3.46466 0.9745C3.23216 0.694 2.93816 0.508 2.57966 0.457C2.48066 0.4435 2.38016 0.4375 2.28266 0.4375H0.958156C0.896656 0.7465 0.866656 1.0645 0.866656 1.393C0.866656 1.7125 0.896656 2.0245 0.958156 2.332L1.90616 2.362L2.06066 2.3785C2.09666 2.389 2.18816 2.404 2.20466 2.41C2.20916 2.4115 2.31416 2.446 2.39066 2.5135C2.60666 2.6995 2.73566 2.9695 2.73716 3.328C2.73866 3.898 2.73716 4.471 2.73716 5.0425V5.827H0.160156C0.565156 6.2515 0.922156 6.637 1.29566 7.006C1.37366 7.084 1.51016 7.135 1.62416 7.141C1.98716 7.156 2.35166 7.147 2.73866 7.147V7.4515C2.73866 8.662 2.75366 9.874 2.73116 11.0845C2.72216 11.554 2.88266 11.9275 3.17216 12.2695C4.17116 13.4485 5.40716 13.84 6.88316 13.36C8.69516 12.7765 9.85316 11.4925 10.5552 9.775C10.9062 8.9185 10.9347 8.026 10.6617 7.141Z"
        fill={color}
      />
    </svg>
  );
}
