import { Button, Form, FormProps, Input, Radio, Select } from "antd";
import { ReactNode, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import plasHolderImg from "../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  createHash,
  removeHash,
  selectSearchOption,
} from "../../helpers/siteConfig";
import {
  createAppSlideAsync,
  updateAppSlideAsync,
} from "../../store/features/AppSlide/SlideApi";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetSingleSlideData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
  children?: ReactNode;
} & CustomModalProps;

export default function CreateSlideForm({
  children,
  reRender,
  data,
  type,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [uploadingBanner, setUploadingBanner] = useState(false);

  const [photoName, setPhotoName] = useState("Upload Slider Banner");

  const { postLoading, updateLoading, slide } = useAppSelector(
    (state) => state.slide
  );

  const sliderData = data as GetSingleSlideData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, ...rest } = values;

    const newValues = {
      ...rest,
      details: createHash(details),
    };

    if ((type === "EDIT" || type === "VIEW") && sliderData?._id) {
      dispatch(
        updateAppSlideAsync({
          data: newValues,
          others: {
            reRender,
            id: sliderData?._id,
          },
        })
      );
    } else {
      dispatch(
        createAppSlideAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      sliderData?._id &&
      updateLoading === false
    ) {
      if (sliderData?.image) {
        const fileNameArr = stringToArray(sliderData?.image, ".");
        setPhotoName(
          sliderData?.image
            ? `${sliceString(sliderData?.image, 15, "...")}.${
                fileNameArr?.[fileNameArr?.length - 1]
              }`
            : "Upload Banner"
        );
      }

      form.setFieldsValue({
        slidePosition: sliderData?.slidePosition,
        title: sliderData?.title,
        details: sliderData?.details ? removeHash(sliderData?.details) : "",
        sorting: sliderData?.sorting,
        image:
          sliderData?.image && sliderData?.image !== "N/A"
            ? sliderData?.image
            : "",
      });
    }
  }, [
    form,
    sliderData?._id,
    sliderData?.details,
    sliderData?.image,
    sliderData?.slidePosition,
    sliderData?.sorting,
    sliderData?.title,
    type,
    updateLoading,
  ]);

  // remove image
  const handleRemoveImage = () => {
    form.setFieldsValue({
      image: undefined,
    });
    setPhotoName("Re-Upload Banner");
  };

  return (
    <CustomModal width={720} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark "
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="">
            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item
                name="title"
                className="mb-1.5"
                rules={[
                  {
                    required: true,
                    message: "Please write title",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Slide Title" />
              </Form.Item>

              <Form.Item
                name="sorting"
                className="mb-1.5"
                rules={[
                  {
                    required: true,
                    message: "Please select sort",
                  },
                ]}
              >
                <Select
                  placeholder="Select one"
                  size="large"
                  allowClear
                  showSearch
                  filterOption={selectSearchOption}
                  options={[...Array(20)].map((_, index) => ({
                    label: `${index + 1}`,
                    value: `${index + 1}`,
                  }))}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item
                className="custom__image__input__placeholder mb-4"
                name="image"
                label="Upload Image"
                htmlFor="..."
                // extra={
                //   type !== "VIEW"
                //     ? "File type:PNG, JPG, JPEG || Max size 5MB"
                //     : null
                // }
                valuePropName="fileUrl"
                rules={[
                  {
                    required: true,
                    message: "Please upload a banner",
                  },
                ]}
              >
                <FileUpload
                  errorMessage="Product image upload fail"
                  disabled={type === "VIEW"}
                  handleLoading={(val) => setUploadingBanner(val)}
                  handleName={(val) =>
                    setPhotoName(val || "Upload Slider image")
                  }
                  onChange={(url) => form.setFieldsValue({ image: url })}
                >
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    disabled={type === "VIEW"}
                  >
                    <div className="flex justify-between">
                      <span className="flex gap-3 items-center">
                        <BsrmTableHeader />
                        {uploadingBanner ? "Uploading..." : photoName}
                      </span>
                      <BsrmAttachment />
                    </div>
                  </Button>
                </FileUpload>
                <p className="text-header text-[12px] font-normal leading-5 not-italic ">
                  File type:PNG, JPG, JPEG || Max size 5MB
                </p>

                <div className="flex justify-between mb-2">
                  <p className="text-black text-[14px] font-normal leading-6">
                    FileName.JPEG
                  </p>
                  <span
                    className="cursor-pointer text-[#1B4DFF] text-[14px] font-semibold leading-6"
                    onClick={handleRemoveImage}
                  >
                    Remove
                  </span>
                </div>
              </Form.Item>

              {type !== "ADD" ? (
                <Image
                  width="100%"
                  height={150}
                  preview={false}
                  alt={APP_NAME}
                  src={
                    form.getFieldValue("image")
                      ? `${IMAGE_URL}${form.getFieldValue("image")}`
                      : plasHolderImg
                  }
                />
              ) : null}
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item
                name="slidePosition"
                label="Slide Type"
                rules={[
                  {
                    required: true,
                    message: "Please select category type",
                  },
                ]}
              >
                <Radio.Group className="grid grid-cols-2">
                  <Radio value="Top Slide" disabled={type === "VIEW"}>
                    <span className="text-[16px] font-medium font-roboto leading-7 text-[#2D3643]">
                      Top Slide
                    </span>
                  </Radio>
                  <Radio value="Body Slide" disabled={type === "VIEW"}>
                    <span className="text-[16px] font-medium font-roboto leading-7 text-[#2D3643]">
                      Body Slide
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {type !== "ADD" ? (
                <Form.Item name="status" label="Slide Mode">
                  <Radio.Group
                    defaultValue={"Active"}
                    className="grid grid-cols-2"
                  >
                    <Radio value="Active" disabled={type === "VIEW"}>
                      <span className="text-[16px] font-medium font-roboto leading-7 text-[#2D3643]">
                        Active
                      </span>
                    </Radio>
                    <Radio value="Inactive" disabled={type === "VIEW"}>
                      <span className="text-[16px] font-medium font-roboto leading-7 text-[#2D3643]">
                        Inactive
                      </span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              ) : null}
            </div>

            <Form.Item
              name="details"
              label="Slide Description"
              className="md:col-span-2 "
              rules={[
                {
                  required: true,
                  message: "Enter slider details",
                },
              ]}
            >
              <ReactQuill
                className="h-[120px] w-full"
                theme="snow"
                formats={formats}
                modules={modules}
                readOnly={type === "VIEW"}
              />
            </Form.Item>
          </div>
          <br />
          <br />

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Save"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
