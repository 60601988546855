import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import DivisionCreateForm from "../../../components/ModalForm/DivisionCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteDivisionAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetDivisionData, TableData } from "../../../types/redux";

export default function DivisionList() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();

  const { limit, page, handlePagination, handleLimit } = useTable();
  const { loading, countDivision, divisions, deleteLoading } = useAppSelector(
    (state) => state.location
  );

  const columns: ColumnsType<TableData<GetDivisionData>> = [
    {
      title: "No.",
      align: "center",
      width: "130px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Division Name",
      dataIndex: "name",
    },
    {
      title: "Latitude",
      dataIndex: "location",
      render: (val) => val?.coordinates?.[1],
    },
    {
      title: "Longitude",
      dataIndex: "location",
      render: (val) => val?.coordinates?.[0],
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetDivisionData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteDivisionAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  return (
    <AdminLayout
      title="Division List" // browser title
      headerTitle="Division List" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetDivisionData>>
        loading={loading}
        total={countDivision}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(divisions)}
        handleLimit={handleLimit}
      />

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <DivisionCreateForm
          title={
            open.type === "EDIT"
              ? "Update Division"
              : open.type === "VIEW"
              ? "View Division"
              : "Create Division"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Division"
          // questionText="Are you sure to delete division?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
