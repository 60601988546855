export default function BsrmCalenderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M0.8125 6.1875H13.1875M10.375 1.6875V3.9375M3.625 1.6875V3.9375M1.375 2.8125H12.625C12.9357 2.8125 13.1875 3.06434 13.1875 3.375V14.625C13.1875 14.9357 12.9357 15.1875 12.625 15.1875H1.375C1.06434 15.1875 0.8125 14.9357 0.8125 14.625V3.375C0.8125 3.06434 1.06434 2.8125 1.375 2.8125Z"
        stroke="#3D4A5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
