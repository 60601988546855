import { DatePicker, Space } from "antd";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import BsrmTaka from "../../assets/icons/dashboard/BsrmTaka";
import BsrmTopArrow from "../../assets/icons/dashboard/BsrmTopArrow";
import DownIcon from "../../assets/icons/DownIcon";
import { AddZero, numberShort } from "../../utils/number";

function SalesChart({
  data,
  handleDateChange,
  year,
  selectedYearSales,
  salesProgress,
}: any) {
  const [load, setLoad] = useState(true);
  // console.log(data);
  const newData = [...Array(12)]?.map((el, idx) => {
    const item = data?.find((el2: any) => el2?._id?.month === idx + 1);
    if (item) {
      return item;
    }
    return {
      _id: {
        month: idx + 1,
        year: data?.[0]?._id?.year || new Date().getFullYear(),
      },
      sum: 0,
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  const series = [
    {
      // name: "XYZ MOTORS",
      data: newData?.map((item: { sum: any }) => item?.sum || 0),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#07BFA5"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Sales This Year",
      align: "left",
      style: {
        color: "#5e718d",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "500",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 10,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 150, 1],
        gradientToColors: ["#07BFA533", "#07BFA533"],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number = 0) {
          return numberShort(val);
        },
        style: {
          colors: "#afbaca",
          fontSize: "14px",
          fontFamily: "Roboto",
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#afbaca",
          fontSize: "14px",
          fontFamily: "Roboto",
        },
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      borderColor: "#e9eff6",
      strokeDashArray: 5,
    },
    tooltip: {
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        return `<div class="px-3 py-2 bg-[#2d3643] rounded-md text-[#f0f3f8] text-sm font-medium font-Roboto">
                  ${numberShort(series[seriesIndex][dataPointIndex])} BDT
                </div>`;
      },
    },
  };

  return (
    <div>
      <Space className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <p className="text-[22px] font-semibold leading-9 text-body ">
            <BsrmTaka /> {selectedYearSales?.toLocaleString("en-IN")}
          </p>

          {salesProgress >= 0 ? (
            <p className="text-primary-admin bg-[#F2FFF9] px-1.5 py-1 text-[14px] font-medium leading-6">
              <span className="mr-[6px]">
                <BsrmTopArrow />
              </span>
              <span>{AddZero(salesProgress)} %</span>
            </p>
          ) : (
            <p className="text-[#ff574c] bg-[#fff4f4] px-1.5 py-1 text-[14px] font-medium leading-6">
              <span className="mr-[6px]">
                <DownIcon />
              </span>
              <span>{AddZero(salesProgress)} %</span>
            </p>
          )}
        </div>
        <DatePicker
          className="max-w-[120px]"
          size="large"
          onChange={handleDateChange}
          picker="year"
          clearIcon={false}
          value={year}
        />
      </Space>
      <div id="chart">
        {!load && (
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
            width={"100%"}
          />
        )}
      </div>
    </div>
  );
}

export default SalesChart;
