import { Divider, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import BsrmMagnifine from "../../../assets/icons/BsrmMagnifine";
import Loader from "../../../components/common/Loader";
import MessageBox from "../../../components/Retailer/LiveChat/MessageBox";
import SingleUser from "../../../components/Retailer/LiveChat/SingleUser";
import { useSocket } from "../../../Context/SocketProvider";
import { toast } from "../../../helpers/toast";
import useCustomDebounce from "../../../hooks/useCustomDebounce";
import useDebounce from "../../../hooks/useDebounce";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllCustomerAsync,
  getRetailerChatDataAsync,
} from "../../../store/features/retailerLiveChat/retailerLiveChatAPI";
import { addMessage } from "../../../store/features/retailerLiveChat/retailerLiveChatSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { RetailerChatParams } from "../../../types/redux/RETAILER/liveChat";

interface ChatProps {}

const Chat: React.FC<ChatProps> = () => {
  const dispatch = useAppDispatch();
  const { onChange } = useCustomDebounce(() => typingEmit(), 1000);
  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [typing, setTyping] = useState<boolean>(false);
  const { socket } = useSocket();
  const [messaging, setMessaging] = useState(true);
  const { login } = useAppSelector((state) => state.login);
  const [customerId, setCustomerId] = useState<string>("");
  const [fileUrl, setFileUrl] = useState("");
  const [location, setLocation] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const { keyword, handleInputChange } = useDebounce();
  const { allCustomer, customerLoading } = useAppSelector(
    (state) => state.retailerLiveChat
  );

  useEffect(() => {
    if (!customerId || !login?._id) return;
    const roomId = login?._id + customerId;
    socket.emit("joinTask", {
      roomId,
      type: "Retailer",
      user: login?._id,
    });

    const handleSentMessageSuccess = (data: any) => {
      setMessaging(false);
      if (data.type === "Customer") {
        dispatch(
          addMessage({
            createdAt: Date.now(),
            message: data.message,
            type: "Customer",
            messageType: data.messageType,
          })
        );
      } else {
        dispatch(
          addMessage({
            createdAt: Date.now(),
            message: data.message,
            type: "Retailer",
            messageType: data.messageType,
          })
        );
      }

      setMessage("");
      setSending(false);
      getCustomer();
    };

    socket.on("SentMessageSuccess", handleSentMessageSuccess);
    // socket.on("UserJoinInRoom", getUserStatus);
    socket.on("userTypingShow", () => setTyping(true));
    socket.on("userTypingEnded", () => setTyping(false));
    // socket.on("userLoggedOff", getUserStatus);

    return () => {
      socket.emit("logoff", {
        roomId,
        type: "Retailer",
        user: login?._id,
      });
      socket.off("SentMessageSuccess", handleSentMessageSuccess);
      // socket.off("UserJoinInRoom", getUserStatus);
      socket.off("userTypingShow", () => setTyping(true));
      socket.off("userTypingEnded", () => setTyping(false));
      // socket.off("userLoggedOff", getUserStatus);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, dispatch, login?._id, socket]);

  window.onbeforeunload = function () {
    socket.emit("logoff", {
      roomId: login?._id + customerId,
      type: "Retailer",
      user: login?._id,
    });
  };

  const api = useCallback(() => {
    if (customerId) {
      const params: RetailerChatParams = {
        limit: 0,
        pageNo: 0,
        customerId,
      };

      dispatch(getRetailerChatDataAsync({ params }));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    api();
  }, [api]);
  const handleMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(e);
    setMessage(value);
    if (!value) {
      socket.emit("userTypingEnd", customerId, login?._id);
    }
  };

  const handleFileUrl = (val: string) => {
    setFileUrl(val);
    setOpenDropdown(false);
  };
  const handleRemoveLocation = () => {
    setLocation("");
    setOpenDropdown(false);
  };
  const handleDropdown = (val: boolean) => {
    setOpenDropdown(val);
  };

  const handleFetchLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(
            `${position.coords.latitude},${position.coords.longitude}`
          );
          setOpenDropdown(false);
        },
        () => {
          toast("error", "Location find fail");
        }
      );
    } else {
      toast("error", "Geolocation is not supported by this browser.");
    }
  };

  const typingEmit = () => {
    socket.emit("userTyping", customerId, login?._id);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSending(true);

    const body = {
      room: login?._id + customerId,
      retailerId: login?._id,
      customerId: customerId,
      type: "Retailer",
      message: message,
      messageType: "Text",
    };

    if (message) socket.emit("sentMessage", body);
  };

  const handleFileSubmit = () => {
    if (fileUrl || location) {
      setSending(true);

      const body = {
        room: login?._id + customerId,
        retailerId: login?._id,
        customerId: customerId,
        type: "Retailer",
        message: fileUrl || location,
        messageType: fileUrl ? "File" : location ? "Location" : "",
      };

      setFileUrl("");
      setLocation("");

      socket.emit("sentMessage", body);
    }
  };

  const getCustomer = useCallback(() => {
    dispatch(
      getAllCustomerAsync({
        params: {
          pageNo: 0,
          limit: 0,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    if (allCustomer?.[0]?.customerId?._id) {
      setCustomerId(allCustomer[0]?.customerId._id);
    }
  }, [allCustomer]);

  const customerList = allCustomer?.filter((item) =>
    item?.customerId?.name?.toLowerCase()?.includes(keyword?.toLowerCase())
  );

  return (
    <AdminLayout
      title="Live Chat"
      headerTitle="Live Chat"
      className="dashboard"
    >
      <section className="flex gap-4">
        <div className="w-[344px] h-full bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
          <div className="bg-accent rounded-t-[14px] p-4 relative">
            <p className="text-[20px] text-body leading-8 font-semibold">
              Message
            </p>

            <Input
              className="mt-4 -mb-2"
              size="large"
              placeholder="Search by name"
              suffix={<BsrmMagnifine />}
              onChange={handleInputChange}
            />

            <Divider className="bg-[#D7DFE9] !mb-1" />
          </div>

          <div className="h-[390px] overflow-y-auto">
            {customerLoading && messaging ? (
              <Loader />
            ) : (
              customerList?.map((el) => (
                <SingleUser
                  {...el?.customerId}
                  active={customerId === el?.customerId?._id}
                  onClick={() => setCustomerId(el?.customerId?._id)}
                  key={el._id}
                  message={el?.message}
                  messageType={el?.messageType}
                  status={el?.status}
                  type={el?.type}
                />
              ))
            )}
          </div>
        </div>

        <MessageBox
          customerId={customerId}
          handleMessage={handleMessage}
          handleSubmit={handleSubmit}
          sending={sending}
          typing={typing}
          message={message}
          openDropdown={openDropdown}
          handleFileUrl={handleFileUrl}
          handleDropdown={handleDropdown}
          handleFileSubmit={handleFileSubmit}
          handleFetchLocation={handleFetchLocation}
          handleRemoveLocation={handleRemoveLocation}
          fileUrl={fileUrl}
          location={location}
        />
      </section>
    </AdminLayout>
  );
};

export default Chat;
