import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllAdminOrderProductParams,
  RetailerOrderProductData,
  RetailerOrderProductDetails,
} from "../../../types/redux/ADMIN/retailerOrderProduct";

// get all Order
export const getAllAdminOrderProductAsync = createAsyncThunk<
  GetCommonData<RetailerOrderProductData>,
  Params<GetAllAdminOrderProductParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-order/getAllAdminOrderProduct",
  async ({ params }, { rejectWithValue }) => {
    const { ...rest } = params as GetAllAdminOrderProductParams;
    try {
      const data = await AXIOS.get(`${BASE_API.manageAdminOrder}order-list`, {
        params: { ...rest },
      });
      return {
        data: data.data?.orders,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Order fail");
      return rejectWithValue(error);
    }
  }
);

// single api
export const getSingleAdminOrderProductAsync = createAsyncThunk<
  RetailerOrderProductDetails,
  Params<SingleDataParams>
>(
  "admin-order/getSingleAdminOrderProduct",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageAdminOrder}order-details/${params?.id}`
      );

      return data.data as RetailerOrderProductDetails;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Order fail");
      return rejectWithValue(error);
    }
  }
);
