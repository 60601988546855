import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import PlaceholderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import Image from "../../../components/common/Image";
import BlogCategoryForm from "../../../components/ModalForm/BlogCategoryForm";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArrayRevereLoginLog,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteBlogCategoryAsync,
  getAllBlogCategoryAsync,
} from "../../../store/features/Blog/blogAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { GetBlogCategoryData } from "../../../types/redux/ADMIN/blog";
export default function BlogCategory() {
  const dispatch = useAppDispatch();
  const [selectStatus, setSelectStatus] = useState("");
  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination } = useTable();
  const { countBlogCategory, blogCategory, deleteLoading } = useAppSelector(
    (state) => state.blog
  );

  const columns: ColumnsType<TableData<GetBlogCategoryData>> = [
    {
      title: "No.",
      align: "center",
      width: "130px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Category Name",
      align: "left",
      dataIndex: "name",
    },
    {
      title: "Category Label",
      align: "left",
      dataIndex: "label",
    },

    {
      title: "Icon",
      dataIndex: "icon",
      align: "center",
      // width: "398px",
      className: "table__head__icon__bg__highlight",

      render: (val: GetBlogCategoryData) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            width={30}
            height={30}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={PlaceholderImage}
          />
        </Space>
      ),
    },

    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (val) => (
        <span
          className={`status ${
            val
              ? "bg-status-complete-bg  text-status-complete-text "
              : "bg-status-danger-bg text-status-danger-text"
          }`}
        >
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetBlogCategoryData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteBlogCategoryAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllBlogCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
        },
      })
    );
  }, [dispatch, limit, page, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  const allStatus = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  return (
    <AdminLayout
      title="Post Category"
      headerTitle="Post Category List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create Now
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<GetBlogCategoryData>>
          loading={false}
          total={countBlogCategory}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArrayRevereLoginLog(blogCategory)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                size="middle"
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <BlogCategoryForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Blog Category"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
