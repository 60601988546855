import { ReactNode } from "react";
import BSRM_LOGO from "../assets/images/login/BSRM_Logo.svg";
import ghorbari_Logo from "../assets/images/login/Ghorbari_Logo.svg";
import SHADOW_HOME from "../assets/images/login/shadow_image.svg";
import AppLayout from "./AppLayout";

type Props = {
  bsrmLogo?: string;
  ghorbariLogo?: string;
  shadowHome?: string;
  title?: string;
  children: ReactNode;
  bgColor?: string;
};

export default function LoginLayout({
  shadowHome = SHADOW_HOME,
  ghorbariLogo = ghorbari_Logo,
  bsrmLogo = BSRM_LOGO,
  title,
  children,
  bgColor = "bg-primary-admin",
}: Props) {
  return (
    <AppLayout title="Login">
      <div className="h-screen  bg-[#F7F9FC]">
        <div className="h-full ">
          <div className="">
            <div className="py-28  flex items-center justify-center ">
              <div className="z-10 w-[388px] h-full text-center rounded-lg border-8 border-[#E9EFF6] p-6 gap-6 bg-[#FFFFFF]  ">
                <div className="pb-10">
                  <img
                    src={ghorbariLogo}
                    alt="ghorbariLogo"
                    className="w-[164.92px] h-[28.58px]"
                  />
                  <div className="text-[14px] leading-6 text-header">
                    {title}
                  </div>
                </div>
                <div className="">{children}</div>
              </div>
            </div>

            <div className="flex justify-center gap-x-3 items-center ">
              <h5>Powered by</h5>
              <img
                src={bsrmLogo}
                alt="bsrm_logo"
                className="w-[110px] h-[32.52px]"
              />
            </div>
          </div>
        </div>
      </div>

      <img
        className="h-[438.8px] w-[827.88px] absolute bottom-0 right-0 "
        src={shadowHome}
        alt=""
      />
    </AppLayout>
  );
}
