import { Button, Form, FormProps, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import {
  createBlogCategoryAsync,
  getAllBlogCategoryAsync,
  updateBlogCategoryAsync,
} from "../../store/features/Blog/blogAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetBlogCategoryData } from "../../types/redux/ADMIN/blog";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
  data: GetBlogCategoryData;
} & CustomModalProps;

export default function BlogCategoryForm({
  reRender,
  data,
  type,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [iconName, setIconName] = useState("Upload Icon");
  const [uploadingIcon, setUploadingIcon] = useState(false);

  const { blogCategory, updateLoading, postLoading } = useAppSelector(
    (state) => state.blog
  );
  const blogCategoryData = data as GetBlogCategoryData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && blogCategoryData?._id) {
      dispatch(
        updateBlogCategoryAsync({
          data: values,
          others: {
            reRender,
            id: blogCategoryData?._id,
          },
        })
      );
    } else {
      dispatch(
        createBlogCategoryAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };
  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      blogCategoryData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(blogCategoryData?.icon, ".");
      setIconName(
        blogCategoryData?.icon
          ? `${sliceString(blogCategoryData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Icon"
      );

      form.setFieldsValue({
        name: blogCategoryData?.name,
        label: blogCategoryData?.label,
        icon: blogCategoryData?.icon,
        status: blogCategoryData?.status + "",
      });
    }
  }, [blogCategoryData, form, type, updateLoading]);

  useEffect(() => {
    dispatch(
      getAllBlogCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          // status: true,
        },
      })
    );
  }, [dispatch]);

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          size="large"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="name"
            label="Category Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input size="large" disabled={type === "VIEW"} placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="label"
            label="Category Label"
            rules={[
              {
                required: true,
                message: "Please enter label",
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Label"
            />
          </Form.Item>

          <Form.Item
            className="custom__image__input__placeholder"
            name="icon"
            label="Select Icon"
            htmlFor="..."
            extra={
              type !== "VIEW" ? "Upload the Icon carefully | JPG, PNG" : null
            }
            valuePropName="fileUrl"
            rules={[
              {
                required: true,
                message: "Please upload a Icon",
              },
            ]}
          >
            <FileUpload
              errorMessage="Icon upload fail"
              disabled={type === "VIEW"}
              handleLoading={(val) => setUploadingIcon(val)}
              handleName={(val) => setIconName(val || "Upload Icon")}
              onChange={(url) => form.setFieldsValue({ banner: url })}
            >
              <Button
                size="large"
                disabled={type === "VIEW"}
                style={{ width: "100%" }}
              >
                <div className="flex justify-between">
                  <span className="flex gap-3 items-center">
                    <BsrmTableHeader />
                    {uploadingIcon ? "Uploading..." : iconName}
                  </span>
                  <BsrmAttachment />
                </div>
              </Button>
            </FileUpload>
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            // className="!mb-2.5"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select category type",
            //   },
            // ]}
          >
            <Radio.Group>
              <Radio value="true" disabled={type === "VIEW"}>
                Active
              </Radio>
              <Radio value="false" disabled={type === "VIEW"}>
                Inactive
              </Radio>
            </Radio.Group>
          </Form.Item>

          {type !== "VIEW" && (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </CustomModal>
  );
}
