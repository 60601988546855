import { createSlice } from "@reduxjs/toolkit";
import {
  GetRetailerProductData,
  GetSingleRetailerProduct,
  retailerProductInitialStatus,
} from "../../../types/redux/ADMIN/retailerProduct";
import {
  getAllAdminProductAsync,
  getSingleAdminProductAsync,
  updateAdminApproveProductAsync,
  updateAdminProductAsync,
  updateAdminRejectProductAsync,
} from "./adminProductAPI";

//retailer product and admin product are same
const initialState: retailerProductInitialStatus<
  GetRetailerProductData,
  GetSingleRetailerProduct
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailerProduct: [],
  retailerAllProduct: [],
  singleProduct: null,
  countRetailerProduct: 0,
  retailerProductSkuData: undefined,
};

const adminProductSlice = createSlice({
  name: "admin-Product", // pending & retailer product
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all productCategory
    builder
      .addCase(getAllAdminProductAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAdminProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerProduct = action.payload?.data;
        state.countRetailerProduct = action.payload.length;
      })
      .addCase(getAllAdminProductAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerProduct = [];
        state.countRetailerProduct = 0;
      });
    // update productCategory
    builder
      .addCase(updateAdminProductAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAdminProductAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAdminProductAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // update Approve Product
    builder
      .addCase(updateAdminApproveProductAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAdminApproveProductAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAdminApproveProductAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // update Reject Product
    builder
      .addCase(updateAdminRejectProductAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAdminRejectProductAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAdminRejectProductAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // get single product
    builder
      .addCase(getSingleAdminProductAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleAdminProductAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getSingleAdminProductAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleProduct = null;
      });
  },
});

export default adminProductSlice;
