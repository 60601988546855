export default function PendingIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#D7DFE9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5C13.8565 5 15.637 5.7375 16.9497 7.05025C18.2625 8.36301 19 10.1435 19 12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12ZM12.75 7.75C12.75 7.55109 12.671 7.36032 12.5303 7.21967C12.3897 7.07902 12.1989 7 12 7C11.8011 7 11.6103 7.07902 11.4697 7.21967C11.329 7.36032 11.25 7.55109 11.25 7.75V12C11.25 12.414 11.586 12.75 12 12.75H15.25C15.4489 12.75 15.6397 12.671 15.7803 12.5303C15.921 12.3897 16 12.1989 16 12C16 11.8011 15.921 11.6103 15.7803 11.4697C15.6397 11.329 15.4489 11.25 15.25 11.25H12.75V7.75Z"
        fill="#8897AE"
      />
    </svg>
  );
}
