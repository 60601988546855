import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  DistrictData,
  DivisionData,
  ErrorMessage,
  GetAllDistrictParams,
  GetAllDivisionParams,
  GetCommonData,
  GetDistrictData,
  GetDivisionData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";

// create a division
export const createDivisionAsync = createAsyncThunk<
  null,
  PostRequest<DivisionData, CommonOthers>
>(
  "admin-location/createDivision",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageDivision, data);

      reRender(true);
      toast("success", "Division create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division create fail");
      return rejectWithValue(error);
    }
  }
);

// get all Division
export const getAllDivisionAsync = createAsyncThunk<
  GetCommonData<GetDivisionData>,
  Params<GetAllDivisionParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-location/getAllDivision", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageDivision}/data`, {
      params,
    });

    return {
      data: data.data?.divisions,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get division fail");
    return rejectWithValue(error);
  }
});

// update a division
export const updateDivisionAsync = createAsyncThunk<
  null,
  PostRequest<DivisionData, SingleDataParams>
>(
  "admin-location/updateDivision",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageDivision}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Division update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a Division
export const deleteDivisionAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>("admin-location/deleteDivision", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.manageDivision}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Division delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Division delete fail");
    return rejectWithValue(error);
  }
});

// District
// create a District
export const createDistrictAsync = createAsyncThunk<
  null,
  PostRequest<DistrictData, CommonOthers>
>(
  "admin-location/createDistrict",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageDistrict, data);

      reRender(true);
      toast("success", "District create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "District create fail");
      return rejectWithValue(error);
    }
  }
);

// get all District
export const getAllDistrictAsync = createAsyncThunk<
  GetCommonData<GetDistrictData>,
  Params<GetAllDistrictParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-location/getAllDistrict", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageDistrict}/data`, {
      params,
    });

    return {
      data: data.data?.districts,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get district fail");
    return rejectWithValue(error);
  }
});

// update a District
export const updateDistrictAsync = createAsyncThunk<
  null,
  PostRequest<DistrictData, SingleDataParams>
>(
  "admin-location/updateDistrict",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageDistrict}/${id}`, data);

      reRender && reRender(true);
      toast("success", "District update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "District update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a District
export const deleteDistrictAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>("admin-location/deleteDistrict", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.manageDistrict}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "District delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "District delete fail");
    return rejectWithValue(error);
  }
});
