import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types/redux";
import {
  AverageSalesYearData,
  GetOrderDataParams,
  GetSalesYearDataParams,
  OrderData,
  SalesYearData,
} from "../../../types/redux/ADMIN/dashboard";

// get all dashboard data
export const getAllAdminOrderDataAsync = createAsyncThunk<
  GetCommonData<OrderData>,
  Params<GetOrderDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dashboard/getAllAdminOrderData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getAdminDashboard}/about-order-related`,
        {
          params,
        }
      );
      return {
        data: data.data,
        length: data.data,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get data fail");
      return rejectWithValue(error);
    }
  }
);

// get sales-this-year
export const getAdminYearSalesGraphDataAsync = createAsyncThunk<
  GetCommonData<SalesYearData>,
  Params<GetSalesYearDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dashboard/getAdminYearSalesGraphData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getAdminDashboard}/sales-this-year`,
        {
          params,
        }
      );
      // console.log("dashboard data--->", data);
      return {
        data: data.data,
        length: data.data,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get data fail");
      return rejectWithValue(error);
    }
  }
);

// get Average Sales year data
export const getAdminWeeklyAverageSalesGraphDataAsync = createAsyncThunk<
  GetCommonData<AverageSalesYearData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dashboard/getAdminWeeklyAverageSalesGraphData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getAdminDashboard}/average-sales-this-week`,
        {
          params,
        }
      );
      // console.log("data--->", data);
      return {
        data: data.data,
        length: data.data,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get data fail");
      return rejectWithValue(error);
    }
  }
);
