import { Button, ButtonProps } from "antd";
import { ReactNode } from "react";

type Props = {
  nextIcon?: ReactNode;
} & ButtonProps;

export default function IconButton({ nextIcon, children, ...rest }: Props) {
  return (
    <Button
      {...rest}
      type="primary"
      className="px-[15px] py-2 flex justify-center items-center text-white "
    >
      {nextIcon ? <span className="mr-2 ">{nextIcon}</span> : null}
      {children}
    </Button>
  );
}
