export default function RemoveIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.18777 7.3125V11.8125M5.81278 7.3125V11.8125M13.6875 3.9375L1.3125 3.9375M12.5628 3.9375V14.625C12.5628 14.7742 12.5035 14.9173 12.398 15.0227C12.2925 15.1282 12.1495 15.1875 12.0003 15.1875H3.00028C2.85109 15.1875 2.70802 15.1282 2.60253 15.0227C2.49704 14.9173 2.43778 14.7742 2.43778 14.625V3.9375M10.3128 3.9375V2.8125C10.3128 2.51413 10.1942 2.22798 9.98327 2.017C9.77229 1.80603 9.48614 1.6875 9.18777 1.6875H5.81278C5.51441 1.6875 5.22826 1.80603 5.01728 2.017C4.8063 2.22798 4.68778 2.51413 4.68778 2.8125V3.9375"
        stroke="#FF3838"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
