import { Button, Space } from "antd";
import { CustomModalProps } from "../../types";
import CustomModal from "./CustomModal";

type Props = {
  questionText?: string;
  handleYes?: (id: string) => void;
  handleCancel?: (data: any) => void;
  deleteLoading: boolean;
} & CustomModalProps;

export default function DeleteModal({
  data,
  questionText,
  deleteLoading,
  handleClose,
  handleYes,
  handleCancel,
  ...rest
}: Props) {
  return (
    <CustomModal handleClose={handleClose} {...rest}>
      <div className="p-6 pt-4">
        <h4 className="text-body font-medium text-base">
          {questionText || "Are you sure to delete?"}
        </h4>

        <Space className="flex justify-end mt-4">
          <Button onClick={handleClose}>No</Button>
          <Button
            onClick={() => {
              handleYes && handleYes(data?._id);
              handleCancel && handleCancel(data);
            }}
            type="primary"
            loading={deleteLoading}
          >
            Yes
          </Button>
        </Space>
      </div>
    </CustomModal>
  );
}
