import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import ManageRetailerForm from "../../../components/ModalForm/ManageRetailerForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import {
  deleteRetailerAsync,
  getAllRetailerAsync,
} from "../../../store/features/retailer/retailerApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetAllRetailerData, TableData } from "../../../types/redux";

export default function ManageRetailers() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const [selectDivision, setSelectDivision] = useState("");
  const [selectDistict, setSelectDistrict] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [selectBsrmAuthorized, setSelectBsrmAuthorized] = useState("");

  const { limit, page, handlePagination, handleLimit } = useTable();
  const { loading, countRetailer, retailer, deleteLoading } = useAppSelector(
    (state) => state?.retailer
  );
  const { districts, divisions } = useAppSelector((state) => state.location);

  const columns: ColumnsType<TableData<GetAllRetailerData>> = [
    {
      title: "No.",
      align: "center",

      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Organization Name",
      align: "left",
      render: (val: GetAllRetailerData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.organizationName}</p>
          <p className="data_table_secondary_data">{val?.ownerName}</p>
        </Space>
      ),
    },
    {
      title: "Retailer Code",
      dataIndex: "retailerCode",
      // width: 300,
    },
    {
      title: "Division",
      dataIndex: "divisionId",
      render: (val) => val?.name,
    },

    {
      title: "District",
      dataIndex: "districtId",
      render: (val) => val?.name,
    },

    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      align: "left",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span
          className={`status ${
            val === "Active" ? "status-done" : "status-danger"
          }`}
        >
          {val}
        </span>
      ),
    },

    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetAllRetailerData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          {/* <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          /> */}
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteRetailerAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllRetailerAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          districtId: selectDistict,
          divisionId: selectDivision,
          bsrmAuthorized: selectBsrmAuthorized,
        },
      })
    );
  }, [
    dispatch,
    limit,
    page,
    selectBsrmAuthorized,
    selectDistict,
    selectDivision,
    selectStatus,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val: boolean) {
    if (val) {
      toggle();
      if (val) {
        getData();
      }
    }
  }

  //district
  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: page,
          limit: limit,
          // name: selectDistrict,
          // divisionId: selectDivision,
        },
      })
    );
  }, [dispatch, limit, page]);

  // division
  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  const allStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const allStatusBsrm = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  return (
    <AdminLayout
      title="Retailer List" // browser title
      headerTitle="Retailer List" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
                // data:"",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<GetAllRetailerData>>
          loading={loading}
          total={countRetailer}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(retailer)}
          handleLimit={handleLimit}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                size="middle"
                placeholder="Filter By Division"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={setSelectDivision}
              />
              <Select
                placeholder="Filter By District"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                onChange={setSelectDistrict}
              />
              <Select
                placeholder="Status"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
              <Select
                placeholder="BSRM Dealer"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatusBsrm}
                onChange={setSelectBsrmAuthorized}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <ManageRetailerForm
          title={
            open.type === "EDIT"
              ? "Update Retailer Store"
              : open.type === "VIEW"
              ? "View Retailer Store"
              : "Create Retailer Store"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          // questionText="Are you sure to delete product category?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
