export default function BsrmOrderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M23.865 18.6323C25.251 15.1283 26.655 11.6333 28.05 8.1353C28.077 8.0723 28.098 8.0033 28.119 7.9283C28.155 7.8143 27.918 7.7183 27.594 7.7183H7.488C7.164 7.7183 6.846 7.4603 6.78 7.1423C6.585 6.2003 6.393 5.2733 6.201 4.3553C6.135 4.0373 5.817 3.7793 5.493 3.7793H2.463C2.139 3.7793 1.875 4.0433 1.875 4.3673V4.6733C1.875 4.9973 2.139 5.2613 2.463 5.2613H4.278C4.602 5.2613 4.92 5.5193 4.983 5.8373C6.09 11.2883 7.191 16.7123 8.298 22.1513C8.364 22.4693 8.178 22.8593 7.962 23.0993C7.659 23.4353 7.47 23.8763 7.473 24.3503C7.476 25.3823 8.322 26.2283 9.351 26.2313C10.374 26.2313 11.247 25.3733 11.25 24.3593C11.25 24.1313 11.208 23.9123 11.127 23.7053C11.01 23.4023 11.097 23.1983 11.424 23.1983H20.766C21.09 23.1983 21.18 23.4023 21.06 23.7053C20.982 23.9033 20.937 24.1163 20.931 24.3383C20.907 25.3343 21.792 26.2283 22.806 26.2343C23.835 26.2403 24.699 25.4003 24.708 24.3773C24.714 23.8013 24.441 23.2703 24.021 22.9163C23.772 22.7063 23.52 22.4183 23.52 22.1783C23.52 21.9383 23.256 21.7433 22.932 21.7433H10.365C10.041 21.7433 9.723 21.4853 9.657 21.1673C9.552 20.6543 9.447 20.1473 9.342 19.6283C9.276 19.3103 9.357 19.0523 9.525 19.0523H9.825C14.289 19.0523 18.753 19.0493 23.214 19.0613C23.565 19.0583 23.733 18.9653 23.865 18.6323Z"
        fill="white"
      />
    </svg>
  );
}
