import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input } from "antd";
import BsrmCar from "../../../../assets/icons/BsrmCar";

type Props = {
  handleDeliveryDuration: (val: boolean) => void;
  deliveryDuration: "Yes" | "No";
  viewId: string | undefined;
};

export default function Shipping({
  handleDeliveryDuration,
  deliveryDuration,
  viewId,
}: Props) {
  return (
    <>
      <div className="mb-4">
        {/* <Checkbox>Track stock quantity for this product </Checkbox> */}
        <Form.Item
          name="weight"
          label="Weight(gm)"
          className="w-[32.33%]"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="0"
            suffix={<InfoCircleOutlined />}
            disabled={viewId ? true : false}
          />
        </Form.Item>
      </div>

      <div className="grid grid-cols-3 items-center gap-2.5">
        <Form.Item
          name="length"
          label="Dimension(cm)"
          extra="Length"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="0"
            suffix={<InfoCircleOutlined />}
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="width"
          extra="Width"
          label=" "
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="0"
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="height"
          label=" "
          extra="Height"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="0"
            disabled={viewId ? true : false}
          />
        </Form.Item>
      </div>

      <Form.Item
        // name="deliveryDuration"
        label="Want to Show this Delivery Note?"
      >
        <div className="bg-[#F2F5FF] p-[14px] rounded-[10px] mt-1.5">
          <p className="text-[14px] font-semibold leading-6 flex items-center">
            <Checkbox
              checked={deliveryDuration === "Yes" ? true : false}
              onChange={(val) => {
                // form.setFieldsValue({
                //   deliveryDuration: val?.target?.checked ? "Yes" : "No",
                // });
                handleDeliveryDuration(val?.target?.checked);
              }}
              disabled={viewId ? true : false}
            >
              <span className="ml-3 mr-4 relative top-0.5">
                <BsrmCar />
              </span>
              <span className="relative -top-[3px] text-[#4A72FF]">
                Usually Delivers in 24 to 48 hours.
              </span>
            </Checkbox>
          </p>
        </div>
      </Form.Item>
    </>
  );
}
