import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetAllRetailerData,
  GetAllRetailerParams,
  GetCommonData,
  Params,
  PostRequest,
  RetailerData,
  SingleDataParams,
  SingleRetailerData,
} from "../../../types/redux";

// create a retailer
export const createRetailerAsync = createAsyncThunk<
  null,
  PostRequest<RetailerData, CommonOthers>
>(
  "admin-retailer/createRetailer",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageRetailer, data);

      reRender(true);
      toast("success", "Retailer create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Retailer create fail");
      return rejectWithValue(error);
    }
  }
);

// get all retailer
export const getAllRetailerAsync = createAsyncThunk<
  GetCommonData<GetAllRetailerData>,
  Params<GetAllRetailerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-retailer/getAllRetailer", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageRetailer}/data`, {
      params,
    });
    return {
      data: data?.data?.retailers,
      length: data?.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Retailer fail");
    return rejectWithValue(error);
  }
});

// update a single Scratch Type
export const singleRetailerAsync = createAsyncThunk<
  SingleRetailerData,
  Params<SingleDataParams>
>("admin-retailer/singleRetailer", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageRetailer}/${params?.id}`);
    return data.data?.retailer as SingleRetailerData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single scratch type get fail");
    return rejectWithValue(error);
  }
});

// update a product category
export const updateRetailerAsync = createAsyncThunk<
  null,
  PostRequest<any, any>
>(
  "admin-retailer/updateRetailer",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageRetailer}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Retailer update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Retailer update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a product category
export const deleteRetailerAsync = createAsyncThunk<null, Params<any>>(
  "admin-retailer/deleteRetailer",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageRetailer}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Retailer delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Retailer delete fail");
      return rejectWithValue(error);
    }
  }
);
