export const BASE_API = {
  // Auth -(Account Management)
  login: "/api/v1/admin-auth/login",

  logout: "/api/v1/admin-auth/logout",
  retailerForgotPassword: "/api/v1/retailer-auth/forgot-password",
  retailerUpdatePassword: "/api/v1/retailer-auth/reset-password",

  // change password
  ChangePass: "/api/v1/admin-auth/update-my-password",
  retailerChangePassword: "/api/v1/retailer-auth/update-my-password",

  // Product category (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  manageProductCategory: "/api/v1/product-category",
  // Scratch type (create, update/:id, singleGet/:id, getAll/data?query, typeSetup/setup/:id)
  manageScratchType: "/api/v1/scratch-type",
  // Division (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  manageDivision: "/api/v1/division",
  // District (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  manageDistrict: "/api/v1/district",
  // Expert category (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  manageExpertCategory: "/api/v1/expert-category",
  // Expert  (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  manageExpert: "/api/v1/expert",
  // Retailer  (create, update/:id, singleGet/:id, getAll/data?query)
  manageRetailer: "/api/v1/retailer",

  // Product Brand  (create, update/:id, singleGet/:id, getAll/data?query)
  manageProductBrand: "/api/v1/brand",

  // slide  (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageAppSlide: "/api/v1/slide",

  // RMC  (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageRmc: "/api/v1/ready-mix",

  // blog-category  (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageBlogCategory: "/api/v1/blog-category",

  // blog  (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageBlog: "/api/v1/blog",

  // bank  (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageBank: "/api/v1/bank",
  // bank  (update/:id, singleGet/:id, getAll/data?query, approve, reject)
  manageAdminProduct: "/api/v1/product",

  // order  (singleGet/:id, getAll/data?query)
  manageAdminOrder: "/api/v1/order/",

  //faq  (update/:id, singleGet/:id, getAll/data?query,  delete/:id)
  manageFaq: "/api/v1/faq",

  //customer  (singleGet/:id, getAll/data?query)
  manageCustomer: "/api/v1/customer",

  // common-data  (create)
  manageCommonData: "/api/v1/common-data",

  /* RETAILER API */
  retailerLogin: "/api/v1/retailer-auth/login",
  retailerLogout: "/api/v1/retailer-auth/logout",
  // (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageRetailerProduct: "/api/v1/retailer-panel/product",

  // (create, update/:id, singleGet/:id, getAll/data?query, delete/:id)
  manageRetailerOrder: "/api/v1/retailer-panel/order",

  // (singleGet/:id)
  manageNotification: "/api/v1/retailer-panel/notification",
  manageAdminNotification: "/api/v1/notification",

  // customer live chat
  getCustomerData: "/api/v1/retailer-panel/profile/customer-list",
  getRetailerChatData: "/api/v1/retailer-panel/profile/get-live-chat-data",
  getRetailerOnlineStatus:
    "/api/v1/retailer-panel/profile/check-customer-online-status",

  fileUpload: "/api/v1/data-dump/upload-File",

  getAdminDashboard: "/api/v1/dashboard",
  getRetailerDashboard: "/api/v1/retailer-panel/dashboard",
};
