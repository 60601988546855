import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

type Props = {
  viewId: string | undefined;
};

export default function Advance({ viewId }: Props) {
  const [value, setValue] = useState("");
  return (
    <>
      <div className="mb-4">
        <Form.Item
          name="shippingDeliveryNote"
          label="Shipping/Delivery Notes"
          className=""
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select region",
          //   },
          // ]}
        >
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Type Shipping/Delivery Notes..."
            autoSize={{ minRows: 3, maxRows: 5 }}
            disabled={viewId ? true : false}
          />
        </Form.Item>
      </div>

      {/* <div className="">
        <Checkbox disabled={viewId ? true : false}>
          <p className="text-[#3D4A5C] text-[14px] font-semibold leading-6">
            Enable Reviews
          </p>
        </Checkbox>
      </div> */}
    </>
  );
}
