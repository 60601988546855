/* eslint-disable no-useless-computed-key */
import { Button, Dropdown, Form, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import BsrmBackArrow from "../../../assets/icons/BsrmBackArrow";
import RemoveIcon from "../../../assets/icons/RemoveIcon";
import ThreeDotIcon from "../../../assets/icons/ThreeDotIcon";
import Loader from "../../../components/common/Loader";
import {
  ArrayOption,
  findDataSelect,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllProductCategoryAsync } from "../../../store/features/productCategory/productCategoryAPI";
import {
  setupScratchTypeAsync,
  singleScratchTypeAsync,
} from "../../../store/features/scratchType/scratchTypeAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Subcategory {
  categoryId?: string;
  serviceType?: string;
  subcategories?: Subcategory[];
}

interface Row {
  categoryId?: string;
  serviceType?: string;
  subcategories: Subcategory[];
}

function unmergeCategories(input: any) {
  let output = [] as any;

  input?.forEach((item: any) => {
    if (item?.serviceType === "Next Level") {
      if (item?.subcategories) {
        item?.subcategories?.forEach((sub: any) => {
          let newItem = {
            categoryId: item?.categoryId,
            serviceType: item?.serviceType,
            subcategories: [sub],
          };
          output.push(newItem);
        });
      }
    } else {
      let newItem = {
        categoryId: item?.categoryId,
        serviceType: item?.serviceType,
      };
      output.push(newItem);
    }
  });
  return output;
}

export default function ScratchTypeSetup() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: scratchId } = useParams();
  const { productCategory, updateLoading } = useAppSelector(
    (state) => state.productCategory
  );
  const { singleLoading, singleScratchType } = useAppSelector(
    (state) => state.scratchType
  );

  const [rows, setRows] = useState<Row[]>([{ subcategories: [] }]);
  const handleAddRow = (index: number) => {
    const newRow: Row = { subcategories: [] };
    const updatedRows = [...rows];
    updatedRows.splice(index + 1, 0, newRow);

    setRows(updatedRows);

    const getValues = form.getFieldsValue();
    const updatedRowValue = [...getValues?.categories];
    updatedRowValue?.splice(index + 1, 0, newRow);

    form.setFieldsValue({
      categories: updatedRowValue,
    });
  };

  const handleRemoveRow = (index: number) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(updatedRows);

    const getValues = form.getFieldsValue();
    const updatedRowValue = getValues?.categories?.filter(
      (_: any, idx: number) => idx !== index
    );

    form.setFieldsValue({
      categories: updatedRowValue,
    });
  };

  const handleServiceTypeChange = (value: string, index: number) => {
    const updatedRows = [...rows];
    if (value === "Next Level") {
      updatedRows[index].subcategories.push({
        subcategories: [],
      });
      updatedRows[index].serviceType = "";
    } else {
      updatedRows[index].serviceType = value;
    }
    setRows(updatedRows);
  };

  const findData = (id: string) => {
    const product = productCategory?.find((el) => el?._id === id);
    return {
      label: product?.label,
      icon: product?.icon,
      title: product?.title,
      banner: product?.banner || "",
      details: product?.details,
    };
  };

  const onFinish = (values: any) => {
    const dataValues = values?.categories?.map((el: any) => {
      if (el?.subcategories?.length > 1) {
        return {
          ...el,
          serviceType: "Next Level",
          ...findData(el?.categoryId),
          subcategories: [
            {
              ...el?.subcategories?.[0],
              serviceType: "Next Level",
              ...findData(el?.subcategories?.[0]?.categoryId),
              subcategories: [
                {
                  ...el?.subcategories?.[1],
                  serviceType: el?.serviceType,
                  ...findData(el?.subcategories?.[1]?.categoryId),
                },
              ],
            },
          ],
        };
      }

      if (el?.subcategories?.length === 1) {
        return {
          ...el,
          serviceType: "Next Level",
          ...findData(el?.categoryId),
          subcategories: [
            {
              ...el?.subcategories?.[0],
              serviceType: el?.serviceType,
              ...findData(el?.subcategories?.[0]?.categoryId),
            },
          ],
        };
      }

      return { ...el, ...findData(el?.categoryId) };
    });

    function mergeCategories(data: any) {
      const mergedCategories: any = [];

      data.forEach((category: any) => {
        const existingCategory = mergedCategories.find(
          (cat: any) => cat.categoryId === category.categoryId
        );

        if (existingCategory) {
          if (category?.subcategories && existingCategory?.subcategories) {
            category.subcategories.forEach((subCat: any) => {
              const existingSubCategory = existingCategory?.subcategories.find(
                (sub: any) => sub?.categoryId === subCat?.categoryId
              );
              const subCategory = existingSubCategory?.subcategories || [];

              if (existingSubCategory) {
                if (subCat?.subcategories) {
                  existingSubCategory.subcategories = [
                    ...subCategory,
                    ...subCat.subcategories,
                  ];
                }
              } else {
                existingCategory.subcategories.push(subCat);
              }
            });
          }
        } else {
          mergedCategories.push(category);
        }
      });

      // Now merge subcategories within each main category
      mergedCategories.forEach((category: any) => {
        if (category.subcategories) {
          const mergedSubCategories: any = [];
          category.subcategories.forEach((subCat: any) => {
            const existingSubCategory = mergedSubCategories.find(
              (sub: any) => sub.categoryId === subCat.categoryId
            );
            if (existingSubCategory) {
              if (subCat.subcategories && existingSubCategory.subcategories) {
                existingSubCategory.subcategories = [
                  ...existingSubCategory.subcategories,
                  ...subCat.subcategories,
                ];
              }
            } else {
              mergedSubCategories.push(subCat);
            }
          });
          category.subcategories = mergedSubCategories;
        }
      });

      return mergedCategories;
    }

    dispatch(
      setupScratchTypeAsync({
        data: { categories: mergeCategories(dataValues) },
        others: {
          id: scratchId as string,
          reRender,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (scratchId) {
      dispatch(
        singleScratchTypeAsync({
          params: {
            id: scratchId,
          },
        })
      );
    }
  }, [dispatch, scratchId]);

  function reRender(val?: boolean) {
    if (val) {
      navigate("/admin/scratch-type");
    }
  }

  useEffect(() => {
    const singleType = unmergeCategories(
      singleScratchType?.categories?.map((el) => {
        if (el?.subcategories) {
          return {
            ...el,
            subcategories: unmergeCategories(el?.subcategories),
          };
        }

        return el;
      })
    )?.map((el: any) => {
      const updateData = {
        ...el,
        subcategories: [] as any[],
        categoryId: findDataSelect(productCategory, el?.categoryId),
        serviceType:
          el?.serviceType !== "Next Level"
            ? el?.serviceType
            : el?.subcategories[0]?.serviceType !== "Next Level"
            ? el?.subcategories[0]?.serviceType
            : el?.subcategories?.[0]?.subcategories?.[0]?.serviceType,
      };

      if (el?.subcategories?.[0]) {
        updateData?.subcategories?.push({
          ...el?.subcategories?.[0],
          categoryId: findDataSelect(
            productCategory,
            el?.subcategories?.[0]?.categoryId
          ),
          subcategories: null,
        });
      }

      if (el?.subcategories?.[0]?.subcategories?.[0]) {
        updateData?.subcategories?.push({
          ...el?.subcategories?.[0]?.subcategories?.[0],
          categoryId: findDataSelect(
            productCategory,
            el?.subcategories?.[0]?.subcategories?.[0]?.categoryId
          ),
          subcategories: null,
        });
      }

      return updateData;
    });

    if (scratchId && singleScratchType?.categories?.length) {
      form.setFieldsValue({
        categories: singleType,
      });
      setRows(singleType as any);
    } else {
      form.setFieldsValue({
        categories: [],
      });
      setRows([{ subcategories: [] }]);
    }
  }, [form, productCategory, scratchId, singleScratchType?.categories]);

  return (
    <AdminLayout
      title="Scratch"
      headerTitle={
        <Link to="/admin/scratch-type" className="flex items-center gap-5">
          <div className="w-10 h-10 border border-solid border-[#D7DFF9] rounded-full flex justify-center items-center ">
            <BsrmBackArrow />
          </div>
          <h3 className="text-body text-[24px]">
            {singleScratchType?.name} Setup
          </h3>
        </Link>
      }
      className="dashboard"
    >
      <div className="bg-white p-6 rounded-md">
        {singleLoading ? (
          <Loader />
        ) : (
          <Form
            className="custom-form custom-placeholder-form hide_star_mark"
            layout="vertical"
            onFinish={onFinish}
            size="large"
            form={form}
          >
            {rows.map((row, rowIndex) => (
              <div className="grid grid-cols-4 gap-2.5" key={rowIndex}>
                <Form.Item
                  name={["categories", rowIndex, "categoryId"]}
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <Select
                    rootClassName="select_full_option"
                    placeholder="Select Category"
                    size="large"
                    allowClear
                    showSearch
                    filterOption={selectSearchOption}
                    options={ArrayOption(productCategory, "label", "_id")}
                  />
                </Form.Item>
                {row.subcategories.map((subcat, subIndex) => (
                  <Form.Item
                    key={subIndex}
                    name={[
                      "categories",
                      rowIndex,
                      "subcategories",
                      subIndex,
                      "categoryId",
                    ]}
                    label="Sub Category"
                    rules={[
                      {
                        required: true,
                        message: "Please select subcategory",
                      },
                    ]}
                  >
                    <Select
                      rootClassName="select_full_option"
                      placeholder="Select Subcategory"
                      size="large"
                      allowClear
                      showSearch
                      filterOption={selectSearchOption}
                      options={ArrayOption(productCategory, "label", "_id")}
                    />
                  </Form.Item>
                ))}
                <div className="flex items-center gap-2.5">
                  <Form.Item
                    name={["categories", rowIndex, "serviceType"]}
                    label="Service Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                    className="flex-1"
                  >
                    <Select
                      placeholder="Select type"
                      size="large"
                      allowClear
                      showSearch
                      filterOption={selectSearchOption}
                      options={typeList
                        .slice(row?.subcategories?.length >= 2 ? 1 : 0)
                        .map((el) => ({
                          value: el,
                          label: el,
                        }))}
                      onChange={(value) => {
                        handleServiceTypeChange(value, rowIndex);

                        if (value === "Next Level") {
                          // form.setFieldsValue({
                          // });
                          // console.log({
                          //   categories: form.getFieldValue("categories"),
                          // });
                        }
                      }}
                    />
                  </Form.Item>
                  <div className="!mt-[17px]">
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Button
                                className="status-success"
                                block
                                icon={<AddIcon />}
                                onClick={() => handleAddRow(rowIndex)}
                              >
                                Add
                              </Button>
                            ),
                            className: "!px-1.5",
                          },
                          {
                            key: "2",
                            label: (
                              <Button
                                className="status-fail"
                                block
                                icon={<RemoveIcon />}
                                onClick={() => handleRemoveRow(rowIndex)}
                              >
                                Remove
                              </Button>
                            ),
                            className: "!px-1.5",
                          },
                        ],
                      }}
                      placement="bottomRight"
                      className="scratch_dropdown"
                    >
                      <Button
                        className="!pl-[22px] !pr-6"
                        icon={<ThreeDotIcon />}
                      />
                    </Dropdown>
                  </div>
                </div>
              </div>
            ))}
            <Space size={15} className="flex justify-center items-center mt-6">
              <Link to="/admin/scratch-type">
                <Button className="custom_normal_btn cancel_btn">Cancel</Button>
              </Link>
              <Button
                size="middle"
                className="custom_normal_btn"
                type="primary"
                htmlType="submit"
                loading={updateLoading}
              >
                Save
              </Button>
            </Space>
          </Form>
        )}
      </div>
    </AdminLayout>
  );
}

const typeList = [
  "Next Level",
  "Budget Calculator",
  "Find Retailer",
  "EMI Calculator",
  "Expert List",
  "Order",
  "Call to Expert",
  "Ready Mix",
  "Steel Bars",
  "Paint Cost Calculator",
  "Take Photo",
];
