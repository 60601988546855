import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllRetailerProductParams,
  GetRetailerProductData,
  GetSingleRetailerProduct,
  RetailerProductData,
} from "../../../types/redux/ADMIN/retailerProduct";

// create a product category
export const createRetailerProductAsync = createAsyncThunk<
  null,
  PostRequest<RetailerProductData, CommonOthers>
>(
  "retailer-product/createRetailerProduct",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageRetailerProduct, data);
      reRender(true);
      toast("success", "Product create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors?.response?.data as ErrorMessage;
      toast("error", error?.message || "Product create fail");
      return rejectWithValue(error);
    }
  }
);

// get all product category
export const getAllRetailerProductAsync = createAsyncThunk<
  GetCommonData<GetRetailerProductData>,
  Params<GetAllRetailerProductParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-product/getAllRetailerProduct",
  async ({ params }, { rejectWithValue }) => {
    const { ...rest } = params as GetAllRetailerProductParams;

    try {
      const data = await AXIOS.get(`${BASE_API.manageRetailerProduct}/data`, {
        params: { ...rest },
      });
      return {
        data: data.data?.products,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product category fail");
      return rejectWithValue(error);
    }
  }
);

// SKU data get
export const getRetailerProductSKUAsync = createAsyncThunk<
  number | undefined,
  null,
  {
    rejectValue: ErrorMessage;
  }
>("retailer-product/getRetailerProductSKU", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.manageRetailerProduct}/generate-product-sku`
    );

    return data?.data?.sku;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "SKU fail");
    return rejectWithValue(error);
  }
});

// update a product category
export const updateRetailerProductAsync = createAsyncThunk<
  null,
  PostRequest<RetailerProductData, SingleDataParams>
>(
  "retailer-product/updateRetailerProduct",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageRetailerProduct}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Product update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product update fail");
      return rejectWithValue(error);
    }
  }
);
// delete a product category
export const deleteRetailerProductAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>(
  "retailer-product/deleteRetailerProduct",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageRetailerProduct}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Product delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Product delete fail");
      return rejectWithValue(error);
    }
  }
);

// single api
export const getSingleRetailerProductAsync = createAsyncThunk<
  GetSingleRetailerProduct,
  Params<SingleDataParams>
>(
  "retailer-product/getSingleRetailerProduct",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageRetailerProduct}/${params?.id}`
      );

      return data.data?.product as GetSingleRetailerProduct;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product fail");
      return rejectWithValue(error);
    }
  }
);

// update a product status
export const updateRetailerProductStatusAsync = createAsyncThunk<
  null,
  PostRequest<any, SingleDataParams>
>(
  "retailer-product/updateRetailerProductStatus",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageRetailerProduct}/${id}`, data);

      reRender && reRender(true);
      toast("success", `Product ${data?.status?.toLowerCase()} successfully`);
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast(
        "error",
        error?.message || `Product ${data?.status?.toLowerCase()} fail`
      );
      return rejectWithValue(error);
    }
  }
);
