import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  DivisionData,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import { GetFaqData } from "../../../types/redux/ADMIN/faq";

// create
export const createFaqAsync = createAsyncThunk<
  null,
  PostRequest<GetFaqData, CommonOthers>
>(
  "admin-faq/createFaq",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageFaq, data);

      reRender(true);
      toast("success", "Faq create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Faq create fail");
      return rejectWithValue(error);
    }
  }
);

// get all faq
export const getAllFaqAsync = createAsyncThunk<
  GetCommonData<GetFaqData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-faq/getAllFaq", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageFaq}/data`, {
      params,
    });
    return {
      data: data.data?.faqs,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Faq fail");
    return rejectWithValue(error);
  }
});

// update a division
export const updateFaqAsync = createAsyncThunk<
  null,
  PostRequest<DivisionData, SingleDataParams>
>(
  "admin-faq/updateFaq",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageFaq}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Faq update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Faq update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a faq
export const deleteFaqAsync = createAsyncThunk<null, Params<SingleDataParams>>(
  "admin-faq/deleteFaq",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageFaq}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Faq delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Faq delete fail");
      return rejectWithValue(error);
    }
  }
);
