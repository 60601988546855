export default function BsrmDeleteIconTwo() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="12" fill="#8897AE" stroke="white" />
      <path
        d="M9.28015 8.22009C9.13798 8.08761 8.94993 8.01549 8.75563 8.01892C8.56133 8.02234 8.37594 8.10106 8.23853 8.23847C8.10112 8.37588 8.02241 8.56127 8.01898 8.75557C8.01555 8.94987 8.08767 9.13792 8.22015 9.28009L11.9402 13.0001L8.22015 16.7201C8.14647 16.7888 8.08736 16.8716 8.04637 16.9636C8.00538 17.0556 7.98334 17.1549 7.98156 17.2556C7.97979 17.3563 7.99831 17.4563 8.03603 17.5497C8.07375 17.6431 8.1299 17.7279 8.20112 17.7991C8.27233 17.8703 8.35717 17.9265 8.45056 17.9642C8.54394 18.0019 8.64397 18.0205 8.74468 18.0187C8.84538 18.0169 8.94469 17.9949 9.03669 17.9539C9.12869 17.9129 9.21149 17.8538 9.28015 17.7801L13.0002 14.0601L16.7202 17.7801C16.7888 17.8538 16.8716 17.9129 16.9636 17.9539C17.0556 17.9949 17.1549 18.0169 17.2556 18.0187C17.3563 18.0205 17.4564 18.0019 17.5498 17.9642C17.6431 17.9265 17.728 17.8703 17.7992 17.7991C17.8704 17.7279 17.9266 17.6431 17.9643 17.5497C18.002 17.4563 18.0205 17.3563 18.0187 17.2556C18.017 17.1549 17.9949 17.0556 17.9539 16.9636C17.9129 16.8716 17.8538 16.7888 17.7802 16.7201L14.0602 13.0001L17.7802 9.28009C17.9126 9.13792 17.9848 8.94987 17.9813 8.75557C17.9779 8.56127 17.8992 8.37588 17.7618 8.23847C17.6244 8.10106 17.439 8.02234 17.2447 8.01892C17.0504 8.01549 16.8623 8.08761 16.7202 8.22009L13.0002 11.9401L9.28015 8.22009Z"
        fill="white"
      />
    </svg>
  );
}
