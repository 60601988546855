import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllRetailerProductParams,
  GetRetailerProductData,
  GetSingleRetailerProduct,
  RetailerProductData,
} from "../../../types/redux/ADMIN/retailerProduct";

// get all product
export const getAllAdminProductAsync = createAsyncThunk<
  GetCommonData<GetRetailerProductData>,
  Params<GetAllRetailerProductParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-product/getAllAdminProduct",
  async ({ params }, { rejectWithValue }) => {
    const { ...rest } = params as GetAllRetailerProductParams;

    try {
      const data = await AXIOS.get(`${BASE_API.manageAdminProduct}/data`, {
        params: { ...rest },
      });

      return {
        data: data.data?.products,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product category fail");
      return rejectWithValue(error);
    }
  }
);

// update a product
export const updateAdminProductAsync = createAsyncThunk<
  null,
  PostRequest<RetailerProductData, SingleDataParams>
>(
  "admin-product/updateAdminProduct",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageAdminProduct}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Product update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product update fail");
      return rejectWithValue(error);
    }
  }
);

// update approve product
export const updateAdminApproveProductAsync = createAsyncThunk<
  null,
  PostRequest<any, SingleDataParams>
>(
  "admin-product/updateAdminApproveProduct",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(
        `${BASE_API.manageAdminProduct}/approve-product/${id}`,
        data
      );

      reRender && reRender(true);
      toast("success", "successfully Approved");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Approved fail");
      return rejectWithValue(error);
    }
  }
);

// update approve product
export const updateAdminRejectProductAsync = createAsyncThunk<
  null,
  PostRequest<any, SingleDataParams>
>(
  "admin-product/updateAdminRejectProduct",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(
        `${BASE_API.manageAdminProduct}/reject-product/${id}`,
        data
      );

      reRender && reRender(true);
      toast("success", "successfully Reject");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Reject fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const getSingleAdminProductAsync = createAsyncThunk<
  GetSingleRetailerProduct,
  Params<SingleDataParams>
>(
  "admin-product/getSingleAdminProduct",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageAdminProduct}/${params?.id}`
      );

      return data.data?.product as GetSingleRetailerProduct;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product fail");
      return rejectWithValue(error);
    }
  }
);
