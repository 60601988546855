import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllRetailerData,
  RetailerInitialStatus,
  SingleRetailerData,
} from "../../../types/redux";
import {
  createRetailerAsync,
  deleteRetailerAsync,
  getAllRetailerAsync,
  singleRetailerAsync,
  updateRetailerAsync,
} from "./retailerApi";

const initialState: RetailerInitialStatus<
  GetAllRetailerData,
  SingleRetailerData
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailer: [],
  singleRetailer: null,
  countRetailer: 0,
};

const retailerSlice = createSlice({
  name: "admin-retailer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create retailer
    builder
      .addCase(createRetailerAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createRetailerAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createRetailerAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // get all retailer
    builder
      .addCase(getAllRetailerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRetailerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailer = action.payload.data;
        state.countRetailer = action.payload.length;
      })
      .addCase(getAllRetailerAsync.rejected, (state) => {
        state.loading = false;
        state.retailer = [];
        state.countRetailer = 0;
      });

    // update productCategory
    builder
      .addCase(updateRetailerAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRetailerAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateRetailerAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // delete productCategory
    builder
      .addCase(deleteRetailerAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteRetailerAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteRetailerAsync.rejected, (state) => {
        state.deleteLoading = false;
      });

    // single retailer
    builder
      .addCase(singleRetailerAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleRetailer = null;
      })

      .addCase(singleRetailerAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleRetailer = action.payload;
      })
      .addCase(singleRetailerAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleRetailer = null;
      });
  },
});

export default retailerSlice;
