import { Button, Form, FormProps, Input, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  ArrayOption,
  createHash,
  findDataSelect,
  removeHash,
  selectSearchOption,
} from "../../helpers/siteConfig";
import {
  createProductCategoryAsync,
  getAllProductCategoryAsync,
  updateProductCategoryAsync,
} from "../../store/features/productCategory/productCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetProductCategoryData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ProductCategoryForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [iconName, setIconName] = useState("Upload Icon");
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [bannerName, setBannerName] = useState("Upload Banner");
  const { postLoading, updateLoading, productAllCategory } = useAppSelector(
    (state) => state.productCategory
  );

  const categoryData = data as GetProductCategoryData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, details_bangla, ...rest } = values;
    console.log(details_bangla);
    const newValues = {
      ...rest,
      details: createHash(details),
      details_bangla: createHash(details_bangla),
    };

    if (type === "EDIT" && categoryData?._id) {
      dispatch(
        updateProductCategoryAsync({
          data: newValues,
          others: {
            reRender,
            id: categoryData?._id,
          },
        })
      );
    } else {
      dispatch(
        createProductCategoryAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (type === "EDIT" && categoryData?._id && updateLoading === false) {
      // setCategoryIcon(categoryData?.icon);
      const fileNameArr = stringToArray(categoryData?.icon, ".");
      setIconName(
        categoryData?.icon
          ? `${sliceString(categoryData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Icon"
      );

      const bannerNameArr = stringToArray(categoryData?.banner, ".");
      setBannerName(
        categoryData?.banner
          ? `${sliceString(categoryData?.banner, 15, "...")}.${
              bannerNameArr?.[bannerNameArr?.length - 1]
            }`
          : "Upload Banner"
      );

      form.setFieldsValue({
        name: categoryData?.name,
        name_bangla: categoryData?.name_bangla,
        label: categoryData?.label,
        label_bangla: categoryData?.label_bangla,
        title: categoryData?.title,
        title_bangla: categoryData?.title_bangla,
        icon: categoryData?.icon,
        banner: categoryData?.banner,
        parentCategoryId: findDataSelect(
          productAllCategory,
          categoryData?.parentCategoryId?._id
        ),
        categoryType: categoryData?.categoryType,
        details: categoryData?.details ? removeHash(categoryData?.details) : "",
        details_bangla: categoryData?.details_bangla
          ? removeHash(categoryData?.details_bangla)
          : "",
      });
    }
  }, [
    categoryData?._id,
    categoryData?.banner,
    categoryData?.categoryType,
    categoryData?.details,
    categoryData?.icon,
    categoryData?.label,
    categoryData?.title,
    categoryData?.name,
    categoryData?.parentCategoryId,
    form,
    productAllCategory,
    type,
    updateLoading,
    categoryData?.name_bangla,
    categoryData?.label_bangla,
    categoryData?.title_bangla,
    categoryData?.details_bangla,
  ]);

  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
          isAllData: true,
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch]);

  return (
    <CustomModal width={750} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark "
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="grid md:grid-cols-2 gap-x-4">
            <Form.Item
              name="parentCategoryId"
              label="Parent Category"
              rules={[
                {
                  required: true,
                  message: "Please select parent category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                rootClassName="select_full_option"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(productAllCategory, "name", "_id")}
                // disabled={type === "VIEW"}
              />
            </Form.Item>
            <Form.Item
              name="categoryType"
              label="Category Type"
              // rootClassName="hide_form_label"
              className="!mb-2.5"
              rules={[
                {
                  required: true,
                  message: "Please select category type",
                },
              ]}
            >
              <Radio.Group>
                <Radio
                  value="Product"
                  // disabled={type === "VIEW"}
                >
                  Product Category
                </Radio>
                <Radio
                  value="Others"
                  // disabled={type === "VIEW"}
                >
                  Others Category
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="name"
              label="Category Name (English)"
              rules={[
                {
                  required: true,
                  message: "Please enter category name (english)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Name (English)"
              />
            </Form.Item>

            <Form.Item
              name="name_bangla"
              label="Category Name (Bangla)"
              rules={[
                {
                  required: true,
                  message: "Please enter category name (Bangla)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Name (Bangla)"
              />
            </Form.Item>

            <Form.Item
              name="label"
              label="Category Label (English)"
              rules={[
                {
                  required: true,
                  message: "Please enter category label (english)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Label (English)"
              />
            </Form.Item>

            <Form.Item
              name="label_bangla"
              label="Category Label (Bangla)"
              rules={[
                {
                  required: true,
                  message: "Please enter category label (Bangla)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Label (Bangla)"
              />
            </Form.Item>

            <Form.Item
              name="title"
              label="Category Title (English)"
              rules={[
                {
                  required: false,
                  message: "Please enter category title (English)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Title (English)"
              />
            </Form.Item>
            <Form.Item
              name="title_bangla"
              label="Category Title (Bangla)"
              rules={[
                {
                  required: false,
                  message: "Please enter category title (Bangla)",
                },
              ]}
            >
              <Input
                size="large"
                // disabled={type === "VIEW"}
                placeholder="Title (Bangla)"
              />
            </Form.Item>

            {type === "EDIT" ? (
              <>
                <div className="mb-4">
                  <Image
                    width={74}
                    height={74}
                    src={`${IMAGE_URL}${form.getFieldValue("icon")}`}
                    alt={APP_NAME}
                    className="bg-primary-admin !rounded-full p-2.5 border-4 border-solid border-[#B0E4CF]"
                    rootClassName="text-center"
                  />
                </div>
                <div className="mb-4">
                  <Image
                    width={140}
                    height={84}
                    src={`${IMAGE_URL}${form.getFieldValue("banner")}`}
                    alt={APP_NAME}
                    rootClassName="text-center"
                  />
                </div>
              </>
            ) : null}

            <Form.Item
              className="custom__image__input__placeholder"
              name="icon"
              label="Select Icon"
              htmlFor="..."
              extra={"Upload the icon carefully | SVG, PNG"}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload a icon",
                },
              ]}
            >
              <FileUpload
                errorMessage="Product icon upload fail"
                // disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingIcon(val)}
                handleName={(val) => setIconName(val || "Upload Icon")}
                onChange={(url) => form.setFieldsValue({ icon: url })}
              >
                <Button
                  size="large"
                  // disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingIcon ? "Uploading..." : iconName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="banner"
              label="Select Banner"
              htmlFor="..."
              extra={"Upload the banner carefully | JPG, PNG"}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload a banner",
                },
              ]}
            >
              <FileUpload
                errorMessage="Banner upload fail"
                // disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingBanner(val)}
                handleName={(val) => setBannerName(val || "Upload Banner")}
                onChange={(url) => form.setFieldsValue({ banner: url })}
              >
                <Button
                  size="large"
                  // disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingBanner ? "Uploading..." : bannerName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              name="details"
              label="Category Description (English)"
              className="md:col-span-2 "
              //  rules={[
              //    {
              //      required: true,
              //      message: "Please upload a banner",
              //    },
              //  ]}
            >
              <ReactQuill
                className="h-[150px] w-full"
                theme="snow"
                formats={formats}
                modules={modules}
                // readOnly={type === "VIEW"}
              />
            </Form.Item>
            <br />
            <Form.Item
              name="details_bangla"
              label="Category Description (Bangla)"
              className="md:col-span-2 "
            >
              <ReactQuill
                className="h-[150px] w-full"
                theme="snow"
                formats={formats}
                modules={modules}
                // readOnly={type === "VIEW"}
              />
            </Form.Item>
          </div>
          <br />

          <div className="flex justify-center">
            <Button
              className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
              type="primary"
              htmlType="submit"
              loading={postLoading || updateLoading}
            >
              {type === "EDIT" ? "Update Now" : "Create Now"}
            </Button>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
}
