export default function BsrmEditIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="33.5"
        height="33.5"
        rx="16.75"
        fill="white"
        stroke="#D7DFE9"
        strokeWidth="1.5"
      />
      <path
        d="M20.5 13.5L22.5 15.5M23.5 17.5V23C23.5 23.1326 23.4473 23.2598 23.3536 23.3536C23.2598 23.4473 23.1326 23.5 23 23.5H13C12.8674 23.5 12.7402 23.4473 12.6464 23.3536C12.5527 23.2598 12.5 23.1326 12.5 23V13C12.5 12.8674 12.5527 12.7402 12.6464 12.6464C12.7402 12.5527 12.8674 12.5 13 12.5H18.5M18 20H16V18L22 12L24 14L18 20Z"
        stroke="#5E718D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
