import { Space } from "antd";
import { useCallback, useEffect } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmEdit from "../../../assets/icons/BsrmEdit";

// import PlaceholderImage from "../../../assets/images/placeholder-image.png";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import CreateSlideForm from "../../../components/ModalForm/CreateSlideForm";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import Image from "../../../components/common/Image";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteAppSlideAsync,
  getAllAppSlideAsync,
} from "../../../store/features/AppSlide/SlideApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export default function AppSlider() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const { limit, page } = useTable();
  const { loading, countSlide, deleteLoading, slide } = useAppSelector(
    (state) => state.slide
  );

  const getData = useCallback(() => {
    dispatch(
      getAllAppSlideAsync({
        params: {
          pageNo: page,
          limit: limit,
        },
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    if (val) {
      toggle();
      getData();
    }
  }

  const handleDelete = (id: string) => {
    dispatch(
      deleteAppSlideAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  return (
    <AdminLayout
      title="Slider"
      headerTitle="App's Slider"
      className="dashboard"
      headerChildren={
        <Space size="middle" className="filtering__select__option">
          {/* <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            defaultValue="All Slide"
            className="min-w-[8rem]"
            options={[
              {
                value: "item 1",
                label: "item 1",
              },
              {
                value: "Item 2",
                label: "Item 2",
              },
            ]}
          /> */}

          {/* <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            defaultValue="Status"
            className="min-w-[8rem]"
            options={[
              {
                value: "item 1",
                label: "item 1",
              },
              {
                value: "Item 2",
                label: "Item 2",
              },
            ]}
          /> */}

          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <div className="h-full grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-6">
          {slide.map((val, index) => (
            <div key={index} className="w-[280px] ">
              <Image
                className="rounded-tl-[12px] rounded-tr-[12px]"
                width={280}
                height={150}
                preview={true}
                alt={APP_NAME}
                src={`${IMAGE_URL}${val.image}`}
              />

              <div className="p-4 gap-[10px]">
                <div className="flex text-nowrap gap-x-2.5">
                  <p className="bg-[#F2F5FF] text-[#4A72FF] py-[2px] px-2 rounded-md items-center text-[12px] font-medium leading-5">
                    {val.slidePosition}
                  </p>
                  <p className="bg-[#e3f6ed] text-[#11A75C] py-[2px] px-2 rounded-md items-center text-[12px] font-medium leading-5">
                    Active
                  </p>
                  <p className="bg-[#FFF9DF] text-[#D8A800] py-[2px] px-2 rounded-md items-center text-[12px] font-medium leading-5">
                    #{val.sorting}
                  </p>
                </div>

                <div className="flex justify-between items-center mt-2">
                  <p className="text-[16px] font-medium leading-7">
                    {val.title}
                  </p>

                  <div className="flex justify-end gap-x-2">
                    <div
                      className="rounded-full p-2 bg-accent w-8 h-8 align cursor-pointer select-none"
                      onClick={() =>
                        handleOpen({
                          type: "EDIT",
                          data: val,
                        })
                      }
                    >
                      <span className="text-center items-center ml-0.5">
                        <BsrmEdit />
                      </span>
                    </div>
                    <div
                      className="rounded-full p-2 bg-accent w-8 h-8 align cursor-pointer select-none"
                      onClick={() =>
                        handleOpen({
                          type: "DELETE",
                          data: val,
                        })
                      }
                    >
                      <span className="text-center items-center ml-0.5">
                        <BsrmDelete />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <CreateSlideForm
          title={
            open.type === "EDIT"
              ? "Update Slide"
              : open.type === "VIEW"
              ? "View Slide"
              : "Create Slide"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {/*DELETE  */}
      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          // questionText="Are you sure to delete product category?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
