import { Button, Form, FormProps } from "antd";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { formats } from "../../../components/Editor/Formats";
import { modules } from "../../../components/Editor/Modules";
import Loader from "../../../components/common/Loader";
import {
  createHash,
  refreshPage,
  removeHash,
} from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createCommonDataAsync,
  getAllCommonDataAsync,
} from "../../../store/features/comonData/CommonApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonData } from "../../../types/redux/ADMIN/commonData";

export default function AboutUs() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { loading, postLoading, settingsData } = useAppSelector(
    (state) => state.commonData
  );

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { aboutUs, aboutUs_bangla } = values;
    const newValues: CommonData = {
      type: "about-us",
      aboutUs: createHash(aboutUs),
      aboutUs_bangla: createHash(aboutUs_bangla),
    };

    dispatch(
      createCommonDataAsync({
        data: newValues,
        others: {
          reRender,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCommonDataAsync(null));
  }, [dispatch]);

  useEffect(() => {
    if (settingsData?._id && postLoading === false) {
      form.setFieldsValue({
        aboutUs: removeHash(settingsData?.aboutUs || ""),
        aboutUs_bangla: removeHash(settingsData?.aboutUs_bangla || ""),
      });
    }
  }, [
    form,
    postLoading,
    settingsData?._id,
    settingsData?.aboutUs,
    settingsData?.aboutUs_bangla,
  ]);
  function reRender(val: boolean) {}

  return (
    <AdminLayout
      title="About us" // browser title
      headerTitle="About us" // page header
      className="dashboard"
    >
      {loading && <Loader />}

      <section className="w-full h-screen bg-white rounded-[14px] p-6 items-center gap-[6px]">
        <Form onFinish={onFinish} form={form}>
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="">
              <p className="text-[14px] font-medium leading-6 text-header py-[10px]">
                BSRM About (English)
              </p>
              <Form.Item
                name="aboutUs"
                rules={[
                  {
                    required: true,
                    message: "Please enter bsrm about (English)",
                  },
                ]}
              >
                <ReactQuill
                  className="h-[400px]"
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </Form.Item>
            </div>
            <div className="">
              <p className="text-[14px] font-medium leading-6 text-header py-[10px]">
                BSRM About (Bangla)
              </p>
              <Form.Item
                name="aboutUs_bangla"
                rules={[
                  {
                    required: true,
                    message: "Please enter bsrm about (Bangla)",
                  },
                ]}
              >
                <ReactQuill
                  className="h-[400px]"
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className="text-center">
              <Button
                className="px-10"
                type="primary"
                htmlType="submit"
                loading={postLoading}
                onClick={refreshPage}
              >
                {postLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </section>
    </AdminLayout>
  );
}
