export default function BsrmCustomerManage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M0.905151 19.2491C1.82724 17.6531 3.1531 16.3278 4.74954 15.4064C6.34598 14.485 8.15679 14 10 14C11.8433 14 13.6541 14.4851 15.2505 15.4065C16.8469 16.3279 18.1728 17.6533 19.0948 19.2493M16.7499 5.75V6.875M16.7499 5.75C17.5784 5.75 18.2499 5.07843 18.2499 4.25C18.2499 3.42157 17.5784 2.75 16.7499 2.75M16.7499 5.75C15.9215 5.75 15.2499 5.07843 15.2499 4.25C15.2499 3.42157 15.9215 2.75 16.7499 2.75M16.7499 2.75V1.625M18.049 5L19.0232 5.5625M15.4509 5L14.4766 5.5625M18.049 3.5L19.0232 2.9375M15.4509 3.5L14.4766 2.9375M15.4962 10.4103C15.0473 11.4318 14.3224 12.3078 13.4029 12.9399C12.4834 13.572 11.4058 13.935 10.2913 13.9882C9.17675 14.0413 8.0695 13.7826 7.094 13.2409C6.1185 12.6993 5.31344 11.8962 4.76934 10.9221C4.22524 9.94797 3.96367 8.84136 4.01404 7.72671C4.06442 6.61206 4.42474 5.53356 5.0545 4.61248C5.68425 3.6914 6.55847 2.96426 7.57885 2.51281C8.59923 2.06137 9.72532 1.90352 10.8305 2.05702"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
