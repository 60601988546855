import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import {
  BlogCategoryData,
  GetAllBlogCategoryParams,
  GetBlogCategoryData,
} from "../../../types/redux/ADMIN/blog";

// create a product category
export const createBlogCategoryAsync = createAsyncThunk<
  null,
  PostRequest<BlogCategoryData, CommonOthers>
>(
  "admin-blogCategory/createBlogCategory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageBlogCategory, data);

      reRender(true);
      toast("success", "Product Blog create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Blog category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all product category
export const getAllBlogCategoryAsync = createAsyncThunk<
  GetCommonData<GetBlogCategoryData>,
  Params<GetAllBlogCategoryParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-blogCategory/getAllBlogCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageBlogCategory}/data`, {
        params,
      });
      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get blog fail");
      return rejectWithValue(error);
    }
  }
);

// update a blog category
export const updateBlogCategoryAsync = createAsyncThunk<
  null,
  PostRequest<BlogCategoryData, SingleDataParams>
>(
  "admin-blogCategory/updateBlogCategory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageBlogCategory}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Blog category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Blog category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a product category
export const deleteBlogCategoryAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>(
  "admin-blogCategory/deleteBlogCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageBlogCategory}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Blog category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Blog category delete fail");
      return rejectWithValue(error);
    }
  }
);
