export default function BsrmReload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M4.61322 8.98872H1.23822M1.23822 8.98872V12.3637M1.23822 8.98872L3.62474 11.3752C4.1993 11.9498 4.88141 12.4056 5.63211 12.7165C6.38281 13.0275 7.18741 13.1875 7.99996 13.1875C8.81252 13.1875 9.61711 13.0275 10.3678 12.7165C11.1185 12.4056 11.8006 11.9498 12.3752 11.3752M11.3867 5.01128H14.7617M14.7617 5.01128V1.63628M14.7617 5.01128L12.3752 2.62478C11.8006 2.05021 11.1185 1.59445 10.3678 1.2835C9.61711 0.972545 8.81252 0.8125 7.99996 0.8125C7.18741 0.8125 6.38281 0.972545 5.63211 1.2835C4.88141 1.59445 4.1993 2.05021 3.62474 2.62478"
        stroke="#455468"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
