import { createSlice } from "@reduxjs/toolkit";
import { RetailerCustomerInitialStatus } from "../../../types/redux/RETAILER/liveChat";
import {
  getAllCustomerAsync,
  getRetailerChatDataAsync,
} from "./retailerLiveChatAPI";

const initialState: RetailerCustomerInitialStatus<any, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  customerLoading: false,
  allCustomer: [],
  countAllCustomer: 0,
  retailerChatData: [],
  countRetailerChat: 0,
};

const retailerLiveChatSlice = createSlice({
  name: "retailer-live-chat",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.retailerChatData.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    // get all productCategory
    builder
      .addCase(getAllCustomerAsync.pending, (state, action) => {
        state.customerLoading = true;
      })
      .addCase(getAllCustomerAsync.fulfilled, (state, action) => {
        state.customerLoading = false;
        state.allCustomer = action.payload?.data;
        state.countAllCustomer = action.payload.length;
      })
      .addCase(getAllCustomerAsync.rejected, (state, action) => {
        state.customerLoading = false;
        state.allCustomer = [];
        state.countAllCustomer = 0;
      });
    // get all productCategory
    builder
      .addCase(getRetailerChatDataAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRetailerChatDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerChatData = action.payload?.data;
        state.countRetailerChat = action.payload.length;
      })
      .addCase(getRetailerChatDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerChatData = [];
        state.countRetailerChat = 0;
      });
  },
});

export const { addMessage } = retailerLiveChatSlice.actions;

export default retailerLiveChatSlice;
