export default function FaqUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clip-path="url(#clip0_1306_27599)">
        <path
          d="M25.77 24.0939C18.864 19.9299 11.703 19.7679 4.761 23.7759C2.316 25.1859 0.738 27.0369 0 29.0319H30C29.328 27.1299 27.915 25.3869 25.77 24.0939ZM14.988 0.960944C19.887 0.954944 23.823 4.85194 23.847 9.72994C23.871 14.5749 19.917 18.5799 15.069 18.6189C10.152 18.6609 6.198 14.7129 6.192 9.75694C6.186 4.85195 10.071 0.966944 14.988 0.960944Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1306_27599">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
