export default function BsrmTopArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
    >
      <path
        d="M5.5 12V1M5.5 1L1 5.5M5.5 1L10 5.5"
        stroke="#07BFA5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
