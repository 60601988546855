import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllNotificationParams,
  RetailerNotificationData,
} from "../../../types/redux/ADMIN/notification";

// get all notification
export const getAllNotificationAsync = createAsyncThunk<
  GetCommonData<RetailerNotificationData>,
  Params<GetAllNotificationParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-notification/getAllNotification",
  async ({ params }, { rejectWithValue }) => {
    const { isHeader, ...rest } = params as GetAllNotificationParams;

    try {
      const data = await AXIOS.get(
        `${BASE_API.manageNotification}/notification-list`,
        {
          params: { ...rest },
        }
      );
      return {
        data: data.data?.notifications,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Notification fail");
      return rejectWithValue(error);
    }
  }
);

// single api
export const getSingleNotificationAsync = createAsyncThunk<
  RetailerNotificationData,
  Params<SingleDataParams>
>(
  "retailer-notification/getSingleNotification",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageNotification}/notification-list/${params?.id}`
      );

      return data.data as RetailerNotificationData;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Notification fail");
      return rejectWithValue(error);
    }
  }
);
