import { Button, Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import {
  ArrayOption,
  findDataSelect,
  selectSearchOption,
} from "../../helpers/siteConfig";
import {
  createDistrictAsync,
  getAllDivisionAsync,
  updateDistrictAsync,
} from "../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetDistrictData } from "../../types/redux";
import CustomModal from "../common/CustomModal";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function DistrictCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [selectDivision, setSelectDivision] = useState("");
  const { postLoading, updateLoading, divisions } = useAppSelector(
    (state) => state.location
  );

  const districtData = data as GetDistrictData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { longitude, latitude, ...rest } = values;

    const newValue = {
      ...rest,
      location: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
    };
    if (type === "EDIT" && districtData?._id) {
      dispatch(
        updateDistrictAsync({
          data: newValue,
          others: {
            reRender,
            id: districtData?._id,
          },
        })
      );
    } else {
      dispatch(
        createDistrictAsync({
          data: newValue,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      districtData?._id &&
      updateLoading === false
    ) {
      setSelectDivision(districtData?.division?._id);
      form.setFieldsValue({
        name: districtData?.name,
        division: findDataSelect(divisions, districtData?.division?._id),
        latitude: districtData?.location?.coordinates?.[1],
        longitude: districtData?.location?.coordinates?.[0],
      });
    }
  }, [
    districtData?._id,
    districtData?.division?._id,
    districtData?.location?.coordinates,
    districtData?.name,
    divisions,
    form,
    type,
    updateLoading,
  ]);

  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
        },
      })
    );
  }, [dispatch]);

  // Validator function to check for non-negative numbers
  const validateNonNegative = (rule: any, value: number) => {
    if (value < 0) {
      return Promise.reject("Must be a positive number");
    }
    return Promise.resolve();
  };

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="division"
            label="Select Division"
            rules={[
              {
                required: true,
                message: "Please select division",
              },
            ]}
          >
            <Select
              placeholder="Division"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(divisions, "name", "_id")}
              onChange={(val) => {
                setSelectDivision(val);
                form.setFieldValue("name", undefined);
              }}
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="District Name"
            rules={[
              {
                required: true,
                message: "Please enter district name",
              },
            ]}
          >
            <Input size="large" disabled={type === "VIEW"} placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[
              {
                required: true,
                message: "Please enter latitude",
              },
              {
                validator: validateNonNegative,
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Latitude"
            />
          </Form.Item>

          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[
              {
                required: true,
                message: "Please enter longitude",
              },
              {
                validator: validateNonNegative,
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Longitude"
            />
          </Form.Item>

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
