import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  BrandData,
  CommonOthers,
  ErrorMessage,
  GetAllBrandByFilterParams,
  GetAllBrandParams,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";

// create a retailer
export const createBrandAsync = createAsyncThunk<
  null,
  PostRequest<BrandData, CommonOthers>
>(
  "admin-brand/createBrand",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageProductBrand, data);

      reRender(true);
      toast("success", "Brand create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Brand create fail");
      return rejectWithValue(error);
    }
  }
);

// get all brand
export const getAllBrandAsync = createAsyncThunk<
  GetCommonData<BrandData>,
  Params<GetAllBrandParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-brand/getAllBrand", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageProductBrand}/data`, {
      params,
    });

    return {
      data: data.data?.brands,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get brand fail");
    return rejectWithValue(error);
  }
});

// get all brand by filter
export const getAllBrandByFilterAsync = createAsyncThunk<
  GetCommonData<BrandData>,
  Params<GetAllBrandByFilterParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-brand/getAllBrandByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageProductBrand}/data`, {
        params,
      });

      return {
        data: data.data?.brands,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get brand fail");
      return rejectWithValue(error);
    }
  }
);

// update a expert category
export const updateBrandAsync = createAsyncThunk<
  null,
  PostRequest<BrandData, SingleDataParams>
>(
  "admin-brand/updateBrand",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageProductBrand}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Brand update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Brand update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a expert category
export const deleteBrandAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>("admin-brand/deleteBrand", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.manageProductBrand}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Brand delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Brand delete fail");
    return rejectWithValue(error);
  }
});
