export default function BsrmFailedOrder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M8.68732 1.25H1.93677C1.78758 1.25 1.64451 1.30926 1.53902 1.41475C1.43353 1.52024 1.37427 1.66332 1.37427 1.8125V14.1875C1.37427 14.3367 1.43353 14.4798 1.53902 14.5853C1.64451 14.6907 1.78758 14.75 1.93677 14.75H12.0623C12.1362 14.75 12.2093 14.7355 12.2776 14.7072C12.3458 14.6789 12.4078 14.6375 12.4601 14.5853C12.5123 14.533 12.5537 14.471 12.582 14.4028C12.6103 14.3345 12.6248 14.2614 12.6248 14.1875V5.1875M8.68732 1.25L12.6248 5.1875M8.68732 1.25V5.1875H12.6248M5.31232 8L8.68732 11.375M8.68732 8L5.31232 11.375"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
