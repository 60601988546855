export default function BsrmProductCategory() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M7.5 10.5H13.5M7.5 7.5H13.5M4.5 0.75V17.25M1.5 0.75H16.5C16.9142 0.75 17.25 1.08579 17.25 1.5V16.5C17.25 16.9142 16.9142 17.25 16.5 17.25H1.5C1.08579 17.25 0.75 16.9142 0.75 16.5V1.5C0.75 1.08579 1.08579 0.75 1.5 0.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
