import React, { ReactNode, useRef } from "react";
import BsrmEditIcon from "../../assets/icons/BsrmEditIcon";
import BsrmMinusIcon from "../../assets/icons/BsrmMinusIcon";
import BsrmPlusIcon from "../../assets/icons/BsrmPlusIcon";

interface ButtonProps {
  text: ReactNode;
  className: string;
  id?: string;
  handleOpen?: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, className, handleOpen }) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handlePlusClick = () => {
    if (!ref.current) return;

    ref.current.classList.add("active");
    const panel = ref.current.nextElementSibling as HTMLElement;

    if (panel && !panel.style.maxHeight) {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  };

  const handleMinusClick = () => {
    if (!ref.current) return;

    ref.current.classList.remove("active");
    const panel = ref.current.nextElementSibling as HTMLElement;

    if (panel) {
      panel.style.maxHeight = "";
    }
  };

  return (
    <button ref={ref} className={className} style={{ backgroundColor: "#fff" }}>
      <p className="text-[18px] font-semibold leading-[30px] flex justify-between">
        {text}
        <div className="flex items-center gap-4">
          <span onClick={() => handleOpen && handleOpen()}>
            <BsrmEditIcon />
          </span>
          <span onClick={handlePlusClick}>
            <BsrmPlusIcon />
          </span>
          <span onClick={handleMinusClick}>
            <BsrmMinusIcon />
          </span>
        </div>
      </p>
    </button>
  );
};

export default Button;
