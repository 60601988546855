import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import PlaceholderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import RadyMixCreateForm from "../../../components/ModalForm/RadyMixCreateForm";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBrandAsync } from "../../../store/features/Brand/BrandApi";
import {
  deleteRmcAsync,
  getAllRmcDataAsync,
} from "../../../store/features/RMC/rmcAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { ReadyMixData } from "../../../types/redux/ADMIN/rmc";

export default function RadyMixCement() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const { open, toggle, handleOpen } = useModal();
  const [selectBrand, setSelectBrand] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const { brand } = useAppSelector((state) => state.brand);
  const { loading, countRmc, rmc, deleteLoading } = useAppSelector(
    (state) => state.RMC
  );
  const columns: ColumnsType<TableData<ReadyMixData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      dataIndex: "icon",
      render: (val: ReadyMixData) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            width={70}
            height={50}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={PlaceholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Product Title",
      align: "left",
      dataIndex: "name",
    },
    {
      title: "Brand",
      align: "left",
      render: (val) => val?.brandId?.name,
    },
    {
      title: "Contact Number",
      align: "left",
      dataIndex: "phoneNumber",
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: ReadyMixData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />

          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  //DELETE
  const handleDelete = (id: string) => {
    dispatch(
      deleteRmcAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllRmcDataAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          brandId: selectBrand,
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch, limit, page, selectBrand, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }
  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  const allStatus = [
    { label: "Active", value: "true" },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  return (
    <AdminLayout
      title="Ready Mix"
      headerTitle="Ready Mix Concrete"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
                // data: val,
              })
            }
          >
            Create Now
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<ReadyMixData>>
          loading={loading}
          total={countRmc}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(rmc)}
          handleLimit={handleLimit}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Brand"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(brand, "name", "_id")}
                onChange={setSelectBrand}
              />
              <Select
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <RadyMixCreateForm
          title={
            open.type === "EDIT"
              ? "Update Ready-Mix"
              : open.type === "VIEW"
              ? "View Ready-Mix"
              : "Ready-Mix Create"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Ready-Mix"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
