import React from "react";

interface PanelProps {
  text: string;
  className: string;
}

const Panel: React.FC<PanelProps> = ({ text, className }) => {
  return (
    <div className={className}>
      <p
        className="paragraph px-5"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default Panel;
