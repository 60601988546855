import { Button, Form, FormProps, Input } from "antd";
import { useEffect } from "react";
import {
  createDivisionAsync,
  updateDivisionAsync,
} from "../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetDivisionData } from "../../types/redux";
import CustomModal from "../common/CustomModal";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function DivisionCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.location
  );

  const divisionData = data as GetDivisionData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { longitude, latitude, ...rest } = values;
    const newValue = {
      ...rest,
      location: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
    };

    if (type === "EDIT" && divisionData?._id) {
      dispatch(
        updateDivisionAsync({
          data: newValue,
          others: {
            reRender,
            id: divisionData?._id,
          },
        })
      );
    } else {
      dispatch(
        createDivisionAsync({
          data: newValue,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      divisionData?._id &&
      updateLoading === false
    ) {
      form.setFieldsValue({
        name: divisionData?.name,
        latitude: divisionData?.location?.coordinates?.[1],
        longitude: divisionData?.location?.coordinates?.[0],
      });
    }
  }, [
    divisionData?._id,
    divisionData?.location?.coordinates,
    divisionData?.name,
    form,
    type,
    updateLoading,
  ]);

  // Validator function to check for non-negative numbers
  const validateNonNegative = (rule: any, value: number) => {
    if (value < 0) {
      return Promise.reject("Must be a positive number");
    }
    return Promise.resolve();
  };

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark "
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="name"
            label="Division Name"
            rules={[
              {
                required: true,
                message: "Please enter division name",
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Division"
            />
          </Form.Item>

          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[
              {
                required: true,
                message: "Please enter latitude",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Please enter a valid integer (0-9 only)",
              },
              {
                validator: validateNonNegative,
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Latitude"
            />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[
              {
                required: true,
                message: "Please enter longitude",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Please enter a valid integer (0-9 only)",
              },
              {
                validator: validateNonNegative,
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Longitude"
            />
          </Form.Item>

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
