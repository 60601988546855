import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllAdminProductAsync } from "../../../store/features/adminProduct/adminProductAPI";
import { getAllBrandAsync } from "../../../store/features/Brand/BrandApi";
import { getAllProductCategoryAsync } from "../../../store/features/productCategory/productCategoryAPI";
import { getAllRetailerAsync } from "../../../store/features/retailer/retailerApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import {
  GetAdminProductData,
  GetRetailerProductData,
  GetSingleRetailerProduct,
} from "../../../types/redux/ADMIN/retailerProduct";
import CreateProductForm from "../../retailers/ManageProducts/CreateProductForm";

export default function PendingProduct() {
  const dispatch = useAppDispatch();

  const { limit, page, handlePagination, handleLimit } = useTable();
  const { open, toggle } = useModal();
  const [selectBrand, setSelectBrand] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectRetailer, setSelectRetailer] = useState("");
  const [selectStatus, setSelectStatus] = useState("Pending");

  const { loading, countRetailerProduct, retailerProduct } = useAppSelector(
    (state) => state.adminProduct
  );
  const { brand } = useAppSelector((state) => state.brand);
  const { productCategory } = useAppSelector((state) => state.productCategory);
  const { retailer } = useAppSelector((state) => state?.retailer);

  const columns: ColumnsType<TableData<GetRetailerProductData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      dataIndex: "image",
      render: (val: GetSingleRetailerProduct) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            className="rounded"
            width={70}
            height={70}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={placeholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Product Title",
      align: "left",
      render: (val: GetSingleRetailerProduct) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.name}</p>
          <p className="data_table_sku_data">{val?.sku}</p>
          <p className="data_table_badges_data">{val?.categoryId?.name}</p>
        </Space>
      ),
    },
    {
      title: "Retailer",
      align: "left",
      render: (val: GetAdminProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.retailerId?.ownerName}
          </p>
          <p className="data_table_sku_data">
            {val?.retailerId?.organizationName}
          </p>
        </Space>
      ),
    },
    {
      title: "Price",
      align: "left",
      render: (val: GetSingleRetailerProduct) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.salesPrice} BDT/{val?.unit}
          </p>
          <p className="data_table_price_discount_data">
            {val?.discountPercentage || 0}% Off
          </p>
        </Space>
      ),
    },
    {
      title: "Brand",
      align: "left",
      render: (val) => val?.brandId?.name,
    },
    {
      title: "Total Unite",
      align: "left",
      render: (val: GetSingleRetailerProduct) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.stockQuantity} {val?.unit}
          </p>
        </Space>
      ),
    },
    {
      title: "Status",
      align: "center",
      render: (val: GetRetailerProductData) => (
        <span
          className={`status ${
            val?.retailerRequestStatus === "Approved"
              ? "status-done"
              : val?.retailerRequestStatus === "Pending"
              ? "status-waiting"
              : val?.retailerRequestStatus === "Save as Draft"
              ? "status-waiting"
              : val?.retailerRequestStatus === "Reject"
              ? "status-danger"
              : null
          }`}
        >
          {val?.retailerRequestStatus}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      render: (val: GetSingleRetailerProduct) => (
        <div className="flex justify-center gap-2">
          <Link
            to={`/admin/manage-products/pending-products/details/${val?._id}`}
          >
            <Button className="bg-white py-[6px]" icon={<BsrmEyeIcon />} />
          </Link>
        </div>
      ),
    },
  ];

  const getData = useCallback(() => {
    dispatch(
      getAllAdminProductAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: "",
          brandId: selectBrand,
          categoryId: selectCategory,
          retailerRequestStatus: selectStatus,
          retailerId: selectRetailer,
        },
      })
    );
  }, [
    dispatch,
    limit,
    page,
    selectBrand,
    selectCategory,
    selectRetailer,
    selectStatus,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  // retailer
  useEffect(() => {
    dispatch(
      getAllRetailerAsync({
        params: {
          pageNo: page,
          limit: limit,
        },
      })
    );
  }, [dispatch, limit, page]);

  // dispatch category data
  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: "true",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  const allStatus = [
    { label: "Approved", value: "Approved" },
    { label: "Pending", value: "Pending" },
    { label: "Reject", value: "Reject" },
  ];

  return (
    <AdminLayout
      title="Product"
      headerTitle="Pending Product"
      className="dashboard"
    >
      <section className="">
        <CustomTable<TableData<GetRetailerProductData>>
          loading={loading}
          total={countRetailerProduct}
          page={page}
          limit={limit}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(retailerProduct)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Retailer"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(retailer, "organizationName", "_id")}
                onChange={setSelectRetailer}
              />
              <Select
                placeholder="Filter By Category"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(productCategory, "name", "_id")}
                onChange={setSelectCategory}
              />
              <Select
                placeholder="Filter By Brand"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(brand, "name", "_id")}
                onChange={setSelectBrand}
              />
              <Select
                value={selectStatus}
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "EDIT" || open.type === "VIEW") && (
        <CreateProductForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {/* {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          questionText="Are you sure to delete product ?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )} */}
    </AdminLayout>
  );
}
