import { createSlice } from "@reduxjs/toolkit";
import {
  GetRetailerProductData,
  GetSingleRetailerProduct,
  retailerProductInitialStatus,
} from "../../../types/redux/ADMIN/retailerProduct";
import {
  createRetailerProductAsync,
  deleteRetailerProductAsync,
  getAllRetailerProductAsync,
  getRetailerProductSKUAsync,
  getSingleRetailerProductAsync,
  updateRetailerProductAsync,
  updateRetailerProductStatusAsync,
} from "./retailerProductAPI";

const initialState: retailerProductInitialStatus<
  GetRetailerProductData,
  GetSingleRetailerProduct
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailerProduct: [],
  retailerProductSkuData: undefined,
  retailerAllProduct: [],
  singleProduct: null,
  countRetailerProduct: 0,
};

const retailerProductSlice = createSlice({
  name: "retailer-pendingProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create productCategory
    builder
      .addCase(createRetailerProductAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createRetailerProductAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createRetailerProductAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all productCategory
    builder
      .addCase(getAllRetailerProductAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllRetailerProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerProduct = action.payload?.data;
        state.countRetailerProduct = action.payload.length;
      })
      .addCase(getAllRetailerProductAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerProduct = [];
        state.countRetailerProduct = 0;
      });

    // get Product SKU
    builder
      .addCase(getRetailerProductSKUAsync.pending, (state, action) => {
        state.loading = true;
        state.retailerProductSkuData = undefined;
      })
      .addCase(getRetailerProductSKUAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerProductSkuData = action.payload;
      })
      .addCase(getRetailerProductSKUAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerProductSkuData = undefined;
      });

    // update productCategory
    builder
      .addCase(updateRetailerProductAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRetailerProductAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateRetailerProductAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // update product status
    builder
      .addCase(updateRetailerProductStatusAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRetailerProductStatusAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateRetailerProductStatusAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete productCategory
    builder
      .addCase(deleteRetailerProductAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteRetailerProductAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteRetailerProductAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
    // get single product
    builder
      .addCase(getSingleRetailerProductAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleProduct = null;
      })
      .addCase(getSingleRetailerProductAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getSingleRetailerProductAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleProduct = null;
      });
  },
});

export default retailerProductSlice;
