export default function ThreeDotIcon() {
  return (
    <svg
      width="4"
      height="12"
      viewBox="0 0 4 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.3438C2.46599 11.3438 2.84375 10.966 2.84375 10.5C2.84375 10.034 2.46599 9.65625 2 9.65625C1.53401 9.65625 1.15625 10.034 1.15625 10.5C1.15625 10.966 1.53401 11.3438 2 11.3438Z"
        fill="#5E718D"
      />
      <path
        d="M2 6.84375C2.46599 6.84375 2.84375 6.46599 2.84375 6C2.84375 5.53401 2.46599 5.15625 2 5.15625C1.53401 5.15625 1.15625 5.53401 1.15625 6C1.15625 6.46599 1.53401 6.84375 2 6.84375Z"
        fill="#5E718D"
      />
      <path
        d="M2 2.34375C2.46599 2.34375 2.84375 1.96599 2.84375 1.5C2.84375 1.03401 2.46599 0.65625 2 0.65625C1.53401 0.65625 1.15625 1.03401 1.15625 1.5C1.15625 1.96599 1.53401 2.34375 2 2.34375Z"
        fill="#5E718D"
      />
      <path
        d="M2 11.3438C2.46599 11.3438 2.84375 10.966 2.84375 10.5C2.84375 10.034 2.46599 9.65625 2 9.65625C1.53401 9.65625 1.15625 10.034 1.15625 10.5C1.15625 10.966 1.53401 11.3438 2 11.3438Z"
        stroke="#8897AE"
      />
      <path
        d="M2 6.84375C2.46599 6.84375 2.84375 6.46599 2.84375 6C2.84375 5.53401 2.46599 5.15625 2 5.15625C1.53401 5.15625 1.15625 5.53401 1.15625 6C1.15625 6.46599 1.53401 6.84375 2 6.84375Z"
        stroke="#8897AE"
      />
      <path
        d="M2 2.34375C2.46599 2.34375 2.84375 1.96599 2.84375 1.5C2.84375 1.03401 2.46599 0.65625 2 0.65625C1.53401 0.65625 1.15625 1.03401 1.15625 1.5C1.15625 1.96599 1.53401 2.34375 2 2.34375Z"
        stroke="#8897AE"
      />
    </svg>
  );
}
