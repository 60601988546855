export default function BsrmOrderCancel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.2271 3.22703L12.773 12.773M14.7501 8C14.7501 11.7279 11.728 14.75 8.00007 14.75C4.27215 14.75 1.25007 11.7279 1.25007 8C1.25007 4.27208 4.27215 1.25 8.00007 1.25C11.728 1.25 14.7501 4.27208 14.7501 8Z"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
