import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import { BankData, BankDataParams } from "../../../types/redux/ADMIN/bank";

// create
export const createBankAsync = createAsyncThunk<
  null,
  PostRequest<BankData, CommonOthers>
>(
  " admin-bank/createBank",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageBank, data);

      reRender(true);
      toast("success", "Bank create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank create fail");
      return rejectWithValue(error);
    }
  }
);

// get all expert
export const getAllBankAsync = createAsyncThunk<
  GetCommonData<BankData>,
  Params<BankDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(" admin-bank/getAllBank", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageBank}/data`, {
      params,
    });
    return {
      data: data.data?.banks,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get bank fail");
    return rejectWithValue(error);
  }
});

// update a expert
export const updateBankAsync = createAsyncThunk<
  null,
  PostRequest<BankData, SingleDataParams>
>(
  " admin-bank/updateBank",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageBank}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Bank update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a expert
export const deleteBankAsync = createAsyncThunk<null, Params<SingleDataParams>>(
  " admin-bank/deleteBank",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageBank}/${params?.id}`);
      params?.reRender && params?.reRender(true);
      toast("success", "Bank delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Bank delete fail");
      return rejectWithValue(error);
    }
  }
);

// update a single Scratch Type
export const singleBankAsync = createAsyncThunk<any, Params<SingleDataParams>>(
  " admin-bank/singleBank",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageScratchType}/${params?.id}`
      );

      return data.data?.ScratchType as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single scratch type get fail");
      return rejectWithValue(error);
    }
  }
);
