export default function BsrmNotification() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M6.00001 15.9999V16.7499C6.00001 17.5456 6.31608 18.3087 6.87868 18.8713C7.44129 19.4339 8.20436 19.7499 9.00001 19.7499C9.79566 19.7499 10.5587 19.4339 11.1213 18.8713C11.6839 18.3087 12 17.5456 12 16.7499V15.9999M2.26904 7.74994C2.2678 6.86045 2.44262 5.97951 2.78343 5.1579C3.12424 4.33628 3.6243 3.59025 4.25477 2.9628C4.88525 2.33535 5.63368 1.83889 6.45693 1.50204C7.28017 1.16519 8.16194 0.994607 9.05142 1.00013C12.7629 1.02772 15.7317 4.11275 15.7317 7.83468V8.49994C15.7317 11.8577 16.4342 13.8061 17.0529 14.871C17.1196 14.9848 17.1551 15.1142 17.1558 15.246C17.1565 15.3779 17.1224 15.5076 17.0569 15.6221C16.9915 15.7366 16.8971 15.8318 16.7831 15.8982C16.6691 15.9645 16.5397 15.9996 16.4078 15.9999H1.59222C1.46034 15.9996 1.33087 15.9645 1.21689 15.8981C1.1029 15.8318 1.00844 15.7366 0.943012 15.622C0.877587 15.5075 0.843521 15.3778 0.84425 15.2459C0.844978 15.114 0.880476 14.9846 0.947161 14.8709C1.56622 13.8059 2.26904 11.8575 2.26904 8.49994L2.26904 7.74994Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
