export default function BsrmLocation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.421 27.4864C13.854 26.7154 13.299 25.9624 12.765 25.2004C10.929 22.5904 9.23105 19.8964 7.86005 17.0074C7.18805 15.5884 6.57005 14.1424 6.27005 12.5974C5.70305 9.69937 6.37505 7.09537 8.33705 4.87837C10.23 2.74537 12.654 1.76737 15.498 1.88437C19.467 2.04937 22.932 5.03437 23.739 8.93737C24.069 10.5394 23.961 12.1054 23.469 13.6654C22.482 16.7674 20.937 19.5904 19.17 22.2964C17.907 24.2284 16.533 26.0884 15.207 27.9784C15.174 28.0234 15.141 28.0654 15.096 28.1164C15.03 28.1884 14.73 27.9064 14.421 27.4864ZM19.365 10.9324C19.482 8.67337 17.607 6.56737 15.366 6.44437C12.78 6.30037 10.776 8.10037 10.638 10.7014C10.518 12.9604 12.435 15.0574 14.724 15.1654C17.241 15.2854 19.236 13.4614 19.365 10.9324Z"
        fill="white"
      />
    </svg>
  );
}
