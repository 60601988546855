import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllCustomerData,
  GetAllCustomerParams,
  GetCustomerDetails,
} from "../../../types/redux/ADMIN/customer";
import { GetAllRetailerOrderProductParams } from "../../../types/redux/ADMIN/retailerOrderProduct";

// get all Order
export const getAllCustomerAsync = createAsyncThunk<
  GetCommonData<GetAllCustomerData>,
  Params<GetAllCustomerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-customer/getAllCustomer", async ({ params }, { rejectWithValue }) => {
  const { ...rest } = params as GetAllRetailerOrderProductParams;

  try {
    const data = await AXIOS.get(`${BASE_API.manageCustomer}/data`, {
      params: { ...rest },
    });

    return {
      data: data.data?.customers,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Customer fail");
    return rejectWithValue(error);
  }
});

// single api
export const getSingleCustomerDataAsync = createAsyncThunk<
  GetCustomerDetails,
  Params<SingleDataParams>
>(
  "admin-customer/getSingleCustomerData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageCustomer}/customer-details/${params?.id}`
      );

      return data.data as GetCustomerDetails;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Customer fail");
      return rejectWithValue(error);
    }
  }
);
