export default function BsrmNotificationViewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
    >
      <path
        d="M9 0.9375C3.375 0.9375 1.125 6.00055 1.125 6.00055C1.125 6.00055 3.375 11.0625 9 11.0625C14.625 11.0625 16.875 6.00055 16.875 6.00055C16.875 6.00055 14.625 0.9375 9 0.9375Z"
        stroke="#455468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.81309C10.5533 8.81309 11.8125 7.55389 11.8125 6.00059C11.8125 4.44729 10.5533 3.18809 9 3.18809C7.4467 3.18809 6.1875 4.44729 6.1875 6.00059C6.1875 7.55389 7.4467 8.81309 9 8.81309Z"
        stroke="#455468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
