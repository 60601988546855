import { Button, Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import { selectSearchOption } from "../../helpers/siteConfig";
import {
  createScratchTypeAsync,
  updateScratchTypeAsync,
} from "../../store/features/scratchType/scratchTypeAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetScratchTypeData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ScratchCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [iconName, setIconName] = useState("Upload Icon");
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [bannerName, setBannerName] = useState("Upload Banner");
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.scratchType
  );

  const scratchData = data as GetScratchTypeData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && scratchData?._id) {
      dispatch(
        updateScratchTypeAsync({
          data: values,
          others: {
            reRender,
            id: scratchData?._id,
          },
        })
      );
    } else {
      dispatch(
        createScratchTypeAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      scratchData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(scratchData?.icon, ".");
      setIconName(
        scratchData?.icon
          ? `${sliceString(scratchData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Icon"
      );

      const bannerNameArr = stringToArray(scratchData?.banner, ".");
      setBannerName(
        scratchData?.banner
          ? `${sliceString(scratchData?.banner, 15, "...")}.${
              bannerNameArr?.[bannerNameArr?.length - 1]
            }`
          : "Upload Banner"
      );

      form.setFieldsValue({
        name: scratchData?.name || undefined,
        name_bangla: scratchData?.name_bangla || undefined,
        icon: scratchData?.icon || undefined,
        banner: scratchData?.banner || undefined,
        sorting: scratchData?.sorting || undefined,
        title: scratchData?.title,
        title_bangla: scratchData?.title_bangla,
      });
    }
  }, [
    scratchData?._id,
    scratchData?.icon,
    scratchData?.name,
    form,
    type,
    updateLoading,
    scratchData?.banner,
    scratchData?.sorting,
    scratchData?.title,
    scratchData?.name_bangla,
    scratchData?.title_bangla,
  ]);

  return (
    <CustomModal width={750} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <Form.Item
              name="name"
              label="Scratch Type Name (English)"
              rules={[
                {
                  required: true,
                  message: "Please enter scratch name (English)",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Name (English)"
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <Form.Item
              name="name_bangla"
              label="Scratch Type Name (Bangla)"
              rules={[
                {
                  required: true,
                  message: "Please enter scratch name (Bangla)",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Name (Bangla)"
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <Form.Item
              name="title"
              label="Scratch Type Title (English)"
              rules={[
                {
                  required: true,
                  message: "Please enter category title (English)",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Title (English)"
              />
            </Form.Item>

            <Form.Item
              name="title_bangla"
              label="Scratch Type Title (Bangla)"
              rules={[
                {
                  required: true,
                  message: "Please enter category title (Bangla)",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Title (Bangla)"
              />
            </Form.Item>

            <div className="col-span-2">
              <Form.Item
                name="sorting"
                label="Sorting"
                rules={[
                  {
                    required: true,
                    message: "Please select sort",
                  },
                ]}
              >
                <Select
                  placeholder="Select one"
                  size="large"
                  allowClear
                  showSearch
                  filterOption={selectSearchOption}
                  options={[...Array(20)].map((_, idx) => ({
                    label: `${idx + 1}`,
                    value: `${idx + 1}`,
                  }))}
                  disabled={type === "VIEW"}
                />
              </Form.Item>
            </div>

            <Form.Item
              className="custom__image__input__placeholder"
              name="icon"
              label="Select Icon"
              htmlFor="..."
              extra={
                type !== "VIEW" ? "Upload the icon carefully | SVG, PNG" : null
              }
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload a icon",
                },
              ]}
            >
              <FileUpload
                errorMessage="Product icon upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingIcon(val)}
                handleName={(val) => setIconName(val || "Upload Icon")}
                onChange={(url) => form.setFieldsValue({ icon: url })}
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingIcon ? "Uploading..." : iconName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="banner"
              label="Banner"
              htmlFor="..."
              extra={
                type !== "VIEW"
                  ? "Upload the banner carefully | SVG, PNG"
                  : null
              }
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload a banner",
                },
              ]}
            >
              <FileUpload
                errorMessage="Product icon upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingBanner(val)}
                handleName={(val) => setBannerName(val || "Upload Banner")}
                onChange={(url) => form.setFieldsValue({ banner: url })}
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingBanner ? "Uploading..." : bannerName}
                    </span>

                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <div className="flex items-start">
              {type === "EDIT" || type === "VIEW" ? (
                <div className="flex justify-center ">
                  <Image
                    width={65}
                    height={65}
                    src={`${IMAGE_URL}${form.getFieldValue("icon")}`}
                    alt={APP_NAME}
                    className="bg-primary-admin !rounded-full p-2.5 border-4 border-solid border-[#B0E4CF]"
                    rootClassName="text-center"
                  />
                </div>
              ) : null}
            </div>

            <div className="flex items-start">
              {type === "EDIT" || type === "VIEW" ? (
                <div className="flex justify-center">
                  <Image
                    width={140}
                    height={84}
                    src={`${IMAGE_URL}${form.getFieldValue("banner")}`}
                    alt={APP_NAME}
                    rootClassName="text-center"
                  />
                </div>
              ) : null}
            </div>

            {/* <Form.Item
              name="details"
              label="Scratch Details"
              className="md:col-span-2 mb-6"
            >
              <ReactQuill
                className="h-[200px]"
                theme="snow"
                formats={formats}
                modules={modules}
                readOnly={type === "VIEW"}
              />
            </Form.Item> */}
          </div>

          {type !== "VIEW" ? (
            <div className="flex justify-center mt-2">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
