import { Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { ReactNode, useState } from "react";
import AXIOS from "../../helpers/api";
import { BASE_API } from "../../helpers/apiUrl";
import { toast } from "../../helpers/toast";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";

type Props = {
  errorMessage?: string;
  handleLoading: (val: boolean) => void;
  handleName: (val: string) => void;
  onChange: (val: string | null) => void;
  children: ReactNode;
} & UploadProps;

export default function FileUpload({
  errorMessage = "File upload fail",
  handleLoading,
  handleName,
  onChange,
  children,
  ...rest
}: Props) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    handleLoading(true);

    try {
      const response = await AXIOS.post(`${BASE_API.fileUpload}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const fileNameArr = stringToArray(response?.data?.file?.url, ".");

      if (response.status === 200) {
        handleName(
          `${sliceString(response?.data?.file?.url, 15, "...")}.${
            fileNameArr?.[fileNameArr?.length - 1]
          }`
        );

        onChange(response?.data?.file?.url);
        handleLoading(false);
      }
    } catch (error) {
      toast("error", errorMessage);
      handleLoading(false);
    }

    return false;
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);

    if (info.file.status === "removed") {
      onChange(null);
      handleName("");
    }
  };

  return (
    <Upload
      name="image"
      beforeUpload={handleUpload}
      maxCount={1}
      accept=".png,.jpeg,.svg"
      className="custom-upload"
      showUploadList={false}
      onChange={handleChange}
      fileList={fileList}
      {...rest}
    >
      {children}
    </Upload>
  );
}
