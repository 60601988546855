export default function BsrmManageOrder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M4.25 4V1.75C4.25 1.55109 4.32902 1.36032 4.46967 1.21967C4.61032 1.07902 4.80109 1 5 1H14C14.1989 1 14.3897 1.07902 14.5303 1.21967C14.671 1.36032 14.75 1.55109 14.75 1.75V16L11.75 13.8574M11.75 19L6.49931 15.25L1.25 19V4.75C1.25 4.55109 1.32902 4.36032 1.46967 4.21967C1.61032 4.07902 1.80109 4 2 4H11C11.1989 4 11.3897 4.07902 11.5303 4.21967C11.671 4.36032 11.75 4.55109 11.75 4.75V19Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
