import { createSlice } from "@reduxjs/toolkit";
import {
  GetProductCategoryData,
  ProductCategoryInitialStatus,
} from "../../../types/redux";
import {
  createProductCategoryAsync,
  deleteProductCategoryAsync,
  getAllProductCategoryAsync,
  getSingleProductCategoryAsync,
  updateProductCategoryAsync,
} from "./productCategoryAPI";

const initialState: ProductCategoryInitialStatus<GetProductCategoryData, any> =
  {
    loading: false,
    postLoading: false,
    updateLoading: false,
    deleteLoading: false,
    singleLoading: false,
    productCategory: [],
    productAllCategory: [],
    singleCategoryData: null,
    countProductCategory: 0,
  };

const productCategorySlice = createSlice({
  name: "admin-productCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create productCategory
    builder
      .addCase(createProductCategoryAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createProductCategoryAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createProductCategoryAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all productCategory
    builder
      .addCase(getAllProductCategoryAsync.pending, (state, action) => {
        if (!action?.meta?.arg?.params?.isAllData) {
          state.loading = true;
          state.productCategory = [];
        }
      })
      .addCase(getAllProductCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.meta?.arg?.params?.isAllData) {
          state.productAllCategory = action.payload.data;
        } else {
          state.productCategory = action.payload.data;
          state.countProductCategory = action.payload.length;
        }
      })
      .addCase(getAllProductCategoryAsync.rejected, (state, action) => {
        state.loading = false;
        if (!action?.meta?.arg?.params?.isAllData) {
          state.productCategory = [];
          state.countProductCategory = 0;
        }
      });
    // update productCategory
    builder
      .addCase(updateProductCategoryAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateProductCategoryAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateProductCategoryAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete productCategory
    builder
      .addCase(deleteProductCategoryAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteProductCategoryAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteProductCategoryAsync.rejected, (state) => {
        state.deleteLoading = false;
      });

    // single scratchType
    builder
      .addCase(getSingleProductCategoryAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleCategoryData = null;
      })
      .addCase(getSingleProductCategoryAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleCategoryData = action.payload;
      })
      .addCase(getSingleProductCategoryAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleCategoryData = null;
      });
  },
});

export default productCategorySlice;
