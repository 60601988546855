import { createSlice } from "@reduxjs/toolkit";

import {
  AdminNotificationData,
  adminNotificationInitialStatus,
} from "../../../types/redux/ADMIN/adminNotification";
import {
  getAllAdminNotificationAsync,
  getSingleAdminNotificationAsync,
} from "./adminNotificationAPI";

const initialState: adminNotificationInitialStatus<
  AdminNotificationData,
  AdminNotificationData
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  adminNotification: [],
  adminHeaderNotification: [],
  singleAdminNotification: null,
  countAdminNotification: 0,
};

const adminNotificationSlice = createSlice({
  name: "admin-notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all notification
    builder
      .addCase(getAllAdminNotificationAsync.pending, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.singleLoading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(getAllAdminNotificationAsync.fulfilled, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.singleLoading = false;
          state.adminHeaderNotification = action.payload?.data;
        } else {
          state.loading = false;
          state.adminNotification = action.payload?.data;
          state.countAdminNotification = action.payload.length;
        }
      })
      .addCase(getAllAdminNotificationAsync.rejected, (state, action) => {
        state.loading = false;
        state.singleLoading = false;
        state.adminNotification = [];
        state.countAdminNotification = 0;
      });

    // get single product
    builder
      .addCase(getSingleAdminNotificationAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleAdminNotificationAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleAdminNotification = action.payload;
      })
      .addCase(getSingleAdminNotificationAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleAdminNotification = null;
      });
  },
});

export default adminNotificationSlice;
