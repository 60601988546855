import { Badge } from "antd";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import Image from "../../common/Image";

type Props = {
  active: boolean;
  onlineStatus: "Yes" | "No";
  name: string;
  image: string;
  phoneNumber: string;
  message: string;
  messageType: "Text" | "File" | "Location";
  status: "Seen" | "Unseen";
  type: "Retailer" | "Customer";
  _id: string;
  onClick: () => void;
};

export default function SingleUser({
  active,
  onlineStatus,
  name,
  message,
  messageType,
  image,
  type,
  status,
  onClick,
}: Props) {
  return (
    <div
      className={`select-none cursor-pointer px-4 py-2 ${
        active ? "bg-[#eefbf9]" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex gap-3 items-center ">
        <div
          className={`rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat ${
            onlineStatus === "Yes" ? "user_online" : ""
          }`}
        >
          {/* count={100} overflowCount={99} */}
          <Badge
            rootClassName="custom_badge"
            dot
            count={100}
            overflowCount={99}
          >
            <Image
              className="!rounded-full w-[56px] h-[56px]"
              width={56}
              height={56}
              preview={false}
              alt={APP_NAME}
              src={image ? IMAGE_URL + image : placeholderImage}
              style={{
                border: "1px solid white",
                borderRadius: "100%",
              }}
            />
          </Badge>
        </div>
        <div className="">
          <p className="text-[18px] font-medium leading-[30px] text-black">
            {name}
          </p>
          <p
            className={`text-[14px] leading-6 text-black ${
              type === "Customer" && status === "Unseen" ? "font-bold" : ""
            }`}
          >
            {messageType === "Text"
              ? message?.length > 30
                ? message?.slice(0, 30) + "..."
                : message
              : messageType === "File"
              ? `${type === "Customer" ? "Receive" : "Send"} Image`
              : messageType === "Location"
              ? `${type === "Customer" ? "Receive" : "Send"} Location`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
