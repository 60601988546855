import { createSlice } from "@reduxjs/toolkit";
import {
  BlogInitialStatus,
  GetBlogCategoryData,
} from "../../../types/redux/ADMIN/blog";
import {
  createBlogCategoryAsync,
  deleteBlogCategoryAsync,
  getAllBlogCategoryAsync,
  updateBlogCategoryAsync,
} from "./blogAPI";

const initialState: BlogInitialStatus<GetBlogCategoryData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  blogCategory: [],
  countBlogCategory: 0,
};

const blogSlice = createSlice({
  name: "admin-blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create productCategory
    builder
      .addCase(createBlogCategoryAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBlogCategoryAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBlogCategoryAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all productCategory
    builder
      .addCase(getAllBlogCategoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.blogCategory = action.payload.data;
        state.countBlogCategory = action.payload.length;
      })
      .addCase(getAllBlogCategoryAsync.rejected, (state) => {
        state.loading = false;
        state.blogCategory = [];
        state.countBlogCategory = 0;
      });
    // update productCategory
    builder
      .addCase(updateBlogCategoryAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBlogCategoryAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateBlogCategoryAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete productCategory
    builder
      .addCase(deleteBlogCategoryAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteBlogCategoryAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteBlogCategoryAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default blogSlice;
