export default function BsrmPending() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00008 7L12.2273 12.2273C12.306 12.3059 12.3596 12.4061 12.3813 12.5153C12.403 12.6244 12.3918 12.7375 12.3493 12.8403C12.3067 12.943 12.2346 13.0309 12.1421 13.0927C12.0496 13.1545 11.9408 13.1875 11.8296 13.1875H2.17058C2.05933 13.1875 1.95057 13.1545 1.85807 13.0927C1.76557 13.0309 1.69347 12.943 1.6509 12.8403C1.60832 12.7375 1.59718 12.6244 1.61889 12.5153C1.64059 12.4061 1.69416 12.3059 1.77283 12.2273L7.00008 7ZM7.00008 7L12.2273 1.77275C12.306 1.69408 12.3596 1.59385 12.3813 1.48474C12.403 1.37562 12.3918 1.26252 12.3493 1.15974C12.3067 1.05696 12.2346 0.969106 12.1421 0.907298C12.0496 0.84549 11.9408 0.8125 11.8296 0.8125H2.17058C2.05933 0.8125 1.95057 0.84549 1.85807 0.907298C1.76557 0.969106 1.69347 1.05696 1.6509 1.15974C1.60832 1.26252 1.59718 1.37562 1.61889 1.48474C1.64059 1.59385 1.69416 1.69408 1.77283 1.77275L7.00008 7Z"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
