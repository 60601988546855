import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import dashboardAdminOrderSlice from "./features/adminDashboard/adminOdersDataSlice";
import adminNotificationSlice from "./features/adminNotification/adminNotificationSlice";
import adminOrderProductSlice from "./features/adminOrder/orderProductSlice";
import adminProductSlice from "./features/adminProduct/adminProductSlice";
import appSlice from "./features/appSlice";
import appSlideSlice from "./features/AppSlide/SlideSlice";
import loginSlice from "./features/auth/loginSlice";
import bankSlice from "./features/Bank/BankSlice";
import blogSlice from "./features/Blog/blogSlice";
import blogPostSlice from "./features/BlogPost/blogPostSlice";
import brandSlice from "./features/Brand/BrandSlice";
import commonDataSlice from "./features/comonData/CommonSlice";
import customerSlice from "./features/customer/customerSlice";
import dashboardOrderSlice from "./features/dashboard/odersDataSlice";
import expertSlice from "./features/expert/expertSlice";
import expertCategorySlice from "./features/expertCategory/expertCategorySlice";
import faqSlice from "./features/Faq/faqSlice";
import locationSlice from "./features/locations/locationSlice";
import retailerNotificationSlice from "./features/notification/notificationSlice";
import retailerOrderProductSlice from "./features/Order/orderProductSlice";
import productCategorySlice from "./features/productCategory/productCategorySlice";
import retailerSlice from "./features/retailer/retailerSlice";
import retailerLiveChatSlice from "./features/retailerLiveChat/retailerLiveChatSlice";
import retailerProductSlice from "./features/retailerProduct/retailerProductSlice";
import rmcSlice from "./features/RMC/rmcSlice";
import scratchTypeSlice from "./features/scratchType/scratchTypeSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    productCategory: productCategorySlice.reducer,
    scratchType: scratchTypeSlice.reducer,
    location: locationSlice.reducer,
    expertCategory: expertCategorySlice.reducer,
    expert: expertSlice.reducer,
    retailer: retailerSlice.reducer,
    brand: brandSlice.reducer,
    slide: appSlideSlice.reducer,
    RMC: rmcSlice.reducer,
    blog: blogSlice.reducer,
    blogPost: blogPostSlice.reducer,
    retailerProduct: retailerProductSlice.reducer,
    adminProduct: adminProductSlice.reducer,
    bank: bankSlice.reducer,
    faqs: faqSlice.reducer,
    orderProduct: retailerOrderProductSlice.reducer,
    adminOrderProduct: adminOrderProductSlice.reducer,
    notification: retailerNotificationSlice.reducer,
    adminNotification: adminNotificationSlice.reducer,
    customer: customerSlice.reducer,
    commonData: commonDataSlice,
    retailerLiveChat: retailerLiveChatSlice.reducer,

    adminDashboard: dashboardAdminOrderSlice.reducer, // admin

    dashboardOrder: dashboardOrderSlice.reducer, // retailer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
