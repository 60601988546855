import { Button, Form, FormProps } from "antd";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import Loader from "../../../components/common/Loader";
import { formats } from "../../../components/Editor/Formats";
import { modules } from "../../../components/Editor/Modules";
import {
  createHash,
  refreshPage,
  removeHash,
} from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createCommonDataAsync,
  getAllCommonDataAsync,
} from "../../../store/features/comonData/CommonApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonData } from "../../../types/redux/ADMIN/commonData";

export default function Privacy() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { loading, postLoading, settingsData } = useAppSelector(
    (state) => state.commonData
  );

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { privacyPolicy, privacyPolicy_bangla } = values;
    const newValues: CommonData = {
      type: "privacy-policy",
      privacyPolicy: createHash(privacyPolicy),
      privacyPolicy_bangla: createHash(privacyPolicy_bangla),
    };

    dispatch(
      createCommonDataAsync({
        data: newValues,
        others: {
          reRender,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCommonDataAsync(null));
  }, [dispatch]);

  useEffect(() => {
    if (settingsData?._id && postLoading === false) {
      form.setFieldsValue({
        privacyPolicy: removeHash(settingsData?.privacyPolicy || ""),
        privacyPolicy_bangla: removeHash(
          settingsData?.privacyPolicy_bangla || ""
        ),
      });
    }
  }, [
    form,
    postLoading,
    settingsData?._id,
    settingsData?.privacyPolicy,
    settingsData?.privacyPolicy_bangla,
  ]);
  function reRender(val: boolean) {}

  return (
    <AdminLayout
      title="Privacy"
      headerTitle="Privacy and Confidentiality"
      className="dashboard"
    >
      {loading && <Loader />}

      <section className="w-full h-screen bg-white rounded-[14px] p-6 items-center gap-[6px]">
        <Form onFinish={onFinish} form={form}>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
            <div className="">
              <p className="text-[14px] font-medium leading-6 text-header py-[10px]">
                Text Editor (English)
              </p>
              <Form.Item
                name="privacyPolicy"
                rules={[
                  {
                    required: true,
                    message: "Please enter the privacy details (English)",
                  },
                ]}
              >
                <ReactQuill
                  className="h-[400px]"
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </Form.Item>
            </div>

            <div className="">
              <p className="text-[14px] font-medium leading-6 text-header py-[10px]">
                Text Editor (Bangla)
              </p>
              <Form.Item
                name="privacyPolicy_bangla"
                rules={[
                  {
                    required: true,
                    message: "Please enter the privacy details (Bangla)",
                  },
                ]}
              >
                <ReactQuill
                  className="h-[400px]"
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className="text-center">
              <Button
                className="px-10"
                type="primary"
                htmlType="submit"
                loading={postLoading}
                onClick={refreshPage}
              >
                {postLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </section>
    </AdminLayout>
  );
}
