import { createSlice } from "@reduxjs/toolkit";
import {
  RetailerOrderProductData,
  RetailerOrderProductDetails,
  retailerOrderProductInitialStatus,
} from "../../../types/redux/ADMIN/retailerOrderProduct";
import {
  getAllOrderProductAsync,
  getSingleOrderProductAsync,
} from "./orderProductAPI";

const initialState: retailerOrderProductInitialStatus<
  RetailerOrderProductData,
  RetailerOrderProductDetails
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailerOrderProduct: [],
  singleRetailerOrderProduct: null,
  countRetailerOrderProduct: 0,
};

const retailerOrderProductSlice = createSlice({
  name: "retailer-orderProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all productCategory
    builder
      .addCase(getAllOrderProductAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrderProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerOrderProduct = action.payload?.data;
        state.countRetailerOrderProduct = action.payload.length;
      })
      .addCase(getAllOrderProductAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerOrderProduct = [];
        state.countRetailerOrderProduct = 0;
      });

    // get single product
    builder
      .addCase(getSingleOrderProductAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleOrderProductAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleRetailerOrderProduct = action.payload;
      })
      .addCase(getSingleOrderProductAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleRetailerOrderProduct = null;
      });
  },
});

export default retailerOrderProductSlice;
