import { createSlice } from "@reduxjs/toolkit";
import {
  RetailerOrderProductData,
  RetailerOrderProductDetails,
  retailerOrderProductInitialStatus,
} from "../../../types/redux/ADMIN/retailerOrderProduct";

import {
  getAllAdminOrderProductAsync,
  getSingleAdminOrderProductAsync,
} from "./orderProductAPI";

const initialState: retailerOrderProductInitialStatus<
  RetailerOrderProductData,
  RetailerOrderProductDetails
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailerOrderProduct: [],
  singleRetailerOrderProduct: null,
  countRetailerOrderProduct: 0,
};

const adminOrderProductSlice = createSlice({
  name: "admin-orderProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all productCategory
    builder
      .addCase(getAllAdminOrderProductAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAdminOrderProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerOrderProduct = action.payload?.data;
        state.countRetailerOrderProduct = action.payload.length;
      })
      .addCase(getAllAdminOrderProductAsync.rejected, (state, action) => {
        state.loading = false;
        state.retailerOrderProduct = [];
        state.countRetailerOrderProduct = 0;
      });

    // get single product
    builder
      .addCase(getSingleAdminOrderProductAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleAdminOrderProductAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleRetailerOrderProduct = action.payload;
      })
      .addCase(getSingleAdminOrderProductAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleRetailerOrderProduct = null;
      });
  },
});

export default adminOrderProductSlice;
