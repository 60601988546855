import { createSlice } from "@reduxjs/toolkit";
import {
  GetCommonSettingData,
  PrivacyInitialStatus,
} from "../../../types/redux/ADMIN/commonData";
import { createCommonDataAsync, getAllCommonDataAsync } from "./CommonApi";

const initialState: PrivacyInitialStatus<GetCommonSettingData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  settingsData: null,
};

const commonDataSlice = createSlice({
  name: "admin-commonData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create brand
    builder
      .addCase(createCommonDataAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createCommonDataAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createCommonDataAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // get all brand
    builder
      .addCase(getAllCommonDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCommonDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.settingsData = action.payload;
      })
      .addCase(getAllCommonDataAsync.rejected, (state) => {
        state.loading = false;
        state.settingsData = null;
      });
  },
});

export default commonDataSlice.reducer;
