export default function BsrmLoveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15.0091 7.66284C15.4831 6.80784 16.0741 6.09984 16.8031 5.50584C19.5061 3.30384 23.6881 3.51984 26.1241 6.01284C28.0591 7.99284 28.5361 10.3448 27.7861 12.9698C27.2071 15.0008 26.1061 16.7528 24.7681 18.3608C22.6021 20.9618 19.9681 23.0138 17.1241 24.8138C16.4941 25.2128 15.8491 25.5908 15.2041 25.9628C15.1081 26.0168 14.9341 26.0318 14.8441 25.9808C11.5741 24.1178 8.51706 21.9668 5.95506 19.1798C4.41906 17.5088 3.14706 15.6638 2.38806 13.5038C1.75806 11.7128 1.63806 9.90984 2.42706 8.14284C3.45306 5.84484 5.24406 4.43784 7.72806 4.08984C10.8541 3.65484 13.2781 4.83984 14.9281 7.55484C14.9461 7.58184 14.9641 7.60284 15.0091 7.66284Z"
        fill="white"
      />
    </svg>
  );
}
