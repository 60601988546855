export default function BsrmAttachment({ color = "#5E718D" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M10.4995 4.25L3.99112 10.8665C3.7567 11.1009 3.625 11.4189 3.625 11.7504C3.625 12.0819 3.7567 12.3998 3.99112 12.6343C4.22554 12.8687 4.54348 13.0004 4.875 13.0004C5.20652 13.0004 5.52446 12.8687 5.75888 12.6343L13.5173 4.76777C13.7495 4.53562 13.9336 4.26002 14.0592 3.95671C14.1849 3.65339 14.2495 3.3283 14.2495 3C14.2495 2.6717 14.1849 2.34661 14.0592 2.04329C13.9336 1.73998 13.7495 1.46438 13.5173 1.23223C13.2852 1.00009 13.0096 0.815938 12.7063 0.690301C12.4029 0.564665 12.0778 0.5 11.7495 0.5C11.4212 0.5 11.0961 0.564664 10.7928 0.690301C10.4895 0.815938 10.2139 1.00009 9.98177 1.23223L2.22335 9.09873C1.52009 9.80199 1.125 10.7558 1.125 11.7504C1.125 12.7449 1.52009 13.6988 2.22335 14.402C2.92661 15.1053 3.88044 15.5004 4.875 15.5004C5.86956 15.5004 6.82339 15.1053 7.52665 14.402L13.937 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
