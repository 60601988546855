import { Image, Radio } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BsrmBackArrow from "../../../assets/icons/BsrmBackArrow";
import BsrmLocation from "../../../assets/icons/BsrmLocation";
import BsrmLoveIcon from "../../../assets/icons/BsrmLoveIcon";
import BsrmOrderIcon from "../../../assets/icons/BsrmOrderIcon";
import placeHilderImage from "../../../assets/images/placeholder-image.png";
import CustomerOrderDetails from "../../../components/ModalForm/CustomerOrderDetails";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import useModal from "../../../hooks/useModal";
import AdminLayout from "../../../layouts/AdminLayout";
import { getSingleCustomerDataAsync } from "../../../store/features/customer/customerAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import CustomerManageOrder from "./CustomerManageOrder";
import CustomerProfileAddressBook from "./CustomerProfileAddressBook";
import CustomerProfileWishlist from "./CustomerProfileWishlist";

type TabType = "ORDER" | "WISH" | "ADDRESS";

export default function CustomerProfileDetails() {
  const dispatch = useAppDispatch();
  const [tabClick, setTabClick] = useState<TabType>("ORDER");
  const { viewId } = useParams();
  const { open, toggle, handleOpen } = useModal();

  const { singleCustomerData, loading } = useAppSelector(
    (state) => state.customer
  );

  // console.log(
  //   "singleCustomerData->",
  //   singleCustomerData?.customer?.deliveryAddress
  // );

  useEffect(() => {
    dispatch(
      getSingleCustomerDataAsync({
        params: {
          id: viewId as string,
        },
      })
    );
  }, [dispatch, viewId]);

  return (
    <AdminLayout
      title="Profile Details"
      headerTitle={
        <Link to="/admin/customerManage" className="flex items-center gap-5">
          <div className="w-10 h-10 border border-solid border-[#D7DFF9] rounded-full flex justify-center items-center ">
            <BsrmBackArrow />
          </div>
          <h3 className="text-body text-[24px]">Customer Profile Details</h3>
        </Link>
      }
      className="dashboard"
    >
      <section className="flex gap-4">
        <div className="w-[344px] h-full  bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
          <div className="bg-accent h-[5rem] rounded-t-[14px] p-4 relative">
            <Image
              className="absolute top-0 start-0 "
              width={90}
              height={90}
              preview={false}
              src={`${IMAGE_URL}${singleCustomerData?.customer?.image}`}
              alt={APP_NAME}
              fallback={placeHilderImage}
              style={{ border: "1px solid white", borderRadius: "100%" }}
            />
          </div>

          <div className="p-4 rounded-[14px] ">
            <div className="mt-[18px]">
              <h3 className="paragraph_heading">
                {singleCustomerData?.customer?.name}
              </h3>
              <p className="text-[16px] leading-7 font-medium">
                {singleCustomerData?.customer?.phoneNumber} |{" "}
                {singleCustomerData?.customer?.email}
                <p>
                  {singleCustomerData?.customer?.divisionId?.name},{" "}
                  {singleCustomerData?.customer?.districtId?.name}
                </p>
              </p>
            </div>
            <Radio.Group
              value={singleCustomerData?.customer?.status}
              className="mt-3"
            >
              <Radio value={singleCustomerData?.customer?.status}>Active</Radio>
              <Radio value={!singleCustomerData?.customer?.status}>
                Inactive
              </Radio>
            </Radio.Group>
          </div>

          {/* one */}
          <div
            className={`${
              tabClick === "ORDER" ? "bg-[#EEFBF9]" : ""
            } px-4 py-2 mt-2.5 cursor-pointer select-none `}
            onClick={() => setTabClick("ORDER")}
          >
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-14 h-14 bg-primary-admin text-white flex items-center justify-center">
                <BsrmOrderIcon />
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-body">
                  Order List
                </p>
                <p className="text-[14px] font-medium leading-6 text-[#159383]">
                  Total Product: {singleCustomerData?.ordersData.length}
                </p>
              </div>
            </div>
          </div>

          {/* two */}
          <div
            className={`${
              tabClick === "WISH" ? "bg-[#EEFBF9]" : ""
            } px-4 py-2 mt-2.5 cursor-pointer select-none `}
            onClick={() => setTabClick("WISH")}
          >
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-14 h-14 bg-primary-admin text-white flex items-center justify-center">
                <BsrmLoveIcon />
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-body">
                  Wishlist
                </p>
                <p className="text-[14px] font-medium leading-6 text-[#159383]">
                  Total Product: {singleCustomerData?.wishList.length}
                </p>
              </div>
            </div>
          </div>

          {/* three */}
          <div
            className={`${
              tabClick === "ADDRESS" ? "bg-[#EEFBF9]" : ""
            } px-4 py-2 mt-2.5 cursor-pointer select-none `}
            onClick={() => setTabClick("ADDRESS")}
          >
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-14 h-14 bg-primary-admin text-white flex items-center justify-center">
                <BsrmLocation />
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-body">
                  Address Book
                </p>
                <p className="text-[14px] font-medium leading-6 text-[#159383]">
                  Total Address:{" "}
                  {singleCustomerData?.customer?.deliveryAddress == null
                    ? 0
                    : singleCustomerData?.customer?.deliveryAddress?.length}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tabClick === "ORDER" ? (
          <CustomerManageOrder Data={singleCustomerData} />
        ) : tabClick === "WISH" ? (
          <CustomerProfileWishlist
            wishlistData={singleCustomerData?.wishList}
          />
        ) : tabClick === "ADDRESS" ? (
          <CustomerProfileAddressBook
            data={singleCustomerData?.customer?.deliveryAddress}
          />
        ) : null}
      </section>

      {/* Modal */}
      {open.type === "VIEW" && (
        <CustomerOrderDetails
          title="Order Details"
          open={open.open}
          // data={open?.data}
          handleClose={toggle}
          // reRender={reRender}
        />
      )}
    </AdminLayout>
  );
}
