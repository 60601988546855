import { createSlice } from "@reduxjs/toolkit";
import {
  GetScratchTypeData,
  GetSingleScratchType,
  ScratchTypeInitialStatus,
} from "../../../types/redux";
import {
  createScratchTypeAsync,
  getAllScratchTypeAsync,
  getAllScratchTypeByFilteringAsync,
  setupScratchTypeAsync,
  singleScratchTypeAsync,
  updateScratchTypeAsync,
} from "./scratchTypeAPI";

const initialState: ScratchTypeInitialStatus<
  GetScratchTypeData,
  GetSingleScratchType
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  scratchType: [],
  scratchTypeFilter: [],
  countScratchType: 0,
  singleScratchType: null,
};

const scratchTypeSlice = createSlice({
  name: "admin-scratchType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create scratchType
    builder
      .addCase(createScratchTypeAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createScratchTypeAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createScratchTypeAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all scratchType
    builder
      .addCase(getAllScratchTypeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllScratchTypeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.scratchType = action.payload.data;
        state.countScratchType = action.payload.length;
      })
      .addCase(getAllScratchTypeAsync.rejected, (state) => {
        state.loading = false;
        state.scratchType = [];
        state.countScratchType = 0;
      });

    // get all scratchType By filter
    builder
      .addCase(getAllScratchTypeByFilteringAsync.pending, (state) => {
        //  state.loading = true;
      })
      .addCase(getAllScratchTypeByFilteringAsync.fulfilled, (state, action) => {
        //  state.loading = false;
        state.scratchTypeFilter = action.payload.data;
        //  state.countScratchType = action.payload.length;
      })
      .addCase(getAllScratchTypeByFilteringAsync.rejected, (state) => {
        //  state.loading = false;
        state.scratchTypeFilter = [];
        //  state.countScratchType = 0;
      });

    // update scratchType
    builder
      .addCase(updateScratchTypeAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateScratchTypeAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateScratchTypeAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // single scratchType
    builder
      .addCase(singleScratchTypeAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleScratchType = null;
      })
      .addCase(singleScratchTypeAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleScratchType = action.payload;
      })
      .addCase(singleScratchTypeAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleScratchType = null;
      });
    // update setup scratch type
    builder
      .addCase(setupScratchTypeAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(setupScratchTypeAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(setupScratchTypeAsync.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default scratchTypeSlice;
