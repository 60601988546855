export default function BsrmMessage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M1 6.5V14.625C1 14.7908 1.06585 14.9497 1.18306 15.0669C1.30027 15.1842 1.45924 15.25 1.625 15.25H15.375C15.5408 15.25 15.6997 15.1842 15.8169 15.0669C15.9342 14.9497 16 14.7908 16 14.625V6.5M1 6.5L8.5 1.5L16 6.5M1 6.5L7.13633 10.875H9.86367L16 6.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
