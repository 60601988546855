import React from "react";

interface CustomButtonProps {
  children: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children }) => {
  return (
    <div className="bg-primary-admin text-white mt-2 px-[20px] py-[7px] rounded-lg text-[14px] font-medium leading-6   ">
      {children}
    </div>
  );
};

export default CustomButton;
