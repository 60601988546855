import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import DistrictCreateForm from "../../../components/ModalForm/DistrictCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import {
  ArrayOption,
  addKeyInArray,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteDistrictAsync,
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetDistrictData, TableData } from "../../../types/redux";

export default function DistrictList() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const [selectDivision, setSelectDivision] = useState("");

  const { limit, page, handlePagination, handleLimit } = useTable();
  const { loading, countDistrict, districts, deleteLoading, divisions } =
    useAppSelector((state) => state.location);

  const columns: ColumnsType<TableData<GetDistrictData>> = [
    {
      title: "No.",
      align: "center",
      width: "130px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "District",
      dataIndex: "name",
    },
    {
      title: "Division",
      dataIndex: "division",
      render: (val) => val?.name,
    },
    {
      title: "Latitude",
      dataIndex: "location",
      render: (val) => val?.coordinates?.[1],
    },
    {
      title: "Longitude",
      dataIndex: "location",
      render: (val) => val?.coordinates?.[0],
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetDistrictData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteDistrictAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: page,
          limit: limit,
          divisionId: selectDivision,
        },
      })
    );
  }, [dispatch, limit, page, selectDivision]);

  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  return (
    <AdminLayout
      title="District List" // browser title
      headerTitle="District List" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetDistrictData>>
        loading={loading}
        total={countDistrict}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(districts)}
        handleLimit={handleLimit}
        tableTopChildren={
          <Space size="small" className="filtering__select__option">
            <Select
              size="middle"
              placeholder="Filter by Division"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(divisions, "name", "_id")}
              onChange={setSelectDivision}
            />
            {/* <Select
              size="middle"
              placeholder="Filter by Status"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={allStatus}
              onChange={setSelectStatus}
            /> */}
          </Space>
        }
      />

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <DistrictCreateForm
          title={
            open.type === "EDIT"
              ? "Update District"
              : open.type === "VIEW"
              ? "View District"
              : "Create District"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete District"
          // questionText="Are you sure to delete division?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
