import { LockOutlined } from "@ant-design/icons";
import { Button, Form, FormProps, Input } from "antd";
import BsrmEmailIcon from "../../../assets/icons/BsrmEmailIcon";
import { createChangePasswordAsync } from "../../../store/features/auth/loginAPI";
import { userLogout } from "../../../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export default function ChangePassword() {
  const dispatch = useAppDispatch();

  const { updateLoading } = useAppSelector((state) => state.login);

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    dispatch(
      createChangePasswordAsync({
        data: values,
        others: {
          reRender,
        },
      })
    );
  };

  function reRender(val: boolean) {
    if (val) {
      dispatch(userLogout());
    }
  }

  return (
    <>
      <div className="w-[460px] h-full  bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
        <div className="px-6 py-4">
          <h3 className="mb-6">Change Password</h3>
          <Form
            name="normal_login"
            className="custom-form custom-placeholder-form hide_star_mark login-form space-y-2.5"
            layout="vertical"
            size="large"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="passwordCurrent"
              label="Old Password"
              rules={[
                { required: true, message: "Please enter your old password" },
                {
                  type: "number",
                  min: 8,
                  message: "ensure the minimum 8 characters",
                },
              ]}
            >
              <Input.Password
                placeholder="Current password"
                prefix={<LockOutlined />}
                suffix={<BsrmEmailIcon />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              label="New Password"
              rules={[
                { required: true, message: "Please enter your new password" },
                {
                  min: 8,
                  message: "Password must be minimum 8 characters",
                },
              ]}
            >
              <Input.Password
                placeholder="New password"
                prefix={<LockOutlined />}
                suffix={<BsrmEmailIcon />}
              />
            </Form.Item>

            <Form.Item
              name="passwordConfirm"
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password and Confirm password do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm password"
                prefix={<LockOutlined />}
                suffix={<BsrmEmailIcon />}
              />
            </Form.Item>

            <Form.Item className="flex justify-center mt-1.5">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={updateLoading}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
