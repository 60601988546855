import { Button, Form, Input } from "antd";
import KeyIcon from "../../assets/icons/KeyIcon";
import LoginLayout from "../../layouts/LoginLayout";
import { retailerUpdatePasswordAsync } from "../../store/features/auth/loginAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";

const NewPasswordChange: React.FC = () => {
  const dispatch = useAppDispatch();
  const { updateLoading } = useAppSelector((state) => state.login);

  const onFinish = (values: any) => {
    dispatch(
      retailerUpdatePasswordAsync({ data: values, others: { reRender } })
    );
  };

  function reRender(val: boolean) {}

  return (
    <LoginLayout title="Set your new password">
      <Form
        name="form_item_path"
        className="custom-form custom-placeholder-form login-form custom-login-form"
        layout="vertical"
        onFinish={onFinish}
        requiredMark="optional"
        size="large"
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password prefix={<KeyIcon />} placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password prefix={<KeyIcon />} placeholder="Password" />
        </Form.Item>

        <Form.Item className="mt-5">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={updateLoading}
            block
          >
            {/* {loginLoading ? "Loading..." : "Log in"} */}
            Submit
          </Button>
        </Form.Item>
      </Form>
      {/* <p className="text-center font-medium mt-8">
            Don't have an account? 
            <Link
              className="text-primary-np no-underline hover:underline"
              to="sign-up"
            >
              Sign Up
            </Link>
          </p> */}
    </LoginLayout>
  );
};

export default NewPasswordChange;
