export default function BsrmEmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M1 1.25H19M1 1.25V14C1 14.1989 1.07902 14.3897 1.21967 14.5303C1.36032 14.671 1.55109 14.75 1.75 14.75H18.25C18.4489 14.75 18.6397 14.671 18.7803 14.5303C18.921 14.3897 19 14.1989 19 14V1.25M1 1.25L10 9.5L19 1.25"
        stroke="#5E718D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
