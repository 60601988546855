import { Image as AntImage } from "antd";
import { ImageProps } from "antd/es/image";

type Props = {
  width?: number | string;
  height?: number;
  src: string;
  placeholder?: string;
  preview?: boolean;
  rounded?: string | number | "full";
} & ImageProps;

const Image: React.FC<Props> = ({
  src,
  placeholder = src,
  width = 200,
  // height = null,
  preview = false,
  // rounded = 0,
  ...rest
}) => {
  return (
    <AntImage
      width={width}
      // height={height}
      preview={preview}
      src={src}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        // borderRadius: rounded === "full" ? "50%" : rounded,
      }}
      placeholder={
        <AntImage
          preview={false}
          src={placeholder}
          width={width}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            // borderRadius: rounded === "full" ? "50%" : rounded,
          }}
        />
      }
      {...rest}
    />
  );
};

export default Image;
