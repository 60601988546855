export default function BsrmAppSettings() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M16.7502 15.75L16.7503 17.25M19.0002 12.75H14.5002M3.25026 12.75L3.25019 17.25M1.00024 9.75H5.50024M10.0002 8.25L10.0003 17.25M12.2502 5.25H7.75024M16.7503 0.75L16.7502 12.75M10.0003 0.75L10.0002 5.25M3.25019 0.75L3.25026 9.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
