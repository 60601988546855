import { Image } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BsrmAddressIcon from "../../../assets/icons/BsrmAddressIcon";
import BsrmBackArrow from "../../../assets/icons/BsrmBackArrow";
import BsrmCar from "../../../assets/icons/BsrmCar";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmGreenCheck from "../../../assets/icons/BsrmGreenCheck";
import BsrmStockOut from "../../../assets/icons/BsrmStockOut";
import BsrmTAKAicon from "../../../assets/icons/BsrmTAKAicon";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import DeleteModal from "../../../components/common/DeleteModal";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  calculateDiscountPercentage,
  calculateSalePrice,
  removeHash,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getSingleRetailerProductAsync,
  updateRetailerProductStatusAsync,
} from "../../../store/features/retailerProduct/retailerProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";

export default function RetailerProductDetails() {
  const { allViewId, viewId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const { singleProduct, updateLoading } = useAppSelector(
    (state) => state.retailerProduct
  );
  const { login } = useAppSelector((state) => state.login);
  const path = stringToArray(location.pathname, "/")?.[2];
  const navigate = useNavigate();
  const [activeImg, setActiveImg] = useState("");

  useEffect(() => {
    const id = viewId || allViewId;
    dispatch(
      getSingleRetailerProductAsync({
        params: {
          id: id as string,
        },
      })
    );
  }, [dispatch, viewId, allViewId]);

  //Discount__price___relater____calculation____start

  // Ensure singleProduct and its properties are defined
  const regularPrice =
    typeof singleProduct?.regularPrice === "string"
      ? parseFloat(singleProduct.regularPrice)
      : singleProduct?.regularPrice;
  const salesPrice = singleProduct?.salesPrice;

  // Calculate discount percentage only if both prices are defined
  const discountPercentage =
    regularPrice && salesPrice
      ? calculateDiscountPercentage(regularPrice, salesPrice)
      : 0;

  // Ensure regularPrice is a number, default to 0 if it's undefined or invalid
  const numericRegularPrice =
    typeof regularPrice === "number" && !isNaN(regularPrice) ? regularPrice : 0;

  //Discount__price___relater____calculation____end

  const handleActive = () => {
    dispatch(
      updateRetailerProductStatusAsync({
        data: {
          status: "Active",
        },
        others: {
          id: singleProduct?._id as string,
          reRender,
        },
      })
    );
  };

  // Dispatch the Reject action
  const handleInactive = () => {
    dispatch(
      updateRetailerProductStatusAsync({
        data: {
          status: "Inactive",
        },
        others: {
          id: singleProduct?._id as string,
          reRender,
        },
      })
    );
  };

  function reRender(val: boolean) {
    if (val) {
      navigate(`/retailer/manage-products/${path}`);
    }
  }

  useEffect(() => {
    if (singleProduct?.image) {
      setActiveImg(singleProduct?.image);
    }
  }, [singleProduct?.image]);

  return (
    <>
      {open.type === "INACTIVE" && (
        <DeleteModal
          title="Inactive Product"
          questionText="Are you sure to inactive this product?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleInactive}
          deleteLoading={updateLoading}
        />
      )}
      {open.type === "ACTIVE" && (
        <DeleteModal
          title="Active Product"
          questionText="Are you sure to active this product?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleActive}
          deleteLoading={updateLoading}
        />
      )}
      <AdminLayout
        title="Product Details" // browser title
        headerTitle={
          <Link
            to={`/retailer/manage-products/${path}`}
            className="flex items-center gap-5"
          >
            <div className="w-10 h-10 border border-solid border-[#D7DFF9] rounded-full flex justify-center items-center ">
              <BsrmBackArrow />
            </div>
            <h3 className="text-body text-[24px]">Product Details</h3>
          </Link>
        } // page header
        className="dashboard"
        //   headerChildren={
        //     <Space size="middle">
        //       <IconButton nextIcon={<BsrmAddIcon />}>Create New</IconButton>
        //     </Space>
        //   }
      >
        <section className="p-6 ">
          <div className="grid grid-cols-2 gap-[50px] pb-6 border-b border-x-0 border-t-0 border-solid border-[#D7DFE9]">
            <div className="rounded-lg">
              <Image
                width={504}
                height={504}
                preview={false}
                src={activeImg ? `${IMAGE_URL}${activeImg}` : placeholderImage}
                alt={APP_NAME}
                className="object-contain"
              />

              <div className="flex gap-x-4 mt-[18px]">
                {singleProduct?.gallery?.map((img, index) => (
                  <Image
                    key={index}
                    className="rounded-lg hover:border hover:border-primary-admin hover:border-solid object-contain"
                    width={80}
                    height={80}
                    preview={false}
                    src={img ? `${IMAGE_URL}${img}` : placeholderImage}
                    alt={APP_NAME}
                    onClick={() => setActiveImg(img)}
                  />
                ))}
              </div>
            </div>

            <div className="">
              <div className="" style={{ borderBottom: "1px solid #AFBACA" }}>
                <h5 className="text-others-deep text-[20px] font-semibold leading-8 mb-2.5">
                  {singleProduct?.name}
                </h5>

                <div className="flex gap-x-1.5 mb-2.5">
                  <div
                    className="text-[14px] pr-3.5 font-medium leading-6 text-body flex justify-center items-center gap-x-[6px]"
                    style={{ borderRight: "2px solid #AFBACA" }}
                  >
                    Category : <BsrmDOT /> {singleProduct?.categoryId?.name}
                  </div>
                  <div
                    className="gap-x-1.5 text-[14px] pl-1.5 pr-3.5 font-medium leading-6 text-body flex justify-center items-center"
                    style={{ borderRight: "2px solid #AFBACA" }}
                  >
                    {typeof singleProduct?.stockQuantity === "number" &&
                    singleProduct?.stockQuantity === 0 ? (
                      <BsrmStockOut />
                    ) : (
                      <BsrmGreenCheck />
                    )}
                    <span className="pl-1">
                      {typeof singleProduct?.stockQuantity === "number" &&
                      singleProduct?.stockQuantity === 0
                        ? "Sold Out"
                        : "In Stock"}
                    </span>
                  </div>
                  <div className="gap-x-1.5 text-[14px] pl-1.5 font-medium leading-6 text-[#D26500]">
                    Unit : {singleProduct?.stockQuantity} {singleProduct?.unit}
                  </div>
                </div>

                <div className="text-[14px] font-medium leading-6 text-body mb-2.5">
                  Brand :
                  <span className="pl-1.5 text-[#4A72FF] bg-[#F2F5FF] rounded-md px-2 py-1">
                    {singleProduct?.brandId?.name}
                  </span>
                </div>
              </div>

              <div
                className="py-[21px]"
                style={{ borderBottom: "1px solid #AFBACA" }}
              >
                <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                  Regular Price :
                  <span
                    className={`${
                      singleProduct?.discountAmount ? "line-through" : ""
                    } ml-5 font-semibold   text-body`}
                  >
                    {singleProduct?.regularPrice}
                    <BsrmTAKAicon color="#3d4a5c" />
                  </span>
                </div>
                {singleProduct?.discountAmount ? (
                  <div className="text-[16px] pr-1.5 font-medium leading-7 text-body">
                    Offer Price :
                    <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                      {calculateSalePrice(
                        numericRegularPrice,
                        discountPercentage
                      )}
                      <BsrmTAKAicon color="#4A72FF" />
                    </span>
                  </div>
                ) : null}

                {/* 
                <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      {val?.discountAmount > 0 ? (
                        <>
                          <span className="line-through font-semibold   text-[#8897AE] ">
                            {val?.unitPrice} <BsrmTAKAicon color="#8897AE" />
                          </span>
                          <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                            {val?.unitPrice - val?.discountAmount}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      ) : (
                        <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                          {val?.unitPrice}
                          <BsrmTAKAicon color="#4A72FF" />
                        </span>
                      )}
                    </div> */}
              </div>

              <p className="paragraph py-[21px]">
                <div
                  dangerouslySetInnerHTML={{
                    __html: removeHash(singleProduct?.shortDescription || ""),
                  }}
                />
              </p>
              <div className="bg-[#F2F5FF] p-[14px] rounded-[10px]">
                <p className="text-[#4A72FF] text-[14px] font-semibold leading-6 flex justify-start items-center">
                  <span className="mr-4">
                    <BsrmCar />
                  </span>
                  Usually Delivers in 24 to 48 hours.
                </p>
              </div>

              <div className="py-[21px]">
                <p className="paragraph">
                  The price is applicable insides of Dhaka city and outside of
                  Dhaka city it will increase.
                </p>
                <p className="mt-2.5 paragraph">
                  Call us:
                  <span className=""> +8801300800800</span>
                </p>
              </div>

              <div className="bg-[#FFFAF1] rounded-[10px] p-[14px] mb-[24px]">
                <div className="flex gap-4">
                  <BsrmAddressIcon />
                  <div className="">
                    <p className="text-[14px] font-medium leading-6 text-[#8F6A2E]">
                      Retailer Info
                    </p>
                    <p className="text-[14px] font-medium leading-6 text-[#455468]">
                      {login?.organizationName}
                    </p>
                    <p className="text-[14px] leading-6 text-[#455468]">
                      {login?.phoneNumber} | {login?.email}
                    </p>
                    <p className="text-[16px] leading-7 text-[#455468]">
                      {login?.address}
                    </p>
                  </div>
                </div>
              </div>

              {singleProduct?.retailerRequestStatus === "Approved" ? (
                <div className="grid grid-cols-2">
                  {singleProduct?.status === "Active" ? (
                    <button
                      type="button"
                      className="bg-[#FF3838] hover:bg-[#fb5f5f] text-white px-[20px] py-2 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center border-none "
                      onClick={() =>
                        handleOpen({
                          type: "INACTIVE",
                        })
                      }
                      disabled={updateLoading}
                    >
                      Inactive
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="bg-primary-admin hover:bg-[#06d9bd]  text-white px-[20px] py-2 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center border-none"
                      onClick={() =>
                        handleOpen({
                          type: "ACTIVE",
                        })
                      }
                      disabled={updateLoading}
                    >
                      Active
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}

              {/*........................... Only for all product page............................ */}

              {/* <div className="grid grid-cols-2 gap-x-[21px]">
                <Button className="bg-[#FF3838]  text-white px-[20px] py-5 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center">
                  {" "}
                  Active
                </Button>
                <Button className="bg-primary-admin  text-white px-[20px] py-5 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center">
                  {" "}
                  Inactive
                </Button>
              </div> */}
            </div>
          </div>

          <section className="mt-6">
            <h3 className="text-body text-[24px]">Description</h3>

            <article>
              <h2 className="paragraph__heading">
                Building Better, Together: Choose Akij Cement
              </h2>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: removeHash(singleProduct?.longDescription || ""),
                  }}
                />
              </p>
              {/* <ul className="ml-7">
                <li className="list_item">
                  Production capacity: 8,160 MTs/day
                </li>
                <li className="list_item">Owns Sack Plant</li>
                <li className="list_item">Modern quality control system</li>
                <li className="list_item">Backward linkage with vessels</li>
                <li className="list_item">
                  Ready Mix Concrete facility: 410 M³/hour
                </li>
              </ul> */}
            </article>

            {/* <article className="">
              <h2 className="paragraph__heading">Portland Composite Cement</h2>
              <p className="paragraph">
                Akij’s PCC CEM-II/A-M (S-L) Strength Class – 42.5 N is a durable
                Portland Composite Cement. It has high clinker content for added
                strength. A Fly-Ash Free Blast Furnace Iron Slag base makes the
                cement. This ensures strong concrete in tough conditions.
                Ingredients: 95-97% Clinker and 3-5% Gypsum.
              </p>
            </article> */}

            {/* <article className="">
              <h2 className="paragraph__heading">
                Portland Composite Cement (B-M) (S-L)
              </h2>
              <p className="paragraph">
                Bangladesh has been using a type of cement called PCC for the
                past 20 years. PCC meets the standards set by European Norms
                (EN). PCC is as durable and strong as traditional Portland
                cement (PC). It requires less clinker (65-79% compared to PC’s
                95%). Akij produces 42.5N strength-class PCC.
              </p>
            </article> */}

            {/* <article className="">
              <h2 className="paragraph__heading">Portland Cement (CEM-I)</h2>
              <p className="paragraph">
                Akij Cement makes 52.5N strength class blended cement. It falls
                under the Portland Cement (PC) category. The cement consists of
                95-97% clinker and 3-5% gypsum. This combination helps control
                the setting process. They sell the cement in 50kg polyfibre
                bags.
              </p>
              <p className="font-semibold ">Why Choose Akij Cement</p>
              <ul className="ml-7">
                <li className="list_item">
                  Production capacity: 8,160 MTs/day
                </li>
                <li className="list_item">Owns Sack Plant</li>
                <li className="list_item">Modern quality control system</li>
                <li className="list_item">Backward linkage with vessels</li>
                <li className="list_item">
                  Ready Mix Concrete facility: 410 M³/hour
                </li>
              </ul>
            </article> */}
          </section>
        </section>
      </AdminLayout>
      <div
        className="px-6 py-4 sticky bottom-0 bg-white z-10"
        style={{ borderTop: "2px solid #AFBACA" }}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-x-6">
            <Image
              width={100}
              height={100}
              preview={false}
              src={
                singleProduct?.image
                  ? `${IMAGE_URL}${singleProduct?.image}`
                  : placeholderImage
              }
              alt={APP_NAME}
            />
            <div className="">
              <h5 className="text-others-deep text-[20px] font-semibold leading-8 mb-2.5">
                {singleProduct?.name}
              </h5>

              <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                Regular Price :
                <span
                  className={`${
                    singleProduct?.discountAmount ? "line-through" : ""
                  } ml-5 font-semibold   text-body`}
                >
                  {singleProduct?.regularPrice} <BsrmTAKAicon color="#3d4a5c" />
                </span>
              </div>
              {singleProduct?.discountAmount ? (
                <div className="text-[16px] pr-1.5 font-medium leading-7 text-body">
                  Offer Price :
                  <span className="ml-10 font-semibold   text-[#4A72FF]">
                    {singleProduct?.salesPrice} <BsrmTAKAicon color="#4A72FF" />
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          {singleProduct?.retailerRequestStatus === "Approved" ? (
            <>
              {singleProduct?.status === "Active" ? (
                <button
                  type="button"
                  className="bg-[#FF3838] hover:bg-[#fb5f5f] text-white px-[20px] py-2 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center border-none "
                  onClick={() =>
                    handleOpen({
                      type: "INACTIVE",
                    })
                  }
                  disabled={updateLoading}
                >
                  Inactive
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-primary-admin hover:bg-[#06d9bd]  text-white px-[20px] py-2 rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center border-none"
                  onClick={() =>
                    handleOpen({
                      type: "ACTIVE",
                    })
                  }
                  disabled={updateLoading}
                >
                  Active
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
