import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetAllScratchTypeParams,
  GetCommonData,
  GetScratchTypeData,
  GetSingleScratchType,
  Params,
  PostRequest,
  ScratchTypeData,
  SetupScratchType,
  SingleDataParams,
} from "../../../types/redux";

// create a Scratch Type
export const createScratchTypeAsync = createAsyncThunk<
  null,
  PostRequest<ScratchTypeData, CommonOthers>
>(
  "admin-scratchType/createScratchType",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageScratchType, data);

      reRender(true);
      toast("success", "Scratch type create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Scratch type create fail");
      return rejectWithValue(error);
    }
  }
);

// get all Scratch Type
export const getAllScratchTypeAsync = createAsyncThunk<
  GetCommonData<GetScratchTypeData>,
  Params<GetAllScratchTypeParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-scratchType/getAllScratchType",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageScratchType}/data`, {
        params,
      });

      return {
        data: data.data?.ScratchTypes,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get scratch type fail");
      return rejectWithValue(error);
    }
  }
);

// get all Scratch Type by filter
export const getAllScratchTypeByFilteringAsync = createAsyncThunk<
  GetCommonData<GetScratchTypeData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-scratchType/getAllScratchTypeByFiltering",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageScratchType}/data`, {
        params,
      });

      return {
        data: data.data?.ScratchTypes,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get scratch type fail");
      return rejectWithValue(error);
    }
  }
);

// update a product category
export const updateScratchTypeAsync = createAsyncThunk<
  null,
  PostRequest<ScratchTypeData, SingleDataParams>
>(
  "admin-scratchType/updateScratchType",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageScratchType}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Scratch type update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Scratch type update fail");
      return rejectWithValue(error);
    }
  }
);

// update a single Scratch Type
export const singleScratchTypeAsync = createAsyncThunk<
  GetSingleScratchType,
  Params<SingleDataParams>
>(
  "admin-scratchType/singleScratchType",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.manageScratchType}/${params?.id}`
      );

      return data.data?.ScratchType as GetSingleScratchType;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single scratch type get fail");
      return rejectWithValue(error);
    }
  }
);

// setup a product category
export const setupScratchTypeAsync = createAsyncThunk<
  null,
  PostRequest<SetupScratchType, SingleDataParams>
>(
  "admin-scratchType/setupScratchType",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageScratchType}/setup/${id}`, data);

      reRender && reRender(true);
      toast("success", "Scratch type setup successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Scratch type setup fail");
      return rejectWithValue(error);
    }
  }
);
