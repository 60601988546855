import { App as AntApp, ConfigProvider, notification } from "antd";
import { onMessage } from "firebase/messaging";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import BsrmNotificationViewIcon from "./assets/icons/BsrmNotificationViewIcon";
import Loader from "./components/common/Loader";
import { generateToken, messaging } from "./helpers/firebaseConfig";
import useApp from "./hooks/useApp";
import useAuthChecked from "./hooks/useAuthChecked";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { authRouters, publicRouters } from "./routers/routers";
import { setNotificationToken } from "./store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "./store/store";
import { stringToArray } from "./utils/array";

type MSGType = {
  title: string;
  body: string;
};

const App: React.FC = () => {
  useApp();
  const location = useLocation();
  const { authChecked } = useAuthChecked();
  const [api, contextHolder] = notification.useNotification();
  const { siteRole } = useAppSelector((state) => state.login);
  const isRETAILER = stringToArray(location.pathname, "/")?.[0] === "retailer";
  const dispatch = useAppDispatch();
  // const isRETAILER = false;

  if ("serviceWorker" in navigator) {
    if (isRETAILER) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            // console.log(
            //   "Service Worker registered with scope: ",
            //   registration.scope
            // );
          })
          .catch((error) => {
            console.error("Service Worker registration failed: ", error);
          });
      });
    }
  }

  // Notification with firebase
  useEffect(() => {
    if (isRETAILER) {
      generateToken()
        .then((el) => {
          // console.log("token", el);
          dispatch(setNotificationToken(el));
        })
        .catch((el) => console.log(el));

      const openNotification = (msg: MSGType) => {
        console.log(msg);
        if (msg?.title !== "map") {
          api.open({
            message: msg?.title,
            description: msg?.body,
            icon: <BsrmNotificationViewIcon />,
          });
        }
      };

      // const playSound = () => {
      //   const audio = new Audio(notificationSound);
      //   audio.play();
      // };

      onMessage(messaging, (payload) => {
        const notification = payload?.notification as MSGType;
        if (notification) {
          openNotification(notification);
        }
        // playSound();
      });
    }
  }, [api, dispatch, isRETAILER]);

  if (authChecked) {
    return <Loader />;
  }

  return (
    <ConfigProvider
      // renderEmpty={() => <Empty image={img} />}
      theme={{
        token: {
          colorPrimary: "#07BFA5",
          colorPrimaryText: "#3D4A5C",
          fontFamily: "Roboto",
        },
        components: {
          Slider: {
            colorBgBase: "red",
          },
          Button: {
            colorPrimary: "#0d0d0d",
            colorPrimaryHover: "#222",
          },
          Timeline: {
            tailColor: "#07BFA5",
            itemPaddingBottom: 18,
          },
        },
      }}
    >
      <AntApp>
        {/* <Router> */}
        {contextHolder}
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={
                  siteRole === "ADMIN"
                    ? "/admin/dashboard"
                    : siteRole === "RETAILER"
                    ? "/retailer/dashboard"
                    : "/admin/login"
                }
              />
            }
          />
          <Route path="/admin" element={<Navigate to={"/admin/dashboard"} />} />
          <Route
            path="/retailer"
            element={<Navigate to={"/retailer/dashboard"} />}
          />

          {/* public routers */}
          {publicRouters.map(({ path, element: Element }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <Suspense fallback={<Loader />}>
                  <Element />
                </Suspense>
              }
            />
          ))}

          {/* Auth routers */}
          {authRouters.map(({ path, element: Element, roles }, idx) => {
            const admin = roles?.length && roles?.includes("ADMIN");
            const retailer = roles?.length && roles?.includes("RETAILER");

            if (admin || retailer) {
              return (
                <Route
                  path={
                    admin && !isRETAILER
                      ? "/admin"
                      : retailer && isRETAILER
                      ? "/retailer"
                      : ""
                  }
                  element={<SidebarLayout />}
                  key={idx + path}
                >
                  <Route
                    path={path}
                    element={
                      <AuthMiddleware roles={roles}>
                        <Suspense fallback={<Loader />}>
                          <Element />
                        </Suspense>
                      </AuthMiddleware>
                    }
                  />
                </Route>
              );
            }

            return null;
          })}
        </Routes>
        {/* </Router> */}
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
