import { createSlice } from "@reduxjs/toolkit";

import { DashboardOrderInitialStatus } from "../../../types/redux/ADMIN/dashboard";
import {
  getAdminWeeklyAverageSalesGraphDataAsync,
  getAdminYearSalesGraphDataAsync,
  getAllAdminOrderDataAsync,
} from "./adminOdersDataApi";

const initialState: DashboardOrderInitialStatus<any, any, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  oderData: null,
  salesYearData: null,
  averageSalesWeekData: null,
};

const dashboardAdminOrderSlice = createSlice({
  name: "admin-dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get dashboard data
    builder
      .addCase(getAllAdminOrderDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAdminOrderDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.oderData = action.payload.data;
        // state.countBrand = action.payload.length;
      })
      .addCase(getAllAdminOrderDataAsync.rejected, (state) => {
        state.loading = false;
        state.oderData = [];
        // state.countBrand = 0;
      });

    // get Sales Year
    builder
      .addCase(getAdminYearSalesGraphDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminYearSalesGraphDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.salesYearData = action.payload.data;
        // state.countBrand = action.payload.length;
      })
      .addCase(getAdminYearSalesGraphDataAsync.rejected, (state) => {
        state.loading = false;
        state.salesYearData = null;
        // state.countBrand = 0;
      });

    // get Average Sales week
    builder
      .addCase(getAdminWeeklyAverageSalesGraphDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAdminWeeklyAverageSalesGraphDataAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          state.averageSalesWeekData = action.payload.data;
          // state.countBrand = action.payload.length;
        }
      )
      .addCase(getAdminWeeklyAverageSalesGraphDataAsync.rejected, (state) => {
        state.loading = false;
        state.averageSalesWeekData = null;
        // state.countBrand = 0;
      });
  },
});

export default dashboardAdminOrderSlice;
