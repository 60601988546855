import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import { SlideData } from "../../../types/redux/ADMIN/appSlide";

// create a slide
export const createAppSlideAsync = createAsyncThunk<
  null,
  PostRequest<SlideData, CommonOthers>
>(
  "admin-app-slide/createAppSlide",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageAppSlide, data);

      reRender(true);
      toast("success", "Slide create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Slide create fail");
      return rejectWithValue(error);
    }
  }
);

// get all app slide
export const getAllAppSlideAsync = createAsyncThunk<
  GetCommonData<SlideData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-app-slide/getAllAppSlide", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageAppSlide}/data`, {
      params,
    });
    return {
      data: data.data?.slides,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Slide fail");
    return rejectWithValue(error);
  }
});

// update a slide
export const updateAppSlideAsync = createAsyncThunk<
  null,
  PostRequest<SlideData, SingleDataParams>
>(
  "admin-app-slide/updateAppSlide",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageAppSlide}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Slide update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Slide update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a slide
export const deleteAppSlideAsync = createAsyncThunk<null, Params<any>>(
  "admin-app-slide/deleteAppSlide",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageAppSlide}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Slide delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Slide delete fail");
      return rejectWithValue(error);
    }
  }
);
