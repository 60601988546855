import { Button, Image, Space } from "antd";

import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import PlaceholderImage from "../../../assets/images/placeholder.png";
import ScratchCreateForm from "../../../components/ModalForm/ScratchCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllScratchTypeAsync,
  getAllScratchTypeByFilteringAsync,
} from "../../../store/features/scratchType/scratchTypeAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  GetProductCategoryData,
  GetScratchTypeData,
  TableData,
} from "../../../types/redux";

export default function ScratchList() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const [selectCategory, setSelectCategory] = useState("");

  const { loading, countScratchType, scratchTypeFilter, scratchType } =
    useAppSelector((state) => state.scratchType);

  const columns: ColumnsType<TableData<GetScratchTypeData>> = [
    {
      title: "No.",
      align: "center",
      width: "100px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Scratch Type Name",
      dataIndex: "name",
    },
    {
      title: "Banner",
      dataIndex: "banner",
      align: "left",
      render: (val) => (
        <div>
          <Image
            width={150}
            height={70}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={PlaceholderImage}
          />
        </div>
      ),
    },
    {
      title: "Icon",
      dataIndex: "icon",
      align: "center",
      width: "100px",
      className: "table__head__icon__bg__highlight",
      render: (val) => (
        <div>
          <img className="w-[30px]" src={`${IMAGE_URL}${val}`} alt="" />
        </div>
      ),
    },
    {
      title: "Sorting",
      dataIndex: "sorting",
      align: "center",
      // width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetProductCategoryData) => (
        <div className="flex justify-center gap-2">
          <Link
            to={`/admin/scratch-type/type/${val?._id}`}
            className=" bg-primary-admin w-[116px] rounded-lg text-[14px] font-medium leading-7 flex justify-center items-center"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Setup
          </Link>

          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const getData = useCallback(() => {
    dispatch(
      getAllScratchTypeAsync({
        params: {
          pageNo: page,
          limit: limit,
          categoryId: selectCategory,
        },
      })
    );
  }, [dispatch, limit, page, selectCategory]);

  useEffect(() => {
    dispatch(
      getAllScratchTypeByFilteringAsync({
        params: {
          pageNo: 0,
          limit: 0,
          // categoryId: "",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  return (
    <AdminLayout
      title="Scratch"
      headerTitle="Scratch List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetScratchTypeData>>
        loading={loading}
        total={countScratchType}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
        handleLimit={handleLimit}
        columns={columns}
        dataList={addKeyInArray(scratchType)}
        // tableTopChildren={
        //   <Space size="small" className="filtering__select__option">
        //     <Select
        //       placeholder="Filter By Scratch type"
        //       className="filter_select_width "
        //       allowClear
        //       showSearch
        //       filterOption={selectSearchOption}
        //       options={ArrayOption(scratchTypeFilter, "name", "name")} // data, lavel, value
        //       onChange={setSelectCategory}
        //     />
        //   </Space>
        // }
      />

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <ScratchCreateForm
          title={
            open.type === "EDIT"
              ? "Update Scratch"
              : open.type === "VIEW"
              ? "View Scratch"
              : "Create Scratch"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}
    </AdminLayout>
  );
}
