import { createSlice } from "@reduxjs/toolkit";
import {
  BlogPostInitialStatus,
  GetBlogPostData,
} from "../../../types/redux/ADMIN/blogPost";
import {
  createBlogPostAsync,
  deleteBlogPostAsync,
  getAllBlogPostAsync,
  updateBlogPostAsync,
} from "./blogPostAPI";

const initialState: BlogPostInitialStatus<GetBlogPostData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  blogPost: [],
  countBlogPost: 0,
};

const blogPostSlice = createSlice({
  name: "admin-blogPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createBlogPostAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBlogPostAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBlogPostAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all
    builder
      .addCase(getAllBlogPostAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogPostAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.blogPost = action.payload.data;
        state.countBlogPost = action.payload.length;
      })
      .addCase(getAllBlogPostAsync.rejected, (state) => {
        state.loading = false;
        state.blogPost = [];
        state.countBlogPost = 0;
      });
    // update
    builder
      .addCase(updateBlogPostAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBlogPostAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateBlogPostAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete
    builder
      .addCase(deleteBlogPostAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteBlogPostAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteBlogPostAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default blogPostSlice;
