import { createSlice } from "@reduxjs/toolkit";
import { BankInitialStatus } from "../../../types/redux/ADMIN/bank";
import {
  createBankAsync,
  deleteBankAsync,
  getAllBankAsync,
  singleBankAsync,
  updateBankAsync,
} from "./BankApi";

const initialState: BankInitialStatus<any, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  bankList: [],
  singleBankData: null,
  countBank: 0,
};

const bankSlice = createSlice({
  name: "admin-bank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create expert
    builder
      .addCase(createBankAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBankAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBankAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all expert
    builder
      .addCase(getAllBankAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBankAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.bankList = action.payload.data;
        state.countBank = action.payload.length;
      })
      .addCase(getAllBankAsync.rejected, (state) => {
        state.loading = false;
        state.bankList = [];
        state.countBank = 0;
      });

    // single
    builder
      .addCase(singleBankAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleBankData = null;
      })
      .addCase(singleBankAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleBankData = action.payload;
      })
      .addCase(singleBankAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleBankData = null;
      });

    // update expert
    builder
      .addCase(updateBankAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBankAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateBankAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete expert
    builder
      .addCase(deleteBankAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteBankAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteBankAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default bankSlice;
