export default function BsrmList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
    >
      <path
        d="M4.87548 11H14.875M4.87548 6H14.875M4.875 1H14.875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.4375 11.9375C1.95527 11.9375 2.375 11.5178 2.375 11C2.375 10.4822 1.95527 10.0625 1.4375 10.0625C0.919733 10.0625 0.5 10.4822 0.5 11C0.5 11.5178 0.919733 11.9375 1.4375 11.9375Z"
        fill="#5E718D"
        stroke="currentColor"
      />
      <path
        d="M1.4375 6.9375C1.95527 6.9375 2.375 6.51777 2.375 6C2.375 5.48223 1.95527 5.0625 1.4375 5.0625C0.919733 5.0625 0.5 5.48223 0.5 6C0.5 6.51777 0.919733 6.9375 1.4375 6.9375Z"
        fill="#5E718D"
        stroke="currentColor"
      />
      <path
        d="M1.4375 1.9375C1.95527 1.9375 2.375 1.51777 2.375 1C2.375 0.482233 1.95527 0.0625 1.4375 0.0625C0.919733 0.0625 0.5 0.482233 0.5 1C0.5 1.51777 0.919733 1.9375 1.4375 1.9375Z"
        fill="#5E718D"
        stroke="currentColor"
      />
    </svg>
  );
}
