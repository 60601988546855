import { Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { ReactNode, useState } from "react";
import AXIOS from "../../helpers/api";
import { BASE_API } from "../../helpers/apiUrl";
import { toast } from "../../helpers/toast";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";

type Props = {
  errorMessage?: string;
  disabled?: boolean;
  accept?: string;
  handleImageChange: (url: string) => void;
  handleLoading?: (val: boolean) => void;
  handleFileName?: (val: string) => void;
  children: ReactNode;
} & UploadProps;

export default function CustomFileUpload({
  errorMessage = "File upload fail",
  disabled,
  handleImageChange,
  handleLoading,
  handleFileName,
  accept = ".png,.jpeg,.jpg",
  children,
  ...rest
}: Props) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    handleLoading && handleLoading(true);

    try {
      const response = await AXIOS.post(`${BASE_API.fileUpload}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = response?.data?.file?.url as string;
      const fileNameArr = stringToArray(url, ".");

      if (response.status === 200) {
        handleFileName &&
          handleFileName(
            `${sliceString(url, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          );

        handleImageChange(url);
        handleLoading && handleLoading(false);
      }
    } catch (error) {
      toast("error", errorMessage);
      handleLoading && handleLoading(false);
    }

    return false;
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);

    if (info.file.status === "removed") {
      handleImageChange("");
      handleFileName && handleFileName("");
    }
  };

  return (
    <Upload
      name="photo"
      beforeUpload={handleUpload}
      maxCount={1}
      accept={accept}
      className="custom-upload cursor-pointer"
      showUploadList={false}
      onChange={handleChange}
      fileList={fileList}
      disabled={disabled}
      style={{ width: "100%" }}
      {...rest}
    >
      {children}
    </Upload>
  );
}
