export default function BsrmCar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.25002 18.7505C8.25002 19.1483 8.09198 19.5298 7.81068 19.8111C7.52937 20.0924 7.14784 20.2505 6.75002 20.2505C6.35219 20.2505 5.97066 20.0924 5.68936 19.8111C5.40805 19.5298 5.25002 19.1483 5.25002 18.7505M8.25002 18.7505C8.25002 18.3527 8.09198 17.9711 7.81068 17.6898C7.52937 17.4085 7.14784 17.2505 6.75002 17.2505C6.35219 17.2505 5.97066 17.4085 5.68936 17.6898C5.40805 17.9711 5.25002 18.3527 5.25002 18.7505M8.25002 18.7505H14.25M5.25002 18.7505H3.37502C3.07665 18.7505 2.7905 18.632 2.57952 18.421C2.36855 18.21 2.25002 17.9238 2.25002 17.6255V14.2505M14.25 18.7505H16.5M14.25 18.7505V14.2505M2.25002 14.2505V6.61548C2.24843 6.34165 2.34848 6.07696 2.53081 5.87265C2.71313 5.66835 2.96477 5.53894 3.23702 5.50948C6.57006 5.16351 9.92997 5.16351 13.263 5.50948C13.828 5.56748 14.25 6.04748 14.25 6.61548V7.57348M2.25002 14.2505H14.25M19.5 18.7505C19.5 19.1483 19.342 19.5298 19.0607 19.8111C18.7794 20.0924 18.3978 20.2505 18 20.2505C17.6022 20.2505 17.2207 20.0924 16.9394 19.8111C16.6581 19.5298 16.5 19.1483 16.5 18.7505M19.5 18.7505C19.5 18.3527 19.342 17.9711 19.0607 17.6898C18.7794 17.4085 18.3978 17.2505 18 17.2505C17.6022 17.2505 17.2207 17.4085 16.9394 17.6898C16.6581 17.9711 16.5 18.3527 16.5 18.7505M19.5 18.7505H20.625C21.246 18.7505 21.754 18.2465 21.715 17.6265C21.5141 14.3241 20.4019 11.142 18.502 8.43348C18.3211 8.17976 18.0848 7.9705 17.8111 7.82151C17.5373 7.67252 17.2333 7.58771 16.922 7.57348H14.25M14.25 7.57348V14.2505"
        stroke="#4A72FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
