import { createSlice } from "@reduxjs/toolkit";
import { FaqInitialStatus, GetFaqData } from "../../../types/redux/ADMIN/faq";
import {
  createFaqAsync,
  deleteFaqAsync,
  getAllFaqAsync,
  updateFaqAsync,
} from "./faqAPI";

const initialState: FaqInitialStatus<GetFaqData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  faqs: [],
  countFaq: 0,
};

const faqSlice = createSlice({
  name: "admin-faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create division
    builder
      .addCase(createFaqAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createFaqAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createFaqAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all division
    builder
      .addCase(getAllFaqAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFaqAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload.data;
        state.countFaq = action.payload.length;
      })
      .addCase(getAllFaqAsync.rejected, (state) => {
        state.loading = false;
        state.faqs = [];
        state.countFaq = 0;
      });
    // update division
    builder
      .addCase(updateFaqAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateFaqAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateFaqAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete division
    builder
      .addCase(deleteFaqAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteFaqAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteFaqAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default faqSlice;
