export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ font: [] }],
    // [{ size: [] }],
    // [{ undo: "undo" }, { redo: "redo" }],
    // [{ color: [] }, { background: [] }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ direction: "rtl" }],
    // [{ align: [] }],
    // ["blockquote", "code-block"],
    // ["formula"],
    // ["emoji"],
  ],
  // emoji: {
  //   emojiList,
  //   buttonIcon: "🙂",
  // },

  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};
