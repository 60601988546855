import { createSlice } from "@reduxjs/toolkit";
import { GetRmcData, rmcInitialStatus } from "../../../types/redux/ADMIN/rmc";
import {
  createRmcAsync,
  deleteRmcAsync,
  getAllRmcDataAsync,
  singRmcAsync,
  updateRmcAsync,
} from "./rmcAPI";

const initialState: rmcInitialStatus<GetRmcData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  rmc: [],
  singleRmcData: null,
  countRmc: 0,
};

const rmcSlice = createSlice({
  name: "admin-rmcSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create rmc
    builder
      .addCase(createRmcAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createRmcAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createRmcAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // get all productCategory
    builder
      .addCase(getAllRmcDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRmcDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.rmc = action.payload.data;
        state.countRmc = action.payload.length;
      })
      .addCase(getAllRmcDataAsync.rejected, (state) => {
        state.loading = false;
        state.rmc = [];
        state.countRmc = 0;
      });
    // update productCategory
    builder
      .addCase(updateRmcAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRmcAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateRmcAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete productCategory
    builder
      .addCase(deleteRmcAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteRmcAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteRmcAsync.rejected, (state) => {
        state.deleteLoading = false;
      });

    // single
    builder
      .addCase(singRmcAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleRmcData = null;
      })
      .addCase(singRmcAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleRmcData = action.payload;
      })
      .addCase(singRmcAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleRmcData = null;
      });
  },
});

export default rmcSlice;
