export default function BsrmOrders() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M12.9375 12.9375C12.1608 12.9375 11.5312 13.5671 11.5312 14.3438C11.5312 15.1204 12.1608 15.75 12.9375 15.75C13.7142 15.75 14.3438 15.1204 14.3438 14.3438C14.3438 13.5671 13.7142 12.9375 12.9375 12.9375ZM12.9375 12.9375H4.90909L2.94761 2.14938C2.92405 2.01977 2.85574 1.90255 2.75461 1.81814C2.65347 1.73373 2.52592 1.6875 2.39419 1.6875H1.125M4.39773 10.125H13.2259C13.4893 10.125 13.7444 10.0325 13.9467 9.86372C14.149 9.69491 14.2856 9.46046 14.3327 9.20125L15.1875 4.5H3.375M7.03125 14.3438C7.03125 15.1204 6.40165 15.75 5.625 15.75C4.84835 15.75 4.21875 15.1204 4.21875 14.3438C4.21875 13.5671 4.84835 12.9375 5.625 12.9375C6.40165 12.9375 7.03125 13.5671 7.03125 14.3438Z"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
