export default function BsrmDoubleTikMark({ color = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
    >
      <path
        d="M9.25 1.25L3.75 6.75L1 4.00012M15 1.25L9.5 6.75L8.03919 5.28925"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
