import { createSlice } from "@reduxjs/toolkit";
import {
  customerInitialStatus,
  GetAllCustomerData,
  GetCustomerDetails,
} from "../../../types/redux/ADMIN/customer";
import { getAllCustomerAsync, getSingleCustomerDataAsync } from "./customerAPI";

const initialState: customerInitialStatus<
  GetAllCustomerData,
  GetCustomerDetails
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  customerData: [],
  singleCustomerData: null,
  countCustomerData: 0,
};

const customerSlice = createSlice({
  name: "admin-customerData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all data
    builder
      .addCase(getAllCustomerAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCustomerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.customerData = action.payload?.data;
        state.countCustomerData = action.payload.length;
      })
      .addCase(getAllCustomerAsync.rejected, (state, action) => {
        state.loading = false;
        state.customerData = [];
        state.countCustomerData = 0;
      });

    // get single data
    builder
      .addCase(getSingleCustomerDataAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleCustomerDataAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleCustomerData = action.payload;
      })
      .addCase(getSingleCustomerDataAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleCustomerData = null;
      });
  },
});

export default customerSlice;
