import { Button, Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { selectSearchOption } from "../../helpers/siteConfig";
import {
  createFaqAsync,
  updateFaqAsync,
} from "../../store/features/Faq/faqAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetExpertCategoryData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function FaqCategory({ type, data, reRender, ...rest }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [iconName, setIconName] = useState("Upload Icon");
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.expertCategory
  );

  const categoryData = data as GetExpertCategoryData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && categoryData?._id) {
      dispatch(
        updateFaqAsync({
          data: {
            ...data,
            ...values,
          },
          others: {
            reRender,
            id: categoryData?._id,
          },
        })
      );
    } else {
      dispatch(
        createFaqAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      categoryData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(categoryData?.icon, ".");
      setIconName(
        categoryData?.icon
          ? `${sliceString(categoryData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Icon"
      );

      form.setFieldsValue({
        category: data?.category,
        category_bangla: data?.category_bangla,
        icon: categoryData?.icon || "",
        sorting: categoryData?.sorting || "",
      });
    }
  }, [
    categoryData?._id,
    categoryData?.icon,
    categoryData?.sorting,
    data?.category,
    data?.category_bangla,
    form,
    type,
    updateLoading,
  ]);

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="category"
            label="Category name (English)"
            rules={[
              {
                required: true,
                message: "Please enter category name (English)",
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Name (english)"
            />
          </Form.Item>

          <Form.Item
            name="category_bangla"
            label="Category name (Bangla)"
            rules={[
              {
                required: true,
                message: "Please enter category name (Bangla)",
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Name (bangla)"
            />
          </Form.Item>

          <Form.Item
            className="custom__image__input__placeholder"
            name="icon"
            label="Select Icon"
            htmlFor="..."
            extra={
              type !== "VIEW" ? "Upload the icon carefully | SVG, PNG" : null
            }
            valuePropName="fileUrl"
            rules={[
              {
                required: true,
                message: "Please upload a icon",
              },
            ]}
          >
            <FileUpload
              errorMessage="Product icon upload fail"
              disabled={type === "VIEW"}
              handleLoading={(val) => setUploadingIcon(val)}
              handleName={(val) => setIconName(val || "Upload Icon")}
              onChange={(url) => form.setFieldsValue({ icon: url })}
            >
              <Button
                size="large"
                disabled={type === "VIEW"}
                style={{ width: "100%" }}
              >
                <div className="flex justify-between">
                  <span className="flex gap-3 items-center">
                    <BsrmTableHeader />
                    {uploadingIcon ? "Uploading..." : iconName}
                  </span>
                  <BsrmAttachment />
                </div>
              </Button>
            </FileUpload>
          </Form.Item>

          <Form.Item
            name="sorting"
            label="Sorting"
            className="col-span-2"
            rules={[
              {
                required: true,
                message: "Please select sort",
              },
            ]}
          >
            <Select
              placeholder="Select one"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={[...Array(20)].map((_, idx) => ({
                label: `${idx + 1}`,
                value: `${idx + 1}`,
              }))}
              disabled={type === "VIEW"}
            />
          </Form.Item>

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
