import { ReactNode, useEffect } from "react";
import placholder_image from "../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import { removeHash } from "../../helpers/siteConfig";
import { getSingleProductCategoryAsync } from "../../store/features/productCategory/productCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import CustomModal from "../common/CustomModal";
import Image from "../common/Image";

type Props = {
  type: "VIEW";
  children?: ReactNode;
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ProductCategoryViewForm({
  reRender,
  type,
  children,
  data,
  ...rest
}: Props) {
  const dispatch = useAppDispatch();

  const { singleCategoryData } = useAppSelector(
    (state) => state.productCategory
  );

  useEffect(() => {
    if (data?._id) {
      dispatch(
        getSingleProductCategoryAsync({
          params: {
            id: data._id,
          },
        })
      );
    }
  }, [data?._id, dispatch]);

  return (
    <CustomModal width={720} {...rest}>
      <div className="p-6 ">
        <div className="text-[14px] font-medium leading-6 text-body">
          <p className="">
            <span className="underline">Parent Category: </span>
            <span>{singleCategoryData?.parentCategoryId?.name}</span>
          </p>
          <br />
          <p className="">
            Category Type: <span> {singleCategoryData?.categoryType} </span>
          </p>
          <br />
          <p className="">
            Category Name (English): <span> {singleCategoryData?.name} </span>
          </p>
          <br />
          <p className="">
            Category Name (Bangla):{" "}
            <span> {singleCategoryData?.name_bangla} </span>
          </p>
          <br />
          <p className="">
            Category Label (English): <span> {singleCategoryData?.label} </span>
          </p>
          <br />
          <p className="">
            Category Label (Bangla):{" "}
            <span> {singleCategoryData?.label_bangla} </span>
          </p>
          <br />
          <p className="">
            Category Title (English): <span> {singleCategoryData?.title} </span>
          </p>
          <br />
          <p className="">
            Category Title (Bangla):{" "}
            <span> {singleCategoryData?.title_bangla} </span>
          </p>
          <br />
          <p className="">
            Category Description (English) :{" "}
            <span>
              {singleCategoryData?.details
                ? removeHash(singleCategoryData?.details)
                : ""}
            </span>
          </p>
          <br />
          <p className="">
            Category Description (Bangla) :{" "}
            <span>
              {singleCategoryData?.details_bangla
                ? removeHash(singleCategoryData?.details_bangla)
                : ""}
            </span>
          </p>
          <br />
          <div className="grid md:grid-cols-2 sm:grid-cols-1">
            <Image
              width={74}
              height={74}
              src={`${IMAGE_URL}${singleCategoryData?.icon}`}
              alt={APP_NAME}
              className="bg-primary-admin !rounded-full p-2.5 border-4 border-solid border-[#B0E4CF]"
              rootClassName="text-center"
              placeholder={placholder_image}
            />

            <Image
              width={140}
              height={84}
              src={`${IMAGE_URL}${singleCategoryData?.banner}`}
              alt={APP_NAME}
              rootClassName="text-center"
              placeholder={placholder_image}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
