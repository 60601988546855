export default function BsrmTableHeader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M1 12.75L5.71966 8.03033C5.7893 7.96068 5.87198 7.90544 5.96297 7.86775C6.05397 7.83006 6.1515 7.81066 6.24999 7.81066C6.34848 7.81066 6.44601 7.83006 6.537 7.86775C6.62799 7.90544 6.71067 7.96068 6.78032 8.03033L10.9697 12.2197C11.0393 12.2893 11.122 12.3446 11.213 12.3822C11.304 12.4199 11.4015 12.4393 11.5 12.4393C11.5985 12.4393 11.696 12.4199 11.787 12.3822C11.878 12.3446 11.9607 12.2893 12.0303 12.2197L13.9697 10.2803C14.0393 10.2107 14.122 10.1554 14.213 10.1177C14.304 10.0801 14.4015 10.0607 14.5 10.0607C14.5985 10.0607 14.696 10.0801 14.787 10.1177C14.878 10.1554 14.9607 10.2107 15.0303 10.2803L19 14.25M1.75 1.5H18.25C18.6642 1.5 19 1.83579 19 2.25V15.75C19 16.1642 18.6642 16.5 18.25 16.5H1.75C1.33579 16.5 1 16.1642 1 15.75V2.25C1 1.83579 1.33579 1.5 1.75 1.5Z"
        stroke="#5E718D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.625 7.5C13.2463 7.5 13.75 6.99632 13.75 6.375C13.75 5.75368 13.2463 5.25 12.625 5.25C12.0037 5.25 11.5 5.75368 11.5 6.375C11.5 6.99632 12.0037 7.5 12.625 7.5Z"
        fill="#5E718D"
      />
    </svg>
  );
}
