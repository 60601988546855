export default function BsrmTaka() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M20.2584 12.7256C19.6344 10.7 18.1392 9.6056 16.176 9.716C14.9952 9.7832 14.1384 10.4768 13.884 11.6288C13.8 12.008 13.7856 12.416 13.8144 12.8048C13.9296 14.3864 14.8632 15.2528 16.4472 15.2768C16.6704 15.2816 16.8912 15.2768 17.1408 15.2768C17.0952 15.5528 17.0784 15.7712 17.0184 15.98C16.4736 17.9096 15.1752 19.2176 13.488 20.18C12.9576 20.4848 12.3672 20.5736 11.7504 20.5352C10.464 20.4536 9.79199 19.7552 9.78959 18.4568C9.78719 16.6952 9.78959 14.936 9.78959 13.1744V12.7304H13.0968C12.612 12.2312 12.168 11.8328 11.7936 11.3744C11.3208 10.7936 10.7856 10.448 10.0104 10.6064C9.95999 10.616 9.90239 10.5992 9.78959 10.5848V10.112C9.78479 8.6528 9.81119 7.1912 9.77039 5.732C9.76319 5.492 9.74399 5.2568 9.70559 5.0264C9.58079 4.2368 9.27839 3.5048 8.74319 2.8592C8.37119 2.4104 7.90079 2.1128 7.32719 2.0312C7.16879 2.0096 7.00799 2 6.85199 2H4.73279C4.63439 2.4944 4.58639 3.0032 4.58639 3.5288C4.58639 4.04 4.63439 4.5392 4.73279 5.0312L6.24959 5.0792L6.49679 5.1056C6.55439 5.1224 6.70079 5.1464 6.72719 5.156C6.73439 5.1584 6.90239 5.2136 7.02479 5.3216C7.37039 5.6192 7.57679 6.0512 7.57919 6.6248C7.58159 7.5368 7.57919 8.4536 7.57919 9.368V10.6232H3.45599C4.10399 11.3024 4.67519 11.9192 5.27279 12.5096C5.39759 12.6344 5.61599 12.716 5.79839 12.7256C6.37919 12.7496 6.96239 12.7352 7.58159 12.7352V13.2224C7.58159 15.1592 7.60559 17.0984 7.56959 19.0352C7.55519 19.7864 7.81199 20.384 8.27519 20.9312C9.87359 22.8176 11.8512 23.444 14.2128 22.676C17.112 21.7424 18.9648 19.688 20.088 16.94C20.6496 15.5696 20.6952 14.1416 20.2584 12.7256Z"
        fill="#3D4A5C"
      />
    </svg>
  );
}
