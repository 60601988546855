import React from "react";

import Button from "./Button";
import Panel from "./Panel";
import "./style.css";

// Define the type for the click event handler

interface AccordionProps {
  question: string;
  answer: string;
  handleOpen?: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  question,
  answer,
  handleOpen,
}) => {
  return (
    <>
      <Button text={question} className={"accordion"} handleOpen={handleOpen} />
      <Panel text={answer} className={"panel"} />
    </>
  );
};

export default Accordion;
