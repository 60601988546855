import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { AUTH_SAVE } from "../../../helpers/config";
import { toast } from "../../../helpers/toast";
import {
  AuthUser,
  ChangePasswordData,
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetLogoutParams,
  LoginActionType,
  LoginData,
  PasswordUpdateParams,
  PostRequest,
} from "../../../types/redux";

// login user
export const loginUserAsync = createAsyncThunk<
  LoginActionType<AuthUser>,
  PostRequest<LoginData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-login/login",
  async (
    { data: { role, ...rest }, others: { reRender } },
    { rejectWithValue }
  ) => {
    try {
      const loginData = await AXIOS.post(
        role === "RETAILER" ? BASE_API.retailerLogin : BASE_API.login,
        { ...rest }
      );

      const responseData = {
        token: loginData?.data?.token,
        data: loginData?.data?.user,
        siteRole:
          loginData?.data?.userType === "admin"
            ? "ADMIN"
            : loginData?.data?.userType === "employee"
            ? "RETAILER"
            : loginData?.data?.userType,
      };

      if (responseData?.token && responseData?.data?.email) {
        AXIOS.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responseData?.token}`;

        localStorage.setItem(`${AUTH_SAVE}`, JSON.stringify(responseData));
      }

      reRender(true);
      toast("success", "Login successfully");

      return responseData as LoginActionType<AuthUser>;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Login fail");
      return rejectWithValue(error);
    }
  }
);

// get all logout data
export const getLogoutAsync = createAsyncThunk<
  GetCommonData<any>,
  GetLogoutParams,
  {
    rejectValue: ErrorMessage;
  }
>("admin-login/getLogout", async ({ reRender }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.logout}`);

    reRender(true);
    return {
      data: data.data,
      length: data.data,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get data fail");
    return rejectWithValue(error);
  }
});

// get all logout data RETAILER
export const getRetailerLogoutAsync = createAsyncThunk<
  GetCommonData<any>,
  GetLogoutParams,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-login/getRetailerLogout",
  async ({ reRender }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.retailerLogout}`);

      reRender(true);
      return {
        data: data.data,
        length: data.data,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get data fail");
      return rejectWithValue(error);
    }
  }
);

// create
export const createChangePasswordAsync = createAsyncThunk<
  null,
  PostRequest<ChangePasswordData, CommonOthers>
>(
  "admin-login/createChangePassword",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.ChangePass}`, data);

      reRender && reRender(true);
      toast("success", "successfully password changed");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "password change fail");
      return rejectWithValue(error);
    }
  }
);

// retailer
export const retailerChangePasswordAsync = createAsyncThunk<
  null,
  PostRequest<ChangePasswordData, CommonOthers>
>(
  "admin-login/retailerChangePassword",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.retailerChangePassword}`, data);

      reRender && reRender(true);
      toast("success", "successfully password changed");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "password change fail");
      return rejectWithValue(error);
    }
  }
);

// forgot password retailer
export const forgotPasswordRetailerAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/retailerForgotPassword",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.retailerForgotPassword, data);

      toast("success", "Token sent! please check your email");
      // reRender(true);

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "User not found");
      return rejectWithValue(error);
    }
  }
);

// update password retailer
export const retailerUpdatePasswordAsync = createAsyncThunk<
  null,
  PostRequest<any, PasswordUpdateParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/retailerUpdatePassword",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      const updatePassword = await AXIOS.post(
        BASE_API.retailerUpdatePassword,
        data
      );
      toast("success", "Token sent! please check your email");
      // reRender(true);
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "User not found");
      return rejectWithValue(error);
    }
  }
);
