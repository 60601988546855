import { Space } from "antd";
import { HTMLAttributes, ReactNode } from "react";
import Subheader from "../components/ui/Subheader";
import AppLayout from "./AppLayout";

type Props = {
  title?: string;
  children: ReactNode;
  headerTitle?: ReactNode;
  headerChildren?: ReactNode;
  subheaderTitle?: string;
  subheaderChildren?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export default function AdminLayout({
  headerTitle = "",
  headerChildren = null,
  subheaderTitle = "",
  subheaderChildren = null,
  children,
  ...rest
}: Props) {
  return (
    <AppLayout {...rest}>
      {headerTitle || headerChildren ? (
        <Space
          wrap={true}
          align="center"
          className="flex justify-between mb-4 header_children"
        >
          <h3 className="text-body text-[24px]">{headerTitle}</h3>
          {headerChildren && <div>{headerChildren}</div>}
        </Space>
      ) : null}

      {subheaderTitle || subheaderChildren ? (
        <Subheader title={subheaderTitle}>{subheaderChildren}</Subheader>
      ) : null}

      <main>{children}</main>
    </AppLayout>
  );
}
