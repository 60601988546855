export default function BsrmSecondIcon() {
  return (
    <svg
      width="41"
      height="8"
      viewBox="0 0 41 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M7.80917 3.96312C7.80917 3.96312 9.12259 3.78254 9.12259 2.31134C9.12259 0.661882 6.49575 0.777634 6.49575 0.777634H0V7.22847L6.87894 7.22615C7.97566 7.22615 9.30097 6.86617 9.30097 5.5848C9.30097 4.30343 7.80917 3.96312 7.80917 3.96312ZM2.0362 1.93746L6.19448 1.90274C6.42307 1.90274 7.01636 1.90274 7.01636 2.66323C7.01636 3.42372 6.10199 3.42372 6.10199 3.42372H2.03488V1.93746H2.0362ZM6.10199 6.06632H2.03488V4.57892L5.99496 4.54419C6.64903 4.54419 7.08771 4.66457 7.08771 5.30468C7.08904 6.06632 6.10199 6.06632 6.10199 6.06632ZM16.8062 3.38436C16.8062 3.38436 14.1715 3.38436 13.4857 3.38436C12.7999 3.38436 12.5621 3.06257 12.5911 2.544C12.6242 1.95714 13.52 1.94325 13.52 1.94325H18.7592L18.7988 0.782264H12.6004C12.6004 0.782264 10.1836 0.702395 10.1836 2.62387C10.1836 4.54535 12.4088 4.5847 12.4088 4.5847C12.4088 4.5847 15.3329 4.5847 15.4704 4.5847C15.6078 4.5847 16.8868 4.50483 16.8868 5.26532C16.8868 6.02581 15.8364 5.99109 15.8364 5.99109H10.5364V7.22731H17.0243C17.0243 7.22731 19.4 6.92983 19.4 5.2086C19.3987 3.48738 16.8062 3.38436 16.8062 3.38436ZM28.2782 2.74425C28.2782 0.769531 26.0847 0.769531 26.0847 0.769531H20.1149V7.22731L22.2515 7.23194V4.71435H24.2573L26.1455 7.22731H28.7037L26.571 4.61248C26.5723 4.61133 28.2782 4.71897 28.2782 2.74425ZM24.9629 3.64712C24.5626 3.64712 22.2581 3.64712 22.2581 3.64712V1.91547H24.8691C24.8691 1.91547 25.9037 1.91547 25.9037 2.71879C25.9037 3.52326 25.362 3.64712 24.9629 3.64712ZM37.1722 0.86329L34.7964 5.5466L32.4655 0.823935H29.359V7.22847H31.4613V2.46413L33.7459 7.22963H35.8931L38.224 2.62503V7.22847H40.28V0.86329H37.1722Z"
        fill="#EB7101"
      />
    </svg>
  );
}
