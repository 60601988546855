export default function BsrmSkuActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
    >
      <path
        d="M16.875 5.3125L12.9375 0.8125H5.0625L1.125 5.3125M16.875 5.3125L9 13.75M16.875 5.3125H1.125M9 13.75L1.125 5.3125M9 13.75L12.4517 5.3125L9 0.8125L5.54829 5.3125L9 13.75Z"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
