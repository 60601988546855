export default function LocationIcon() {
  return (
    <svg
      width="62"
      height="61"
      viewBox="0 0 62 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31" cy="30.5" r="30.5" fill="#6F8EFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.04 41.351L30.11 41.391L30.138 41.407C30.249 41.467 30.3733 41.4985 30.4995 41.4985C30.6257 41.4985 30.75 41.467 30.861 41.407L30.889 41.392L30.96 41.351C31.3511 41.1191 31.7328 40.8716 32.104 40.609C33.0651 39.9305 33.963 39.1667 34.787 38.327C36.731 36.337 38.75 33.347 38.75 29.5C38.75 27.312 37.8808 25.2135 36.3336 23.6664C34.7865 22.1192 32.688 21.25 30.5 21.25C28.312 21.25 26.2135 22.1192 24.6664 23.6664C23.1192 25.2135 22.25 27.312 22.25 29.5C22.25 33.346 24.27 36.337 26.213 38.327C27.0366 39.1667 27.9343 39.9304 28.895 40.609C29.2666 40.8716 29.6485 41.1191 30.04 41.351ZM30.5 32.5C31.2956 32.5 32.0587 32.1839 32.6213 31.6213C33.1839 31.0587 33.5 30.2956 33.5 29.5C33.5 28.7044 33.1839 27.9413 32.6213 27.3787C32.0587 26.8161 31.2956 26.5 30.5 26.5C29.7044 26.5 28.9413 26.8161 28.3787 27.3787C27.8161 27.9413 27.5 28.7044 27.5 29.5C27.5 30.2956 27.8161 31.0587 28.3787 31.6213C28.9413 32.1839 29.7044 32.5 30.5 32.5Z"
        fill="white"
      />
    </svg>
  );
}
