import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";

type Props = {
  viewId: string | undefined;
};

export default function Inventory({ viewId }: Props) {
  return (
    <>
      {/* <div className="py-3 mb-4">
        <Checkbox className="text-[16px] font-medium leading-7 text-body">
          Track stock quantity for this product
        </Checkbox>
      </div> */}
      <div className="grid grid-cols-3 gap-2.5">
        <Form.Item
          name="sku"
          label="SKU"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select region",
          //   },
          // ]}
        >
          <Input
            size="large"
            placeholder="IHB854756555R"
            suffix={<InfoCircleOutlined />}
            disabled
          />
        </Form.Item>

        <Form.Item
          name="stockQuantity"
          label="Quantity"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="0"
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="unit"
          label="Unit Type"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select",
          //   },
          // ]}
        >
          <Select
            placeholder="Select unit type"
            size="large"
            allowClear
            showSearch
            disabled={viewId ? true : false}
            options={[
              {
                value: "Ton",
                label: "Ton",
              },
              {
                value: "KG",
                label: "KG",
              },
              {
                value: "Foot",
                label: "Foot",
              },
              {
                value: "PCS",
                label: "PCS",
              },
              {
                value: "Meter",
                label: "Meter",
              },
              {
                value: "Bag",
                label: "Bag",
              },
            ]}
          />
        </Form.Item>
      </div>
    </>
  );
}
