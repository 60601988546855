export default function BsrmDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M8.68777 6.3125V10.8125M5.31277 6.3125V10.8125M13.1875 2.9375L0.8125 2.93751M12.0628 2.9375V13.625C12.0628 13.7742 12.0035 13.9173 11.898 14.0227C11.7925 14.1282 11.6495 14.1875 11.5003 14.1875H2.50027C2.35109 14.1875 2.20802 14.1282 2.10253 14.0227C1.99704 13.9173 1.93777 13.7742 1.93777 13.625V2.9375M9.81277 2.9375V1.8125C9.81277 1.51413 9.69425 1.22798 9.48327 1.017C9.27229 0.806026 8.98614 0.6875 8.68777 0.6875H5.31277C5.01441 0.6875 4.72826 0.806026 4.51728 1.017C4.3063 1.22798 4.18777 1.51413 4.18777 1.8125V2.9375"
        stroke="#8897AE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
