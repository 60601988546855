export default function BsrmOrderValue() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M3.50073 1.375H14.1882C14.3374 1.375 14.4805 1.43426 14.586 1.53975C14.6915 1.64524 14.7507 1.78832 14.7507 1.9375V10.375M1.81323 3.625H11.9382C12.2489 3.625 12.5007 3.87684 12.5007 4.1875V12.0625C12.5007 12.3732 12.2489 12.625 11.9382 12.625H1.81323C1.50257 12.625 1.25073 12.3732 1.25073 12.0625V4.1875C1.25073 3.87684 1.50257 3.625 1.81323 3.625Z"
        stroke="#07BFA5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
