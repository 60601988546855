import { Button, Form, FormProps, Input, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  ArrayOption,
  createHash,
  removeHash,
  selectSearchOption,
} from "../../helpers/siteConfig";
import { getAllBrandAsync } from "../../store/features/Brand/BrandApi";
import { getAllProductCategoryAsync } from "../../store/features/productCategory/productCategoryAPI";
import {
  createRmcAsync,
  singRmcAsync,
  updateRmcAsync,
} from "../../store/features/RMC/rmcAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetRmcData } from "../../types/redux/ADMIN/rmc";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function RadyMixCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [iconName, setIconName] = useState("Upload product image");
  const [bannerName, setBannerName] = useState("Upload product image");
  const { productCategory } = useAppSelector((state) => state.productCategory);

  const { brand } = useAppSelector((state) => state.brand);
  const { postLoading, updateLoading } = useAppSelector((state) => state.RMC);
  const readyMixData = data as GetRmcData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, ...rest } = values;

    const newValues = {
      ...rest,
      details: createHash(details),
    };

    if (type === "EDIT" && readyMixData?._id) {
      dispatch(
        updateRmcAsync({
          data: newValues,
          others: {
            reRender,
            id: readyMixData?._id,
          },
        })
      );
    } else {
      dispatch(
        createRmcAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // dispatch category data
  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch]);

  // single data
  useEffect(() => {
    if (readyMixData?._id) {
      dispatch(
        singRmcAsync({
          params: {
            id: readyMixData?._id,
            // reRender,
          },
        })
      );
    }
  }, [dispatch, readyMixData?._id]);

  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  //   add value in modal for view and edit

  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      readyMixData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(readyMixData?.icon, ".");
      setIconName(
        readyMixData?.icon
          ? `${sliceString(readyMixData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Image"
      );

      form.setFieldsValue({
        status: readyMixData?.status + "",
        productCategoryId: readyMixData?.productCategoryId,
        brandId: readyMixData?.brandId._id,
        name: readyMixData?.name,
        phoneNumber: readyMixData?.phoneNumber,
        sorting: readyMixData?.sorting,
        details: readyMixData?.details ? removeHash(readyMixData?.details) : "",
        icon: readyMixData?.icon,
        banner: readyMixData?.banner,
      });
    }
  }, [
    form,
    productCategory,
    readyMixData?._id,
    readyMixData?.banner,
    readyMixData?.brandId,
    readyMixData?.details,
    readyMixData?.icon,
    readyMixData?.name,
    readyMixData?.productCategoryId,
    readyMixData?.phoneNumber,
    readyMixData?.sorting,
    readyMixData?.status,
    type,
    updateLoading,
  ]);

  return (
    <CustomModal width={750} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark  grid md:grid-cols-2 gap-x-4"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="productCategoryId"
            label="Product Category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(productCategory, "name", "_id")}
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            name="brandId"
            label="Brand"
            rules={[
              {
                required: true,
                message: "Please select brand name",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(brand, "name", "_id")}
              disabled={type === "VIEW"}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input size="large" placeholder="Name" disabled={type === "VIEW"} />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Mobile"
            rules={[
              {
                required: true,
                message: "Please enter number",
              },
              {
                pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                message: "Please enter a valid Bangladeshi phone number",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Mobile number"
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <div className="col-span-2 flex justify-between">
            <Form.Item
              className="custom__image__input__placeholder w-[49%]"
              name="icon"
              label="Select Icon"
              htmlFor="..."
              extra={
                type !== "VIEW" ? "Upload the icon carefully | jpeg,png" : null
              }
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload a icon",
                },
              ]}
            >
              <FileUpload
                errorMessage="Product image upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingIcon(val)}
                handleName={(val) =>
                  setBannerName(val || "Upload Product image")
                }
                onChange={(url) => form.setFieldsValue({ banner: url })}
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingIcon ? "Uploading..." : bannerName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>
            <div className="">
              {type === "EDIT" || type === "VIEW" ? (
                <div className="flex justify-center ">
                  <Image
                    width={140}
                    height={84}
                    src={`${IMAGE_URL}${form.getFieldValue("icon")}`}
                    alt={APP_NAME}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <Form.Item
            name="sorting"
            label="Sorting"
            className=""
            rules={[
              {
                required: true,
                message: "Please select Sort",
              },
            ]}
          >
            <Select
              placeholder="Select one"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={[...Array(10)].map((_, idx) => ({
                label: `${idx + 1}`,
                value: `${idx + 1}`,
              }))}
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item label="Status" name="status">
            <Radio.Group disabled={type === "VIEW"}>
              <Radio value="true">Active</Radio>
              <Radio value="false">Inactive</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="details"
            label="Add Description"
            className="md:col-span-2"
          >
            <ReactQuill
              className="h-[170px]"
              theme="snow"
              formats={formats}
              modules={modules}
              value=""
              readOnly={type === "VIEW"}
            />
          </Form.Item>

          <br />
          {type !== "VIEW" ? (
            <div className="col-span-2 flex justify-between">
              <div></div>
              <div className=" w-32 ">
                <Button
                  className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                  type="primary"
                  htmlType="submit"
                  loading={postLoading || updateLoading}
                >
                  {type === "EDIT" ? "Update Now" : "Create Now"}
                </Button>
              </div>
              <div></div>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
