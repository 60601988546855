import { createSlice } from "@reduxjs/toolkit";
import { BrandInitialStatus } from "../../../types/redux";
import {
  createBrandAsync,
  deleteBrandAsync,
  getAllBrandAsync,
  getAllBrandByFilterAsync,
  updateBrandAsync,
} from "./BrandApi";

const initialState: BrandInitialStatus<any, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  brand: [],
  brandByFilter: [],
  countBrand: 0,
};

const brandSlice = createSlice({
  name: "admin-brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create brand
    builder
      .addCase(createBrandAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBrandAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBrandAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // get all brand
    builder
      .addCase(getAllBrandAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBrandAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.brand = action.payload.data;
        state.countBrand = action.payload.length;
      })
      .addCase(getAllBrandAsync.rejected, (state) => {
        state.loading = false;
        state.brand = [];
        state.countBrand = 0;
      });

    // get all brand by filter
    builder
      .addCase(getAllBrandByFilterAsync.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getAllBrandByFilterAsync.fulfilled, (state, action) => {
        // state.loading = false;
        state.brand = action.payload.data;
        // state.countBrand = action.payload.length;
      })
      .addCase(getAllBrandByFilterAsync.rejected, (state) => {
        // state.loading = false;
        state.brand = [];
        // state.countBrand = 0;
      });

    // update scratchType
    builder
      .addCase(updateBrandAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBrandAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateBrandAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // delete brand
    builder
      .addCase(deleteBrandAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteBrandAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteBrandAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default brandSlice;
