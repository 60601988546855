import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import BrandCreateForm from "../../../components/ModalForm/BrandCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteBrandAsync,
  getAllBrandAsync,
} from "../../../store/features/Brand/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { BrandData, TableData } from "../../../types/redux";

export default function ProductBrandList() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();

  const [selectStatus, setSelectStatus] = useState("");
  const { open, toggle, handleOpen } = useModal();
  const { brand, countBrand, deleteLoading, loading } = useAppSelector(
    (state) => state.brand
  );

  console.log("brand--->", brand);
  const columns: ColumnsType<TableData<BrandData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Brand Name",
      dataIndex: "name",
      align: "left",
    },

    {
      title: "Logo",
      align: "center",
      dataIndex: "logo",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            width={92}
            height={33}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={placeholderImage}
          />
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />

          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />

          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteBrandAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          brandId: "",
        },
      })
    );
  }, [dispatch, limit, page, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }
  const allStatus = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  return (
    <AdminLayout
      title="Product Brand" // browser title
      headerTitle="Product Brand List" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create Now
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<BrandData>>
          loading={loading}
          total={countBrand}
          page={page}
          limit={limit}
          columns={columns}
          handlePagination={handlePagination}
          dataList={addKeyInArray(brand)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              {/* <Select
                placeholder="Filter By Brand"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(brand, "name", "_id")}
                onChange={setSelectBrand}
              /> */}
              <Select
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <BrandCreateForm
          title={
            open.type === "EDIT"
              ? "Update Brand"
              : open.type === "VIEW"
              ? "View Brand"
              : "Brand Create"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Brand"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
