export default function BsrmLiveChat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M5.50012 10.5V14.25C5.50012 14.4489 5.57914 14.6397 5.71979 14.7803C5.86044 14.921 6.05121 15 6.25012 15H15.2892L19.0001 18V6C19.0001 5.80109 18.9211 5.61032 18.7805 5.46967C18.6398 5.32902 18.449 5.25 18.2501 5.25H14.5001M4.71102 10.5L1.00012 13.5V1.5C1.00012 1.30109 1.07914 1.11032 1.21979 0.96967C1.36044 0.829018 1.55121 0.75 1.75012 0.75H13.7501C13.949 0.75 14.1398 0.829018 14.2805 0.96967C14.4211 1.11032 14.5001 1.30109 14.5001 1.5V9.75C14.5001 9.94891 14.4211 10.1397 14.2805 10.2803C14.1398 10.421 13.949 10.5 13.7501 10.5H4.71102Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
