import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import { CommonOthers, ErrorMessage, PostRequest } from "../../../types/redux";
import {
  CommonData,
  GetCommonSettingData,
} from "../../../types/redux/ADMIN/commonData";

// create a Privacy
export const createCommonDataAsync = createAsyncThunk<
  null,
  PostRequest<CommonData, CommonOthers>
>(
  "admin-commonData/createCommonData",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageCommonData, data);

      reRender(true);
      toast("success", "Successfully Created");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Create fail");
      return rejectWithValue(error);
    }
  }
);

export const getAllCommonDataAsync = createAsyncThunk<
  GetCommonSettingData,
  null,
  {
    rejectValue: ErrorMessage;
  }
>("admin-commonData/getAllCommonData", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageCommonData}/data`);

    return data.data?.commonData as GetCommonSettingData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Data get fail");
    return rejectWithValue(error);
  }
});
