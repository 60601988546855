import { Button, Space } from "antd";
import { useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import RetailerChangePassword from "./RetailerChangePassword";
import RetailerUserInfo from "./RetailerUserInfo";
import RetailerUserLogDetails from "./RetailerUserLogDetails";

type TabType = "USER_LOG" | "CHANGE_PASSWORD";

export default function RetailerSettings() {
  const [tab, setTab] = useState<TabType>("USER_LOG");

  return (
    <AdminLayout
      title="Settings" // browser title
      headerTitle="Settings" // page header
      className="dashboard"
      headerChildren={
        <Space className="flex justify-end" size="middle">
          <div className="flex items-center bg-white rounded-md gap-[5px] p-[5px] border border-solid border-others-border">
            <Button
              className={`px-10 font-medium ${
                tab !== "USER_LOG" ? "!border-none" : ""
              }`}
              type={tab === "USER_LOG" ? "primary" : "default"}
              onClick={() => setTab("USER_LOG")}
            >
              User Info
            </Button>
            <Button
              className={`font-medium ${
                tab !== "CHANGE_PASSWORD" ? "!border-none" : ""
              }`}
              type={tab === "CHANGE_PASSWORD" ? "primary" : "default"}
              onClick={() => setTab("CHANGE_PASSWORD")}
            >
              Change Password
            </Button>
          </div>
        </Space>
      }
    >
      <RetailerUserInfo>
        {tab === "USER_LOG" ? (
          <RetailerUserLogDetails />
        ) : tab === "CHANGE_PASSWORD" ? (
          <RetailerChangePassword />
        ) : null}
      </RetailerUserInfo>
    </AdminLayout>
  );
}
