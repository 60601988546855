import React from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import SocketProvider from "./Context/SocketProvider";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "./styles/index.css";
import "./styles/override.css";
// import "./styles/retailerStyle.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <SocketProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </SocketProvider>
  </React.StrictMode>
);

reportWebVitals();
