export default function BsrmLocation2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.628 23.1829L11.6316 23.1841C11.868 23.2873 12 23.2633 12 23.2633C12 23.2633 12.132 23.2873 12.3696 23.1841L12.372 23.1829L12.3792 23.1793L12.4008 23.1697C12.5147 23.1168 12.6271 23.0607 12.738 23.0017C12.9612 22.8865 13.2732 22.7137 13.6464 22.4821C14.3904 22.0213 15.3804 21.3229 16.3752 20.3641C18.3624 18.4489 20.4 15.4549 20.4 11.2633C20.4 10.1602 20.1827 9.06788 19.7606 8.04874C19.3384 7.02961 18.7197 6.1036 17.9397 5.32358C17.1597 4.54357 16.2337 3.92483 15.2145 3.50269C14.1954 3.08055 13.1031 2.86328 12 2.86328C10.8969 2.86328 9.80457 3.08055 8.78543 3.50269C7.7663 3.92483 6.84029 4.54357 6.06028 5.32358C5.28027 6.1036 4.66153 7.02961 4.23939 8.04874C3.81725 9.06788 3.59998 10.1602 3.59998 11.2633C3.59998 15.4537 5.63758 18.4489 7.62598 20.3641C8.45848 21.1637 9.3727 21.8736 10.3536 22.4821C10.7335 22.718 11.1243 22.9358 11.5248 23.1349L11.5992 23.1697L11.6208 23.1793L11.628 23.1829ZM12 13.9633C12.7161 13.9633 13.4028 13.6788 13.9092 13.1725C14.4155 12.6661 14.7 11.9794 14.7 11.2633C14.7 10.5472 14.4155 9.86044 13.9092 9.35409C13.4028 8.84775 12.7161 8.56328 12 8.56328C11.2839 8.56328 10.5971 8.84775 10.0908 9.35409C9.58444 9.86044 9.29998 10.5472 9.29998 11.2633C9.29998 11.9794 9.58444 12.6661 10.0908 13.1725C10.5971 13.6788 11.2839 13.9633 12 13.9633Z"
        fill="#8897AE"
      />
    </svg>
  );
}
