export default function BsrmBrandList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M12 7.00018L0.75 18.2502M3 16.0002C-1.5 8.50018 4.5 1.00018 17.25 1.75018C18 14.5002 10.5 20.5002 3 16.0002Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
