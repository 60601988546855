import axios from "axios";
import { API_URL, AUTH_SAVE } from "./config";

const AXIOS = axios.create({
  baseURL: API_URL,
});

AXIOS.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV !== "production") {
      // console.log("GLOBAL_ERROR", error.response);
    }

    if (
      error.response?.status === 401 &&
      (error.response?.data?.message ===
        "The user belonging to this token does no longer exist." ||
        error.response?.data?.message ===
          "User recently changed password! Please log in again.")
    ) {
      localStorage.removeItem(`${AUTH_SAVE}`);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default AXIOS;
