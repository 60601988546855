import { Image } from "antd";
import { ColumnsType } from "antd/es/table";
import placeHolderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArrayRevereLoginLog } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import { useAppSelector } from "../../../store/store";

import { formatDate, formatTime } from "../../../utils/time";

export default function RetailerUserLogDetails() {
  const { limit, page, handlePagination } = useTable();
  const { login } = useAppSelector((state) => state.login);
  const loggedData = login?.loginLog?.slice((page - 1) * limit, limit * page);

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      align: "center",

      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Login Time",
      align: "left",

      render: (val) => (
        <>
          <p className="data_table_primary_data">
            {formatDate(val?.loginTime)}
          </p>
          <p className="data_table_secondary_data">
            {formatTime(val?.loginTime)}
          </p>
        </>
      ),
    },
    {
      title: "Logout Time",
      align: "left",

      render: (val) => (
        <>
          {val?.logoutTime ? (
            <>
              <p className="data_table_primary_data">
                {formatDate(val?.logoutTime)}
              </p>
              <p className="data_table_secondary_data">
                {formatTime(val?.logoutTime)}
              </p>
            </>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "IP Address",
      dataIndex: "ip",
      align: "left",
    },
  ];

  return (
    <section className="space-y-6">
      <div
        className="flex-auto w-[712px] rounded-[14px]  bg-white p-6 "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="flex space-x-6">
          <Image
            className="absolute top-0 start-0 "
            width={150}
            height={200}
            preview={true}
            alt={APP_NAME}
            src={`${IMAGE_URL}${login?.storeImage}`}
            fallback={placeHolderImage}
            style={{ border: "1px solid white" }}
          />

          <Image
            className="absolute top-0 start-0 "
            width={150}
            height={200}
            preview={true}
            alt={APP_NAME}
            src={`${IMAGE_URL}${login?.tradeLicenseFile}`}
            fallback={placeHolderImage}
            style={{ border: "1px solid white" }}
          />

          <Image
            className="absolute top-0 start-0 "
            width={150}
            height={200}
            preview={true}
            alt={APP_NAME}
            src={`${IMAGE_URL}${login?.authorizationLetterFile}`}
            fallback={placeHolderImage}
            style={{ border: "1px solid white" }}
          />
        </div>
      </div>

      <div
        className="flex-auto w-[712px] rounded-[14px]  bg-white p-6 "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="rounded-t-[14px] p-4 ">
          <p className="text-[20px] font-semibold leading-8 text-body">
            Access Log
          </p>
        </div>

        {/* table */}
        <div className="">
          <CustomTable
            loading={false}
            total={login?.loginLog?.length}
            isTopRow={false}
            page={page}
            handlePagination={handlePagination}
            columns={columns}
            dataList={addKeyInArrayRevereLoginLog(loggedData)}
          />
        </div>
      </div>
    </section>
  );
}
