export default function GalleryIcon() {
  return (
    <svg
      width="62"
      height="61"
      viewBox="0 0 62 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31" cy="30.5" r="30.5" fill="#D638EE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 25C21 24.4033 21.2371 23.831 21.659 23.409C22.081 22.9871 22.6533 22.75 23.25 22.75H39.75C40.3467 22.75 40.919 22.9871 41.341 23.409C41.7629 23.831 42 24.4033 42 25V37C42 37.5967 41.7629 38.169 41.341 38.591C40.919 39.0129 40.3467 39.25 39.75 39.25H23.25C22.6533 39.25 22.081 39.0129 21.659 38.591C21.2371 38.169 21 37.5967 21 37V25ZM22.5 35.06V37C22.5 37.414 22.836 37.75 23.25 37.75H39.75C39.9489 37.75 40.1397 37.671 40.2803 37.5303C40.421 37.3897 40.5 37.1989 40.5 37V35.06L37.81 32.371C37.5287 32.0901 37.1475 31.9323 36.75 31.9323C36.3525 31.9323 35.9713 32.0901 35.69 32.371L34.81 33.25L35.78 34.22C35.8537 34.2887 35.9128 34.3715 35.9538 34.4635C35.9948 34.5555 36.0168 34.6548 36.0186 34.7555C36.0204 34.8562 36.0018 34.9562 35.9641 35.0496C35.9264 35.143 35.8703 35.2278 35.799 35.299C35.7278 35.3703 35.643 35.4264 35.5496 35.4641C35.4562 35.5018 35.3562 35.5204 35.2555 35.5186C35.1548 35.5168 35.0555 35.4948 34.9635 35.4538C34.8715 35.4128 34.7887 35.3537 34.72 35.28L29.56 30.121C29.2787 29.8401 28.8975 29.6823 28.5 29.6823C28.1025 29.6823 27.7213 29.8401 27.44 30.121L22.5 35.061V35.06ZM32.625 27.25C32.625 26.9516 32.7435 26.6655 32.9545 26.4545C33.1655 26.2435 33.4516 26.125 33.75 26.125C34.0484 26.125 34.3345 26.2435 34.5455 26.4545C34.7565 26.6655 34.875 26.9516 34.875 27.25C34.875 27.5484 34.7565 27.8345 34.5455 28.0455C34.3345 28.2565 34.0484 28.375 33.75 28.375C33.4516 28.375 33.1655 28.2565 32.9545 28.0455C32.7435 27.8345 32.625 27.5484 32.625 27.25Z"
        fill="white"
      />
    </svg>
  );
}
