import { createSlice } from "@reduxjs/toolkit";
import { ExpertInitialStatus, GetExpertData } from "../../../types/redux";
import {
  createExpertAsync,
  deleteExpertAsync,
  getAllExpertAsync,
  getSingleExpertAsync,
  updateExpertAsync,
} from "./expertAPI";

const initialState: ExpertInitialStatus<GetExpertData, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  expert: [],
  countExpert: 0,
  singleExpert: null,
};

const expertSlice = createSlice({
  name: "admin-expert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create expert
    builder
      .addCase(createExpertAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createExpertAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createExpertAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all expert
    builder
      .addCase(getAllExpertAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllExpertAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.expert = action.payload.data;
        state.countExpert = action.payload.length;
      })
      .addCase(getAllExpertAsync.rejected, (state) => {
        state.loading = false;
        state.expert = [];
        state.countExpert = 0;
      });

    // single scratchType
    builder
      .addCase(getSingleExpertAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleExpert = null;
      })
      .addCase(getSingleExpertAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleExpert = action.payload;
      })
      .addCase(getSingleExpertAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleExpert = null;
      });

    // update expert
    builder
      .addCase(updateExpertAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateExpertAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateExpertAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // delete expert
    builder
      .addCase(deleteExpertAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteExpertAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteExpertAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default expertSlice;
