import CryptoJS from "crypto-js";
import img from "../assets/images/placeholder-image.png";
import placeholder_img from "../assets/images/placeholder.png";
import { secret } from "./config";

export const selectSearchOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const addKeyInArray = (data: any) => {
  return data?.map((el: object & { _id?: string }, idx: number) => ({
    ...el,
    key: el?._id || `${idx}`,
  }));
};

export const addKeyInArrayRevereLoginLog = (data: any) => {
  return data
    ?.slice()
    .reverse()
    .map((el: { _id?: string }, idx: number) => ({
      ...el,
      key: el?._id || `${idx}`,
    }));
};

export const ArrayOption = (data: any[], label: string, value: string) => {
  return data.map((el) => ({
    label: el[label],
    value: el[value],
  }));
};

export const SingleArrayOption = (data: any[]) => {
  return data.map((el) => ({
    label: el,
    value: el,
  }));
};

export const findDataSelect = (
  data: any[],
  id: string,
  key: string = "_id"
) => {
  return data?.find((el) => el?.[key] === id) ? id : undefined;
};

// placeholder images
export const IMG = img;
export const PLACEHOLDER_IMG = placeholder_img;

// quill placeholder
export function decodeHTMLEntities(text: any) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

export const createHash = (text: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(text), secret).toString();
};

export const removeHash = (text: string) => {
  const bytes = CryptoJS.AES.decrypt(text, secret);
  try {
    const texts = bytes.toString(CryptoJS.enc.Utf8);
    return texts.slice(1, -1);
  } catch (err) {
    return "";
  }
};

//this function for regular price, sale price but discount = ?
export function calculateDiscountPercentage(
  regularPrice: string | number,
  salePrice: string | number
): number {
  // Convert to number if inputs are strings
  const regularPriceNumber =
    typeof regularPrice === "string" ? parseFloat(regularPrice) : regularPrice;
  const salePriceNumber =
    typeof salePrice === "string" ? parseFloat(salePrice) : salePrice;

  if (regularPriceNumber <= 0 || salePriceNumber <= 0) {
    return 0; // To avoid division by zero or negative values
  }
  // Calculate the discount percentage
  let discountPercentage: number =
    ((regularPriceNumber - salePriceNumber) / regularPriceNumber) * 100;

  // Round the discount percentage to two decimal places
  discountPercentage = Math.round(discountPercentage * 100) / 100;

  return discountPercentage;
}

//this function for regular price, discount, sale price =?
export function calculateSalePrice(
  regularPrice: number | string,
  discountPercentage: number | string
): number {
  // Convert regularPrice and discountPercentage to numbers if they are strings
  const regularPriceNumber =
    typeof regularPrice === "string" ? parseFloat(regularPrice) : regularPrice;
  const discountPercentageNumber =
    typeof discountPercentage === "string"
      ? parseFloat(discountPercentage)
      : discountPercentage;

  // Handle invalid inputs
  if (
    isNaN(regularPriceNumber) ||
    regularPriceNumber <= 0 ||
    isNaN(discountPercentageNumber) ||
    discountPercentageNumber < 0 ||
    discountPercentageNumber > 100
  ) {
    return 0;
  }

  // Calculate the sale price
  let salePrice: number =
    regularPriceNumber * (1 - discountPercentageNumber / 100);

  // Round the sale price to two decimal places
  salePrice = Math.round(salePrice * 100) / 100;

  return salePrice;
}

export function timeAgo(createdAt: string): string {
  const createdDate = new Date(createdAt); // Parse the string into a Date object
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - createdDate.getTime(); // Difference in milliseconds

  // Convert the difference to different time units
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

// refresher
export function refreshPage() {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

// koto percent baki ache ......................................(02)
export function calculatePercentage(totalValue: number, othersValue: number) {
  if (totalValue === 0) {
    return 0;
  }
  const result = (othersValue / totalValue) * 100;
  return result.toFixed(2);
}
// console.log(calculateCancelPercentage(100, 15));
