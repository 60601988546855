export default function BsrmNotificationHeader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M14.9681 17.957C16.8313 17.7364 18.6619 17.2967 20.4221 16.647C18.9314 14.9958 18.1078 12.8495 18.1111 10.625V9.875C18.1111 8.2837 17.4789 6.75758 16.3537 5.63236C15.2285 4.50714 13.7024 3.875 12.1111 3.875C10.5198 3.875 8.99365 4.50714 7.86843 5.63236C6.74321 6.75758 6.11107 8.2837 6.11107 9.875V10.625C6.11409 12.8496 5.29006 14.996 3.79907 16.647C5.53207 17.287 7.35907 17.732 9.25407 17.957M14.9681 17.957C13.0701 18.1821 11.1521 18.1821 9.25407 17.957M14.9681 17.957C15.1122 18.4069 15.148 18.8844 15.0727 19.3507C14.9973 19.817 14.8129 20.259 14.5345 20.6406C14.256 21.0222 13.8914 21.3326 13.4703 21.5466C13.0492 21.7606 12.5834 21.8722 12.1111 21.8722C11.6387 21.8722 11.173 21.7606 10.7519 21.5466C10.3308 21.3326 9.96614 21.0222 9.68769 20.6406C9.40924 20.259 9.22484 19.817 9.14949 19.3507C9.07413 18.8844 9.10997 18.4069 9.25407 17.957"
        stroke="#455468"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
