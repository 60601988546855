import { Pagination, PaginationProps, Select, Table } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { ColumnsType } from "antd/es/table";
import { ReactNode } from "react";
import NextIcon from "../../assets/icons/NextIcon";
import PrevIcon from "../../assets/icons/PrevIcon";
import { selectSearchOption } from "../../helpers/siteConfig";
import { AddZero } from "../../utils/number";

interface Props<DataListType> {
  loading: boolean;
  total: number;
  limit?: number;
  columns: ColumnsType<DataListType>;
  dataList: DataListType[];
  page: number;
  isSearch?: boolean;
  isPagination?: boolean;
  bordered?: boolean;
  isRowColor?: boolean;
  isTopRow?: boolean;
  isLimitRow?: boolean;
  handlePagination?: (paginationPage: number, paginationLimit: number) => void;
  handleLimit?: (val: number) => void;
  tableTopChildren?: ReactNode;
}

const CustomTable = <DataListType extends AnyObject>({
  loading,
  total,
  page,
  limit = 10,
  columns,
  dataList,
  isPagination = true,
  bordered = true,
  isRowColor = true,
  isTopRow = true,
  isLimitRow = true,
  handlePagination,
  handleLimit,
  tableTopChildren,
}: Props<DataListType>) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <span>
          <PrevIcon />
        </span>
      );
    }
    if (type === "next") {
      return (
        <span>
          <NextIcon />
        </span>
      );
    }
    return originalElement;
  };

  const totalPage = Math.ceil(total / limit);

  return (
    <div className="p-5">
      {isTopRow ? (
        <div className="flex justify-between gap-5 items-center flex-wrap mb-4">
          {isLimitRow ? (
            <div className="flex items-center gap-2 text-body text-[14px]">
              <span>Total Rows</span>
              <Select
                size="middle"
                showSearch
                filterOption={selectSearchOption}
                value={limit}
                options={[
                  {
                    label: "10",
                    value: "10",
                  },
                  {
                    label: "20",
                    value: "20",
                  },
                  {
                    label: "50",
                    value: "50",
                  },
                  {
                    label: "100",
                    value: "100",
                  },
                ]}
                onChange={handleLimit}
                className="w-[70px] limit_dropdown"
              />
              <span>of {AddZero(total)}</span>
            </div>
          ) : null}

          <div className="ml-auto ">{tableTopChildren}</div>
        </div>
      ) : null}

      <Table
        columns={columns}
        dataSource={dataList}
        loading={loading}
        pagination={false}
        className={`custom_ihb_table ${isRowColor ? "color_table" : ""}`}
        bordered={bordered}
      />

      {isPagination && dataList?.length ? (
        <div className="flex justify-between items-center mt-4 relative custom_pagination">
          <div className="text-[14] leading-6 font-[400] text-others-header">
            Showing {page} of {totalPage} {totalPage > 1 ? "Pages" : "Page"}
          </div>
          <Pagination
            onChange={handlePagination}
            current={page}
            showSizeChanger={false}
            total={total}
            itemRender={itemRender}
            pageSize={limit}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
