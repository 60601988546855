import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";
import {
  GetAllRmcParams,
  GetRmcData,
  ReadyMixData,
} from "../../../types/redux/ADMIN/rmc";

// create a RMC
export const createRmcAsync = createAsyncThunk<
  null,
  PostRequest<ReadyMixData, CommonOthers>
>(
  "admin-Rmc/createRmc",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageRmc, data);
      reRender(true);
      toast("success", "RMC create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "RMC create fail");
      return rejectWithValue(error);
    }
  }
);

// get all product category
export const getAllRmcDataAsync = createAsyncThunk<
  GetCommonData<GetRmcData>,
  Params<GetAllRmcParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-Rmc/getAllRmcData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageRmc}/data`, {
      params,
    });

    return {
      data: data.data?.readyMixs,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get RMC category fail");
    return rejectWithValue(error);
  }
});

// update a product category
export const updateRmcAsync = createAsyncThunk<
  null,
  PostRequest<ReadyMixData, SingleDataParams>
>(
  "admin-Rmc/updateRmcData",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageRmc}/${id}`, data);

      reRender && reRender(true);
      toast("success", "RMC update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "RMC update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a product category
export const deleteRmcAsync = createAsyncThunk<null, Params<SingleDataParams>>(
  "admin-Rmc/deleteRmcData",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageRmc}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Rmc delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Rmc delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singRmcAsync = createAsyncThunk<any, Params<SingleDataParams>>(
  "admin-Rmc/singRmc",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageRmc}/${params?.id}`);

      return data.data?.readyMixs as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single ready mix data get fail");
      return rejectWithValue(error);
    }
  }
);
