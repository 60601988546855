import { DatePicker, DatePickerProps, Form, Input } from "antd";
import ReactQuill from "react-quill";
import BsrmCalenderIcon from "../../../../assets/icons/BsrmCalenderIcon";
import { formats } from "../../../../components/Editor/Formats";
import { modules } from "../../../../components/Editor/Modules";
import { calculateDiscountPercentage } from "../../../../helpers/siteConfig";
import { disableTodayAndPastDates } from "../../../../utils/time";

type Props = {
  viewId: string | undefined;
  type: "ADD" | "EDIT" | "VIEW";
  form: any;
};

export default function General({ viewId, type, form }: Props) {
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {};

  return (
    <>
      <div className="grid grid-cols-4 gap-2.5">
        <Form.Item
          name="regularPrice"
          label="Regular Price"
          rules={[
            {
              required: true,
              message: "Please select regular price",
            },
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="00.00"
            disabled={viewId ? true : false}
            onChange={(val) => {
              if (
                form.getFieldValue("salesPrice") &&
                form.getFieldValue("regularPrice")
              ) {
                form.setFieldsValue({
                  discountPercentage: calculateDiscountPercentage(
                    form.getFieldValue("regularPrice"),
                    form.getFieldValue("salesPrice")
                  ),
                });
              } else {
                form.setFieldsValue({
                  discountPercentage: 0,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="salesPrice"
          label="Sale Price"
          rules={[
            {
              required: true,
              message: "Please select sale price",
            },
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid integer (0-9 only)",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  Number(value || 0) >
                  Number(getFieldValue("regularPrice") || 0)
                ) {
                  return Promise.reject(
                    new Error(
                      "Sale price must be small or Equal to regular price!"
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            size="large"
            placeholder="00.00"
            disabled={viewId ? true : false}
            onChange={(val) => {
              if (
                form.getFieldValue("salesPrice") &&
                form.getFieldValue("regularPrice")
              ) {
                form.setFieldsValue({
                  discountPercentage: calculateDiscountPercentage(
                    form.getFieldValue("regularPrice"),
                    form.getFieldValue("salesPrice")
                  ),
                });
              } else {
                form.setFieldsValue({
                  discountPercentage: 0,
                });
              }
            }}
          />
        </Form.Item>

        {/* {type === "VIEW" ? ( */}
        <Form.Item name="discountPercentage" label="Discount Percentage">
          <Input size="large" placeholder="00 %" disabled />
        </Form.Item>
        {/* ) : null} */}

        <Form.Item
          name="schedule"
          label="Schedule"
          rules={[
            {
              required: true,
              message: "Please select",
            },
          ]}
        >
          <DatePicker
            onChange={onChange}
            disabled={viewId ? true : false}
            suffixIcon={<BsrmCalenderIcon />}
            disabledDate={disableTodayAndPastDates}
          />
        </Form.Item>
      </div>

      <Form.Item
        name="shortDescription"
        label="Product Short Description"
        rules={[
          {
            required: true,
            message: "Please enter description",
          },
        ]}
      >
        <ReactQuill
          className="h-[370px]"
          theme="snow"
          formats={formats}
          modules={modules}
          readOnly={viewId ? true : false}
        />
      </Form.Item>
    </>
  );
}
