export default function BsrmPhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.5 18.75C10.3011 18.75 10.1103 18.829 9.96967 18.9697C9.82902 19.1103 9.75 19.3011 9.75 19.5C9.75 19.6989 9.82902 19.8897 9.96967 20.0303C10.1103 20.171 10.3011 20.25 10.5 20.25H13.5C13.6989 20.25 13.8897 20.171 14.0303 20.0303C14.171 19.8897 14.25 19.6989 14.25 19.5C14.25 19.3011 14.171 19.1103 14.0303 18.9697C13.8897 18.829 13.6989 18.75 13.5 18.75H10.5Z"
        fill="#8897AE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.625 0.75C7.72989 0.75 6.87145 1.10558 6.23851 1.73851C5.60558 2.37145 5.25 3.22989 5.25 4.125V19.875C5.25 20.7701 5.60558 21.6286 6.23851 22.2615C6.87145 22.8944 7.72989 23.25 8.625 23.25H15.375C16.2701 23.25 17.1286 22.8944 17.7615 22.2615C18.3944 21.6286 18.75 20.7701 18.75 19.875V4.125C18.75 3.22989 18.3944 2.37145 17.7615 1.73851C17.1286 1.10558 16.2701 0.75 15.375 0.75H8.625ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75V3.375C9.75 3.996 10.254 4.5 10.875 4.5H13.125C13.746 4.5 14.25 3.996 14.25 3.375V3H15.375C15.996 3 16.5 3.504 16.5 4.125V19.875C16.5 20.496 15.996 21 15.375 21H8.625C8.32663 21 8.04048 20.8815 7.8295 20.6705C7.61853 20.4595 7.5 20.1734 7.5 19.875V4.125Z"
        fill="#8897AE"
      />
    </svg>
  );
}
