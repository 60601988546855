import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import PlaceholderImage from "../../../assets/images/placeholder.png";
import ExpertCategoryCreateForm from "../../../components/ModalForm/ExpertCategoryCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteExpertCategoryAsync,
  getAllExpertCategoryAsync,
} from "../../../store/features/expertCategory/expertCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetExpertCategoryData, TableData } from "../../../types/redux";

export default function ExpertCategory() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const { deleteLoading, loading, countExpertCategory, expertCategory } =
    useAppSelector((state) => state.expertCategory);
  const { limit, page, handlePagination, handleLimit } = useTable();
  const [selectStatus, setSelectStatus] = useState("");

  const columns: ColumnsType<TableData<GetExpertCategoryData>> = [
    {
      title: "No.",
      align: "center",
      width: "130px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Category Name",
      align: "left",
      dataIndex: "name",
    },
    {
      title: "Category Label",
      align: "left",
      dataIndex: "label",
    },
    {
      title: "Banner",
      dataIndex: "banner",
      align: "center",
      className: "",
      render: (val) => (
        <Image
          width={150}
          height={70}
          preview={false}
          alt={APP_NAME}
          src={`${IMAGE_URL}${val}`}
          fallback={PlaceholderImage}
        />
      ),
    },
    {
      title: "Icon",
      dataIndex: "icon",
      align: "center",
      className: "table__head__icon__bg__highlight",
      render: (val) => (
        <div>
          <img className="w-[30px]" src={`${IMAGE_URL}${val}`} alt="" />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val) => (
        <div className="flex justify-center gap-2">
          <Button
            icon={<BsrmEyeIcon />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            icon={<BsrmEdit />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const getData = useCallback(() => {
    dispatch(
      getAllExpertCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
        },
      })
    );
  }, [dispatch, limit, page, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  const handleDelete = (id: string) => {
    dispatch(
      deleteExpertCategoryAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const allStatus = [
    { label: "Active", value: "true" },
    {
      label: "Inactive",
      value: "false",
    },
  ];

  return (
    <AdminLayout
      title="Our Expert"
      headerTitle="Expert Category List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create Now
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetExpertCategoryData>>
        loading={loading}
        total={countExpertCategory}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(expertCategory)}
        handleLimit={handleLimit}
        tableTopChildren={
          <Space size="middle" className="filtering__select__option">
            <Select
              size="middle"
              placeholder="Status"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={allStatus}
              onChange={setSelectStatus}
            />
          </Space>
        }
      />

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <ExpertCategoryCreateForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Expert Category"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
