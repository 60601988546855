import { Divider, Image } from "antd";
import { Fragment, ReactNode } from "react";
import BsrmReceive from "../../assets/icons/BsrmReceive";
import BsrmShipping from "../../assets/icons/BsrmShipping";
import BsrmTAKAicon from "../../assets/icons/BsrmTAKAicon";
import BsrmWhiteTikMark from "../../assets/icons/BsrmWhiteTikMark";
import BsrmgreenMarkOutline from "../../assets/icons/BsrmgreenMarkOutline";
import PendingIcon from "../../assets/icons/PendingIcon";
import ShiftIcon from "../../assets/icons/ShiftIcon";
import placeholderImage from "../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import { CustomModalProps } from "../../types";
import { formatDate, formatTime } from "../../utils/time";
import CustomModal from "../common/CustomModal";

type Props = {
  children?: ReactNode;
} & CustomModalProps;

export default function OrderDetailsHistory({
  data,
  children,
  ...rest
}: Props) {
  const dataList = [
    {
      ...dataItem("Pending"),
      icon: <PendingIcon />,
    },
    {
      ...dataItem("Ready to Shipped"),
      icon: <ShiftIcon />,
    },
    {
      ...dataItem("Shipped"),
      icon: <ShiftIcon />,
    },
    {
      ...dataItem("Delivered"),
      icon: <ShiftIcon />,
    },
    {
      ...dataItem("Partially Processed"),
      icon: <ShiftIcon />,
    },
  ];

  console.log("data", data);

  function dataItem(str: string) {
    const findData = data?.order?.deliveryLog?.find(
      (el: any) => el?.status === str
    );
    if (findData) {
      return {
        ...findData,
        isActive: true,
      };
    }

    return {
      status: str,
    };
  }

  return (
    <CustomModal width={670} {...rest}>
      <div className="p-6">
        <div className="grid grid-cols-2 gap-2.5">
          <div className="">
            {data?.order?.status === "Cancel" ? (
              <div className="flex items-center ">
                <BsrmgreenMarkOutline color="#bf2307" />
                <span className="text-[#bf2307] font-medium text-[16px] leading-7 ms-1">
                  Cancel
                </span>
              </div>
            ) : data?.order?.status === "Delivered" ? (
              <div className="flex items-center ">
                <BsrmgreenMarkOutline />
                <span className="text-[#07BFA5] font-medium text-[16px] leading-7 ms-1">
                  Delivered
                </span>
              </div>
            ) : data?.order?.status === "Pending" ? (
              <div className="flex items-center text-status-pending-text">
                <BsrmgreenMarkOutline color="currentColor" />
                <span className="font-medium text-[16px] leading-7 ms-1">
                  Pending
                </span>
              </div>
            ) : (
              <div className="flex items-center text-others-deep">
                <BsrmgreenMarkOutline color="currentColor" />
                <span className="text-others-deep font-medium text-[16px] leading-7 ms-1">
                  {data?.order?.status}
                </span>
              </div>
            )}

            <p className="text-[14px] font-medium leading-6">
              Products was ordered on
            </p>
            <p className="text-[16px] font-medium leading-7">
              {formatDate(data?.order?.createdAt)}
            </p>
          </div>

          <div className="">
            <div className="flex items-center ">
              <BsrmReceive />
              <span className="text-[#FC8E28] font-medium text-[16px] leading-7 ms-1">
                {data?.order?.customerId?.name}
              </span>
            </div>

            <p className="text-[14px] font-medium leading-6">
              {data?.order?.deliveryAddress}
            </p>
            <p className="text-[16px] font-medium leading-7">
              {data?.order?.customerId?.phoneNumber}
            </p>
          </div>
        </div>

        <div
          className="rounded-[10px] p-2.5"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <p className="text-[14px] font-medium leading-6">
            {data?.order?.retailerId?.organizationName}
          </p>
          <div className="">
            <p className="text-blue-500 text-[14px] font-medium leading-6">
              Order Id: {data?.order?.invoiceNo}
            </p>
            <p className="text-[14px] font-medium leading-6">
              To track the delivery of your order
            </p>
          </div>
          <Divider />

          <div className="p-1.5">
            {dataList?.map((el) => (
              <Fragment key={el?._id}>
                <div className="pb-2.5 flex justify-start gap-3 min-h-[70px]">
                  <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                    <div className="text-center">
                      {el?.date ? (
                        <span className="block">
                          {formatDate(el?.date, "MMM D")}
                        </span>
                      ) : (
                        ""
                      )}
                      {el?.date ? (
                        <span>{formatTime(el?.date, "h:mm")}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      el?.isActive ? "bg-primary-admin" : "text-others-deep"
                    } rounded-full w-6 h-6 p-1`}
                  >
                    {el?.isActive ? <BsrmWhiteTikMark /> : <BsrmShipping />}
                  </div>
                  <div className="font-medium">
                    <p
                      className={`text-[14px]  leading-6 ${
                        el?.isActive ? "text-primary-admin" : "text-others-deep"
                      }`}
                    >
                      {el?.status}
                    </p>
                    <p className="text-[14px] leading-6">{el?.details}</p>
                    <p className="text-[16px] leading-7">
                      {data?.order?.deliveryAddress}
                    </p>
                    {/* <p className="text-[16px] leading-7">[Dhaka - North]</p> */}
                  </div>
                </div>

                <hr className="hr" />
              </Fragment>
            ))}

            {/* <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="py-2.5 flex justify-start gap-3">
              <div className="w-8 text-[#5E718D] flex justify-center items-center text-[12px] font-medium leading-[18px]  ">
                Jun 23 11:26
              </div>
              <div className="bg-accent rounded-full w-6 h-6 p-1">
                <BsrmShipping />
              </div>
              <div className="font-medium">
                <p className="text-[14px]  leading-6 text-others-deep">
                  Delivered
                </p>
                <p className="text-[14px] leading-6">
                  Your package has been delivered.
                </p>
                <p className="text-[16px] leading-7">[Dhaka - North]</p>
              </div>
            </div>
            <hr className="hr" />
            <div className="pt-2.5 flex justify-center items-center cursor-pointer">
              <p className="text-[#1B4DFF] font-bold">View Less</p>
            </div> */}
          </div>
        </div>

        <div
          className="bg-white mt-6 p-2.5 rounded-[10px] space-y-1"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <p className="text-[14px] font-medium leading-6">
            Nirvana Enterprise ltd
          </p>
          <hr className="hr" />

          {data?.orderDetails?.map(
            (val: {
              quantity: any;
              discountAmount: any;
              unitPrice: any;
              productId: {
                name: ReactNode;
                image: string | undefined;
              };
            }) => (
              <div className="flex justify-between ">
                <div className="flex gap-x-[10px]">
                  <Image
                    className="rounded-[10px]"
                    width={100}
                    height={80}
                    preview={false}
                    src={`${IMAGE_URL}${val?.productId?.image}`}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                  />
                  <div className="">
                    <h5 className="text-others-deep text-[20px] font-semibold leading-8 mb-2.5">
                      {val?.productId?.name}
                    </h5>

                    {/* <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      <span className="line-through ml-5 font-semibold   text-body ">
                        {val?.unitPrice} <BsrmTAKAicon color="#3d4a5c" />
                      </span>
                      <span className="ml-11 font-semibold   text-[#4A72FF]">
                        {Number(val?.unitPrice ?? 0) -
                          Number(val?.discountAmount ?? 0)}
                        <BsrmTAKAicon color="#4A72FF" />
                      </span>
                    </div> */}

                    {/* protita unit amon format cilo ---->  Number(val?.discountAmount) */}
                    <div className="text-[16px] pr-1.5 font-medium leading-7 text-body ">
                      {val?.discountAmount > 0 ? (
                        <>
                          <span className="line-through font-semibold   text-[#8897AE]  ">
                            {val?.unitPrice} <BsrmTAKAicon color="#8897AE" />
                          </span>
                          <span className="ml-1.5 font-semibold text-[#4A72FF]">
                            {val?.unitPrice -
                              val?.discountAmount / val?.quantity}
                            <BsrmTAKAicon color="#4A72FF" />
                          </span>
                        </>
                      ) : (
                        <span className="ml-1.5 font-semibold   text-[#4A72FF]">
                          {val?.unitPrice - val?.discountAmount / val?.quantity}
                          <BsrmTAKAicon color="#4A72FF" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <hr className="hr" />
          <div className="flex justify-between items-center">
            <p>{formatDate(data?.order?.createdAt)}</p>
            <p>
              {data?.orderDetails?.length} Item. Total: {data?.order?.netAmount}
              <BsrmTAKAicon />
            </p>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
