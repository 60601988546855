export default function BsrmPlusIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="33.5"
        height="33.5"
        rx="16.75"
        fill="white"
        stroke="#07BFA5"
        strokeWidth="1.5"
      />
      <path
        d="M12.5 18H23.5"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12.5V23.5"
        stroke="#07BFA5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
