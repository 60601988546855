export default function DownIcon() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0.5V11.5M5 11.5L0.5 7M5 11.5L9.5 7"
        stroke="#FF574D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
