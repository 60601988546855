import { createSlice } from "@reduxjs/toolkit";

import {
  RetailerNotificationData,
  retailerNotificationInitialStatus,
} from "../../../types/redux/ADMIN/notification";
import {
  getAllNotificationAsync,
  getSingleNotificationAsync,
} from "./notificationAPI";

const initialState: retailerNotificationInitialStatus<
  RetailerNotificationData,
  RetailerNotificationData
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  retailerNotification: [],
  retailerHeaderNotification: [],
  singleRetailerNotification: null,
  countRetailerNotification: 0,
};

const retailerNotificationSlice = createSlice({
  name: "retailer-notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all notification
    builder
      .addCase(getAllNotificationAsync.pending, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.singleLoading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(getAllNotificationAsync.fulfilled, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.singleLoading = false;
          state.retailerHeaderNotification = action.payload?.data;
        } else {
          state.loading = false;
          state.retailerNotification = action.payload?.data;
          state.countRetailerNotification = action.payload.length;
        }
      })
      .addCase(getAllNotificationAsync.rejected, (state, action) => {
        state.loading = false;
        state.singleLoading = false;
        state.retailerNotification = [];
        state.retailerHeaderNotification = [];
        state.countRetailerNotification = 0;
      });

    // get single product
    builder
      .addCase(getSingleNotificationAsync.pending, (state) => {
        state.singleLoading = true;
      })
      .addCase(getSingleNotificationAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleRetailerNotification = action.payload;
      })
      .addCase(getSingleNotificationAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleRetailerNotification = null;
      });
  },
});

export default retailerNotificationSlice;
