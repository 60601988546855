export default function BsrmManageRetail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M2.5 10.0864V16.5C2.5 16.6989 2.57902 16.8897 2.71967 17.0303C2.86032 17.171 3.05109 17.25 3.25 17.25H16.75C16.9489 17.25 17.1397 17.171 17.2803 17.0303C17.421 16.8897 17.5 16.6989 17.5 16.5V10.0865M19 6V7.5C19 8.29565 18.6839 9.05871 18.1213 9.62132C17.5587 10.1839 16.7956 10.5 16 10.5C15.2044 10.5 14.4413 10.1839 13.8787 9.62132C13.3161 9.05871 13 8.29565 13 7.5M19 6L17.6554 1.29396C17.6106 1.13725 17.516 0.999386 17.3859 0.901239C17.2558 0.803091 17.0973 0.75 16.9343 0.75H3.06573C2.90274 0.75 2.7442 0.803091 2.61408 0.901239C2.48396 0.999386 2.38936 1.13725 2.34458 1.29396L1 6M19 6H1M13 7.5V6M13 7.5C13 8.29565 12.6839 9.05871 12.1213 9.62132C11.5587 10.1839 10.7956 10.5 10 10.5C9.20435 10.5 8.44129 10.1839 7.87868 9.62132C7.31607 9.05871 7 8.29565 7 7.5M7 7.5V6M7 7.5C7 8.29565 6.68393 9.05871 6.12132 9.62132C5.55871 10.1839 4.79565 10.5 4 10.5C3.20435 10.5 2.44129 10.1839 1.87868 9.62132C1.31607 9.05871 1 8.29565 1 7.5V6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
