import { HTMLAttributes, ReactNode } from "react";

type Props = {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export default function Subheader({
  title,
  subTitle,
  children,
  ...rest
}: Props) {
  return (
    <div
      className="p-4 rounded-tl-xl rounded-tr-xl justify-between items-center flex flex-wrap bg-status-complete-bg sub_header"
      {...rest}
    >
      <div>
        <h3 className="text-primary-np text-[22px] font-semibold title">
          {title}
        </h3>
        {subTitle ? (
          <h6 className="text-accent text-base font-medium mt-1">{subTitle}</h6>
        ) : null}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
}
