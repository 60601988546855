import { CloseCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Input } from "antd";
import mapboxgl from "mapbox-gl";
import { ReactNode, useEffect, useRef, useState } from "react";
import BsrmAttachment from "../../../assets/icons/BsrmAttachment";
import GalleryIcon from "../../../assets/icons/GalleryIcon";
import LocationIcon from "../../../assets/icons/LocationIcon";
import SeenIcon from "../../../assets/icons/SeenIcon";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import { APP_NAME, IMAGE_URL, MAP_TOKEN } from "../../../helpers/config";
import { useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { formatTime } from "../../../utils/time";
import CustomFileUpload from "../../common/CustomFileUpload";
import Image from "../../common/Image";
import Loader from "../../common/Loader";

mapboxgl.accessToken = MAP_TOKEN as string;

type Props = {
  customerId: string;
  handleMessage: (val: any) => void;
  handleSubmit: (val: any) => void;
  handleFileUrl: (val: string) => void;
  handleDropdown: (val: boolean) => void;
  handleFileSubmit: () => void;
  handleFetchLocation: () => void;
  handleRemoveLocation: () => void;
  sending: boolean;
  openDropdown: boolean;
  typing: boolean;
  message: string;
  fileUrl: string;
  location: string;
};

export default function MessageBox({
  customerId,
  handleMessage,
  handleSubmit,
  handleFileUrl,
  handleDropdown,
  handleFileSubmit,
  handleFetchLocation,
  handleRemoveLocation,
  openDropdown,
  typing,
  message,
  fileUrl,
  location: UploadLocation,
}: Props) {
  const [dots, setDots] = useState(".");
  const [fileLoading, setFileLoading] = useState(false);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const { allCustomer, retailerChatData, loading } = useAppSelector(
    (state) => state.retailerLiveChat
  );

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [retailerChatData, typing]);

  useEffect(() => {
    if (!typing) return;

    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev === "...") return ".";
        return prev + ".";
      });
    }, 800);

    return () => clearInterval(interval);
  }, [typing]);

  const user = allCustomer?.find((el) => el?.customerId?._id === customerId);

  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapContainerRef.current && UploadLocation) {
      const address: number[] = stringToArray(
        UploadLocation,
        ","
      ) as unknown as number[];
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [address?.[1], address?.[0]],
        zoom: 14,
      });

      // Cleanup on unmount
      return () => map.remove();
    }
  }, [UploadLocation]);

  return (
    <div
      className="flex-auto w-[712px] rounded-[14px] bg-white"
      style={{ border: "1px solid #E9EFF6" }}
    >
      <div className="flex gap-3 items-center p-5">
        <div
          className={`rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat  ${
            user?.customerId?.onlineStatus === "Yes" ? "user_online" : ""
          }`}
        >
          <Badge rootClassName="custom_badge" dot>
            <Image
              className="!rounded-full"
              width={56}
              height={56}
              preview={false}
              alt={APP_NAME}
              src={
                user?.customerId?.image
                  ? IMAGE_URL + user?.customerId?.image
                  : placeholderImage
              }
              style={{ border: "1px solid white", borderRadius: "100%" }}
            />
          </Badge>
        </div>
        <div className="">
          <p className="text-[18px] font-medium leading-[30px] text-black">
            {user?.customerId?.name}
          </p>
          <p className="text-[14px] leading-6 text-black">
            {user?.customerId?.email || user?.customerId?.phoneNumber}
          </p>
        </div>
      </div>

      <hr className="hr" />
      <div className="p-5 h-[340px] overflow-y-auto">
        {loading ? (
          <Loader />
        ) : (
          <ul className="list-none p-0">
            {retailerChatData?.map((msg, idx) => (
              <li key={idx}>
                {msg?.messageType === "Text" ? (
                  <SingleMessage
                    type={msg?.type === "Customer" ? "receive" : "send"}
                    time={formatTime(msg?.createdAt, "h:mm A")}
                  >
                    {msg?.message}
                  </SingleMessage>
                ) : msg?.messageType === "File" ? (
                  <ShowFile
                    type={msg?.type === "Customer" ? "receive" : "send"}
                    time={formatTime(msg?.createdAt, "h:mm A")}
                    file={msg?.message}
                  />
                ) : msg?.messageType === "Location" ? (
                  <LocationFile
                    type={msg?.type === "Customer" ? "receive" : "send"}
                    time={formatTime(msg?.createdAt, "h:mm A")}
                    location={msg?.message}
                  />
                ) : null}
              </li>
            ))}

            {typing ? (
              <span
                className={`text-[#69757c] text-xs font-medium font-Poppins leading-tight tracking-tight mt-1.5 flex items-center gap-0.5`}
              >
                Typing{dots}
              </span>
            ) : null}
            <div ref={messageEndRef} />
          </ul>
        )}
      </div>

      <hr className="hr" />
      {!fileLoading && (fileUrl || UploadLocation) ? (
        <div className="px-5 py-3 flex justify-between items-center gap-4">
          <div className="h-20 max-w-[190px] relative">
            {fileUrl ? (
              <img
                className="h-20 max-w-[190px] rounded-lg object-contain"
                src={IMAGE_URL + fileUrl}
                alt=""
              />
            ) : UploadLocation ? (
              <div
                ref={mapContainerRef}
                style={{ width: "190px", height: "80px" }}
              />
            ) : null}

            <Button
              className="!text-red-500 absolute -right-4 -top-4 z-30 !bg-transparent"
              type="text"
              icon={<CloseCircleOutlined className="bg-white rounded-full" />}
              size="large"
              disabled={fileLoading}
              onClick={() => {
                handleRemoveLocation();
                handleFileUrl("");
              }}
            />
          </div>

          <div>
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={handleFileSubmit}
            />
          </div>
        </div>
      ) : (
        <div className="px-5 py-6 flex gap-1.5 items-center">
          <Dropdown
            open={openDropdown}
            onOpenChange={(val) => !fileLoading && handleDropdown(val)}
            trigger={["click"]}
            placement="topLeft"
            arrow={{ pointAtCenter: true }}
            dropdownRender={() => (
              <div className="flex items-center gap-5 bg-white shadow px-7 py-5 !rounded-lg">
                <span>
                  <CustomFileUpload
                    disabled={fileLoading}
                    handleLoading={setFileLoading}
                    handleImageChange={handleFileUrl}
                  >
                    <GalleryIcon />
                  </CustomFileUpload>

                  <h6 className="text-center text-[#5e718d] text-base font-normal font-Roboto">
                    Gallery
                  </h6>
                </span>
                <span>
                  <button
                    onClick={handleFetchLocation}
                    className="bg-transparent border-transparent cursor-pointer"
                  >
                    <LocationIcon />
                  </button>
                  <h6 className="text-center text-[#5e718d] text-base font-normal font-Roboto">
                    Location
                  </h6>
                </span>
              </div>
            )}
          >
            <button className="w-[46px] h-[46px] bg-primary-admin border-none rounded-lg cursor-pointer">
              <BsrmAttachment color="#fff" />
            </button>
          </Dropdown>
          {/*  className="w-[81%]" */}
          <form
            className="w-full flex justify-between gap-1.5"
            onSubmit={handleSubmit}
          >
            <Input
              className="h-[46px] w-full"
              placeholder="Message"
              // suffix="😊"
              onChange={handleMessage}
              value={message}
            />
            <Button
              htmlType="submit"
              type="primary"
              icon={<SendOutlined />}
              className="!w-[50px] h-[46px]"
              // onClick={handleFileSubmit}
            />
          </form>
          {/* <div className="flex">
            <button className="w-[46px] h-[46px] bg-white border-none rounded-lg cursor-pointer">
              <BsrmCameraIcon />
            </button>
            <button className="w-[46px] h-[46px] bg-white border-none rounded-lg cursor-pointer">
              <BsrmMicrophoneIcon />
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
}

type MessageProps = {
  children: ReactNode;
  type: "send" | "receive";
  time: ReactNode;
};

const SingleMessage = ({ children, ...rest }: MessageProps) => (
  <MessageLayout {...rest}>
    <div className="text-[#515b60] text-base font-normal leading-7 tracking-tight bg-[#F9FAFB] py-1 px-2 font-Poppins rounded-sm w-full">
      {children}
    </div>
  </MessageLayout>
);

type FIleProps = {
  file: string;
  type: "send" | "receive";
  time: ReactNode;
};

const ShowFile = ({ file, type, ...rest }: FIleProps) => (
  <MessageLayout type={type} {...rest}>
    <div className={type === "send" ? "flex justify-end" : ""}>
      <img
        src={IMAGE_URL + file}
        className="max-w-[75%] h-40 object-contain"
        alt=""
      />
    </div>
  </MessageLayout>
);

type LocationProps = {
  location: string;
  type: "send" | "receive";
  time: ReactNode;
};

const LocationFile = ({ location, type, ...rest }: LocationProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapContainerRef.current && location) {
      const address: number[] = stringToArray(
        location,
        ","
      ) as unknown as number[];
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [address?.[1], address?.[0]],
        zoom: 14,
      });

      // Cleanup on unmount
      return () => map.remove();
    }
  }, [location]);

  return (
    <MessageLayout type={type} {...rest}>
      {" "}
      <div className={type === "send" ? "flex justify-end" : ""}>
        <div ref={mapContainerRef} style={{ width: "75%", height: "160px" }} />
      </div>
    </MessageLayout>
  );
};

type MessageLayoutProps = {
  children: ReactNode;
  type: "send" | "receive";
  time: ReactNode;
};
const MessageLayout = ({ children, time, type }: MessageLayoutProps) => (
  <div
    className={`flex ${
      type === "receive" ? "justify-start mb-5" : "justify-end mb-2.5"
    }`}
  >
    <div className="w-3/4">
      {children}
      <div
        className={`text-[#69757c] text-xs font-medium font-Poppins leading-tight tracking-tight mt-1.5 flex items-center gap-0.5 ${
          type === "send" ? "text-right justify-end" : ""
        }`}
      >
        {time} <SeenIcon />
      </div>
    </div>
  </div>
);
