import { Radio } from "antd";
import { Key } from "react";
import { AddZero } from "../../../utils/number";

export default function CustomerProfileAddressBook({ data }: any) {
  // console.log("Address book data", data);
  return (
    <>
      <div
        className="flex-auto w-[712px] rounded-[14px]  bg-white p-6 "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="rounded-t-[14px] p-4 flex justify-between">
          <p className="text-[20px] font-semibold leading-8 text-body">
            Address Book ({data?.length > 0 ? AddZero(data?.length) : "00"})
          </p>
        </div>

        <div className="grid grid-cols-2 gap-2.5 mt-2.5">
          {/* one */}

          {data?.map((item: Key | null, idx: any) => (
            <div
              key={idx}
              className="bg-white  p-4 rounded-[10px] "
              style={{ border: "1px solid #E9EFF6" }}
            >
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <p className="text-others-deep text-[14px]  leading-6 ">
                    {item}
                  </p>
                  <Radio defaultChecked />
                </div>

                {/* <p className="text-[#8897AE] text-[14px] leading-6 font-medium">
                  (+880) 1736398503
                </p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
