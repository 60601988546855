export default function BsrmShipping() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.90803 2.3005C2.6601 2.31897 2.42825 2.43478 2.25959 2.62439C2.09093 2.814 1.99812 3.06319 2.00003 3.32131V8.46169H8.00003V3.32131C8.00194 3.06319 7.90913 2.814 7.74047 2.62439C7.57181 2.43478 7.33996 2.31897 7.09203 2.3005C5.69881 2.20712 4.30125 2.20712 2.90803 2.3005ZM12.919 5.05762C12.8622 4.84131 12.7382 4.65054 12.5662 4.51496C12.3942 4.37938 12.1839 4.30657 11.968 4.30785H10C9.73481 4.30785 9.48046 4.41726 9.29292 4.61201C9.10539 4.80676 9.00003 5.07089 9.00003 5.34631V11.8554C9.30406 11.6731 9.64895 11.5771 10 11.5771C10.3511 11.5771 10.696 11.6731 11 11.8554C11.304 12.0377 11.5565 12.2998 11.7321 12.6156C11.9076 12.9313 12 13.2894 12 13.654H13C13.131 13.6552 13.2608 13.629 13.3818 13.577C13.5028 13.525 13.6125 13.4483 13.7043 13.3514C13.7962 13.2546 13.8684 13.1395 13.9166 13.0131C13.9649 12.8867 13.9881 12.7515 13.985 12.6155C13.9107 10.062 13.553 7.52598 12.919 5.05762Z"
        fill="#8897AE"
      />
      <path
        d="M11 13.6538C11 13.9293 10.8946 14.1934 10.7071 14.3881C10.5196 14.5829 10.2652 14.6923 10 14.6923C9.73478 14.6923 9.48043 14.5829 9.29289 14.3881C9.10536 14.1934 9 13.9293 9 13.6538C9 13.3784 9.10536 13.1143 9.29289 12.9195C9.48043 12.7248 9.73478 12.6154 10 12.6154C10.2652 12.6154 10.5196 12.7248 10.7071 12.9195C10.8946 13.1143 11 13.3784 11 13.6538ZM2 12.6154V9.5H8V12.6154C8 12.8908 7.89464 13.1549 7.70711 13.3497C7.51957 13.5444 7.26522 13.6538 7 13.6538C7 13.103 6.78929 12.5747 6.41421 12.1852C6.03914 11.7957 5.53043 11.5769 5 11.5769C4.46957 11.5769 3.96086 11.7957 3.58579 12.1852C3.21071 12.5747 3 13.103 3 13.6538C2.73478 13.6538 2.48043 13.5444 2.29289 13.3497C2.10536 13.1549 2 12.8908 2 12.6154Z"
        fill="#8897AE"
      />
      <path
        d="M6 13.6537C6 13.9291 5.89464 14.1932 5.70711 14.388C5.51957 14.5827 5.26522 14.6922 5 14.6922C4.73478 14.6922 4.48043 14.5827 4.29289 14.388C4.10536 14.1932 4 13.9291 4 13.6537C4 13.3783 4.10536 13.1141 4.29289 12.9194C4.48043 12.7246 4.73478 12.6152 5 12.6152C5.26522 12.6152 5.51957 12.7246 5.70711 12.9194C5.89464 13.1141 6 13.3783 6 13.6537Z"
        fill="#8897AE"
      />
    </svg>
  );
}
