export default function FaqEditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M12.75 3.25L15.75 6.25M17.25 9.25V17.5C17.25 17.6989 17.171 17.8897 17.0303 18.0303C16.8897 18.171 16.6989 18.25 16.5 18.25H1.5C1.30109 18.25 1.11032 18.171 0.96967 18.0303C0.829018 17.8897 0.75 17.6989 0.75 17.5V2.5C0.75 2.30109 0.829018 2.11032 0.96967 1.96967C1.11032 1.82902 1.30109 1.75 1.5 1.75H9.75M9 13H6V10L15 1L18 4L9 13Z"
        stroke="#5E718D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
