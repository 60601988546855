import { Button, DatePicker, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import CustomTable from "../../../components/common/CustomTable";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
  SingleArrayOption,
} from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllAdminOrderProductAsync } from "../../../store/features/adminOrder/orderProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import { getAllRetailerAsync } from "../../../store/features/retailer/retailerApi";
import { TableData } from "../../../types/redux";
import { RetailerOrderProductData } from "../../../types/redux/ADMIN/retailerOrderProduct";
import { formatDate } from "../../../utils/time";

// interface DateRange {
//   startDate: Dayjs | null;
//   endDate: Dayjs | null;
// }

export default function ManageOrder() {
  const dispatch = useAppDispatch();
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [retailerData, setRetailerData] = useState("");
  const [statusData, setStatusData] = useState("");
  const { limit, page, handleLimit, handlePagination } = useTable();
  const { loading, countRetailerOrderProduct, retailerOrderProduct } =
    useAppSelector((state) => state.adminOrderProduct);
  const { retailer } = useAppSelector((state) => state?.retailer);
  const { divisions } = useAppSelector((state) => state.location);
  const { districts } = useAppSelector((state) => state.location);

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  // const newDate = {
  //   startDate: formatDate(date?.startDate?.format(), dateFormat),
  //   endDate: formatDate(date?.endDate?.format(), dateFormat),
  // };

  const columns: ColumnsType<TableData<RetailerOrderProductData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Order Details",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_invoice_data">{val?.invoiceNo}</p>
          {/* <p className="table__content__layer__tow">{val?.productCode}</p>
          <p className="table__content__layer__three  bg-[#F0F3F9] rounded-[6px] px-1 py-[2px] w-[80%]">
            {val?.productCategory}
          </p> */}
          <p className="data_table_sku_data">{formatDate(val?.date)}</p>
        </Space>
      ),
    },
    {
      title: "Customer",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.customerId?.name}</p>
          <p className="data_table_sku_data">{val?.customerId?.phoneNumber}</p>
          {/* <p className="table__content__layer__tow">{val?.productCode}</p>
          <p className="table__content__layer__three  bg-[#F0F3F9] rounded-[6px] px-1 py-[2px] w-[80%]">
            {val?.productCategory}
          </p> */}
        </Space>
      ),
    },
    {
      title: "Retailer",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.retailerId?.organizationName}
          </p>
          <p className="data_table_sku_data">{val?.retailerId?.ownerName}</p>
        </Space>
      ),
    },
    {
      title: "Shipping Address",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.deliveryAddress}</p>
          <p className="data_table_primary_data">
            {val?.districtId?.name}, {val?.divisionId?.name}
          </p>
          <p className="data_table_sku_data">{val.customerId?.phoneNumber}</p>
        </Space>
      ),
    },
    {
      title: "Total Amount",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.netAmount} BDT</p>
          <p className="data_table_uit_data">Total Item: {val?.totalItem}</p>
        </Space>
      ),
    },
    {
      title: "Status",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <span
          className={`status ${
            val?.status === "Cancel"
              ? "status-danger"
              : val?.status === "Delivered"
              ? "status-done"
              : val?.status === "Ready to Shipped"
              ? "status-info"
              : val?.status === "Shipped"
              ? "status-others"
              : "status-pending"
          }`}
        >
          {val?.status}
        </span>
      ),
    },
    {
      title: "Action",
      align: "center",
      width: "150px",
      render: (val: RetailerOrderProductData) => (
        <div className="flex justify-center">
          <Link to={`/admin/manageOrder/orderDetails/${val?._id}`}>
            <Button className="bg-white py-[6px]" icon={<BsrmEyeIcon />} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getAllAdminOrderProductAsync({
        params: {
          pageNo: page,
          limit: limit,
          fromDate: date?.startDate,
          toDate: date?.endDate,
          status: statusData,
          retailerId: retailerData,
          divisionId: division,
          districtId: district,
        },
      })
    );
  }, [
    date?.endDate,
    date?.startDate,
    dispatch,
    district,
    division,
    limit,
    page,
    retailer,
    retailerData,
    statusData,
  ]);

  // retailer
  useEffect(() => {
    dispatch(
      getAllRetailerAsync({
        params: {
          pageNo: page,
          limit: limit,
        },
      })
    );
  }, [dispatch, limit, page]);

  // division
  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: page,
          limit: limit,
          divisionId: division,
          name: "",
        },
      })
    );
  }, [dispatch, division, limit, page]);

  const allStatus = [
    "Pending",
    "Ready to Shipped",
    "Shipped",
    "Delivered",
    "Partially Processed",
    "Cancel",
  ];

  return (
    <AdminLayout
      title="Order List"
      headerTitle="Order List"
      className="dashboard"
    >
      <section className="">
        <CustomTable
          loading={loading}
          total={countRetailerOrderProduct}
          page={page}
          limit={limit}
          columns={columns}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          dataList={addKeyInArray(retailerOrderProduct)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Retailer"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(retailer, "organizationName", "_id")}
                onChange={setRetailerData}
              />

              <Select
                placeholder="Filter By Division"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={(val) => {
                  setDivision(val);
                  setDistrict("");
                }}
              />
              <Select
                placeholder="Filter By District"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                onChange={setDistrict}
                disabled={!division}
                value={district || undefined}
              />
              <Select
                placeholder="Filter By Status"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={SingleArrayOption(allStatus)}
                onChange={setStatusData}
              />
              <RangePicker
                size="large"
                format={dateFormat}
                className="w-[250px]"
                // value={[date.startDate, date.endDate]}
                onChange={(val) =>
                  // setDate({
                  //   startDate: val?.[0] ? val[0] : null,
                  //   endDate: val?.[1] ? val[1] : null,
                  // })
                  setDate({
                    startDate: val?.[0]?.format()
                      ? formatDate(val?.[0]?.format(), dateFormat)
                      : "",
                    endDate: val?.[1]?.format()
                      ? formatDate(val?.[1]?.format(), dateFormat)
                      : "",
                  })
                }
              />
            </Space>
          }
        />
      </section>
    </AdminLayout>
  );
}
