import { MailOutlined } from "@ant-design/icons";
import { Button, Form, FormProps, Input, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { ArrayOption, selectSearchOption } from "../../helpers/siteConfig";
import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../store/features/locations/locationAPI";
import {
  createRetailerAsync,
  singleRetailerAsync,
  updateRetailerAsync,
} from "../../store/features/retailer/retailerApi";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetAllRetailerData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ManageRetailerForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [selectDivision, setSelectDivision] = useState("");
  const { divisions, districts } = useAppSelector((state) => state.location);
  const { postLoading, updateLoading, singleRetailer } = useAppSelector(
    (state) => state.retailer
  );

  const [uploadingTreadLicense, setUploadingTreadLicense] = useState(false);
  const [treadLicenseName, setTreadLicenseName] = useState(
    "Upload Trade License"
  );
  const [authorizationLetterName, setAuthorizationLetterName] = useState(
    "Upload Authorization Letter"
  );
  const [proprietorName, setProprietorPhotoName] = useState(
    "Upload Proprietor Photo"
  );
  const [storePhotoName, setStorePhotoName] = useState("Upload Store Photo");
  const [uploadingAuthorizationLetter, setUploadingAuthorizationLetter] =
    useState(false);

  const [uploadingStorePhoto, setUploadingStorePhoto] = useState(false);

  const [uploadingProprietorPhoto, setUploadingProprietorPhoto] =
    useState(false);

  const retailerData = data as GetAllRetailerData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { longitude, latitude } = values;

    const newValues = {
      ...values,
      location: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
    };

    if (type === "EDIT" && retailerData?._id) {
      const { password, passwordConfirm, ...rest } = newValues;

      dispatch(
        updateRetailerAsync({
          data: { ...rest },
          others: {
            reRender,
            id: retailerData?._id,
          },
        })
      );
    } else {
      dispatch(
        createRetailerAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
          divisionId: selectDivision,
        },
      })
    );
  }, [dispatch, selectDivision]);

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      retailerData?._id &&
      !updateLoading
    ) {
      setSelectDivision(singleRetailer?.divisionId?._id || "");

      if (singleRetailer?.tradeLicenseFile) {
        const fileNameArr = stringToArray(
          singleRetailer?.tradeLicenseFile,
          "."
        );

        setTreadLicenseName(
          singleRetailer?.tradeLicenseFile
            ? `${sliceString(singleRetailer?.tradeLicenseFile, 15, "...")}.${
                fileNameArr?.[fileNameArr?.length - 1]
              }`
            : "Upload Icon"
        );
      }

      if (
        singleRetailer?.authorizationLetterFile &&
        singleRetailer?.authorizationLetterFile !== "M/A"
      ) {
        const authorizationFileName = stringToArray(
          singleRetailer?.authorizationLetterFile,
          "."
        );
        setAuthorizationLetterName(
          singleRetailer?.authorizationLetterFile
            ? `${sliceString(
                singleRetailer?.authorizationLetterFile,
                15,
                "..."
              )}.${authorizationFileName?.[authorizationFileName?.length - 1]}`
            : "Upload Icon"
        );
      }

      if (
        singleRetailer?.proprietorImage &&
        singleRetailer?.proprietorImage !== "N/A"
      ) {
        const proprietorName = stringToArray(
          singleRetailer?.proprietorImage,
          "."
        );
        setProprietorPhotoName(
          singleRetailer?.proprietorImage
            ? `${sliceString(singleRetailer?.proprietorImage, 15, "...")}.${
                proprietorName?.[proprietorName?.length - 1]
              }`
            : "Upload Icon"
        );
      }

      if (singleRetailer?.storeImage && singleRetailer?.storeImage !== "N/A") {
        const storePhotoName = stringToArray(singleRetailer?.storeImage, ".");
        setStorePhotoName(
          singleRetailer?.storeImage
            ? `${sliceString(singleRetailer?.storeImage, 15, "...")}.${
                storePhotoName?.[storePhotoName?.length - 1]
              }`
            : "Upload Icon"
        );
      }

      form.setFieldsValue({
        organizationName: singleRetailer?.organizationName,
        ownerName: singleRetailer?.ownerName,
        email: singleRetailer?.email,
        phoneNumber: singleRetailer?.phoneNumber,
        divisionId: singleRetailer?.divisionId?._id,
        districtId: singleRetailer?.districtId?._id,
        latitude: singleRetailer?.location?.coordinates?.[1],
        longitude: singleRetailer?.location?.coordinates?.[0],
        address: singleRetailer?.address,
        bsrmAuthorized: singleRetailer?.bsrmAuthorized,
        status: singleRetailer?.status,

        storeImage:
          singleRetailer?.storeImage && singleRetailer?.storeImage !== "N/A"
            ? singleRetailer?.storeImage
            : "",
        proprietorImage:
          singleRetailer?.proprietorImage &&
          singleRetailer?.proprietorImage !== "N/A"
            ? singleRetailer?.proprietorImage
            : "",
        authorizationLetterFile:
          singleRetailer?.authorizationLetterFile &&
          singleRetailer?.authorizationLetterFile !== "N/A"
            ? singleRetailer?.authorizationLetterFile
            : "",
        tradeLicenseFile:
          singleRetailer?.tradeLicenseFile &&
          singleRetailer?.tradeLicenseFile !== "N/A"
            ? singleRetailer?.tradeLicenseFile
            : "",
      });
    }
  }, [
    form,
    retailerData?._id,
    singleRetailer?.address,
    singleRetailer?.authorizationLetterFile,
    singleRetailer?.bsrmAuthorized,
    singleRetailer?.districtId?._id,
    singleRetailer?.divisionId?._id,
    singleRetailer?.email,
    singleRetailer?.location?.coordinates,
    singleRetailer?.organizationName,
    singleRetailer?.ownerName,
    singleRetailer?.phoneNumber,
    singleRetailer?.proprietorImage,
    singleRetailer?.status,
    singleRetailer?.storeImage,
    singleRetailer?.tradeLicenseFile,
    type,
    updateLoading,
  ]);

  useEffect(() => {
    if (data?._id) {
      dispatch(
        singleRetailerAsync({
          params: {
            id: data?._id,
          },
        })
      );
    }
  }, [data?._id, dispatch]);

  // Validator function to check for non-negative numbers
  const validateNonNegative = (rule: any, value: number) => {
    if (value < 0) {
      return Promise.reject("must be a positive number");
    }
    return Promise.resolve();
  };

  return (
    <CustomModal width={720} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="grid md:grid-cols-2 gap-x-4">
            <Form.Item
              name="organizationName"
              label="Organization Name"
              rules={[
                {
                  required: true,
                  message: "Please enter organization name",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Enter store name"
              />
            </Form.Item>

            <Form.Item
              name="ownerName"
              label="Proprietor/Owner Name"
              rules={[
                {
                  required: true,
                  message: "Please enter proprietor/owner name",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Enter Proprietor name"
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
                {
                  validator: async (_: any, value: string) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    const emailRegex =
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    if (!emailRegex.test(value)) {
                      return Promise.reject(
                        new Error("Please enter a valid email")
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Enter Email"
                suffix={<MailOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile number",
                },
                {
                  pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                  message: "Please enter a valid Bangladeshi phone number",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                prefix={"+88"}
                placeholder="xxxxxxxxx"
              />
            </Form.Item>

            <Form.Item
              name="divisionId"
              label="Select Division"
              rules={[
                {
                  required: true,
                  message: "Please select division",
                },
              ]}
            >
              <Select
                placeholder="Select division"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={(val) => {
                  setSelectDivision(val);
                  form.setFieldValue("districtId", undefined);
                }}
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <Form.Item
              name="districtId"
              label="Select District"
              rules={[
                {
                  required: true,
                  message: "Please select district",
                },
              ]}
            >
              <Select
                placeholder="Select district"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                // disabled={!selectDivision}
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <div className="md:col-span-2">
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter address",
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={type === "VIEW"}
                  placeholder="Enter Full Address"
                />
              </Form.Item>
            </div>

            <Form.Item
              name="latitude"
              label="Latitude"
              rules={[
                {
                  required: true,
                  message: "Please enter latitude",
                },
                {
                  validator: validateNonNegative,
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Latitude"
              />
            </Form.Item>

            <Form.Item
              name="longitude"
              label="Longitude"
              rules={[
                {
                  required: true,
                  message: "Please enter longitude",
                },
                {
                  validator: validateNonNegative,
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Longitude"
              />
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="tradeLicenseFile"
              label="Upload Trade License"
              htmlFor="..."
              extra="File type: PDF, PNG, JPG, JPEG || Max size 5MB"
              valuePropName="fileUrl"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload a image",
              //   },
              // ]}
            >
              <FileUpload
                errorMessage="Image upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingTreadLicense(val)}
                handleName={(val) =>
                  setTreadLicenseName(val || "Upload Trade License")
                }
                onChange={(url) =>
                  form.setFieldsValue({ tradeLicenseFile: url })
                }
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingTreadLicense
                        ? "Uploading..."
                        : treadLicenseName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="authorizationLetterFile"
              label="Authorization Letter"
              htmlFor="..."
              extra="File type: PDF, PNG, JPG, JPEG || Max size 5MB"
              valuePropName="fileUrl"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload a image",
              //   },
              // ]}
            >
              <FileUpload
                errorMessage="Image upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingAuthorizationLetter(val)}
                handleName={(val) =>
                  setAuthorizationLetterName(val || "Authorization Letter")
                }
                onChange={(url) =>
                  form.setFieldsValue({ authorizationLetterFile: url })
                }
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingAuthorizationLetter
                        ? "Uploading..."
                        : authorizationLetterName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="storeImage"
              label="Store Photo"
              htmlFor="..."
              extra="File type: PDF, PNG, JPG, JPEG || Max size 5MB"
              valuePropName="fileUrl"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload a image",
              //   },
              // ]}
            >
              <FileUpload
                errorMessage="Image upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingStorePhoto(val)}
                handleName={(val) =>
                  setStorePhotoName(val || "Upload Store Photo")
                }
                onChange={(url) => form.setFieldsValue({ storeImage: url })}
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingStorePhoto ? "Uploading..." : storePhotoName}
                    </span>

                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            <Form.Item
              className="custom__image__input__placeholder"
              name="proprietorImage"
              label="Proprietor Photo"
              htmlFor="..."
              extra="File type: PDF, PNG, JPG, JPEG || Max size 5MB"
              valuePropName="fileUrl"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload a image",
              //   },
              // ]}
            >
              <FileUpload
                errorMessage="Image upload fail"
                disabled={type === "VIEW"}
                handleLoading={(val) => setUploadingProprietorPhoto(val)}
                handleName={(val) =>
                  setProprietorPhotoName(val || "Upload Proprietor Photo")
                }
                onChange={(url) =>
                  form.setFieldsValue({ proprietorImage: url })
                }
              >
                <Button
                  size="large"
                  disabled={type === "VIEW"}
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-between">
                    <span className="flex gap-3 items-center">
                      <BsrmTableHeader />
                      {uploadingProprietorPhoto
                        ? "Uploading..."
                        : proprietorName}
                    </span>
                    <BsrmAttachment />
                  </div>
                </Button>
              </FileUpload>
            </Form.Item>

            {type !== "EDIT" && type !== "VIEW" ? (
              <>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                    {
                      min: 6,
                      message: "Password must be minimum 6 characters.",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  label="Confirm Password"
                  rules={[
                    {
                      required: false,
                      message: "Please enter confirm password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password and confirm password do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" placeholder="Confirm Password" />
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              name="bsrmAuthorized"
              label="BSRM Dealer ?"
              rules={[
                {
                  required: true,
                  message: "Please select authorized",
                },
              ]}
            >
              <Radio.Group defaultValue={"No"} disabled={type === "VIEW"}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please select status",
                },
              ]}
            >
              <Radio.Group defaultValue={"Active"} disabled={type === "VIEW"}>
                <Radio value="Active">Active</Radio>
                <Radio value="Inactive">Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
