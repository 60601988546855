import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import PlaceholderImage from "../../../assets/images/placeholder-image.png";
import BankListCreateForm from "../../../components/ModalForm/BankListCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteBankAsync,
  getAllBankAsync,
} from "../../../store/features/Bank/BankApi";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { BankData } from "../../../types/redux/ADMIN/bank";

export default function BankList() {
  const { open, toggle, handleOpen } = useModal();
  const dispatch = useAppDispatch();
  const [selectStatus, setSelectStatus] = useState("true");
  const { limit, page, handlePagination, handleLimit } = useTable();

  const { loading, countBank, bankList, deleteLoading } = useAppSelector(
    (state) => state.bank
  );

  const columns: ColumnsType<TableData<BankData>> = [
    {
      title: "No.",
      align: "center",

      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      dataIndex: "logo",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="horizontal">
          <Image
            width={70}
            height={50}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={PlaceholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Bank Name",
      align: "left",
      dataIndex: "fullName",
    },
    {
      title: "Bank Name (Short)",
      align: "left",
      dataIndex: "shortName",
    },
    {
      title: "Website Link",
      align: "left",
      dataIndex: "websiteLink",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <div className="flex items-center gap-1">
            <BsrmDOT />
            <a target="_blank" href={val} rel="noreferrer">
              {val}
            </a>
          </div>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: BankData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  //DELETE
  const handleDelete = (id: string) => {
    dispatch(
      deleteBankAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllBankAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
        },
      })
    );
  }, [dispatch, limit, page, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }
  const allStatus = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  return (
    <AdminLayout
      title="Bank list"
      headerTitle="Bank List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<BankData>>
          loading={loading}
          total={countBank}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(bankList)}
          handleLimit={handleLimit}
          tableTopChildren={
            <Space size="middle" className="filtering__select__option">
              <Select
                size="middle"
                placeholder="Filter by Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <BankListCreateForm
          title={
            open.type === "EDIT"
              ? "Update Bank data"
              : open.type === "VIEW"
              ? "View Bank data"
              : "Bank Create"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Ready-Mix"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
