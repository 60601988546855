import { Button, DatePicker, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import CustomTable from "../../../components/common/CustomTable";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
  SingleArrayOption,
} from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import { getAllOrderProductAsync } from "../../../store/features/Order/orderProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { RetailerOrderProductData } from "../../../types/redux/ADMIN/retailerOrderProduct";
import { formatDate } from "../../../utils/time";

interface DateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export default function Order() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const [selectStatus, setSelectStatus] = useState("Pending");
  const [selectDivision, setSelectDivision] = useState<string | undefined>(
    undefined
  );
  const [selectDistrict, setSelectDistrict] = useState<string | undefined>(
    undefined
  );
  const { loading, countRetailerOrderProduct, retailerOrderProduct } =
    useAppSelector((state) => state.orderProduct);
  const { divisions } = useAppSelector((state) => state.location);
  const { districts } = useAppSelector((state) => state.location);

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  // const [date, setDate] = useState<DateRange>({
  //   startDate: dayjs(),
  //   endDate: dayjs(),
  // });

  // const newDate = {
  //   startDate: formatDate(date?.startDate?.format(), dateFormat),
  //   endDate: formatDate(date?.endDate?.format(), dateFormat),
  // };

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const columns: ColumnsType<TableData<RetailerOrderProductData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },

    {
      title: "Order Details",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.invoiceNo}</p>
          <p className="data_table_sku_data">{formatDate(val?.date)}</p>
        </Space>
      ),
    },

    {
      title: "Customer Info",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.customerId?.name}</p>
          <p className="data_table_primary_data">{val?.customerId?.email}</p>
        </Space>
      ),
    },

    {
      title: "Shipping Address",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.deliveryAddress}</p>
          <p className="data_table_primary_data">
            {val?.districtId?.name}, {val?.divisionId?.name}
          </p>
          <p className="data_table_sku_data">{val?.customerId?.phoneNumber}</p>
        </Space>
      ),
    },

    {
      title: "Total Amount",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.netAmount} BDT</p>
          <p className="data_table_uit_data">Total Item: {val?.totalItem}</p>
        </Space>
      ),
    },
    {
      title: "Status",
      align: "left",
      render: (val: RetailerOrderProductData) => (
        <span
          className={`status ${
            val?.status === "Cancel"
              ? "status-danger"
              : val?.status === "Delivered"
              ? "status-done"
              : val?.status === "Ready to Shipped"
              ? "status-info"
              : val?.status === "Shipped"
              ? "status-others"
              : "status-pending"
          }`}
        >
          {val?.status}
        </span>
      ),
    },
    // ["Pending", "Cancel", "Ready to Shipped", "Shipped", "Delivered", "Partially Processed"]
    {
      title: "Action",
      align: "center",
      render: (val: RetailerOrderProductData) => (
        <div className="flex justify-center gap-2">
          <Link to={`${location?.pathname}/order-details/${val?._id}`}>
            <Button className="bg-white py-[6px]" icon={<BsrmEyeIcon />} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getAllOrderProductAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          fromDate: date?.startDate,
          toDate: date?.endDate,
          districtId: selectDistrict || "",
          divisionId: selectDivision || "",
        },
      })
    );
  }, [
    date?.endDate,
    date?.startDate,
    dispatch,
    limit,
    page,
    selectDistrict,
    selectDivision,
    selectStatus,
  ]);

  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
          divisionId: selectDivision,
        },
      })
    );
  }, [dispatch, limit, page, selectDivision]);

  const allStatus = [
    "Pending",
    "Ready to Shipped",
    "Shipped",
    "Delivered",
    "Partially Processed",
    "Cancel",
  ];

  return (
    <AdminLayout
      title="Order" // browser title
      headerTitle="Order List" // page header
      className="dashboard"
    >
      <section className="">
        <CustomTable
          loading={loading}
          total={countRetailerOrderProduct}
          page={page}
          limit={limit}
          columns={columns}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          dataList={addKeyInArray(retailerOrderProduct)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              {/* <Search 
                placeholder="input search text"
                 allowClear
                 style={{ width: 200 }}
               />
            */}

              <Select
                placeholder="Division"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={(val) => {
                  setSelectDivision(val);
                  setSelectDistrict(undefined);
                }}
              />
              <Select
                placeholder="District"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                onChange={setSelectDistrict}
                disabled={!selectDivision}
                value={selectDistrict}
              />
              <Select
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={SingleArrayOption(allStatus)}
                onChange={setSelectStatus}
                value={selectStatus}
              />
              {/* <RangePicker
                suffixIcon={<BsrmCalenderIcon />}
                className="w-[200px]"
                // defaultValue={[
                //   dayjs("2015/01/01", dateFormat),
                //   dayjs("2015/01/01", dateFormat),
                // ]}
                onChange={(val) =>
                  setDate({
                    startDate: val?.[0]?.format()
                      ? formatDate(val?.[0]?.format(), dateFormat)
                      : "",
                    endDate: val?.[1]?.format()
                      ? formatDate(val?.[1]?.format(), dateFormat)
                      : "",
                  })
                }
                format={dateFormat}
              /> */}

              <RangePicker
                size="large"
                format={dateFormat}
                className="w-[250px]"
                // value={[date.startDate, date.endDate]}
                onChange={(val) =>
                  setDate({
                    startDate: val?.[0]?.format()
                      ? formatDate(val?.[0]?.format(), dateFormat)
                      : "",
                    endDate: val?.[1]?.format()
                      ? formatDate(val?.[1]?.format(), dateFormat)
                      : "",
                  })
                }
              />
            </Space>
          }
        />
      </section>
    </AdminLayout>
  );
}
