import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import BsrmEmailIcon from "../../../assets/icons/BsrmEmailIcon";
import LoginLayout from "../../../layouts/LoginLayout";
import { forgotPasswordRetailerAsync } from "../../../store/features/auth/loginAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export default function ForgotPassword() {
  const dispatch = useAppDispatch();
  const { updateLoading } = useAppSelector((state) => state.login);

  const onFinish = (values: any) => {
    dispatch(
      forgotPasswordRetailerAsync({ data: values, others: { reRender } })
    );
  };

  function reRender(val: boolean) {}

  return (
    <LoginLayout title="Enter your registered email address">
      <Form
        name="form_item_path"
        className="custom-form custom-placeholder-form login-form custom-login-form"
        layout="vertical"
        onFinish={onFinish}
        requiredMark="optional"
        size="large"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input prefix={<BsrmEmailIcon />} placeholder="name@gmail.com" />
        </Form.Item>

        <Form.Item className="mt-5">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={updateLoading}
            block
          >
            {/* {loginLoading ? "Loading..." : "Log in"} */}
            Submit
          </Button>
        </Form.Item>

        <div className="flex justify-between items-center">
          <Link
            to="/update-password"
            className="ml-auto hover:underline text-body hover:text-body/80"
          >
            Login (go new password page)
          </Link>
        </div>
      </Form>
      {/* <p className="text-center font-medium mt-8">
            Don't have an account? 
            <Link
              className="text-primary-np no-underline hover:underline"
              to="sign-up"
            >
              Sign Up
            </Link>
          </p> */}
    </LoginLayout>
  );
}
