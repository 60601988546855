import { Image } from "antd";
import { ReactNode } from "react";
import BsrmLocation2 from "../../../assets/icons/BsrmLocation2";
import BsrmMessage from "../../../assets/icons/BsrmMessage";
import BsrmPhoneIcon from "../../../assets/icons/BsrmPhoneIcon";
import BsrmSMSIcon from "../../../assets/icons/BsrmSMSIcon";
import BsrmTroffiIcon from "../../../assets/icons/BsrmTroffiIcon";
import BsrmUserIcon from "../../../assets/icons/BsrmUserIcon";
import placeHolderImage from "../../../assets/images/placeholder-image.png";
import CustomButton from "../../../components/ui/CustomButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { useAppSelector } from "../../../store/store";

type Props = {
  children?: ReactNode;
};

export default function RetailerUserInfo({ children }: Props) {
  const { login } = useAppSelector((state) => state.login);
  return (
    <>
      <section className="flex gap-4">
        <div className="w-[344px] h-full  bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
          <div className="bg-accent h-[5rem] rounded-t-[14px] p-4 relative">
            <Image
              className="absolute top-0 start-0 "
              width={90}
              height={90}
              preview={false}
              alt={APP_NAME}
              src={`${IMAGE_URL}${login?.proprietorImage}`}
              fallback={placeHolderImage}
              style={{ border: "1px solid white", borderRadius: "100%" }}
            />
          </div>

          <div className="p-4">
            <div className="mt-[18px]">
              <h3 className="paragraph_heading">{login?.ownerName}</h3>
              <div className="mt-1 w-1/2 flex items-center space-x-1.5 px-2 py-1 bg-[#F2F5FF] rounded-md">
                <BsrmTroffiIcon />
                <p className="text-[14px] font-medium leading-6 text-[#4A72FF]">
                  {login?.role}
                </p>
              </div>
            </div>
          </div>

          {/* one */}
          <div className="px-4 py-2 mt-2.5 border border-[#E9EFF6] border-l-0 border-r-0 border-b-0 border-t border-solid">
            <div className="flex gap-3 items-center ">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center">
                <BsrmUserIcon />
              </div>
              <div className="">
                <p className="text-[14px] font-medium leading-6 text-[#5E718D]">
                  User ID
                </p>
                <p className="text-[18px] font-medium leading-[30px] text-[#2D3643]">
                  {login?.retailerCode}
                </p>
              </div>
            </div>
          </div>

          {/* two */}
          <div className="px-4 py-2 border border-[#E9EFF6] border-l-0 border-t border-b-0 border-r-0 border-solid">
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center">
                <BsrmPhoneIcon />
              </div>
              <div className="">
                <p className="text-[14px] font-medium leading-6 text-[#5E718D]">
                  Mobile Number
                </p>
                <p className="text-[18px] font-medium leading-[30px] text-[#2D3643]">
                  {login?.phoneNumber}
                </p>
              </div>
            </div>
          </div>

          {/* three */}
          <div className="px-4 py-2 border border-[#E9EFF6] border-t border-b-0 border-r-0 border-l-0 border-solid">
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center">
                <BsrmSMSIcon />
              </div>
              <div className="">
                <p className="text-[14px] font-medium leading-6 text-[#5E718D]">
                  Email Address
                </p>
                <p className="text-[18px] font-medium leading-[30px] text-[#2D3643]">
                  {login?.email}
                </p>
              </div>
            </div>
          </div>

          {/* four */}
          <div className="px-4 py-2 border border-[#E9EFF6] border-t border-r-0 border-l-0 border-solid">
            <div className="flex gap-3 items-center">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center">
                <BsrmLocation2 />
              </div>
              <div className="">
                <p className="text-[14px] font-medium leading-6 text-[#5E718D]">
                  Address
                </p>
                <p className="text-[18px] font-medium leading-[30px] text-[#2D3643]">
                  {login?.address}
                </p>
              </div>
            </div>
          </div>

          <div className="px-5 py-6">
            <CustomButton>
              <span className="flex justify-center items-center h-7">
                <BsrmMessage /> <span className="ml-2">Message</span>
              </span>
            </CustomButton>
          </div>
        </div>
        {children}
      </section>
    </>
  );
}
