import { Button, Form, FormProps, Input } from "antd";
import { ReactNode, useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import {
  createBrandAsync,
  getAllBrandAsync,
  updateBrandAsync,
} from "../../store/features/Brand/BrandApi";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetBrandSingleData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
  children?: ReactNode;
} & CustomModalProps;

export default function BrandCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [logoName, setLogoName] = useState("Upload Logo");
  const [uploadingLogo, setUploadingLogo] = useState(false);

  const { postLoading, brand, updateLoading } = useAppSelector(
    (state) => state.brand
  );
  const brandAllData = data as GetBrandSingleData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, ...rest } = values;

    const newValues = {
      ...rest,
    };

    if (type === "EDIT" && brandAllData?._id) {
      dispatch(
        updateBrandAsync({
          data: newValues,
          others: {
            reRender,
            id: brandAllData?._id,
          },
        })
      );
    } else {
      dispatch(
        createBrandAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      brandAllData?._id &&
      updateLoading === false
    ) {
      const bannerNameArr = stringToArray(brandAllData?.logo, ".");
      setLogoName(
        brandAllData?.logo
          ? `${sliceString(brandAllData?.logo, 15, "...")}.${
              bannerNameArr?.[bannerNameArr?.length - 1]
            }`
          : "Upload Banner"
      );

      form.setFieldsValue({
        name: brandAllData?.name,
        name_bangla: brandAllData?.name_bangla,
        banner: brandAllData?.logo,
      });
    }
  }, [
    brandAllData?._id,
    brandAllData?.logo,
    brandAllData?.name,
    brandAllData?.name_bangla,
    form,
    type,
    updateLoading,
  ]);

  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark gap-x-[10px]"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="name"
            label="Brand Name (English)"
            htmlFor="..."
            rules={[
              {
                required: true,
                message: "Please enter brand name(English)",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Brand name (english)"
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            name="name_bangla"
            label="Brand Name (Bangla)"
            htmlFor="..."
            rules={[
              {
                required: true,
                message: "Please enter brand name(Bangla)",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Brand name (bangla)"
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            className="custom__image__input__placeholder"
            name="logo"
            label="Select Logo"
            htmlFor="..."
            extra={
              type !== "VIEW" ? "Upload the icon carefully | SVG, PNG" : null
            }
          >
            <FileUpload
              errorMessage="Logo upload fail"
              disabled={type === "VIEW"}
              handleLoading={(val) => setUploadingLogo(val)}
              handleName={(val) => setLogoName(val || "Upload Logo")}
              onChange={(url) =>
                form.setFieldsValue({ authorizationLetterFile: url })
              }
            >
              <Button
                size="large"
                style={{ width: "100%" }}
                disabled={type === "VIEW"}
              >
                <div className="flex justify-between">
                  <span className="flex gap-3 items-center">
                    <BsrmTableHeader />
                    {uploadingIcon ? "Uploading..." : logoName}
                  </span>
                  <BsrmAttachment />
                </div>
              </Button>
            </FileUpload>
          </Form.Item>

          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
