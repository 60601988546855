import { Button, Space } from "antd";
import { useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import ChangePassword from "./ChangePassword";
import UserInfo from "./UserInfo";
import UserLogDetails from "./UserLogDetails";

type TabType = "USER_LOG" | "CHANGE_PASSWORD";

export default function Settings() {
  const [tab, setTab] = useState<TabType>("USER_LOG");

  return (
    <AdminLayout
      title="Settings"
      headerTitle="Settings"
      className="dashboard"
      headerChildren={
        <Space className="flex justify-end" size="middle">
          <div className="flex items-center bg-white rounded-md gap-[5px] p-[5px] border border-solid border-others-border">
            <Button
              className={`px-10 font-medium ${
                tab !== "USER_LOG" ? "!border-none" : ""
              }`}
              type={tab === "USER_LOG" ? "primary" : "default"}
              onClick={() => setTab("USER_LOG")}
            >
              User Info
            </Button>
            <Button
              className={`font-medium ${
                tab !== "CHANGE_PASSWORD" ? "!border-none" : ""
              }`}
              type={tab === "CHANGE_PASSWORD" ? "primary" : "default"}
              onClick={() => setTab("CHANGE_PASSWORD")}
            >
              Change Password
            </Button>
          </div>
        </Space>
      }
    >
      <UserInfo>
        {tab === "USER_LOG" ? (
          <UserLogDetails />
        ) : tab === "CHANGE_PASSWORD" ? (
          <ChangePassword />
        ) : null}
      </UserInfo>
    </AdminLayout>
  );
}
