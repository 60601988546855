import { useEffect, useRef, useState } from "react";

type UseDebounceReturn = {
  value: string;
  isReCall: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reCall: () => void;
  reRenderData: () => void;
};

const useCustomDebounce = (
  callback: () => void,
  delay: number
): UseDebounceReturn => {
  const savedCallback = useRef<() => void>();
  const [value, setValue] = useState<string>("");
  const [timeOut, setTimeOut] = useState<number>(0);
  const [reRender, setReRender] = useState<boolean>(false);
  const [isReCall, setIsReCall] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const reCall = () => {
    savedCallback.current?.();
  };

  const reRenderData = () => {
    setReRender((prev) => !prev);
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value) {
        savedCallback.current?.();
      }
      setTimeOut(delay);
      setIsReCall(true);
    }, timeOut);

    return () => clearTimeout(delayDebounceFn);
  }, [value, reRender, timeOut, delay]);

  return { value, isReCall, onChange, reCall, reRenderData };
};

export default useCustomDebounce;
