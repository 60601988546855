import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import CustomTable from "../../../components/common/CustomTable";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllCustomerAsync } from "../../../store/features/customer/customerAPI";
import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { GetAllCustomerData } from "../../../types/redux/ADMIN/customer";

export default function CustomerMange() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const [selectDiviosion, setSelectDiviosion] = useState("");
  const [selectDistrict, setSelectDistrict] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const { countCustomerData, loading, customerData } = useAppSelector(
    (state) => state.customer
  );
  const { divisions } = useAppSelector((state) => state.location);
  const { districts } = useAppSelector((state) => state.location);

  const columns: ColumnsType<TableData<GetAllCustomerData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: "Customer Name",
      align: "left",
      dataIndex: "name",
    },
    {
      title: "Division",
      align: "left",
      render: (val: GetAllCustomerData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.divisionId?.name}</p>
        </Space>
      ),
    },
    {
      title: "District",
      align: "left",
      render: (val: GetAllCustomerData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.districtId?.name}</p>
        </Space>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      align: "left",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      align: "left",
    },
    {
      title: "Status",
      align: "left",
      render: (val) => (
        <span
          className={`status ${
            val?.status === "Active"
              ? "status-done"
              : val?.status === "Delete"
              ? "status-danger"
              : "status-waiting"
          }`}
        >
          {val?.status === "Delete" ? "Deleted" : val?.status || "N/A"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "center",
      width: "100px",
      render: (val: GetAllCustomerData) => (
        <div className="flex justify-center gap-2">
          <Link to={`/admin/customerManage/${val?._id}`}>
            <Button className="bg-white py-[6px]" icon={<BsrmEyeIcon />} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getAllCustomerAsync({
        params: {
          pageNo: page,
          limit: limit,
          districtId: selectDistrict,
          divisionId: selectDiviosion,
          status: selectStatus,
        },
      })
    );
  }, [dispatch, limit, page, selectDistrict, selectDiviosion, selectStatus]);

  // division
  useEffect(() => {
    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: page,
          limit: limit,
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  // district
  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: page,
          limit: limit,
          divisionId: "",
          name: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  const allStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Deleted", value: "Delete" },
  ];

  return (
    <AdminLayout
      title="Customers"
      headerTitle="Customer List"
      className="dashboard"
    >
      <section className="">
        <CustomTable<TableData<GetAllCustomerData>>
          loading={loading}
          total={countCustomerData}
          page={page}
          limit={limit}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(customerData)}
          handleLimit={handleLimit}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Division"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={setSelectDiviosion}
              />
              <Select
                placeholder="Filter By District"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                onChange={setSelectDistrict}
              />
              <Select
                placeholder="Status"
                className="filter_select_width"
                size="middle"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>
    </AdminLayout>
  );
}
