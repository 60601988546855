export default function BsrmReceive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M13.75 8.25H17.1875M13.75 11H17.1875M13.75 13.75H17.1875M4.125 17.875H17.875C18.422 17.875 18.9466 17.6577 19.3334 17.2709C19.7202 16.8841 19.9375 16.3595 19.9375 15.8125V6.1875C19.9375 5.64049 19.7202 5.11589 19.3334 4.72909C18.9466 4.3423 18.422 4.125 17.875 4.125H4.125C3.57799 4.125 3.05339 4.3423 2.66659 4.72909C2.2798 5.11589 2.0625 5.64049 2.0625 6.1875V15.8125C2.0625 16.3595 2.2798 16.8841 2.66659 17.2709C3.05339 17.6577 3.57799 17.875 4.125 17.875ZM9.625 8.59375C9.625 8.81946 9.58054 9.04296 9.49417 9.25149C9.40779 9.46002 9.28119 9.64949 9.12159 9.80909C8.96199 9.96869 8.77252 10.0953 8.56399 10.1817C8.35546 10.268 8.13196 10.3125 7.90625 10.3125C7.68054 10.3125 7.45704 10.268 7.24851 10.1817C7.03998 10.0953 6.85051 9.96869 6.69091 9.80909C6.53131 9.64949 6.40471 9.46002 6.31833 9.25149C6.23196 9.04296 6.1875 8.81946 6.1875 8.59375C6.1875 8.13791 6.36858 7.70074 6.69091 7.37841C7.01324 7.05608 7.45041 6.875 7.90625 6.875C8.36209 6.875 8.79926 7.05608 9.12159 7.37841C9.44392 7.70074 9.625 8.13791 9.625 8.59375ZM10.8112 14.4018C9.91678 14.8781 8.91868 15.1266 7.90533 15.125C6.8926 15.1263 5.89518 14.8778 5.00133 14.4018C5.21976 13.8071 5.61545 13.2937 6.13494 12.9311C6.65444 12.5685 7.27271 12.3741 7.90625 12.3741C8.53979 12.3741 9.15806 12.5685 9.67756 12.9311C10.1971 13.2937 10.5927 13.8071 10.8112 14.4018Z"
        stroke="#FF9500"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
