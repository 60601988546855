import { useState } from "react";

export default function useTable(pageNumber = 1, limitCount = 10) {
  const [page, setPage] = useState(pageNumber);
  const [limit, setLimit] = useState(limitCount);

  const handlePagination = (
    paginationPage: number,
    paginationLimit: number
  ) => {
    setPage(paginationPage);
    setLimit(paginationLimit);
  };

  const handleLimit = (val: number) => {
    setLimit(Number(val));
  };

  return {
    page,
    limit,
    handlePagination,
    handleLimit,
  };
}
