import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import ExpertViewDetails from "../../../components/ModalForm/ExpertViewDetails";
import ManageExpertCreateForm from "../../../components/ModalForm/ManageExpertCreateForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  PLACEHOLDER_IMG,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteExpertAsync,
  getAllExpertAsync,
} from "../../../store/features/expert/expertAPI";
import { getAllExpertCategoryAsync } from "../../../store/features/expertCategory/expertCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetExpertData, TableData } from "../../../types/redux";
import { formatDate } from "../../../utils/time";

export default function ManageExpert() {
  const dispatch = useAppDispatch();
  const [selectCategory, setSelectCategory] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const { deleteLoading, loading, countExpert, expert } = useAppSelector(
    (state) => state.expert
  );
  const { expertCategory } = useAppSelector((state) => state.expertCategory);
  const columns: ColumnsType<TableData<GetExpertData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      dataIndex: "image",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="horizontal">
          <Image
            className="rounded-full"
            width={56}
            height={56}
            preview={false}
            alt={APP_NAME}
            src={IMAGE_URL + val}
            fallback={PLACEHOLDER_IMG}
          />
        </Space>
      ),
    },
    {
      title: "Expert's Name",
      align: "left",
      render: (val: GetExpertData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.fullName}</p>
          <p className="data_table_sku_data">{val?.designation}</p>
        </Space>
      ),
    },
    {
      title: "Expert Category",
      align: "left",
      dataIndex: "categoryId",
      render: (val) => val?.name,
    },
    {
      title: "Address & Contact No.",
      align: "left",
      render: (val: GetExpertData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <h5 className="data_table_primary_data">{val?.address}</h5>
          <h5 className="data_table_primary_data">
            {val?.districtId?.name}, {val?.divisionId?.name}
          </h5>
          <p className="data_table_sku_data">{val?.phoneNumber}</p>
        </Space>
      ),
    },
    {
      title: "Client Served",
      align: "left",
      render: (val: GetExpertData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">
            {formatDate(val?.createdAt)}
          </p>
          <p className="data_table_primary_data">
            From {formatDate(val?.updatedAt)}
          </p>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span
          className={`status ${
            val === "Active"
              ? "status-done"
              : val === "Inactive"
              ? "status-danger"
              : ""
          }`}
        >
          {val}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetExpertData) => (
        <div className="flex justify-center gap-2">
          <Button
            icon={<BsrmEyeIcon />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            icon={<BsrmEdit />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const getData = useCallback(() => {
    dispatch(
      getAllExpertAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          categoryId: selectCategory,
        },
      })
    );
  }, [dispatch, limit, page, selectCategory, selectStatus]);

  useEffect(() => {
    dispatch(
      getAllExpertCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  const handleDelete = (id: string) => {
    dispatch(
      deleteExpertAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const allStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  return (
    <AdminLayout
      title="Our Expert"
      headerTitle="Manage Expert List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
                data: "",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetExpertData>>
        loading={loading}
        total={countExpert}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(expert)}
        handleLimit={handleLimit}
        tableTopChildren={
          <Space size="small" className="filtering__select__option">
            <Select
              placeholder="Filter By Category"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(expertCategory, "name", "_id")}
              onChange={setSelectCategory}
            />
            <Select
              placeholder="Status"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={allStatus}
              onChange={setSelectStatus}
            />
          </Space>
        }
      />

      {/* Modal */}
      {(open.type === "ADD" || open.type === "EDIT") && (
        <ManageExpertCreateForm
          title={open.type === "EDIT" ? "Expert Update" : "Create Expert"}
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Expert Category"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}

      {open.type === "VIEW" && (
        <ExpertViewDetails
          title="Expert Details"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}
    </AdminLayout>
  );
}
