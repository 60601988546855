import { Space } from "antd";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import IconButton from "../../../components/common/IconButton";
import AdminLayout from "../../../layouts/AdminLayout";

export default function OrderList() {
  return (
    <AdminLayout
      title="Sales Report" // browser title
      headerTitle="Order List" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton nextIcon={<BsrmAddIcon />}>Create New</IconButton>
        </Space>
      }
    >
      <section className="">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
        dolores id neque alias quas tempora. Animi maxime harum doloremque amet,
        soluta quod sunt quaerat magnam maiores, ratione fugit aut voluptate.
      </section>
    </AdminLayout>
  );
}
