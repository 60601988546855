import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import placeholderImage from "../../../assets/images/placeholder.png";
import BlogPostForm from "../../../components/ModalForm/BlogPostForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBlogCategoryAsync } from "../../../store/features/Blog/blogAPI";
import {
  deleteBlogPostAsync,
  getAllBlogPostAsync,
} from "../../../store/features/BlogPost/blogPostAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { GetBlogPostData } from "../../../types/redux/ADMIN/blogPost";

export default function BlogPost() {
  const dispatch = useAppDispatch();
  const [selectCategory, setSelectCategory] = useState("");
  const [selectStatus, setSelectStatus] = useState("");

  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const { loading, countBlogPost, blogPost, deleteLoading } = useAppSelector(
    (state) => state.blogPost
  );
  const { blogCategory } = useAppSelector((state) => state.blog);

  const columns: ColumnsType<TableData<GetBlogPostData>> = [
    {
      title: "No.",
      align: "center",

      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      dataIndex: "image",
      align: "center",
      render: (val) => (
        <Space
          className="table_column_title bg-[#ecf9f2] rounded px-1 py-0.5"
          size={0}
          direction="horizontal"
        >
          <Image
            width={70}
            height={50}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={placeholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Post Title",
      align: "left",
      render: (val: GetBlogPostData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.title}</h5>
        </Space>
      ),
    },
    {
      title: "Post Category",
      align: "left",
      dataIndex: "categoryId",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <div className="flex items-center gap-1">
            <BsrmDOT />
            <p className="table__content__layer__three">{val?.name}</p>
          </div>
        </Space>
      ),
    },

    {
      title: "Status",

      align: "center",
      render: (val: GetBlogPostData) => (
        <span
          className={`status ${
            val?.status
              ? "bg-status-complete-bg text-status-complete-text"
              : "status-pending"
          }`}
        >
          {val?.status ? "Published" : "Pending"}
        </span>
      ),
    },

    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetBlogPostData) => (
        <div className="flex justify-center gap-2">
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEyeIcon />}
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            className="bg-white py-[6px]"
            icon={<BsrmEdit />}
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
          <Button
            className="bg-white  py-[6px]"
            icon={<BsrmDelete />}
            onClick={() =>
              handleOpen({
                type: "DELETE",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteBlogPostAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllBlogPostAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          categoryId: selectCategory,
        },
      })
    );
  }, [dispatch, limit, page, selectCategory, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  // dispatch Category Data
  useEffect(() => {
    dispatch(
      getAllBlogCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          // status: true,
        },
      })
    );
  }, [dispatch]);

  const allStatus = [
    { label: "Published", value: "true" },
    {
      label: "Un published",
      value: "false",
    },
  ];

  return (
    <AdminLayout
      title="Posts"
      headerTitle="Post List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<GetBlogPostData>>
          loading={loading}
          total={countBlogPost}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(blogPost)}
          handleLimit={handleLimit}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                size="middle"
                placeholder="Select Category"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(blogCategory, "name", "_id")}
                onChange={setSelectCategory}
              />
              <Select
                placeholder="Status"
                size="middle"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <BlogPostForm
          title={
            open.type === "EDIT"
              ? "Update Post"
              : open.type === "VIEW"
              ? "View Post"
              : "Post Create"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Blog"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
