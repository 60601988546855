import { Badge, Button, Divider, Image, Input } from "antd";
import { useState } from "react";
import BsrmAttachment from "../../../assets/icons/BsrmAttachment";
import BsrmMagnifine from "../../../assets/icons/BsrmMagnifine";
import BsrmCameraIcon from "../../../assets/icons/liveChat/BsrmCameraIcon";
import BsrmMicrophoneIcon from "../../../assets/icons/liveChat/BsrmMicrophoneIcon";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import profile_pic from "../../../assets/images/profile_Image/65294841.png";
import { APP_NAME } from "../../../helpers/config";
import AdminLayout from "../../../layouts/AdminLayout";
type TabType = "EXPERT" | "RETAILER";
export default function LiveChat() {
  const [tab, setTab] = useState<TabType>("EXPERT");
  return (
    <AdminLayout
      title="Live Chat" // browser title
      headerTitle="Live Chat" // page header
      className="dashboard"
    >
      <section className="flex gap-4">
        <div className="w-[344px] h-full  bg-white border border-solid border-[#E9EFF6] border-t-0 rounded-[14px]">
          <div className="bg-accent rounded-t-[14px] p-4 relative">
            <p className="text-[20px] text-body leading-8 font-semibold">
              Message
            </p>

            <Input
              className="mt-4 -mb-2"
              size="large"
              placeholder="Search by name, mobile no, email"
              // style={{ width: 200 }}
              suffix={<BsrmMagnifine />}
            />

            <Divider className="bg-[#D7DFE9]" />
            <div className="-mt-3 flex items-center bg-white rounded-md gap-[5px] p-[5px] border border-solid border-others-border">
              <Button
                className={`px-12 font-medium ${
                  tab !== "EXPERT" ? "!border-none" : ""
                }`}
                type={tab === "EXPERT" ? "primary" : "default"}
                onClick={() => setTab("EXPERT")}
              >
                Expert
              </Button>
              <Button
                className={`px-12 font-medium ${
                  tab !== "RETAILER" ? "!border-none" : ""
                }`}
                type={tab === "RETAILER" ? "primary" : "default"}
                onClick={() => setTab("RETAILER")}
              >
                Retailer
              </Button>
            </div>
          </div>

          {/* one */}
          <div className="px-4 py-2 mt-2.5 ">
            <div className="flex gap-3 items-center ">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat">
                <Badge rootClassName="custom_badge" dot>
                  <Image
                    className="!rounded-full"
                    width={56}
                    height={56}
                    preview={false}
                    src={profile_pic}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                    style={{ border: "1px solid white", borderRadius: "100%" }}
                  />
                </Badge>
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-black">
                  Floyd Miles
                </p>
                <p className="text-[14px] leading-6 text-black">
                  Civil Engineer
                </p>
              </div>
            </div>
          </div>
          {/* two */}
          <div className="px-4 py-2 mt-2.5 ">
            <div className="flex gap-3 items-center ">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat">
                <Badge rootClassName="custom_badge" dot>
                  <Image
                    className="!rounded-full"
                    width={56}
                    height={56}
                    preview={false}
                    src={profile_pic}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                    style={{ border: "1px solid white", borderRadius: "100%" }}
                  />
                </Badge>
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-black">
                  Robert Fox
                </p>
                <p className="text-[14px] leading-6 text-black">Engineer</p>
              </div>
            </div>
          </div>

          {/* three */}
          <div className="px-4 py-2 mt-2.5 ">
            <div className="flex gap-3 items-center ">
              <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat">
                <Badge
                  rootClassName="custom_badge"
                  count={100}
                  overflowCount={99}
                >
                  <Image
                    className="!rounded-full"
                    width={56}
                    height={56}
                    preview={false}
                    src={profile_pic}
                    alt={APP_NAME}
                    fallback={placeholderImage}
                    style={{ border: "1px solid white", borderRadius: "100%" }}
                  />
                </Badge>
              </div>
              <div className="">
                <p className="text-[18px] font-medium leading-[30px] text-black">
                  Devon lane
                </p>
                <p className="text-[14px] leading-6 text-black">Painter</p>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div
          className="flex-auto w-[712px] rounded-[14px] bg-white"
          style={{ border: "1px solid #E9EFF6" }}
        >
          <div className="flex gap-3 items-center p-5">
            <div className="rounded-full w-[46px] h-[46px] bg-[#F0F3F9]  flex items-center justify-center live__chat">
              <Badge rootClassName="custom_badge" dot>
                <Image
                  className="!rounded-full"
                  width={56}
                  height={56}
                  preview={false}
                  src={profile_pic}
                  alt={APP_NAME}
                  fallback={placeholderImage}
                  style={{ border: "1px solid white", borderRadius: "100%" }}
                />
              </Badge>
            </div>
            <div className="">
              <p className="text-[18px] font-medium leading-[30px] text-black">
                Robert Fox
              </p>
              <p className="text-[14px] leading-6 text-black">Engineer</p>
            </div>
          </div>

          <hr className="hr" />
          <div className="p-5">
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
            <p> Okay, Thanks for your reply..!</p>
          </div>

          <hr className="hr" />
          <div className="px-5 py-6 flex gap-1.5 items-center">
            <button className="w-[46px] h-[46px] bg-primary-admin border-none rounded-lg">
              <BsrmAttachment color="#fff" />
            </button>
            {/* <button className="w-[46px] h-[46px] bg-white border-none rounded-lg">
              <BsrmDeleteIcon />
            </button> */}

            <Input
              className="h-[46px] w-[81%]"
              placeholder="Message"
              suffix="😊"
            />
            <div className="flex">
              <button className="w-[46px] h-[46px] bg-white border-none rounded-lg cursor-pointer">
                <BsrmCameraIcon />
              </button>
              <button className="w-[46px] h-[46px] bg-white border-none rounded-lg cursor-pointer">
                <BsrmMicrophoneIcon />
              </button>
            </div>
            {/* <button className="w-[46px] h-[46px] bg-white border-none rounded-lg cursor-pointer">
              <BsrmSendIcon />
            </button> */}
          </div>
        </div>
      </section>

      {/* arrestment */}

      {/* <div
        className="p-6 bg-white rounded-[14px] md:w-[353px] "
        style={{ border: "1px solid #E9EFF6" }}
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="">
            <div className="bg-[#9B7BF9]  w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmDocument />
            </div>
            <p className="flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Document
            </p>
          </div>
          <div className="">
            <div className="bg-[#FF574D] w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmCamera />
            </div>
            <p className=" flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Camera
            </p>
          </div>
          <div className="">
            <div className="bg-[#D638EE] w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmGallery />
            </div>
            <p className=" flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Gallery
            </p>
          </div>
          <div className="">
            <div className="bg-[#38D6EF] w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmAudio />
            </div>
            <p className=" flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Audio
            </p>
          </div>
          <div className="">
            <div className="bg-[#6F8EFF] w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmLocation />
            </div>
            <p className=" flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Location
            </p>
          </div>
          <div className="">
            <div className="bg-[#FC8E28] w-[61px] h-[61px] rounded-full flex justify-center items-center cursor-pointer mb-2">
              <BsrmContact />
            </div>
            <p className=" flex justify-center text-[16px] leading-7 text-[#5E718D]">
              Contact
            </p>
          </div>
        </div>
      </div> */}

      {tab === "EXPERT" ? <></> : tab === "RETAILER" ? <></> : null}
    </AdminLayout>
  );
}
