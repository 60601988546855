import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  SingleDataParams,
} from "../../../types/redux";
import { RetailerChatParams } from "../../../types/redux/RETAILER/liveChat";

// get all customer
export const getAllCustomerAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-live-chat/getAllCustomer",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.getCustomerData}`, {
        params,
      });
      return {
        data: data.data?.getData,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get customer fail");
      return rejectWithValue(error);
    }
  }
);

// get retailer Chat Data
export const getRetailerChatDataAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<RetailerChatParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-live-chat/getRetailerChatData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.getRetailerChatData}`, {
        params,
      });
      return {
        data: data.data?.chatData,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get chat data fail");
      return rejectWithValue(error);
    }
  }
);

// get retailer online status
export const getRetailerOnlineStatusAsync = createAsyncThunk<
  any,
  Params<SingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "retailer-live-chat/getRetailerOnlineStatus",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getRetailerOnlineStatus}/${params?.id}`
      );
      return data.data?.getData;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get online status fail");
      return rejectWithValue(error);
    }
  }
);
