export const formats = [
  // "header",
  // "font",
  // "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  // "blockquote",
  // "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  // "color",
  "code-block",
  "formula",
  "direction",
  "code",
  "undo",
  "redo",
  "emoji",
];
