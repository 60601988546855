export default function SeenIcon({ color = "#AFBACA" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82921 11.4904C4.54804 11.2092 4.09215 11.2092 3.81098 11.4904C3.5298 11.7715 3.5298 12.2274 3.81098 12.5085L4.82921 11.4904ZM8.1601 15.8395L7.65098 16.3485C7.93215 16.6297 8.38804 16.6297 8.66921 16.3485L8.1601 15.8395ZM16.3492 8.66857C16.6304 8.38739 16.6304 7.93151 16.3492 7.65033C16.068 7.36916 15.6122 7.36916 15.331 7.65033L16.3492 8.66857ZM8.66921 11.4904C8.38804 11.2092 7.93215 11.2092 7.65098 11.4904C7.3698 11.7715 7.3698 12.2274 7.65098 12.5085L8.66921 11.4904ZM12.0001 15.8395L11.491 16.3485C11.7722 16.6297 12.228 16.6297 12.5092 16.3485L12.0001 15.8395ZM20.1892 8.66857C20.4704 8.38739 20.4704 7.93151 20.1892 7.65033C19.908 7.36916 19.4522 7.36916 19.171 7.65033L20.1892 8.66857ZM3.81098 12.5085L7.65098 16.3485L8.66921 15.3304L4.82921 11.4904L3.81098 12.5085ZM8.66921 16.3485L16.3492 8.66857L15.331 7.65033L7.65098 15.3304L8.66921 16.3485ZM7.65098 12.5085L11.491 16.3485L12.5092 15.3304L8.66921 11.4904L7.65098 12.5085ZM12.5092 16.3485L20.1892 8.66857L19.171 7.65033L11.491 15.3304L12.5092 16.3485Z"
        fill={color}
      />
    </svg>
  );
}
