import { Button, Form, FormProps, Input, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmEmailIcon from "../../assets/icons/BsrmEmailIcon";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  ArrayOption,
  findDataSelect,
  selectSearchOption,
} from "../../helpers/siteConfig";
import {
  createExpertAsync,
  updateExpertAsync,
} from "../../store/features/expert/expertAPI";
import { getAllExpertCategoryAsync } from "../../store/features/expertCategory/expertCategoryAPI";
import {
  getAllDistrictAsync,
  getAllDivisionAsync,
} from "../../store/features/locations/locationAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetExpertData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ManageExpertCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [selectDivision, setSelectDivision] = useState("");
  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [iconName, setIconName] = useState("Upload Image");
  const { expertCategory } = useAppSelector((state) => state.expertCategory);
  const { divisions, districts } = useAppSelector((state) => state.location);
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.expert
  );

  const expertData = data as GetExpertData;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && expertData?._id) {
      const { password, passwordConfirm, ...rest } = values;

      dispatch(
        updateExpertAsync({
          data: { ...rest },
          others: {
            reRender,
            id: expertData?._id,
          },
        })
      );
    } else {
      dispatch(
        createExpertAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAllExpertCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
        },
      })
    );

    dispatch(
      getAllDivisionAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllDistrictAsync({
        params: {
          pageNo: 0,
          limit: 0,
          name: "",
          divisionId: selectDivision,
        },
      })
    );
  }, [dispatch, selectDivision]);

  // add value in modal for view and edit
  useEffect(() => {
    if (type === "EDIT" && expertData?._id && updateLoading === false) {
      // setCategoryIcon(expertData?.icon);
      const fileNameArr = stringToArray(expertData?.image, ".");
      setIconName(
        expertData?.image
          ? `${sliceString(expertData?.image, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Image"
      );

      if (findDataSelect(divisions, expertData?.divisionId?._id)) {
        setSelectDivision(
          findDataSelect(divisions, expertData?.divisionId?._id) || ""
        );
      }

      form.setFieldsValue({
        fullName: expertData?.fullName,
        email: expertData?.email,
        phoneNumber: expertData?.phoneNumber,
        categoryId: findDataSelect(expertCategory, expertData?.categoryId?._id),
        divisionId: findDataSelect(divisions, expertData?.divisionId?._id),
        districtId: findDataSelect(districts, expertData?.districtId?._id),
        address: expertData?.address,
        educationDetails: expertData?.educationDetails,
        designation: expertData?.designation,
        bsrmAuthorized: expertData?.bsrmAuthorized,
        image: expertData?.image,
        password: expertData?.password,
        passwordConfirm: expertData?.passwordConfirm,
      });
    }
  }, [
    districts,
    divisions,
    expertCategory,
    expertData?._id,
    expertData?.address,
    expertData?.bsrmAuthorized,
    expertData?.categoryId?._id,
    expertData?.designation,
    expertData?.districtId?._id,
    expertData?.divisionId?._id,
    expertData?.educationDetails,
    expertData?.email,
    expertData?.fullName,
    expertData?.image,
    expertData?.password,
    expertData?.passwordConfirm,
    expertData?.phoneNumber,
    form,
    type,
    updateLoading,
  ]);

  return (
    <CustomModal width={720} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="grid md:grid-cols-2 gap-x-4">
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Please enter full name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter full name" />
            </Form.Item>

            <Form.Item
              name="categoryId"
              label="Expert Category"
              rules={[
                {
                  required: true,
                  message: "Please enter expert category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(expertCategory, "name", "_id")}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  type: "email",
                },
                {
                  validator: async (_: any, value: string) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    const emailRegex =
                      /^[a-zA-Z0-9._%]+[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    if (!emailRegex.test(value)) {
                      return Promise.reject(
                        new Error("Please enter a valid email")
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter mail id"
                suffix={<BsrmEmailIcon />}
              />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile number",
                },
                {
                  pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                  message: "Please enter a valid Bangladeshi phone number",
                },
              ]}
            >
              <Input size="large" prefix={"+88"} placeholder="xxxxxxxxx" />
            </Form.Item>

            <Form.Item
              name="divisionId"
              label="Select Division"
              rules={[
                {
                  required: true,
                  message: "Please select division",
                },
              ]}
            >
              <Select
                placeholder="Select division"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(divisions, "name", "_id")}
                onChange={(val) => {
                  setSelectDivision(val);
                  form.setFieldValue("districtId", undefined);
                }}
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <Form.Item
              name="districtId"
              label="Select District"
              rules={[
                {
                  required: true,
                  message: "Please select district",
                },
              ]}
            >
              <Select
                placeholder="Select district"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(districts, "name", "_id")}
                disabled={!selectDivision}
              />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Full Address" />
            </Form.Item>

            <Form.Item
              name="educationDetails"
              label="Education Details"
              rules={[
                {
                  required: true,
                  message: "Please enter education details",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Your Degree & University/institute"
              />
            </Form.Item>

            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Please enter designation",
                },
              ]}
            >
              <Input size="large" placeholder="Enter designation" />
            </Form.Item>

            <div
              className="grid"
              style={{ gridTemplateColumns: type === "EDIT" ? "1fr 60px" : "" }}
            >
              <Form.Item
                className="custom__image__input__placeholder"
                name="image"
                label="Select Image"
                htmlFor="..."
                extra="File type: PNG, JPG || Max size 5MB"
                valuePropName="fileUrl"
                rules={[
                  {
                    required: true,
                    message: "Please upload a image",
                  },
                ]}
              >
                <FileUpload
                  errorMessage="Image upload fail"
                  disabled={type === "VIEW"}
                  handleLoading={(val) => setUploadingIcon(val)}
                  handleName={(val) => setIconName(val || "Upload Image")}
                  onChange={(url) => form.setFieldsValue({ image: url })}
                >
                  <Button
                    size="large"
                    disabled={type === "VIEW"}
                    style={{ width: "100%" }}
                  >
                    <div className="flex justify-between">
                      <span className="flex gap-3 items-center">
                        <BsrmTableHeader />
                        {uploadingIcon ? "Uploading..." : iconName}
                      </span>
                      <BsrmAttachment />
                    </div>
                  </Button>
                </FileUpload>
              </Form.Item>
              {form.getFieldValue("image") && type === "EDIT" ? (
                <div className="flex justify-end items-center mb-4">
                  <Image
                    width={60}
                    height={60}
                    src={`${IMAGE_URL}${form.getFieldValue("image")}`}
                    alt={APP_NAME}
                    className="!rounded-full p-2.5"
                    rootClassName="text-center"
                  />
                </div>
              ) : null}
            </div>
            {type !== "EDIT" ? (
              <>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                    {
                      min: 8,
                      message: "Password must be minimum 8 characters.",
                    },
                  ]}
                >
                  <Input.Password
                    // prefix={<PassKeyIcon />}
                    size="large"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter confirm password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password and confirm password do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" placeholder="Confirm Password" />
                </Form.Item>
              </>
            ) : null}
            <div className="md:col-span-2">
              <Form.Item
                name="bsrmAuthorized"
                label="BSRM Expert?"
                // rootClassName="hide_form_label"

                rules={[
                  {
                    required: true,
                    message: "Please select authorized",
                  },
                ]}
              >
                <Radio.Group defaultValue="No">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
              type="primary"
              htmlType="submit"
              loading={postLoading || updateLoading}
            >
              {type === "EDIT" ? "Update Now" : "Create Now"}
            </Button>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
}
