import {
  Button,
  Form,
  FormProps,
  Image,
  Input,
  Select,
  Space,
  Tabs,
  Tag,
} from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import BsrmAttachment from "../../../assets/icons/BsrmAttachment";
import BsrmBackArrow from "../../../assets/icons/BsrmBackArrow";
import BsrmDeleteIconTwo from "../../../assets/icons/BsrmDeleteIconTwo";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import BsrmTagDeleteIcon from "../../../assets/icons/BsrmTagDeleteIcon";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import FileUpload from "../../../components/common/FileUpload";
import IconButton from "../../../components/common/IconButton";
import Loader from "../../../components/common/Loader";
import { formats } from "../../../components/Editor/Formats";
import { modules } from "../../../components/Editor/Modules";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  ArrayOption,
  createHash,
  removeHash,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBrandAsync } from "../../../store/features/Brand/BrandApi";
import { getAllProductCategoryAsync } from "../../../store/features/productCategory/productCategoryAPI";
import {
  createRetailerProductAsync,
  getRetailerProductSKUAsync,
  getSingleRetailerProductAsync,
  updateRetailerProductAsync,
} from "../../../store/features/retailerProduct/retailerProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomModalProps } from "../../../types";
import { stringToArray } from "../../../utils/array";
import { sliceString } from "../../../utils/string";
import { formatDate } from "../../../utils/time";
import Advance from "./Options/Advance";
import General from "./Options/General";
import Inventory from "./Options/Inventory";
import Shipping from "./Options/Shipping";

// type TabType = "GENERAL" | "INVENTORY" | "SHIPPING" | "ADVANCE";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function CreateProductForm({ type, data, ...rest }: Props) {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const { id, viewId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState("general");
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingGallery, setUploadingGallery] = useState(false);
  const [deliveryDuration, setDeliveryDuration] = useState<"Yes" | "No">("No");
  const [productTagList, setProductTagList] = useState<string[]>([]);
  const [imageName, setImageName] = useState("Upload Image");
  const [galleryName, setGalleryName] = useState("Upload Image");
  const [gallery, setGallery] = useState<string[]>([]);
  const [retailerRequestStatus, setRetailerRequestStatus] = useState("");
  const [singleTag, setSingleTag] = useState("");

  const { login } = useAppSelector((state) => state.login);
  const { brand, loading: brandLoading } = useAppSelector(
    (state) => state.brand
  );

  const { productAllCategory, loading: categoryLoading } = useAppSelector(
    (state) => state.productCategory
  );
  const {
    singleLoading,
    singleProduct,
    updateLoading,
    retailerProductSkuData,
    loading,
    postLoading,
  } = useAppSelector((state) => state.retailerProduct);

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const {
      schedule,
      shortDescription,
      longDescription,
      galleryImage,
      singleTag,
      regularPrice,
      salesPrice,
      ...rest
    } = values;
    const newValues = {
      ...rest,
      schedule: formatDate(schedule?.format(), "YYYY-MM-DD"),
      shortDescription: createHash(shortDescription),
      longDescription: createHash(longDescription),
      deliveryDuration,
      retailerId: login?._id,
      tag: productTagList,
      gallery,
      retailerRequestStatus,
      regularPrice: Number(regularPrice),
      salesPrice: Number(salesPrice),
      discountAmount: parseInt((regularPrice - salesPrice).toString()),
    };

    if (id) {
      dispatch(
        updateRetailerProductAsync({
          data: newValues,
          others: {
            reRender,
            id: id as string,
          },
        })
      );
    } else {
      dispatch(
        createRetailerProductAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  function reRender(val: boolean) {
    if (val) {
      navigate("/retailer/manage-products/pending-products");
    }
  }

  const handleDeliveryDuration = (val: boolean) => {
    setDeliveryDuration(val ? "Yes" : "No");
  };
  // dispatch Category Data
  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
          isAllData: true,
          categoryType: "Product",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch]);

  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleRetailerProductAsync({ params: { id } }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if ((id || viewId) && singleProduct?._id && updateLoading === false) {
      const fileNameArr = stringToArray(singleProduct?.image, ".");
      setImageName(
        singleProduct?.image
          ? `${sliceString(singleProduct?.image, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Image"
      );

      const fileNameGallery = stringToArray(singleProduct?.gallery?.[0], ".");
      setGalleryName(
        singleProduct?.gallery?.[0]
          ? `${sliceString(singleProduct?.gallery?.[0], 15, "...")}.${
              fileNameGallery?.[fileNameGallery?.length - 1]
            }`
          : "Upload Image"
      );

      setProductTagList(singleProduct?.tag);
      setGallery(singleProduct?.gallery);
      setDeliveryDuration(singleProduct?.deliveryDuration);

      form.setFieldsValue({
        name: singleProduct?.name,
        longDescription: removeHash(singleProduct?.longDescription),
        regularPrice: singleProduct?.regularPrice,
        salesPrice: singleProduct?.salesPrice,
        discountPercentage: singleProduct?.discountPercentage || 0,
        discountAmount: singleProduct?.discountAmount,
        schedule: moment(singleProduct?.schedule),
        shortDescription: removeHash(singleProduct?.shortDescription),
        sku: singleProduct?.sku,
        stockQuantity: singleProduct?.stockQuantity,
        unit: singleProduct?.unit,
        weight: singleProduct?.weight,
        length: singleProduct?.length,
        width: singleProduct?.width,
        height: singleProduct?.height,
        shippingDeliveryNote: singleProduct?.shippingDeliveryNote,
        categoryId: singleProduct?.categoryId?._id,
        brandId: singleProduct?.brandId?._id,
        image: singleProduct?.image,
        galleryImage: singleProduct?.gallery?.[0] || undefined,
        retailerId: singleProduct?.retailerId,
        retailerRequestStatus: singleProduct?.retailerRequestStatus,
      });
    }
  }, [
    form,
    id,
    singleProduct?._id,
    singleProduct?.brandId?._id,
    singleProduct?.categoryId?._id,
    singleProduct?.deliveryDuration,
    singleProduct?.discountAmount,
    singleProduct?.discountPercentage,
    singleProduct?.gallery,
    singleProduct?.height,
    singleProduct?.image,
    singleProduct?.length,
    singleProduct?.longDescription,
    singleProduct?.name,
    singleProduct?.regularPrice,
    singleProduct?.retailerId,
    singleProduct?.retailerRequestStatus,
    singleProduct?.salesPrice,
    singleProduct?.schedule,
    singleProduct?.shippingDeliveryNote,
    singleProduct?.shortDescription,
    singleProduct?.sku,
    singleProduct?.stockQuantity,
    singleProduct?.tag,
    singleProduct?.unit,
    singleProduct?.weight,
    singleProduct?.width,
    updateLoading,
    viewId,
  ]);

  const handleRemoveImage = () => {
    setImageName("Upload Image");
    form.setFieldsValue({ image: "" });
  };

  const handleTag = (tag: string) => {
    form.setFieldsValue({
      singleTag: "",
    });
    setProductTagList((prev) => [...prev, tag]);
  };

  const handleRemoveTag = (val: string) => {
    const tags = productTagList?.filter((el) => el !== val);
    setProductTagList(tags);
  };

  const handleGalleryImage = (url: string) => {
    setGallery((prev) => [...prev, url]);
  };

  const handleRemoveGallery = (val: string) => {
    const tags = gallery?.filter((el) => el !== val);
    setGallery(tags);
  };

  useEffect(() => {
    if (retailerProductSkuData && !id && !viewId && !singleProduct?._id) {
      form.setFieldsValue({
        sku: retailerProductSkuData,
      });
    }
  }, [form, id, retailerProductSkuData, singleProduct?._id, viewId]);

  useEffect(() => {
    if (!id && !viewId && !singleProduct?._id)
      dispatch(getRetailerProductSKUAsync(null));
  }, [dispatch, id, singleProduct?._id, viewId]);

  return (
    <Form
      className="custom-form custom-placeholder-form hide_star_mark"
      layout="vertical"
      onFinish={onFinish}
      size="large"
      form={form}
    >
      {singleLoading || brandLoading || categoryLoading || loading ? (
        <Loader />
      ) : (
        <AdminLayout
          title={`${id ? "Update" : viewId ? "View" : "Add"} Product`}
          headerTitle={
            <Link
              to="/retailer/manage-products/pending-products"
              className="flex items-center gap-5"
            >
              <div className="w-10 h-10 border border-solid border-[#D7DFF9] rounded-full flex justify-center items-center ">
                <BsrmBackArrow />
              </div>
              <h3 className="text-body text-[24px]">
                {id ? "Update" : viewId ? "View" : "Add"} Product
              </h3>
            </Link>
          }
          headerChildren={
            !viewId &&
            !singleLoading && (
              <Space size="middle">
                {id &&
                singleProduct?.retailerRequestStatus &&
                singleProduct?.retailerRequestStatus !== "Save as Draft" ? (
                  <IconButton
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      setRetailerRequestStatus(
                        singleProduct?.retailerRequestStatus
                      )
                    }
                  >
                    Update
                  </IconButton>
                ) : (
                  <>
                    <Button
                      htmlType="submit"
                      onClick={() => setRetailerRequestStatus("Save as Draft")}
                      className="font-semibold save__as__button"
                      // loading={
                      //   retailerRequestStatus === "Save as Draft" &&
                      //   (postLoading || updateLoading)
                      // }
                      disabled={postLoading || updateLoading}
                    >
                      <div className="text-primary-admin -mt-0.5">
                        Save as Draft
                      </div>
                    </Button>

                    <IconButton
                      className="text-[14px]"
                      type="primary"
                      htmlType="submit"
                      onClick={() => setRetailerRequestStatus("Pending")}
                      loading={
                        retailerRequestStatus === "Pending" &&
                        (postLoading || updateLoading)
                      }
                      disabled={postLoading || updateLoading}
                    >
                      Send to Publish
                    </IconButton>
                  </>
                )}
              </Space>
            )
          }
        >
          <section className="flex gap-6 ">
            <div className="space-y-3 w-[712px] h-full">
              <div className="p-6  bg-white border border-solid border-[#E9EFF6] rounded-[14px]">
                <div>
                  <Form.Item
                    name="name"
                    label="Product Name"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Please enter name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Name"
                      disabled={viewId ? true : false}
                    />
                  </Form.Item>

                  <Form.Item
                    name="longDescription"
                    label="Product Description"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Please enter long Description",
                      },
                    ]}
                  >
                    <ReactQuill
                      className="h-[370px]"
                      theme="snow"
                      formats={formats}
                      modules={modules}
                      readOnly={viewId ? true : false}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="h-full bg-white rounded-md gap-[5px] p-6 border border-solid border-others-border">
                <Tabs activeKey={tab} onChange={(val) => setTab(val || "")}>
                  <TabPane tab="General" key="general">
                    <General viewId={viewId} type={type} form={form} />
                  </TabPane>
                  <TabPane tab="Inventory" key="inventory">
                    <Inventory viewId={viewId} />
                  </TabPane>
                  <TabPane tab="Shipping" key="shipping">
                    <Shipping
                      deliveryDuration={deliveryDuration}
                      handleDeliveryDuration={handleDeliveryDuration}
                      viewId={viewId}
                    />
                  </TabPane>
                  <TabPane tab="Advance" key="advance">
                    <Advance viewId={viewId} />
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <div className="space-y-3 w-[344px]">
              <div className="p-4  bg-white border border-solid border-[#E9EFF6] rounded-[14px] space-y-4">
                <Form.Item
                  name="categoryId"
                  label="Product Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Category"
                    rootClassName="select_full_option"
                    size="large"
                    allowClear
                    showSearch
                    filterOption={selectSearchOption}
                    options={ArrayOption(productAllCategory, "name", "_id")}
                    disabled={viewId ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  name="brandId"
                  label="Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please select brand name",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Brand"
                    rootClassName="select_full_option"
                    size="large"
                    allowClear
                    showSearch
                    filterOption={selectSearchOption}
                    options={ArrayOption(brand, "name", "_id")}
                    disabled={viewId ? true : false}
                  />
                </Form.Item>
              </div>

              <div className="p-4 bg-white border border-solid border-[#E9EFF6] rounded-[14px] ">
                <Form.Item
                  className="custom__image__input__placeholder"
                  name="image"
                  label="Set Product Image"
                  htmlFor="..."
                  extra={"Upload the image carefully | PNG"}
                  valuePropName="fileUrl"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a image",
                    },
                  ]}
                >
                  <FileUpload
                    errorMessage="Product image upload fail"
                    handleLoading={(val) => setUploadingImage(val)}
                    handleName={(val) => setImageName(val || "Upload Image")}
                    onChange={(url) => form.setFieldsValue({ image: url })}
                    disabled={viewId ? true : false}
                  >
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      disabled={viewId ? true : false}
                    >
                      <div className="flex justify-between">
                        <span className="flex gap-3 items-center">
                          <BsrmTableHeader />
                          {uploadingImage ? "Uploading..." : imageName}
                        </span>
                        <BsrmAttachment />
                      </div>
                    </Button>
                  </FileUpload>
                </Form.Item>

                <div className="flex justify-start items-center">
                  <Image
                    width={180}
                    height={180}
                    preview={false}
                    alt={APP_NAME}
                    src={
                      form.getFieldValue("image")
                        ? `${IMAGE_URL}${form.getFieldValue("image")}`
                        : placeholderImage
                    }
                  />
                </div>
                <br />
                <p>Click the image to edit and update</p>
                {!viewId && (
                  <button
                    className="text-[#FF3838] font-Roboto underline cursor-pointer select-none border-transparent bg-transparent leading-6 font-semibold text-[14px]"
                    onClick={handleRemoveImage}
                    // disabled={viewId ? true : false}
                  >
                    Remove
                  </button>
                )}
              </div>

              <div className="p-4  bg-white border border-solid border-[#E9EFF6] rounded-[14px]">
                <Form.Item
                  className="custom__image__input__placeholder"
                  name="galleryImage"
                  label="Product Gallery"
                  htmlFor="..."
                  extra={"Upload the image carefully | PNG"}
                  valuePropName="fileUrl"
                  rules={[
                    {
                      required: true,
                      message: "Please upload image",
                    },
                    // {
                    //   validator: async (_: any, value: string) => {
                    //     if (!value) {
                    //       return Promise.resolve();
                    //     }

                    //     if (gallery?.length >= 6) {
                    //       return Promise.reject(
                    //         new Error("Maximum 6 images can be upload")
                    //       );
                    //     } else {
                    //       return Promise.resolve();
                    //     }
                    //   },
                    // },
                  ]}
                >
                  <FileUpload
                    errorMessage="Product image upload fail"
                    disabled={gallery?.length >= 6 || viewId ? true : false}
                    handleLoading={(val) => setUploadingGallery(val)}
                    handleName={(val) => setGalleryName(val || "Upload Image")}
                    onChange={(url) => {
                      form.setFieldsValue({ galleryImage: url });
                      handleGalleryImage(url as string);
                    }}
                  >
                    <Button
                      size="large"
                      disabled={gallery?.length >= 6 || viewId ? true : false}
                      style={{ width: "100%" }}
                    >
                      <div className="flex justify-between">
                        <span className="flex gap-3 items-center">
                          <BsrmTableHeader />
                          {uploadingGallery ? "Uploading..." : galleryName}
                        </span>
                        <BsrmAttachment />
                      </div>
                    </Button>
                  </FileUpload>
                </Form.Item>

                <div className="grid grid-cols-3 gap-x-[10px] gap-y-4">
                  {gallery?.map((el, idx) => (
                    <div className="relative" key={el + idx}>
                      {!viewId && (
                        <div
                          className="z-10 select-none absolute right-0 -top-[7px] cursor-pointer"
                          onClick={() => handleRemoveGallery(el)}
                        >
                          <BsrmDeleteIconTwo />
                        </div>
                      )}
                      <Image
                        width={90}
                        height={90}
                        preview={false}
                        alt={APP_NAME}
                        src={`${IMAGE_URL}${el}`}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-4  bg-white border border-solid border-[#E9EFF6] rounded-[14px]">
                <div className="flex items-center justify-between">
                  <Form.Item
                    name="singleTag"
                    label="Product Tag"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter product keyword",
                      // },
                      {
                        validator: async (_: any, value: string) => {
                          // if (!value) {
                          //   return Promise.resolve();
                          // }

                          if (productTagList?.find((el) => el === value)) {
                            return Promise.reject(
                              new Error("Product tag must be unique")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter tag name"
                      onChange={(e) => setSingleTag(e.target.value)}
                      disabled={viewId ? true : false}
                    />
                  </Form.Item>

                  <span className="mt-4">
                    <IconButton
                      onClick={() => handleTag(singleTag)}
                      disabled={
                        productTagList?.find((el) => el === singleTag) ||
                        viewId ||
                        !singleTag
                          ? true
                          : false
                      }
                    >
                      Add
                    </IconButton>
                  </span>
                </div>

                <div className="grid grid-cols-2">
                  {productTagList?.map((el, idx) => (
                    <Tag
                      bordered={false}
                      // closable
                      className="flex items-center gap-2.5 bg-white"
                      key={el + idx}
                    >
                      {!viewId && (
                        <span
                          className="cursor-pointer select-none"
                          onClick={() => handleRemoveTag(el)}
                        >
                          <BsrmTagDeleteIcon />
                        </span>
                      )}
                      {el}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </AdminLayout>
      )}
    </Form>
  );
}
