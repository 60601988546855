import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmDelete from "../../../assets/icons/BsrmDelete";
import BsrmDOT from "../../../assets/icons/BsrmDOT";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmGreenCheck from "../../../assets/icons/BsrmGreenCheck";
import BsrmStockOut from "../../../assets/icons/BsrmStockOut";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import placeholderImage from "../../../assets/images/placeholder-image.png";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBrandAsync } from "../../../store/features/Brand/BrandApi";
import { getAllProductCategoryAsync } from "../../../store/features/productCategory/productCategoryAPI";
import {
  deleteRetailerProductAsync,
  getAllRetailerProductAsync,
} from "../../../store/features/retailerProduct/retailerProductAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import {
  GetRetailerProductData,
  GetSingleRetailerProduct,
} from "../../../types/redux/ADMIN/retailerProduct";
import CreateProductForm from "./CreateProductForm";
export default function AddNewProducts() {
  const dispatch = useAppDispatch();

  const { limit, page, handlePagination, handleLimit } = useTable();
  const { open, toggle, handleOpen } = useModal();
  const [selectBrand, setSelectBrand] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectStatus, setSelectStatus] = useState("Pending");
  const { loading, countRetailerProduct, retailerProduct, deleteLoading } =
    useAppSelector((state) => state.retailerProduct);
  const { brand } = useAppSelector((state) => state.brand);
  const { productCategory } = useAppSelector((state) => state.productCategory);

  const columns: ColumnsType<TableData<GetRetailerProductData>> = [
    {
      title: "No.",
      align: "center",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      align: "center",
      dataIndex: "image",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="horizontal">
          <Image
            width={70}
            height={70}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={placeholderImage}
          />
        </Space>
      ),
    },
    {
      title: "Product Title",
      align: "left",
      render: (val: GetSingleRetailerProduct) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.name}</p>
          <p className="data_table_sku_data">{val?.sku}</p>
        </Space>
      ),
    },

    {
      title: "Category",
      align: "left",
      render: (val) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <div className="flex items-center gap-1">
            <BsrmDOT />
            <h5>{val?.categoryId?.name}</h5>
          </div>
        </Space>
      ),
    },

    {
      title: "Brand",
      align: "left",
      render: (val) => val?.brandId?.name,
    },

    {
      title: "Total Unit",
      align: "left",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">
            {val?.weight} {val?.unit}
          </p>

          <p className="text-[#FF3838] flex items-center">
            {typeof val?.stockQuantity === "number" &&
            val?.stockQuantity <= 0 ? (
              <>
                <BsrmStockOut /> Sold Out
              </>
            ) : (
              <>
                <BsrmGreenCheck />
                In Stock
              </>
            )}
          </p>
        </Space>
      ),
    },

    {
      title: "Price",
      align: "left",
      render: (val: GetRetailerProductData) => (
        <Space className="table_column_title " size={0} direction="vertical">
          <p className="data_table_primary_data">{val?.regularPrice} BDT</p>
          <span className="data_table_price_discount_data">
            {val?.discountPercentage}% Off
          </span>
        </Space>
      ),
    },

    {
      title: "Status",
      align: "center",
      // dataIndex: "status",
      render: (val: GetRetailerProductData) => (
        <span
          className={`status ${
            val?.retailerRequestStatus === "Approved"
              ? "status-done"
              : "status-pending"
          }`}
        >
          {val?.retailerRequestStatus}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val) => (
        <div className="flex justify-center gap-2">
          <Link
            to={`/retailer/manage-products/pending-products/details/${val?._id}`}
          >
            <Button
              className="bg-white py-[6px]"
              icon={<BsrmEyeIcon />}
              onClick={() =>
                handleOpen({
                  type: "VIEW",
                  data: val,
                })
              }
            />
          </Link>

          <Link
            to={`/retailer/manage-products/pending-products/update-products/${val?._id}`}
          >
            <Button className="bg-white py-[6px]" icon={<BsrmEdit />} />
          </Link>
          {val?.retailerRequestStatus === "Save as Draft" ||
          val?.retailerRequestStatus === "Reject" ? (
            <Button
              className="bg-white  py-[6px]"
              icon={<BsrmDelete />}
              onClick={() =>
                handleOpen({
                  type: "DELETE",
                  data: val,
                })
              }
            />
          ) : null}

          {/* Druft hole delete thakbe na hole thakbe na  */}
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteRetailerProductAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllRetailerProductAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: "",
          brandId: selectBrand,
          categoryId: selectCategory,
          retailerRequestStatus: selectStatus,
        },
      })
    );
  }, [dispatch, limit, page, selectBrand, selectCategory, selectStatus]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  // dispatch category data
  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: "true",
          parentCategoryId: "",
        },
      })
    );
  }, [dispatch, limit, page]);

  // dispatch Brand Data
  useEffect(() => {
    dispatch(
      getAllBrandAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "true",
        },
      })
    );
  }, [dispatch]);

  const allRetailerRequestStatus = [
    { label: "Approved", value: "Approved" },
    { label: "Pending", value: "Pending" },
    { label: "Save as Draft", value: "Save as Draft" },
    { label: "Reject", value: "Reject" },
  ];

  return (
    <AdminLayout
      title="Products" // browser title
      headerTitle="Add New Product" // page header
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <Link to="/retailer/manage-products/pending-products/create-product">
            <IconButton nextIcon={<BsrmAddIcon />}>Create New</IconButton>
          </Link>
        </Space>
      }
    >
      <section className="">
        <CustomTable<TableData<GetRetailerProductData>>
          loading={loading}
          total={countRetailerProduct}
          page={page}
          limit={limit}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(retailerProduct)}
          tableTopChildren={
            <Space size="small" className="filtering__select__option">
              <Select
                placeholder="Filter By Category"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(productCategory, "name", "_id")}
                onChange={setSelectCategory}
              />
              <Select
                placeholder="Filter By Brand"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={ArrayOption(brand, "name", "_id")}
                onChange={setSelectBrand}
              />
              <Select
                value={selectStatus}
                placeholder="Status"
                className="filter_select_width"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={allRetailerRequestStatus}
                onChange={setSelectStatus}
              />
            </Space>
          }
        />
      </section>

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <CreateProductForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          questionText="Are you sure to delete product ?"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
