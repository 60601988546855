import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  ExpertCategoryData,
  GetAllParamsExpertCategoryData,
  GetCommonData,
  GetExpertCategoryData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";

// create a expert category
export const createExpertCategoryAsync = createAsyncThunk<
  null,
  PostRequest<ExpertCategoryData, CommonOthers>
>(
  "admin-expertCategory/createExpertCategory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageExpertCategory, data);

      reRender(true);
      toast("success", "Expert category create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Expert category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all expert category
export const getAllExpertCategoryAsync = createAsyncThunk<
  GetCommonData<GetExpertCategoryData>,
  Params<GetAllParamsExpertCategoryData>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-expertCategory/getAllExpertCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.manageExpertCategory}/data`, {
        params,
      });

      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get expert category fail");
      return rejectWithValue(error);
    }
  }
);

// update a expert category
export const updateExpertCategoryAsync = createAsyncThunk<
  null,
  PostRequest<ExpertCategoryData, SingleDataParams>
>(
  "admin-expertCategory/updateExpertCategory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageExpertCategory}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Expert category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Expert category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a expert category
export const deleteExpertCategoryAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>(
  "admin-expertCategory/deleteExpertCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageExpertCategory}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Expert category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Expert category delete fail");
      return rejectWithValue(error);
    }
  }
);
