import { Button, Form, FormProps, Input } from "antd";
import { ReactNode, useEffect, useState } from "react";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  createBankAsync,
  updateBankAsync,
} from "../../store/features/Bank/BankApi";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { BankData } from "../../types/redux/ADMIN/bank";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
  data: any;
  children?: ReactNode;
} & CustomModalProps;

export default function BankListCreateForm({
  children,
  reRender,
  data,
  type,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [logoName, setLogoName] = useState("Upload Logo");
  const [uploadingLogo, setUploadingLogo] = useState(false);

  const { postLoading, updateLoading, bankList } = useAppSelector(
    (state) => state.bank
  );

  const bankData = data as BankData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && bankData?._id) {
      dispatch(
        updateBankAsync({
          data: values,
          others: {
            reRender,
            id: bankData?._id,
          },
        })
      );
    } else {
      dispatch(
        createBankAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  //   add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      bankData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(bankData?.logo, ".");
      setLogoName(
        bankData?.logo
          ? `${sliceString(bankData?.logo, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Image"
      );

      form.setFieldsValue({
        fullName: bankData?.fullName,
        shortName: bankData?.shortName,
        websiteLink: bankData?.websiteLink,
        logo: bankData?.logo,
      });
    }
  }, [
    bankData?._id,
    bankData?.fullName,
    bankData?.logo,
    bankData?.shortName,
    bankData?.websiteLink,
    form,
    type,
    updateLoading,
  ]);

  return (
    <CustomModal width={370} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="fullName"
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please enter Bank name",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Title"
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            name="shortName"
            label="Bank Name (Short)"
            rules={[
              {
                required: true,
                message: "Please enter Bank short name",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="short name"
              disabled={type === "VIEW"}
            />
          </Form.Item>

          <Form.Item
            className="custom__image__input__placeholder"
            name="logo"
            label="Select Logo"
            htmlFor="..."
            extra="File type: PNG, JPG || Max size 5MB"
            valuePropName="fileUrl"
            rules={[
              {
                required: true,
                message: "Please upload a bank logo",
              },
            ]}
          >
            <FileUpload
              errorMessage="Logo upload fail"
              disabled={type === "VIEW"}
              handleLoading={(val) => setUploadingLogo(val)}
              handleName={(val) => setLogoName(val || "Upload Logo")}
              onChange={(url) => form.setFieldsValue({ image: url })}
            >
              <Button
                size="large"
                disabled={type === "VIEW"}
                style={{ width: "100%" }}
              >
                <div className="flex justify-between">
                  <span className="flex gap-3 items-center">
                    <BsrmTableHeader />
                    {uploadingLogo ? "Uploading..." : logoName}
                  </span>
                  <BsrmAttachment />
                </div>
              </Button>
            </FileUpload>
          </Form.Item>
          {(form.getFieldValue("logo") && type === "EDIT") ||
          type === "VIEW" ? (
            <div className="">
              <Image
                width={241}
                height={150}
                src={`${IMAGE_URL}${form.getFieldValue("logo")}`}
                alt={APP_NAME}
                rootClassName="text-center"
              />
            </div>
          ) : null}

          <Form.Item
            name="websiteLink"
            label="Bank Website Link"
            extra="Input the bank website link here"
          >
            <Input
              addonBefore="http://"
              placeholder="website link"
              disabled={type === "VIEW"}
            />
          </Form.Item>
          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
