import { createSlice } from "@reduxjs/toolkit";
import {
  SlideData,
  SlideInitialStatus,
} from "../../../types/redux/ADMIN/appSlide";
import {
  createAppSlideAsync,
  deleteAppSlideAsync,
  getAllAppSlideAsync,
  updateAppSlideAsync,
} from "./SlideApi";

const initialState: SlideInitialStatus<SlideData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  singleLoading: false,
  slide: [],
  countSlide: 0,
};

const appSlideSlice = createSlice({
  name: "admin-app-slide",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create brand
    builder
      .addCase(createAppSlideAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createAppSlideAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createAppSlideAsync.rejected, (state) => {
        state.postLoading = false;
      });

    //get all data
    builder
      .addCase(getAllAppSlideAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAppSlideAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.slide = action.payload.data;
        state.countSlide = action.payload.length;
      })
      .addCase(getAllAppSlideAsync.rejected, (state) => {
        state.loading = false;
        state.slide = [];
        state.countSlide = 0;
      });

    // update expert
    builder
      .addCase(updateAppSlideAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAppSlideAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAppSlideAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // delete expert
    builder
      .addCase(deleteAppSlideAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteAppSlideAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteAppSlideAsync.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default appSlideSlice;
