import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
  SingleDataParams,
} from "../../../types/redux";

import {
  BlogPostData,
  GetAllBlogPostParams,
  GetBlogPostData,
} from "../../../types/redux/ADMIN/blogPost";

// create a product
export const createBlogPostAsync = createAsyncThunk<
  null,
  PostRequest<BlogPostData, CommonOthers>
>(
  "admin-blogPost/createBlogPost",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageBlog, data);
      reRender(true);
      toast("success", "Product Blog create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Blog create fail");
      return rejectWithValue(error);
    }
  }
);

// get all product
export const getAllBlogPostAsync = createAsyncThunk<
  GetCommonData<GetBlogPostData>,
  Params<GetAllBlogPostParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-blogPost/getAllBlogPost", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageBlog}/data`, {
      params,
    });
    return {
      data: data.data?.blogs,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get blog fail");
    return rejectWithValue(error);
  }
});

// update a blog
export const updateBlogPostAsync = createAsyncThunk<
  null,
  PostRequest<BlogPostData, SingleDataParams>
>(
  "admin-blogPost/updateBlogPost",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageBlog}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Blog update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Blog update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a product
export const deleteBlogPostAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>("admin-blogPost/deleteBlogPost", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.manageBlog}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Blog delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Blog delete fail");
    return rejectWithValue(error);
  }
});
