import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  ExpertData,
  GetCommonData,
  GetExpertData,
  GetExpertParams,
  Params,
  PostRequest,
  SingleDataParams,
  SingleExpertData,
} from "../../../types/redux";

// create a expert
export const createExpertAsync = createAsyncThunk<
  null,
  PostRequest<ExpertData, CommonOthers>
>(
  "admin-expert/createExpert",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageExpert, data);

      reRender(true);
      toast("success", "Expert create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Expert create fail");
      return rejectWithValue(error);
    }
  }
);

// get all expert
export const getAllExpertAsync = createAsyncThunk<
  GetCommonData<GetExpertData>,
  Params<GetExpertParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-expert/getAllExpert", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageExpert}/data`, {
      params,
    });

    return {
      data: data.data?.experts,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get expert fail");
    return rejectWithValue(error);
  }
});

// update a expert
export const updateExpertAsync = createAsyncThunk<
  null,
  PostRequest<ExpertData, SingleDataParams>
>(
  "admin-expert/updateExpert",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageExpert}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Expert update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Expert update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a expert
export const deleteExpertAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>("admin-expert/deleteExpert", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.manageExpert}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Expert delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Expert delete fail");
    return rejectWithValue(error);
  }
});

// single api
export const getSingleExpertAsync = createAsyncThunk<
  SingleExpertData,
  Params<SingleDataParams>
>("admin-expert/singleExpert", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.manageExpert}/${params?.id}`);
    return data.data?.expert as SingleExpertData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single scratch type get fail");
    return rejectWithValue(error);
  }
});
